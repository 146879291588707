import { type SagaReturnType, debounce, put, cancel } from 'redux-saga/effects';

import * as actions from '../actions';

export const config = {
  action: [actions.setFilterTags.type, actions.setFilterType.type],
  method: debounce.bind(null, 50),
};

export function* func(
  action: SagaReturnType<
    typeof actions.setFilterTags | typeof actions.setFilterType
  >,
) {
  const { payload } = action;
  if (!payload.triggerReload) {
    yield cancel();
    return;
  }

  yield put(actions.loadNextPage(true));
}
