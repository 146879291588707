import { memo, useCallback } from 'react';
import { useRoute } from 'navigation/hooks';
import { Box, Typography } from '@mui/joy';

import EmptyText from 'ui/EmptyText';

import { useDispatcher } from 'store/utils/redux/hooks';
import Button from 'components/Button';

import useLibraryState from '../model/useLibraryState';
import useSearchState from '../model/useSearchState';

type StateMessageProps = {
  isHide?: boolean;
};

const StateMessage = (props: StateMessageProps) => {
  const { isHide } = props;
  const route = useRoute<'Library'>();

  const dispatcher = useDispatcher();

  const libraryState = useLibraryState();
  const searchState = useSearchState();

  const handlePlusOpen = useCallback(() => {
    dispatcher.plus.open();
  }, []);

  if (isHide) {
    return null;
  }

  const { interaction } = route.params;

  if (interaction === 'all' && libraryState === 'nothing-to-show') {
    return (
      <EmptyText title="No results" contentProps={{ sx: { maxWidth: '17em' } }}>
        You have not added anything yet, please use &quot;+&quot; to drop your
        first link
      </EmptyText>
    );
  }

  if (interaction === 'bookmark' && libraryState === 'nothing-to-show') {
    return (
      <EmptyText title="No results" contentProps={{ sx: { maxWidth: '26em' } }}>
        You&apos;ve got no materials in bookmarks, tab the bookmark sign on the
        card you find interesting to add it to your bookmarks
      </EmptyText>
    );
  }

  if (interaction === 'search' && searchState === 'no-results') {
    return (
      <EmptyText title="No results" contentProps={{ sx: { maxWidth: '26em' } }}>
        We didn&apos;t find any materials by this search phrase in your library,
        try to type something else
      </EmptyText>
    );
  }

  if (interaction === 'search' && searchState === 'no-request') {
    return (
      <EmptyText
        title="Search by library"
        contentProps={{ sx: { maxWidth: '26em' } }}
      >
        Enter a search phrase to start the search
      </EmptyText>
    );
  }

  if (interaction === 'search' && searchState === 'nothing-to-search') {
    return (
      <Box
        display="flex"
        flexDirection="column"
        flex={1}
        justifyContent="center"
        alignItems="center"
      >
        <Typography fontSize="1.5rem">Nothing to search</Typography>
        <Typography
          color="neutral"
          fontSize="1rem"
          fontWeight={300}
          display="flex"
          flexDirection="row"
        >
          <Button type="button-icon" variant="text" onPress={handlePlusOpen}>
            <Typography color="primary" fontSize="1rem">
              Add
            </Typography>
          </Button>
          &nbsp;some content to your library
        </Typography>
      </Box>
    );
  }

  return null;
};

export default memo(StateMessage);
