import { memo, useCallback, useEffect, useState } from 'react';
import { type DefaultRootState } from 'react-redux';
import { Box, Typography } from '@mui/joy';

import { useThemeColor } from 'hooks';
import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as plusStore from 'store/nodes/plus';

import CollectionSelectInput from '../elements/CollectionSelectInput';
import Body from '../elements/Body';

import { usePlusContext } from '../../context';

type MaterialsLayoutProps = {
  onCollectionChange?: (value: number | null) => void;
};

const MaterialsLayout = (props: MaterialsLayoutProps) => {
  const { onCollectionChange } = props;
  const { hasNewCollectionButton } = usePlusContext();
  const dispatcher = useDispatcher();
  const color = useThemeColor({ light: '#000000', dark: '#FFFFFF' });

  const data = useSelector(plusStore.selectors.materialsResult);
  const state = useSelector(plusStore.selectors.state);
  const upload = useSelector((s: DefaultRootState) => s.plus.request.upload);

  const [collectionId, setCollectionId] = useState<number | null>(
    (state.props?.toCollection as { id?: number })?.id || null,
  );

  const handleCollectionChange = useCallback(
    (value: number | null) => {
      setCollectionId(value);
      onCollectionChange?.(value);
    },
    [onCollectionChange],
  );

  useEffect(() => {
    if (!collectionId || !data?.uploadSessionId || state.isOpen) {
      return;
    }
    dispatcher.plus.addMaterialsToCollection(
      Number(collectionId),
      data?.uploadSessionId,
    );
  }, [state.isOpen, collectionId, data?.uploadSessionId]);

  const isFiles = upload && upload?.count > 0;
  const isLinks = !isFiles;

  return (
    <Body>
      <Box mb={1.5}>
        <Typography fontSize={12} color="neutral">
          Success
        </Typography>
        {isLinks && (
          <Typography fontSize={16} textColor={color}>
            {`We've successfully parsed ${data?.parsedLinksCount} links, would you like to add them to collection?`}
          </Typography>
        )}
        {isFiles && (
          <Typography fontSize={16} textColor={color}>
            {`We've successfully uploaded ${data?.parsedLinksCount} files, would you like to add them to collection?`}
          </Typography>
        )}
      </Box>
      <Box>
        <Typography fontSize={12} color="neutral">
          Add to collection
        </Typography>
        <CollectionSelectInput
          hasNewCollectionButton={hasNewCollectionButton}
          value={collectionId}
          onChange={handleCollectionChange}
        />
      </Box>
    </Body>
  );
};

export default memo(MaterialsLayout);
