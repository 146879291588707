import {
  type SagaReturnType,
  takeEvery,
  put,
  cancel,
} from 'redux-saga/effects';
import { marked } from 'marked';

import Alert from 'components/Alert';
import { select } from 'store/utils/saga/effects';
import * as noteStore from 'store/nodes/note/slice';
import * as plusStore from 'store/nodes/plus';

import { actions } from '../slice';
import { answerByRequestId } from '../selectors';
import { createItemId } from '../utils';

export const config = {
  action: actions.saveAnswerToNote.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.saveAnswerToNote>) {
  const { requestId } = action.payload;
  const itemId = createItemId({ requestId, type: 'answer' });
  const answer = yield* select(answerByRequestId(itemId));

  if (!answer) {
    yield cancel();
    return;
  }

  yield put(
    noteStore.actions.add({
      html: marked.parse(answer.text) as string,
      ...(typeof answer.resourceId === 'number' && answer.resourceId > 0
        ? {
            relation: {
              resourceType: answer.resourceType,
              resourceId: answer.resourceId,
            },
          }
        : {}),
    }),
  );
  Alert.success('The answer saved to notes');
}
