import { type CSSProperties, useMemo } from 'react';
import { type StyleProp, type ViewStyle, Platform } from 'react-native';

import { useThemeColor } from 'hooks';

import { unit } from 'utils';

export const useActiveOpacity = (
  isLoading: boolean,
  isDisabled: boolean,
): number =>
  useMemo(() => {
    if (isLoading) {
      return 0.8;
    }
    if (isDisabled) {
      return 0.5;
    }
    return 0.8;
  }, [isLoading, isDisabled]);

export const useRegularOpacity = (
  isLoading: boolean,
  isDisabled: boolean,
): number =>
  useMemo(() => {
    if (isLoading) {
      return 0.8;
    }
    if (isDisabled) {
      return 0.5;
    }
    return 1;
  }, [isLoading, isDisabled]);

export const useButtonStyles = (
  type: 'button' | 'button-icon',
  colorConfig: { light?: string; dark?: string },
  radius: 'none' | 'half' | 'full',
  variant: 'contained' | 'outlined' | 'text',
  width?: 'full' | number,
  height?: number,
): StyleProp<ViewStyle> => {
  const color = useThemeColor(colorConfig);

  const heightFinal = useMemo(() => {
    if (type === 'button-icon' && !height) {
      return undefined;
    }
    return height || 40;
  }, [type, height]);

  return useMemo(() => {
    const radiusMap = {
      none: 0,
      half: heightFinal ? (heightFinal / 40) * 8 : undefined,
      full: heightFinal ? heightFinal / 2 : undefined,
    };

    const styles: StyleProp<ViewStyle> = {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      height: heightFinal ? unit(heightFinal) : undefined,
      borderRadius: unit(radiusMap[radius]),
      paddingHorizontal:
        type !== 'button-icon' && heightFinal
          ? unit(heightFinal / 2 - (variant === 'outlined' ? 1 : 0))
          : undefined,
      overflow: 'hidden',
    };

    if (width === 'full') {
      styles.width = '100%';
    } else if (typeof width === 'string') {
      styles.width = width;
    } else if (typeof width === 'number') {
      styles.width = unit(width);
    } else if (Platform.OS === 'web') {
      (styles as CSSProperties).width = 'fit-content';
    } else {
      // need to add native implementation
    }

    if (variant === 'contained') {
      styles.backgroundColor = color;
      styles.borderWidth = 0;
    }
    if (variant === 'outlined') {
      styles.borderWidth = 1;
      styles.borderStyle = 'solid';
      styles.borderColor = color;
    }

    return styles;
  }, [type, colorConfig, radius, variant, width, heightFinal]);
};
