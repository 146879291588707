import { eventChannel } from 'redux-saga';
import { takeEvery, take } from 'redux-saga/effects';

import { navigate } from 'navigation/methods';
import * as api from 'services/api';

import { call } from 'store/utils/saga/effects';

import * as actions from '../actions';

export const config = {
  action: actions.startCredentialListening.type,
  method: takeEvery,
};

function credentialEvents() {
  return eventChannel((emitter) => {
    const refreshTokenExpiredListener = api.credentials.on(
      'REFRESH_TOKEN_EXPIRED',
      () => {
        emitter('ERROR_REFRESH_TOKEN_EXPIRED');
      },
    );
    const updateRequestErrorListener = api.credentials.on(
      'UPDATE_REQUEST_ERROR',
      () => {
        emitter('UPDATE_REQUEST_ERROR');
      },
    );
    return () => {
      refreshTokenExpiredListener.off();
      updateRequestErrorListener.off();
    };
  });
}

export function* func() {
  const chunk = yield* call(() => credentialEvents());
  try {
    while (true) {
      const eventName: string = yield take(chunk);
      if (
        ['ERROR_REFRESH_TOKEN_EXPIRED', 'UPDATE_REQUEST_ERROR'].includes(
          eventName,
        )
      ) {
        yield* call(() => navigate('Auth/Start'));
      }
    }
  } finally {
    console.log('credentialListener terminated');
  }
}
