type WidthValuePair = {
  width: number;
  value: number;
};

const interpolate = (
  lowerPair: WidthValuePair,
  upperPair: WidthValuePair,
  currentWidth: number,
): number => {
  const percentage =
    (currentWidth - lowerPair.width) / (upperPair.width - lowerPair.width);
  return lowerPair.value + (upperPair.value - lowerPair.value) * percentage;
};

const parseSchema = (
  schema?: string,
): { unit: string; pairs: WidthValuePair[] } | undefined => {
  if (!schema) {
    return undefined;
  }

  const [, unit, pairsTemplate] = schema.match(/([a-z%\s]+)\[([^\]]+)]/) || [];

  if (!unit || !pairsTemplate) {
    return undefined;
  }

  const pairs = pairsTemplate
    .split(';')
    .map((pair) => {
      const [width, value] = pair.split('=').map(Number);
      return { width, value };
    })
    .sort((a, b) => a.width - b.width);

  return {
    unit,
    pairs,
  };
};

export default (
  schema?: string,
  currentWindowWidth?: number,
): string | undefined => {
  const parsed = parseSchema(schema);
  if (!schema || !currentWindowWidth || !parsed) {
    return undefined;
  }

  const { unit, pairs } = parsed;

  if (pairs.length === 0) {
    return undefined;
  }

  const minPair = pairs[0];
  const maxPair = pairs[pairs.length - 1];

  if (currentWindowWidth <= minPair.width) {
    return `${minPair.value}${unit}`;
  }
  if (currentWindowWidth >= maxPair.width) {
    return `${maxPair.value}${unit}`;
  }

  const lowerPair = pairs.find(
    (pair, i) =>
      currentWindowWidth >= pair.width &&
      currentWindowWidth < pairs[i + 1].width,
  )!;
  const upperPair = pairs.find((pair) => pair.width > lowerPair.width)!;
  return `${interpolate(lowerPair, upperPair, currentWindowWidth)}${unit}`;
};
