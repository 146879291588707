import { memo, useMemo } from 'react';
import { type StyleProp, type ViewStyle, StyleSheet } from 'react-native';

import type { ImageType } from 'app/entities';

import CompositePoster from 'components/CompositePoster';

type PosterProps = {
  style?: StyleProp<ViewStyle>;
  cover?: ImageType | null;
  contentImages?: ImageType[];
  aspectRatio?: number;
};

const Poster = (props: PosterProps) => {
  const { style, cover, contentImages, aspectRatio } = props;

  const sources = useMemo(() => {
    if (cover) {
      return [cover];
    }
    if (!cover) {
      return contentImages || [];
    }
    return [];
  }, [cover, contentImages]);

  return (
    <CompositePoster
      sources={sources}
      aspectRatio={aspectRatio}
      style={style}
      radius={5}
    />
  );
};

const styles = StyleSheet.create({});

export default memo(Poster);
