import { type CSSProperties, memo, useCallback, useMemo } from 'react';
import {
  type StyleProp,
  type ViewStyle,
  TouchableOpacity,
  StyleSheet,
  Platform,
} from 'react-native';

import { useNavigate, useRoute } from 'navigation/hooks';
import {
  isMaterialScreen,
  isCollectionScreen,
  isProfileScreen,
} from 'navigation/guards';

import { useResponsive } from 'hooks';

import { unit } from 'utils';
import Icon from 'components/LegacyIcon';
import { Text, View, BlurView } from 'components/Themed';

import MaterialActions from './elements/MaterialActions';
import CollectionActions from './elements/CollectionActions';
import ProfileActions from './elements/ProfileActions';

type ContentHeaderProps = {
  style: StyleProp<ViewStyle>;
};

const ContentHeader = (props: ContentHeaderProps) => {
  const { style } = props;
  const route = useRoute();
  const navigate = useNavigate();
  const responsive = useResponsive();

  const handleBack = useCallback(() => {
    // @todo Реализовать универсальную версию в навигаторе
    if (Platform.OS === 'web') {
      if (window.history.length <= 2) {
        navigate('Library', { interaction: 'all' });
      } else {
        window.history.back();
      }
    }
  }, []);

  const { containerStyle, contentStyle } = useMemo((): {
    containerStyle: StyleProp<ViewStyle>;
    contentStyle: StyleProp<ViewStyle>;
  } => {
    const container = StyleSheet.flatten(style);
    const content: StyleProp<ViewStyle> = {};
    const { maxWidth } = container;
    if (maxWidth) {
      delete container.maxWidth;
      content.maxWidth = maxWidth;
    }
    if (Platform.OS === 'web') {
      (container as CSSProperties).position = 'fixed';
    }
    return {
      containerStyle: container,
      contentStyle: content,
    };
  }, [style]);

  return (
    <BlurView
      style={[styles.PageHeader, containerStyle]}
      darkColor="#00000055"
      lightColor="#FFFFFF55"
      factor={8}
    >
      <View style={[styles.content, contentStyle]}>
        <TouchableOpacity
          style={[
            styles.buttonBack,
            responsive.is.tabletL && styles.buttonBackTableL,
          ]}
          onPress={handleBack}
          activeOpacity={0.8}
        >
          <Icon name="ChevronLeft" size={24} />
          <Text size={16} weight="medium" style={styles.backTitle}>
            Back
          </Text>
        </TouchableOpacity>
        <View style={styles.rightPanel}>
          {isMaterialScreen(route) && <MaterialActions />}
          {isCollectionScreen(route) && <CollectionActions />}
          {isProfileScreen(route) && <ProfileActions />}
        </View>
      </View>
    </BlurView>
  );
};

const styles = StyleSheet.create({
  PageHeader: {
    position: 'absolute',
    flexDirection: 'column',
    alignItems: 'center',
    top: 0,
    right: 0,
    left: 0,
    minHeight: unit(64),
  },
  content: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    flex: 1,
  },
  rightPanel: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'flex-end',
    paddingRight: unit(16),
  },
  buttonBack: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: unit(-6),
  },
  buttonBackTableL: {
    marginLeft: unit(10),
  },
  backTitle: {
    marginLeft: unit(4),
  },
});

export default memo(ContentHeader);
