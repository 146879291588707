import {
  memo,
  forwardRef,
  type ForwardRefRenderFunction,
  type ReactNode,
} from 'react';
import { StyleSheet } from 'react-native';
import Modal, { type Direction } from 'react-native-modal';

import { useBreakpoint } from 'hooks';

import { View } from 'components/Themed';

import useMethods from '../utils/useMethods';
import { type Controller } from '../../types';

interface PanelProps {
  children?: ReactNode;
  onShow?: (context: any) => void;
  onHide?: () => void;
  isBlocked?: boolean;
  onContext?: any;
}

const Panel: ForwardRefRenderFunction<Controller, PanelProps> = (
  props,
  forwardedRef,
) => {
  const { onShow, onHide, children, isBlocked = false, onContext } = props;

  const breakpoint = useBreakpoint();

  const { isVisible, handleShow, handleHide, handleCloseQuery } = useMethods(
    forwardedRef,
    onShow,
    undefined,
    onHide,
    undefined,
    onContext,
  );

  const swipeDirection: Direction[] = breakpoint === 'xs' ? ['down'] : [];

  return (
    <Modal
      isVisible={isVisible}
      style={[
        breakpoint === 'xs' && styles.panel,
        breakpoint !== 'xs' && styles.smPanel,
      ]}
      onShow={handleShow}
      onModalHide={handleHide}
      animationInTiming={200}
      animationOutTiming={200}
      onBackdropPress={!isBlocked ? handleCloseQuery : undefined}
      onSwipeComplete={!isBlocked ? handleCloseQuery : undefined}
      swipeDirection={!isBlocked ? swipeDirection : undefined}
      backdropTransitionInTiming={breakpoint !== 'xs' ? 1 : undefined}
      backdropTransitionOutTiming={breakpoint !== 'xs' ? 1 : undefined}
    >
      <View
        style={[
          breakpoint === 'xs' && styles.xsView,
          breakpoint !== 'xs' && styles.smView,
        ]}
        lightColor="#ffffffff"
        darkColor="#000000"
      >
        {children}
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  panel: {
    justifyContent: 'flex-end',
    margin: 0,
  },
  xsView: {
    flex: 1,
  },
  smPanel: {},
  smView: {},
});

export default memo(forwardRef<Controller, PanelProps>(Panel));
