import {
  type ForwardRefRenderFunction,
  type ReactNode,
  type CSSProperties,
  type PropsWithChildren,
  type ReactElement,
  memo,
  forwardRef,
  useMemo,
  useCallback,
} from 'react';
import {
  type PanResponderGestureState,
  type StyleProp,
  type ViewStyle,
  StyleSheet,
  TouchableOpacity,
  Platform,
} from 'react-native';
import Modal, { type Direction } from 'react-native-modal';
import type {
  OrNull,
  GestureResponderEvent,
} from 'react-native-modal/dist/types';

import { useThemeColor, useBreakpoint } from 'hooks';

import { View } from 'components/Themed';
import { unit } from 'utils';
import dialog from 'components/Dialog';

import useMethods from '../utils/useMethods';
import { type Controller } from '../../types';

const defaultProps = {
  layout: 'panel' as 'panel' | 'transparent',
};

type PanelProps = {
  mobile?: {
    toolbar?: ReactNode;
  };
  layout?: 'panel' | 'transparent';
  isBlocked?: boolean;
  isConfirmClosing?: boolean;
  scrollTo?: OrNull<(e: any) => void>;
  scrollOffset?: number;
  scrollOffsetMax?: number;
  propagateSwipe?:
    | boolean
    | ((
        event: GestureResponderEvent,
        gestureState: PanResponderGestureState,
      ) => boolean);
  onShow?: (context: any) => void;
  onBeforeShow?: (context: any) => void;
  onHide?: () => void;
  onBeforeHide?: () => void;
  onContext?: any;
  styles?: {
    xs?: StyleProp<ViewStyle>;
    sm?: StyleProp<ViewStyle>;
  };
} & typeof defaultProps;

const Panel: ForwardRefRenderFunction<
  Controller,
  PropsWithChildren<PanelProps>
> = (props, forwardedRef): ReactElement => {
  const {
    children,
    mobile,
    layout,
    isBlocked,
    isConfirmClosing,
    scrollTo,
    scrollOffset,
    scrollOffsetMax,
    propagateSwipe,
    styles: userStyles,
    onShow,
    onBeforeShow,
    onHide,
    onBeforeHide,
    onContext,
  } = props;

  const breakpoint = useBreakpoint();
  const backdropColor = useThemeColor({ light: '#ffffffff', dark: '#000000' });

  const { isVisible, handleShow, handleHide, handleCloseQuery } = useMethods(
    forwardedRef,
    onShow,
    onBeforeShow,
    onHide,
    onBeforeHide,
    onContext,
  );

  const handleClose = useCallback(() => {
    if (!isConfirmClosing) {
      handleCloseQuery?.();
      return;
    }
    dialog.show(
      {
        title: 'Confirmation',
        text: 'Are you sure want to close this modal?',
      },
      [
        {
          label: 'Back',
          variant: 'outlined',
          lightColor: '#929292',
          darkColor: '#77767E',
          handle: (dialogId: string) => {
            dialog.hide(dialogId);
          },
        },
        {
          label: 'Close',
          variant: 'contained',
          lightColor: '#db3327',
          darkColor: '#db3327',
          textLightColor: '#ffffff',
          textDarkColor: '#ffffff',
          handle: (dialogId: string) => {
            dialog.hide(dialogId);
            handleCloseQuery?.();
          },
        },
      ],
    );
  }, [isConfirmClosing, handleCloseQuery]);

  const stylesFinal = useMemo(() => {
    const result: ViewStyle[] = [];
    if (breakpoint === 'xs') {
      result.push(styles.panel);
    }
    if (breakpoint === 'xs' && layout === 'transparent') {
      result.push(styles.xsPanelTransparent);
    }
    if (breakpoint !== 'xs') {
      result.push(styles.smPanel);
    }
    return result;
  }, [breakpoint, layout]);

  const swipeDirectionFinal = useMemo((): Direction[] => {
    if (breakpoint !== 'xs' || isBlocked) {
      return [];
    }
    return ['down'];
  }, [breakpoint, isBlocked]);

  const animation = useMemo((): Record<
    'in' | 'out',
    {
      method: 'fadeIn' | 'fadeOut' | 'slideInUp' | 'slideOutDown';
      timing: number;
    }
  > => {
    if (breakpoint !== 'xs') {
      return {
        in: {
          method: 'fadeIn',
          timing: 200,
        },
        out: {
          method: 'fadeOut',
          timing: 200,
        },
      };
    }
    return {
      in: {
        method: 'slideInUp',
        timing: 400,
      },
      out: {
        method: 'slideOutDown',
        timing: 300,
      },
    };
  }, [breakpoint]);

  const backdropStyle = useMemo(() => {
    const result: StyleProp<ViewStyle> = {
      ...StyleSheet.flatten(styles.backdropStyle),
      backgroundColor: backdropColor,
    };
    if (Platform.OS === 'web') {
      (result as CSSProperties).position = 'fixed';
    }
    return result;
  }, [backdropColor]);

  return (
    <Modal
      isVisible={isVisible}
      style={stylesFinal}
      onShow={handleShow}
      onModalHide={handleHide}
      onBackdropPress={!isBlocked ? handleClose : undefined}
      backdropOpacity={0.7}
      backdropTransitionInTiming={breakpoint !== 'xs' ? 1 : undefined}
      backdropTransitionOutTiming={breakpoint !== 'xs' ? 1 : undefined}
      customBackdrop={
        <TouchableOpacity
          style={backdropStyle}
          onPress={!isBlocked ? handleClose : undefined}
          activeOpacity={1}
        />
      }
      onSwipeComplete={!isBlocked ? handleCloseQuery : undefined}
      swipeDirection={swipeDirectionFinal}
      scrollTo={scrollTo}
      scrollOffset={scrollOffset}
      scrollOffsetMax={scrollOffsetMax}
      propagateSwipe={propagateSwipe}
      animationIn={animation.in.method}
      animationInTiming={animation.in.timing}
      animationOut={animation.out.method}
      animationOutTiming={animation.out.timing}
    >
      <View
        style={[
          breakpoint === 'xs' && styles.xsView,
          breakpoint === 'xs' &&
            layout === 'transparent' &&
            styles.xsViewTransparent,
          breakpoint === 'xs' &&
            Boolean(mobile?.toolbar) &&
            styles.xsViewWithToolbar,
          breakpoint === 'xs' && userStyles && !!userStyles.xs && userStyles.xs,
          breakpoint !== 'xs' && styles.smView,
          breakpoint !== 'xs' && userStyles && !!userStyles.sm && userStyles.sm,
        ]}
        lightColor={layout === 'panel' ? '#e7e7e7' : undefined}
        darkColor={layout === 'panel' ? '#181818' : undefined}
      >
        {!!mobile?.toolbar && mobile.toolbar}
        {!!children && children}
      </View>
    </Modal>
  );
};

const ForwardedComponent = forwardRef<
  Controller,
  PropsWithChildren<PanelProps>
>(Panel);

ForwardedComponent.defaultProps = defaultProps;

const styles = StyleSheet.create({
  backdropStyle: {
    position: 'absolute',
    backgroundColor: '#000000',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  panel: {
    justifyContent: 'flex-end',
    margin: 0,
    overflow: 'hidden',
  },
  xsPanelTransparent: {},
  xsView: {
    paddingHorizontal: unit(16),
    paddingTop: unit(38),
    paddingBottom: unit(40),
    borderTopLeftRadius: unit(25),
    borderTopRightRadius: unit(25),
  },
  xsViewTransparent: {
    paddingHorizontal: 0,
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: 0,
  },
  xsViewWithToolbar: {
    paddingTop: unit(18),
  },
  smPanel: {
    margin: 0,
  },
  smView: {},
});

export default memo(ForwardedComponent);
