import * as mmkv from 'react-native-mmkv';

export const storage = new mmkv.MMKV();

export const useStorageString = <T = string | undefined>(key: string) =>
  mmkv.useMMKVString(key, storage) as unknown as [T, (value: T) => void];

export const useStorageNumber = (key: string) =>
  mmkv.useMMKVNumber(key, storage);

export const useStorageBoolean = (key: string) =>
  mmkv.useMMKVBoolean(key, storage);

export default storage;
