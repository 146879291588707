import {
  type ForwardedRef,
  type CSSProperties,
  memo,
  useCallback,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {
  type StyleProp,
  type KeyboardTypeOptions,
  type ViewStyle,
  StyleSheet,
  useWindowDimensions,
} from 'react-native';
import InputMask from 'react-input-mask';

import { useResponsive, useThemeColor, useFontProps } from 'hooks';

import { unit } from 'utils';
import { Text, View } from 'components/Themed';

import { createUseStyles } from 'react-jss';
import { useTheme } from '@mui/joy';
import { useField } from '../../utils/context';
import type { TextInputProps as TextInputBaseProps } from './index';

const radiusMap = {
  none: 0,
  half: 10,
  full: 44 / 2,
};

const defaultProps = {
  placeholderColor: '#9a99a2' as string,
  radius: 'half' as 'none' | 'half' | 'full' | number,
  type: 'text' as 'text' | 'password',
  keyboardType: 'default' as KeyboardTypeOptions,
  disabled: false as boolean,
  readonly: false as boolean,
  blocked: false as boolean,
};

type TextInputProps = TextInputBaseProps & typeof defaultProps;

const TextInput = forwardRef(
  (
    props: TextInputProps,
    forwardedRef: ForwardedRef<HTMLInputElement | HTMLTextAreaElement | null>,
  ) => {
    const {
      autoFocus,
      mask,
      name,
      label,
      labelFont,
      placeholder,
      placeholderColor,
      value,
      error,
      errorFont,
      width,
      height,
      radius,
      inputFont,
      type,
      keyboardType,
      disabled,
      readonly,
      blocked,
      StartAdornment,
      EndAdornment,
      onChange,
      onFocus,
      onBlur,
      onEnter,
      multiline,
      style,
      pickerStyle,
      ...viewProps
    } = props;

    const theme = useTheme();
    const outlineColor = useThemeColor({
      light: theme.palette.primary['500'],
      dark: theme.palette.primary['500'],
    });
    const classes = useStyles({
      outlineColor,
    });

    const inputRef = useRef(null);

    const dimensions = useWindowDimensions();
    const responsive = useResponsive();

    const labelFontFinal = useMemo(
      () => ({
        size: labelFont?.size || 15,
        weight: labelFont?.weight || 'regular',
      }),
      [labelFont],
    );

    const errorFontFinal = useMemo(
      () => ({
        size: errorFont?.size || 12,
        weight: errorFont?.weight || 'regular',
      }),
      [errorFont],
    );

    const inputFontParams = useFontProps({
      size: inputFont?.size || 16,
      weight: inputFont?.weight || 'regular',
    });

    const inputFontFinal = useMemo(
      () => ({
        lineHeight: inputFontParams.lineHeight,
        fontSize: inputFontParams.fontSize,
        fontFamily: inputFontParams.fontFamily,
        lightColor: inputFont?.lightColor || '#000000',
        darkColor: inputFont?.darkColor || '#ffffff',
        lightReadonlyColor: inputFont?.lightReadonlyColor || '#929292',
        darkReadonlyColor: inputFont?.darkReadonlyColor || '#77767E',
        lightBackgroundColor: inputFont?.lightBackgroundColor || '#ffffff',
        darkBackgroundColor: inputFont?.darkBackgroundColor || '#000000',
        lightReadonlyBackgroundColor:
          inputFont?.lightReadonlyBackgroundColor || '#d2d2d2',
        darkReadonlyBackgroundColor:
          inputFont?.darkReadonlyBackgroundColor || '#232326',
      }),
      [errorFont, inputFontParams],
    );

    const isBlocked = disabled || readonly;
    const color = useThemeColor({
      light: isBlocked ? '#00000077' : '#000000ff',
      dark: isBlocked ? '#ffffff55' : '#ffffffff',
    });
    const backgroundColor = useThemeColor({
      light: disabled ? '#F9F9F999' : '#ffffff',
      dark: disabled ? '#23232699' : '#232326',
    });

    useEffect(() => {
      const { current: inputObject } = inputRef;
      let ref: HTMLElement | undefined;
      if (
        inputObject &&
        !((inputObject as any) instanceof HTMLElement) &&
        'getInputDOMNode' in inputObject
      ) {
        ref = (inputObject as any).getInputDOMNode?.();
      }
      if (inputObject && (inputObject as any) instanceof HTMLElement) {
        ref = inputObject;
      }
      if (forwardedRef && 'current' in forwardedRef && ref) {
        forwardedRef.current = ref as HTMLInputElement | HTMLTextAreaElement;
      }
    }, [forwardedRef]);

    const field = useField(name);

    const onChangeFinal = onChange || field?.onValueChange;
    const onBlurFinal = onBlur || field?.onBlur;
    const valueFinal = String(value || field?.value || '');
    const errorFinal = error || field?.error;

    const handleKeyDown = useCallback(
      (event: any) => {
        if (event.keyCode === 13) {
          onEnter?.();
        }
      },
      [onEnter],
    );

    const handleChange = useCallback(
      (event: any) => {
        onChangeFinal?.(event.target.value);
      },
      [onChangeFinal],
    );

    const handleBlur = useCallback(
      (event: any) => {
        onBlurFinal?.(event);
      },
      [onBlurFinal],
    );

    const handleFocus = useCallback(
      (event: any) => {
        onFocus?.(event);
      },
      [onFocus],
    );

    const handleKeyPress = useCallback(
      (event: any) => {
        const code = event.charCode || event.keyCode;
        if (code === 13) {
          field?.submitForm?.();
        }
      },
      [field],
    );

    const containerStyleFinal = useMemo(() => {
      const result: StyleProp<ViewStyle> = {
        alignItems: 'stretch',
        height,
        marginTop: field ? dimensions.height * 0.02 : undefined,
        borderRadius: unit(
          typeof radius === 'number' ? radius : radiusMap[radius],
        ),
        ...StyleSheet.flatten(style),
      };
      if (width === 'full') {
        result.width = '100%';
      } else if (typeof width === 'string') {
        result.width = width;
      } else if (typeof width === 'number') {
        result.width = unit(width);
      }
      return result;
    }, [
      width,
      style,
      disabled,
      height,
      dimensions.height,
      responsive.isMoreThen.mobileLarge,
      radius,
    ]);

    const inputStyleFinal = useMemo(
      (): CSSProperties => ({
        ...(StyleSheet.flatten(
          !multiline ? styles.input : styles.textarea,
        ) as CSSProperties),
        borderRadius: unit(
          typeof radius === 'number' ? radius : radiusMap[radius],
        ),
        fontSize: inputFontFinal.fontSize,
        fontFamily: inputFontFinal.fontFamily,
        lineHeight: inputFontFinal.lineHeight,
        color,
        backgroundColor,
        margin: 0,
        ...(StyleSheet.flatten(pickerStyle) as CSSProperties),
        outline: 'none',
        flex: 1,
      }),
      [
        inputFontFinal.fontSize,
        inputFontFinal.fontFamily,
        inputFontFinal.lineHeight,
        pickerStyle,
        color,
        radius,
        backgroundColor,
      ],
    );

    return (
      <View
        style={containerStyleFinal}
        pointerEvents={disabled ? 'none' : 'auto'}
      >
        {Boolean(label) && (
          <Text
            size={labelFontFinal.size}
            weight={labelFontFinal.weight}
            style={styles.itemText}
            lightColor="#797979"
            darkColor="#B8B6BF"
          >
            {label}
          </Text>
        )}
        {!multiline && (
          <InputMask
            ref={inputRef}
            mask={!mask ? '' : mask}
            style={inputStyleFinal}
            autoFocus={autoFocus}
            placeholder={placeholder}
            className={classes.input}
            onChange={!readonly && !blocked ? handleChange : undefined}
            onKeyPress={handleKeyPress}
            value={valueFinal}
            onBlur={handleBlur}
            onFocus={handleFocus}
            type={type}
            readOnly={field?.isLocked}
            disabled={disabled}
            onKeyDown={handleKeyDown}
          />
        )}
        {multiline && (
          <textarea
            ref={inputRef}
            style={inputStyleFinal}
            autoFocus={autoFocus}
            placeholder={placeholder}
            onChange={!readonly && !blocked ? handleChange : undefined}
            value={valueFinal}
            onBlur={handleBlur}
            onFocus={handleFocus}
            readOnly={field?.isLocked}
            disabled={disabled}
            className={classes.input}
          />
        )}
        <View style={styles.itemTextTwo}>
          <Text
            size={errorFontFinal.size}
            weight={errorFontFinal.weight}
            lightColor="#db3327"
            darkColor="#db3327"
          >
            {errorFinal}
            &nbsp;
          </Text>
        </View>
      </View>
    );
  },
);

TextInput.defaultProps = defaultProps;

const useStyles = createUseStyles<'input', { outlineColor?: string }>({
  input: {
    outline: 'none',
    '&:focus': {
      boxShadow: (theme) =>
        !theme?.outlineColor
          ? 'none'
          : `0 0 0 1px ${theme.outlineColor || '#000000'}`,
    },
  },
});

const styles = StyleSheet.create({
  input: {
    paddingTop: unit(8),
    paddingBottom: unit(10),
    paddingLeft: unit(8),
    paddingRight: unit(8),
    lineHeight: unit(26),
    border: 0,
    resize: 'none',
    flex: 1,
  },
  textarea: {
    paddingTop: unit(8),
    paddingBottom: unit(10),
    paddingLeft: unit(8),
    paddingRight: unit(8),
    lineHeight: unit(26),
    minHeight: unit(26 + 44),
    border: 0,
    resize: 'none',
    flex: 1,
  },
  itemText: {
    marginBottom: unit(6),
    marginLeft: unit(6),
  },
  itemTextTwo: {
    position: 'absolute',
    marginLeft: unit(6),
    marginTop: unit(4),
    top: unit(0),
    right: unit(6),
  },
});

export default memo(TextInput);
