import {
  type PropsWithChildren,
  memo,
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { Box, Typography, type BoxProps, type TypographyProps } from '@mui/joy';

type TextExpandProps = Omit<BoxProps, 'position' | 'display'> & {
  lines: number;
  isCollapsed?: boolean;
  slotsProps?: {
    typography?: Omit<TypographyProps, 'position'> & {
      parseHtml?: boolean;
    };
  };
  onCollapsibleChange?: (collapsible: boolean) => void;
};

const TextExpand = (props: PropsWithChildren<TextExpandProps>) => {
  const {
    slotsProps,
    lines,
    children,
    isCollapsed,
    onCollapsibleChange,
    ...boxProps
  } = props;

  const viewerRef = useRef<HTMLDivElement | null>(null);
  const closedRef = useRef<HTMLDivElement | null>(null);
  const openedRef = useRef<HTMLDivElement | null>(null);

  const [collapsible, setCollapsible] = useState(false);
  const [isCollapsedLocal, setIsCollapsedLocal] = useState(true);

  const { sx, parseHtml, ...typographyProps } = slotsProps?.typography || {};

  useEffect(() => {
    const handleResize = () => {
      if (!closedRef.current || !openedRef.current) {
        return;
      }
      const closedRect = closedRef.current.getBoundingClientRect();
      const openedRect = openedRef.current.getBoundingClientRect();
      setCollapsible(openedRect.height > closedRect.height);
    };

    const resizeObserver = new ResizeObserver(() => {
      handleResize();
    });
    if (!closedRef.current || !openedRef.current) {
      return undefined;
    }
    resizeObserver.observe(closedRef.current);
    resizeObserver.observe(openedRef.current);
    window.addEventListener('resize', handleResize);
    return () => {
      resizeObserver.disconnect();
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    onCollapsibleChange?.(collapsible);
  }, [collapsible]);

  const handleClick = useCallback(() => {
    setIsCollapsedLocal(!isCollapsedLocal);
  }, [isCollapsedLocal]);

  const content = useMemo(() => {
    if (typeof children !== 'string') {
      return children;
    }
    if (parseHtml) {
      return (
        <Box
          component="span"
          sx={{
            '&': {
              lineHeight: 1.45,
            },
            '& p:first-child': {
              mt: 0,
            },
            '& p:last-child': {
              mb: 0,
            },
            '& ul, ol': {
              pl: 2.25,
            },
            '& li': {
              mt: 1,
            },
            '& li:first-time': {
              mt: 0,
            },
          }}
          dangerouslySetInnerHTML={{ __html: children }}
        />
      );
    }
    return children;
  }, [children, parseHtml]);

  return (
    <Box {...boxProps} position="relative" sx={{ overflow: 'hidden' }}>
      <Typography
        {...typographyProps}
        ref={viewerRef}
        position="relative"
        sx={{
          ...sx,
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: (
            typeof isCollapsed !== 'undefined' ? isCollapsed : isCollapsedLocal
          )
            ? `${lines}`
            : undefined,
          overflow: 'hidden',
          cursor:
            typeof isCollapsed !== 'undefined' && collapsible
              ? 'pointer'
              : undefined,
        }}
        onClick={typeof isCollapsed === 'undefined' ? handleClick : undefined}
      >
        {content}
      </Typography>
      <Typography
        {...typographyProps}
        ref={closedRef}
        position="absolute"
        top={0}
        sx={{
          ...sx,
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: `${lines}`,
          overflow: 'hidden',
          visibility: 'hidden',
          wordBreak: 'break-all',
        }}
      >
        {content}
      </Typography>
      <Typography
        {...typographyProps}
        ref={openedRef}
        position="absolute"
        top={0}
        sx={{
          ...sx,
          visibility: 'hidden',
        }}
      >
        {content}
      </Typography>
    </Box>
  );
};

export default memo(TextExpand);
