import { createAction } from '@reduxjs/toolkit';

import type {
  PaginationType,
  MaterialType,
  CollectionType,
} from 'app/entities';

export const clear = createAction('SEARCH_MODAL/CLEAR');

export const loadFilters = createAction('SEARCH_MODAL/LOAD_FILTERS', () => ({
  payload: {},
}));

export const loadFiltersDone = createAction(
  'SEARCH_MODAL/LOAD_FILTERS_DONE',
  (isError = false) => ({
    payload: {
      isError,
    },
  }),
);

export const setFilters = createAction(
  'SEARCH_MODAL/SET_FILTERS',
  ({ type }: { type: { options: string[] } }) => ({
    payload: {
      type: {
        options: [...(type?.options || [])],
      },
    },
  }),
);

export const setFilterText = createAction(
  'SEARCH_MODAL/SET_FILTER_TEXT',
  (value: string, triggerReload = true) => ({
    payload: {
      value,
      triggerReload,
    },
  }),
);

export const setFilterType = createAction(
  'SEARCH_MODAL/SET_FILTER_TYPE',
  (value: string, triggerReload = true) => ({
    payload: {
      value,
      triggerReload,
    },
  }),
);

export const setFilterTags = createAction(
  'SEARCH_MODAL/SET_FILTER_TAGS',
  (tags?: string | string[] | null, triggerReload = true) => {
    let value: string[] = [];
    if (Array.isArray(tags) && tags.length > 0) {
      value = [...new Set(tags)];
    }
    if (typeof tags === 'string' && tags.length > 0) {
      value = [tags];
    }
    return {
      payload: {
        value,
        triggerReload,
      },
    };
  },
);

export const loadNextPage = createAction(
  'SEARCH_MODAL/LOAD_NEXT_PAGE',
  (reload = false) => ({
    payload: {
      reload,
    },
  }),
);

export const loadNextPageDone = createAction(
  'SEARCH_MODAL/LOAD_NEXT_PAGE_DONE',
  (reload = false) => ({
    payload: {
      reload,
    },
  }),
);

export const addPage = createAction(
  'SEARCH_MODAL/SET_PAGE',
  (
    items: (MaterialType | CollectionType)[],
    paginationInfo: PaginationType,
    reload = false,
  ) => ({
    payload: {
      items,
      paginationInfo,
    },
    meta: {
      reload,
    },
  }),
);

export const logOut = createAction('SEARCH_MODAL/LOGOUT');
