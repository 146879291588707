import { type DefaultRootState } from 'react-redux';
import createCachedSelector from 're-reselect';

import { type CollectionType } from 'app/entities';

import * as userStore from 'store/nodes/user';

// Определите функцию для создания ключа кеша
const cacheKeyCreator = (
  state: DefaultRootState,
  resourceId: number | null | undefined,
  updateMeta?: { mode: 'add' | 'sub' },
) => `${resourceId}-${updateMeta?.mode || 'none'}`;

const dataByIdSelector = createCachedSelector(
  (state: DefaultRootState, resourceId: number | null | undefined) => state,
  (
    state,
    resourceId: number | null | undefined,
    updateMeta?: { mode: 'add' | 'sub' },
  ) => resourceId,
  (state, resourceId, updateMeta) => updateMeta,
  (state, resourceId, updateMeta): CollectionType | null => {
    if (
      !resourceId ||
      !state.playlist.data ||
      !state.playlist.data?.[resourceId]
    ) {
      return null;
    }

    const playlist: CollectionType = {
      ...state.playlist.data[resourceId],
      ...state?.interaction?.playlist?.[resourceId],
      isMy:
        state.playlist.data[resourceId].user?.id ===
        userStore.selectors.getIdByLogin('my')(state),
    };

    return playlist;
  },
)(cacheKeyCreator);

export default (
    resourceId: number | null | undefined,
    updateMeta?: { mode: 'add' | 'sub' },
  ) =>
  (state: DefaultRootState) => {
    return dataByIdSelector(state, resourceId, updateMeta);
  };
