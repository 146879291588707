import {
  type ForwardedRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { ScrollView } from 'react-native';

import type { FeedListMethods } from '../types';

const isScrollView = (element: ScrollView | any): element is ScrollView =>
  element && 'getScrollableNode' in element;

const isDocument = (element: Document | any): element is Document =>
  element && element instanceof Document;

const useScrollViewMethods = (
  forwardedRef: ForwardedRef<FeedListMethods> | undefined,
) => {
  const ref = useRef<ScrollView | Document>();

  useEffect(() => {
    if (!forwardedRef || !('current' in forwardedRef)) {
      return;
    }
    const { current } = ref;
    if (isScrollView(current)) {
      forwardedRef.current = {
        scrollToStart: (animated = false) => {
          current?.scrollTo({ y: 0, animated });
        },
        scrollToEnd: (animated = false) => {
          current?.scrollToEnd({ animated });
        },
        scrollTo: (position: number, animated = false) => {
          current?.scrollTo({ y: position, animated });
        },
      };
    }
    if (isDocument(current)) {
      forwardedRef.current = {
        scrollToStart: (animated = false) => {
          window.scrollTo({
            top: 0,
            behavior: (animated ? 'smooth' : 'instant') as unknown as
              | 'smooth'
              | 'auto',
          });
        },
        scrollToEnd: (animated = false) => {
          window.scrollTo({
            top: document.body.scrollHeight - window.innerHeight,
            behavior: (animated ? 'smooth' : 'instant') as unknown as
              | 'smooth'
              | 'auto',
          });
        },
        scrollTo: (position: number, animated = false) => {
          window.scrollTo({
            top: position,
            behavior: (animated ? 'smooth' : 'instant') as unknown as
              | 'smooth'
              | 'auto',
          });
        },
      };
    }
  }, [ref.current, forwardedRef]);

  const scrollableElementRef = useCallback((target: ScrollView | Document) => {
    ref.current = target;
  }, []);

  return useMemo(
    () => ({
      scrollableElementRef,
    }),
    [],
  );
};

export default useScrollViewMethods;
