import {
  type SagaReturnType,
  put,
  cancel,
  takeLatest,
} from 'redux-saga/effects';

import * as api from 'services/api';
import type { PaginationInput, UserType } from 'app/entities';

import { call } from 'store/utils/saga/effects';
import * as userStore from 'store/nodes/user';

import Alert from 'components/Alert';

import network from 'lib/network';
import * as actions from '../actions';

export const config = {
  action: actions.load.type,
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof actions.load>) {
  const { login } = action.payload;

  const pagination: PaginationInput = { pageSize: 50 };

  const [subscribers, subscriptions, unsubscribeActiveUser] = yield* call(() =>
    Promise.all([
      api.resource.user.relation.subscribers(login, pagination),
      api.resource.user.relation.subscriptions(login, pagination),
      network
        .request<UserType[]>(`/user/profile/${login}/unsubscribedActiveUsers`)
        .get(),
    ]),
  );

  if (
    subscribers.error ||
    !subscribers.data ||
    subscriptions.error ||
    !subscriptions.data ||
    !unsubscribeActiveUser.data ||
    unsubscribeActiveUser.errors
  ) {
    Alert.error(
      subscribers.error?.message ||
        subscriptions.error?.message ||
        'Server error #30',
    );
    yield put(actions.loadDone());
    yield cancel();
    return;
  }

  yield put(
    userStore.actions.setProfile({
      data: [
        ...subscribers.data.items,
        ...subscriptions.data.items,
        ...unsubscribeActiveUser.data,
      ] as UserType[],
    }),
  );
  yield put(
    actions.addPage(
      'subscribers',
      subscribers.data.items as UserType[],
      subscribers.data.paginationInfo,
    ),
  );
  yield put(
    actions.addPage(
      'subscriptions',
      subscriptions.data.items,
      subscriptions.data.paginationInfo,
    ),
  );
  yield put(
    actions.addPage('unsubscribeActiveUser', unsubscribeActiveUser.data, {
      hasNext: false,
      nextCursor: null,
    }),
  );

  yield put(actions.loadDone());
}
