import qs from 'query-string';

import type {
  CursorType,
  PaginationResult,
  PaginationInput,
  UserType,
} from 'app/entities';
import request from 'services/api/request';
import { prepare } from 'utils';

type Filters = {
  type: string | null;
};

export default async (
  text: string,
  pagination: PaginationInput,
  filters?: Filters,
): Promise<PaginationResult<Omit<UserType, 'isMy' | 'type'>>> => {
  if (!text) {
    return {
      data: {
        items: [],
        paginationInfo: {
          hasNext: false,
          nextCursor: null,
        },
      },
      error: null,
    };
  }

  const query: Record<string, any> = {
    pageSize: pagination?.pageSize || 10,
  };
  query.q = text;
  if (pagination?.cursor) {
    query.cursor = pagination?.cursor;
  }

  const { data, error } = await request.get<CursorType<UserType>>(
    '/user/search',
    { query },
  );
  if (error || !data) {
    return {
      data: null,
      error: Array.isArray(error) ? error[0] : error,
    };
  }

  const subscriptions = await request.get<
    Record<
      number,
      Pick<UserType, 'subscribersCount' | 'subscriptionsCount' | 'isSubscribed'>
    >
  >(`/user/subscriptions/meta?userIds=${data.items.map((item) => item.id)}`);

  const next = qs.parseUrl('');

  return {
    data: {
      items: data.items.map((user) =>
        prepare.user({
          ...user,
          isSubscribed: subscriptions.data?.[user.id]?.isSubscribed || false,
          subscriptionsCount:
            subscriptions.data?.[user.id]?.subscriptionsCount || 0,
          subscribersCount:
            subscriptions.data?.[user.id]?.subscribersCount || 0,
        }),
      ),
      paginationInfo: {
        hasNext: !!next?.query?.cursor,
        nextCursor: (next?.query?.cursor as string) || null,
      },
    },
    error: null,
  };
};
