import { memo, useMemo, useCallback, useState } from 'react';
import { Box, Typography } from '@mui/joy';
import { type LayoutChangeEvent, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';

import { useResponsive } from 'hooks';
import { unit } from 'utils';
import * as playlistStore from 'store/nodes/playlist';

import { Text, View } from 'components/Themed';
import Description from 'components/Description';

import Info from './elements/Info';
import Poster from './elements/Poster';

import ActionsBarMobile from './elements/ActionsBarMobile';

type DescriptionPanelProps = {
  resourceId?: number;
};

const DescriptionPanel = (props: DescriptionPanelProps) => {
  const { resourceId } = props;
  const responsive = useResponsive();
  const [direction, setDirection] = useState<'column' | 'row' | undefined>(
    undefined,
  );

  const data = useSelector(playlistStore.selectors.dataById(resourceId));

  const handleLayout = useCallback((event: LayoutChangeEvent) => {
    if (event.nativeEvent.layout.width <= 500) {
      setDirection('column');
    } else {
      setDirection('row');
    }
  }, []);

  const renderTitle = useMemo(() => {
    if (!data?.title) {
      return null;
    }
    let fontSize = 48;
    if (direction === 'row') {
      fontSize = 48;
    }
    if (responsive.is.mobileL) {
      fontSize = 32;
    }
    return (
      <Typography
        fontSize={fontSize}
        fontWeight={400}
        textColor="text.primary"
        lineHeight={1.1}
      >
        {data.title}
      </Typography>
    );
  }, [direction, responsive.is.mobileL, data?.title]);

  const renderActionsBarMobile = useMemo(() => {
    if (!responsive.is.mobileL || !resourceId) {
      return null;
    }
    return (
      <ActionsBarMobile
        id={resourceId}
        style={{ marginTop: unit(24), marginBottom: unit(8) }}
      />
    );
  }, [responsive.is.mobileL, resourceId]);

  if (!data) {
    return null;
  }

  return (
    <View
      style={[
        styles.DescriptionPanel,
        direction === 'row' && styles.DescriptionPanelRow,
        responsive.is.mobileL && styles.DescriptionPanelMobile,
      ]}
      onLayout={handleLayout}
    >
      {!!direction && (
        <>
          <View
            style={[styles.content, direction === 'row' && styles.contentRow]}
          >
            <Info
              resourceId={resourceId}
              sx={{
                mb: 3,
              }}
            />
            {renderTitle}
            {!!data.description && (
              <Description
                size={17}
                lightColor="rgba(0,0,0,0.85)"
                darkColor="rgba(255,255,255,0.85)"
              >
                {data.description}
              </Description>
            )}
            {renderActionsBarMobile}
          </View>
          <Box
            sx={{
              ...(direction === 'row' && {
                width: 160,
                ml: 5,
              }),
              '@media (max-width: 767px)': {
                mx: -1.5,
                mb: 4,
              },
            }}
          >
            <Poster
              cover={data?.cover}
              contentImages={data?.contentImages}
              aspectRatio={!responsive.is.mobileL ? 1 : 16 / 9}
            />
          </Box>
        </>
      )}
    </View>
  );
};
// '@media (max-width: 767px)': {
const styles = StyleSheet.create({
  DescriptionPanel: {
    flexDirection: 'column',
    marginTop: '4px',
    width: '100%',
    alignItems: 'stretch',
  },
  DescriptionPanelRow: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  DescriptionPanelMobile: {
    flexDirection: 'column-reverse',
  },
  title: {},
  info: {
    marginTop: unit(6),
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  description: {},
  content: {},
  contentRow: {
    flex: 1,
  },
  poster: {},
});

export default memo(DescriptionPanel);
