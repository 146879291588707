import { type FC, memo, useMemo } from 'react';
import { useColorScheme } from '@mui/joy';

import PlanHead from 'widgets/Subscription/ui/Plans/ui/PlanHead';
import Divider from 'components/Divider';
import PlanInclude from 'widgets/Subscription/ui/Plans/ui/PlanInclude';
import Panel from 'widgets/Subscription/ui/Plans/ui/Panel';

import usePlans from './model/usePlans';

const TryFree: FC = () => {
  const plans = usePlans('monthly');

  const { colorScheme } = useColorScheme();

  const plan = useMemo(
    () => plans.find((item) => item.identity.planId === '1'),
    [plans],
  );

  if (!plan) {
    return null;
  }

  return (
    <Panel key={plan.name} variant={colorScheme === 'dark' ? 'solid' : 'plain'}>
      <PlanHead
        name={plan.name}
        description={plan.description}
        note="7 days free trial"
        costValue={plan.cost.value}
        costBy={plan.cost.by}
        costNote={plan.cost.note}
      />
      <Divider margin="1.5 0" />
      <PlanInclude list={plan.includes} />
    </Panel>
  );
};

export default memo(TryFree);
