import type { ResultLegacy } from 'app/entities';
import request from 'services/api/request';
import credentials from 'services/api/credentials';

export interface AuthLogOutData {
  closed: boolean;
}

export default async (): Promise<ResultLegacy<AuthLogOutData>> => {
  if (!credentials.getAccess()) {
    return {
      data: {
        closed: true,
      },
      error: null,
    };
  }
  const { data, error } = await request.post<AuthLogOutData>('/auth/logout', {
    body: {
      refresh: credentials.getRefresh(),
    },
  });
  if (error) {
    credentials.clear();
    return {
      data: null,
      error,
    };
  }
  credentials.clear();
  return {
    data,
    error,
  };
};
