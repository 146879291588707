import {
  forwardRef,
  useCallback,
  useEffect,
  useState,
  useRef,
  type ReactElement,
} from 'react';
import ReactModal from 'react-modal';
import { createUseStyles } from 'react-jss';

import { useBreakpoint, useThemeColor } from 'hooks';

import { unit } from 'utils';
import Icon from 'components/LegacyIcon';

import { type ModalProps } from './Modal';

export const Init = (): ReactElement => {
  const handleScroll = useCallback((event: WheelEvent & MouseEvent) => {
    if (!event) {
      return;
    }
    if (!document.body.classList.contains('ReactModal__Body--open')) {
      return;
    }
    const target = event.target as HTMLElement;
    const block = target.closest('[data-block="scrollable"]') as HTMLDivElement;
    const body = target.closest(
      '[data-block="react-modal-body"]',
    ) as HTMLDivElement;
    const scrollable = block || body;
    if (!scrollable) {
      event.preventDefault();
      return;
    }
    if (event.deltaY < 0 && scrollable.scrollTop === 0) {
      event.preventDefault();
      return;
    }
    if (
      event.deltaY > 0 &&
      scrollable.scrollTop === scrollable.scrollHeight - scrollable.offsetHeight
    ) {
      event.preventDefault();
    }
  }, []);

  useEffect(() => {
    document.body.addEventListener('wheel', handleScroll, { passive: false });
    return () => {
      document.body.removeEventListener('wheel', handleScroll);
    };
  }, [handleScroll]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <></>
  );
};

const defaultProps = {
  hasSubmit: false,
  submitLabel: 'Save',
};

const Modal = forwardRef<any, ModalProps & typeof defaultProps>(
  (props, forwardedRef) => {
    const {
      children,
      width,
      height,
      hasSubmit,
      submitLabel,
      onSubmit,
      onAfterOpen,
      onBeforeOpen,
      onAfterClose,
    } = props;

    const classes = useStyles();
    const contentRef = useRef<HTMLDivElement>();
    const breakpoint = useBreakpoint();
    const contextRef = useRef<any>({});

    const modalBackground = useThemeColor({
      light: '#e7e7e7',
      dark: '#181818',
    });

    const [isOpen, setOpen] = useState<boolean>(false);

    const handleOpen = useCallback(
      (c: any) => {
        contextRef.current = c;
        onBeforeOpen?.();
        setOpen(true);
      },
      [onBeforeOpen],
    );

    const handleClose = useCallback(() => {
      contentRef.current?.removeAttribute('data-is-open');
      setTimeout(() => {
        setOpen(false);
      }, 300);
    }, []);

    const handleContentMounted = useCallback((instance) => {
      contentRef.current = instance as HTMLDivElement;
      setTimeout(() => {
        contentRef.current?.setAttribute('data-is-open', 'true');
      }, 0);
    }, []);

    useEffect(() => {
      if (forwardedRef && 'current' in forwardedRef) {
        forwardedRef.current = {
          open: (c: any = {}) => {
            handleOpen(c);
          },
          close: () => {
            handleClose();
          },
          getContext: () => contextRef.current || {},
        };
      }
    }, [forwardedRef, handleClose, handleOpen]);

    useEffect(() => {
      if (breakpoint === 'xs' && isOpen) {
        document.body.classList.add(classes.noScroll);
      } else {
        document.body.classList.remove(classes.noScroll);
      }
    }, [classes.noScroll, breakpoint, isOpen]);

    const styles =
      breakpoint === 'xs'
        ? undefined
        : {
            content: {
              width: typeof width === 'number' ? unit(width) : width,
              height: typeof height === 'number' ? unit(height) : height,
            },
          };

    return (
      <ReactModal
        isOpen={isOpen}
        onAfterOpen={onAfterOpen}
        onAfterClose={onAfterClose}
        onRequestClose={handleClose}
        className={breakpoint === 'xs' ? classes.xsModal : classes.smModal}
        overlayClassName={
          breakpoint === 'xs' ? classes.xsOverlay : classes.smOverlay
        }
        style={styles}
        contentRef={handleContentMounted}
        ariaHideApp={false}
        shouldCloseOnEsc
        preventScroll
      >
        {breakpoint === 'xs' && (
          <div className={classes.xsToolbar}>
            <button
              type="button"
              aria-label="close"
              role="button"
              onClick={handleClose}
            >
              Cancel
            </button>
            <div />
            {hasSubmit && (
              <button
                type="button"
                aria-label="close"
                role="button"
                onClick={onSubmit}
              >
                {submitLabel}
              </button>
            )}
          </div>
        )}
        {breakpoint !== 'xs' && (
          <button
            type="button"
            aria-label="close"
            role="button"
            className={classes.smClose}
            onClick={handleClose}
          >
            <Icon name="Add" size={16} />
          </button>
        )}
        <div
          data-block="react-modal-body"
          className={breakpoint === 'xs' ? classes.xsBody : classes.smBody}
        >
          {children}
        </div>
      </ReactModal>
    );
  },
);

Modal.defaultProps = defaultProps;

const useStyles = createUseStyles({
  noScroll: {
    overflow: 'hidden',
  },
  xsModal: {
    '&': {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      boxSizing: 'border-box',
      transition: 'transform 300ms ease',
      left: 0,
      right: 0,
      bottom: 0,
      top: unit(32),
      background: '#181818',
      borderTopLeftRadius: unit(16),
      borderTopRightRadius: unit(16),
      height: `calc(100vh - ${unit(32)})`,
      padding: `0 ${unit(16)} ${unit(16)}`,
      boxShadow: `0 0 ${unit(32)} #000000`,
      transform: `translate3d(0, calc(100vh - ${unit(32)}), 0)`,
      outline: 'none',
    },
    '&[data-is-open]': {
      transform: 'translate3D(0, 0, 0)',
    },
  },
  xsBody: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
    marginLeft: unit(-16),
    marginRight: unit(-16),
    paddingLeft: unit(16),
    paddingRight: unit(16),
  },
  xsOverlay: {
    position: 'fixed',
    inset: '0px',
  },
  xsToolbar: {
    '&': {
      display: 'flex',
      alignItems: 'center',
      marginLeft: unit(-16),
      marginRight: unit(-16),
    },
    '& > div': {
      flex: 1,
    },
    '& > button': {
      '&': {
        background: 'none',
        border: 0,
        fontSize: unit(15),
        lineHeight: unit(20),
        padding: unit(16),
        color: '#ffffff',
        transition: 'opacity 600ms ease',
      },
      '&:active': {
        transitionDuration: '300ms',
        opacity: 0.8,
      },
    },
  },
  smModal: {
    '&': {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      boxSizing: 'border-box',
      transition: 'opacity 300ms ease',
      background: '#181818',
      borderRadius: unit(16),
      boxShadow: `0 0 ${unit(16)} #000000`,
      padding: `${unit(24)} ${unit(16)} ${unit(16)}`,
      opacity: 0,
      maxWidth: `calc(100vw - ${unit(96)})`,
      maxHeight: `calc(100vh - ${unit(128)})`,
      outline: 'none',
    },
    '&[data-is-open]': {
      opacity: 1,
    },
  },
  smBody: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
    marginLeft: unit(-16),
    marginRight: unit(-16),
    paddingLeft: unit(16),
    paddingRight: unit(16),
  },
  smOverlay: {
    display: 'flex',
    position: 'fixed',
    inset: '0px',
    background: 'rgba(0, 0, 0, 0.4)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  smClose: {
    '&': {
      position: 'absolute',
      display: 'flex',
      top: unit(-16),
      right: unit(-16),
      transform: 'rotateZ(45deg)',
      margin: 0,
      padding: 0,
      border: 0,
      borderRadius: unit(16),
      boxSizing: 'border-box',
      background: 'none',
      cursor: 'pointer',
      backgroundColor: '#4e4e53',
      height: unit(32),
      width: unit(32),
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow: `0 0 ${unit(8)} #000000`,
      transition: 'opacity 600ms ease',
    },
    '&:active': {
      transitionDuration: '300ms',
      opacity: 0.8,
    },
  },
});

export default Modal;
