import { type ReactElement, memo, useCallback } from 'react';
import { Platform, StyleSheet, TouchableOpacity } from 'react-native';

import { useThemeColor } from 'hooks';

import { Text } from 'components/Themed';
import { unit } from 'utils';

type OpenMaterialSourceProps = {
  to?: string;
  type?: string;
};

const OpenMaterialSource = (props: OpenMaterialSourceProps): ReactElement => {
  const { to, type } = props;
  const borderColor = useThemeColor({ light: '#000000', dark: '#ffffff' });

  const handleOpen = useCallback(() => {
    if (to && Platform.OS === 'web' && !!document) {
      const link = document.createElement('a');
      link.setAttribute('href', to);
      link.setAttribute('target', '_blank');
      link.click();
    }
  }, [to]);

  return (
    <TouchableOpacity
      style={[styles.OpenMaterialSource, { borderColor }]}
      onPress={handleOpen}
      activeOpacity={0.8}
    >
      <Text size={13} lightColor="#000000" darkColor="#ffffff">
        To source
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  OpenMaterialSource: {
    borderWidth: 1,
    flexDirection: 'row',
    paddingHorizontal: unit(16),
    height: unit(30),
    borderRadius: unit(15),
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: unit(8),
  },
});

export default memo(OpenMaterialSource);
