import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { StyleSheet } from 'react-native';

import { useRoute } from 'navigation/hooks';
import { useDispatcher } from 'store/utils/redux/hooks';
import * as activityStore from 'store/nodes/activity';
import * as userStore from 'store/nodes/user';

import { useListData, useListLayout, useResponsive } from 'hooks';
import { unit } from 'utils';

import { Text, View } from 'components/Themed';
import List from 'components/List';

import TopBarDesktop from './elements/TopBarDesktop';
import TopBarMobile from './elements/TopBarMobile';
import Item from './ui/Item';

const PeersFeed = () => {
  const route = useRoute<'CommunityPeers'>();
  const dispatcher = useDispatcher();
  const responsive = useResponsive();

  const { listLayout } = useListLayout('peers.list.mode');

  const user = useSelector(userStore.selectors.getByLogin(route.params?.user));
  const list = useSelector(activityStore.selectors.list);
  const listMeta = useSelector(activityStore.selectors.listMeta);

  const listData = useListData(list.sequence, listMeta, 12);

  const handleNextPage = useCallback(() => {
    dispatcher.activity.loadNextPage();
  }, []);

  const renderItem = useCallback(
    ({ item }: any) => {
      const resourceId = item.id || item.resourceId;
      return (
        <Item
          resourceId={resourceId}
          resourceType={item.resourceType}
          eventType={item.eventType}
          eventTime={item.eventTime}
          userId={item.userId}
          comment={item.comment?.text}
        />
      );
    },
    [responsive.is.tabletL, listLayout.isRow],
  );

  return (
    <>
      <TopBarDesktop />
      <TopBarMobile />
      {!listData.isEmpty && (
        <List
          type="masonry"
          componentStyle={styles.listComponent}
          contentContainerStyle={styles.listContentContainer}
          scrollEmitter="web-page"
          data={listData.sequence}
          keyExtractor={(item) => `${item.id || item.resourceId}`}
          renderItem={renderItem}
          columnsScheme={listLayout.isRow ? '320:1' : '320:1;560:2;960:3'}
          // @ts-ignore
          showsVerticalScrollIndicator={false}
          hasNextPage={list.paginationInfo.hasNext}
          onEndReached={handleNextPage}
          ListFooterComponent={<View style={{ height: unit(108) }} />}
        />
      )}
      {listData.isEmpty && !user && (
        <View style={styles.subscribe}>
          <Text size={17} lightColor="#000000" darkColor="#ffffff">
            Subscribe to your peers
            <br />
            to check what they read
          </Text>
        </View>
      )}
      {listData.isEmpty && !!user && (
        <View style={styles.subscribe}>
          <Text size={17} lightColor="#000000" darkColor="#ffffff">
            {`${user.name} hasn't been active`}
          </Text>
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  listComponent: {
    marginHorizontal: unit(-10),
  },
  listContentContainer: {
    alignSelf: 'stretch',
  },
  subscribe: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
});

export default memo(PeersFeed);
