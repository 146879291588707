import { memo, type PropsWithChildren, useMemo } from 'react';
import { Box, Tooltip, useTheme } from '@mui/joy';
import parse, {
  type DOMNode,
  type HTMLReactParserOptions,
  Element,
  Text,
} from 'html-react-parser';

import TooltipCard from 'widgets/Copilot/elements/TooltipCard';

const Render = (domNode: DOMNode) => {
  const node = domNode as Element;

  if (node.attribs?.class === 'resource-ref') {
    const position = (node.children[0] as Text).data;
    const resourceType = node.attribs['data-resource-type'] as
      | 'Material'
      | 'Collection'
      | 'Note';
    const resourceId = Number(node.attribs['data-resource-id']);

    return (
      <Tooltip
        arrow
        placement="left"
        variant="outlined"
        size="sm"
        title={
          <>
            {resourceType === 'Material' && (
              <TooltipCard materialId={resourceId} />
            )}
          </>
        }
        slotProps={{
          // @ts-ignore
          root: {
            sx: {
              padding: 0,
            },
          },
        }}
      >
        <Box
          className="AnswerRefPoint-root"
          data-type={resourceType}
          data-id={resourceId}
          sx={{
            '&': {
              background: 'var(--ref-background-color)',
              transition: '300ms background ease',
              display: 'inline-block',
              width: '20px',
              height: '20px',
              fontSize: '14px',
              verticalAlign: 'bottom',
              textAlign: 'center',
              borderRadius: '50%',
              opacity: '0.9',
              transform: 'translateY(-2px)',
            },
            '& .AnswerRefPoint-text': {
              color: 'currentColor',
              fontFamily: 'inherit',
              display: 'block',
              transform: 'translateY(1px)',
              fontWeight: '600',
            },
          }}
        >
          <Box className="AnswerRefPoint-text">{position}</Box>
        </Box>
      </Tooltip>
    );
  }

  return null;
};

type CustomHtmlProps = {
  applyReplace?: boolean;
};

const RenderHtml = (props: PropsWithChildren<CustomHtmlProps>) => {
  const { children, applyReplace } = props;

  const theme = useTheme();

  const options = useMemo(
    () =>
      ({
        replace: Render,
      }) as HTMLReactParserOptions,
    [Render],
  );

  return (
    <Box
      fontSize={17}
      lineHeight={1.4}
      color={theme.palette.text.secondary}
      sx={{
        '& > p:first-child': {
          marginTop: 0,
        },
        '& > p:last-child': {
          marginBottom: 0,
        },
        '& li': {
          marginTop: theme.spacing(1.5),
        },
        '& li:first-child': {
          marginTop: 0,
        },
      }}
    >
      {!applyReplace && typeof children === 'string' && parse(children)}
      {applyReplace && typeof children === 'string' && parse(children, options)}
    </Box>
  );
};

export default memo(RenderHtml);
