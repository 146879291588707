import { useCallback, useMemo } from 'react';
import { Platform } from 'react-native';
import * as Linking from 'expo-linking';
import type {
  CollectionType,
  MaterialType,
  NoteType,
  RagMaterialType,
} from 'app/entities';

import Alert from 'components/Alert';
import { guard, stripTags, truncateText } from 'utils';

interface ShareData {
  title: string;
  text: string;
  url: string;
}

const useSharing = (
  data: MaterialType | RagMaterialType | CollectionType | NoteType | null,
) => {
  const { internalUrl, title, description } = useMemo(() => {
    if (guard.isMaterial(data) || guard.isCollection(data)) {
      return data;
    }
    if (guard.isNote(data)) {
      const plainText = stripTags(data.text);
      return {
        internalUrl: data.internalUrl,
        title: truncateText(plainText, 50),
        description: truncateText(plainText, 150),
      };
    }
    return { internalUrl: null, title: null, description: null };
  }, [data]);

  const urlFinal = useMemo(
    () => Linking.createURL(internalUrl || ''),
    [internalUrl],
  );

  const shareData: ShareData = useMemo(
    () => ({
      url: urlFinal,
      title: title || '',
      text: description || '',
    }),
    [urlFinal, title, description],
  );

  const isSupport = useMemo(() => {
    if (!internalUrl) {
      return false;
    }
    if (
      Platform.OS === 'web' &&
      typeof navigator !== 'undefined' &&
      navigator.canShare &&
      navigator.canShare(shareData)
    ) {
      return true;
    }
    if (Platform.OS === 'web') {
      return true;
    }
    return false;
  }, [shareData]);

  const web = useCallback(() => {
    if (navigator.canShare && navigator.canShare(shareData)) {
      navigator
        .share(shareData)
        .then(() => {
          Alert.success('Link sent');
        })
        .catch((error) => {
          Alert.error('Error send link');
        });
      return;
    }
    navigator.clipboard
      .writeText(urlFinal)
      .then(
        () => {
          Alert.success('Copied to clipboard', {
            toastId: 23,
          });
        },
        (err) => {
          Alert.error('Error copy link');
        },
      )
      .catch((error) => {
        Alert.error('Error copy link');
      });
  }, [shareData]);

  const native = useCallback(() => {
    if (!isSupport) {
      return;
    }
    console.log('NEED IMPLEMENT: useSharing.native');
  }, [isSupport]);

  return useMemo(
    () => ({
      isSupport,
      share: Platform.OS === 'web' ? web : native,
    }),
    [isSupport, native, web],
  );
};

export default useSharing;
