import {
  memo,
  useCallback,
  useState,
  useEffect,
  useMemo,
  type SetStateAction,
} from 'react';
import {
  Box,
  DialogContent,
  DialogTitle,
  FormControl,
  Modal,
  ModalClose,
  ModalDialog,
  Textarea,
  Typography,
} from '@mui/joy';
import { StyleSheet } from 'react-native';

import { useSelector, useDispatcher } from 'store/utils/redux/hooks';
import * as searchUserStore from 'store/nodes/searchUser';
import * as userStore from 'store/nodes/user';

import { unit } from 'utils';

import FeedList from 'components/FeedList';
import SlimProfileItem from 'components/SlimProfileItem';
import Relations from 'components/Relations';

import Header from 'components/SearchUser/elements/Header';
import * as modalStore from 'widgets/modals/store';

import { navigate } from 'navigation/methods';

const SearchUser = () => {
  const { isOpen } = useSelector(modalStore.selectors.state('SearchUser'));

  const dispatcher = useDispatcher();

  const userProfile = useSelector(userStore.selectors.getMy);
  const list = useSelector(searchUserStore.selectors.list);
  const listMeta = useSelector(searchUserStore.selectors.listMeta);

  const [text, setText] = useState<string>('');

  useEffect(() => {
    dispatcher.searchUser.loadNextPage(text, 'all', true);
  }, [text]);

  useEffect(() => {
    setText('');
    dispatcher.searchUser.clear();
  }, []);

  const sequenceFinal = useMemo(() => {
    const wireframes = [
      {
        id: 'w-1',
        type: 'wireframe',
      },
      {
        id: 'w-2',
        type: 'wireframe',
      },
      {
        id: 'w-3',
        type: 'wireframe',
      },
      {
        id: 'w-4',
        type: 'wireframe',
      },
      {
        id: 'w-5',
        type: 'wireframe',
      },
      {
        id: 'w-6',
        type: 'wireframe',
      },
    ];
    if (listMeta.firstPage.isLoading) {
      return wireframes;
    }
    if (listMeta.nextPage.isLoading) {
      return [...list.sequence, ...wireframes];
    }
    return list.sequence;
  }, [
    list.sequence,
    listMeta.firstPage.isLoading,
    listMeta.nextPage.isLoading,
  ]);

  const handlePersonPress = useCallback((userLogin: string) => {
    navigate('Profile', { login: userLogin });
  }, []);

  const handleModalClose = useCallback(async () => {
    dispatcher.modal.close('SearchUser');
  }, []);

  const handleChange = useCallback(
    (event: { target: { value: SetStateAction<string> } }) => {
      setText(event.target.value);
    },
    [],
  );

  const renderItem = useCallback(
    ({ id }: any) => <SlimProfileItem id={id} onPress={handlePersonPress} />,
    [handlePersonPress],
  );

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <ModalDialog
        variant="soft"
        size="lg"
        sx={{
          width: '100%',
          // height: '75%',
          maxWidth: 'min(560px, calc(100% - 32px))',
          '--ModalClose-radius': '1.125em',
        }}
      >
        <ModalClose onClick={handleModalClose} />
        <DialogTitle sx={{ mt: -1, flexDirection: 'column' }}>
          Users
          <FormControl>
            <Textarea
              placeholder="Search some person"
              variant="plain"
              maxRows={1}
              value={text}
              name="description"
              onChange={handleChange}
            />
          </FormControl>
        </DialogTitle>
        <DialogContent
          sx={{
            gap: 2,
            mx: 'calc(-1 * var(--Card-padding))',
            px: 'var(--Card-padding)',
          }}
        >
          {text.length === 0 && !userProfile && (
            <Box
              display="flex"
              flex={1}
              justifyContent="center"
              alignItems="center"
            >
              <Typography fontSize={17}>Start typing</Typography>
              <Typography fontSize={15}>to search</Typography>
            </Box>
          )}
          {text.length === 0 && !!userProfile && (
            <Relations
              slim
              login={userProfile?.login}
              onPress={handlePersonPress}
            />
          )}
          {text.length > 0 &&
            listMeta.firstPage.isLoaded &&
            !listMeta.firstPage.isLoading &&
            list.sequence.length === 0 && (
              <Box
                display="flex"
                flex={1}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                my={10}
              >
                <Typography fontSize={17}>No results</Typography>
                <Typography fontSize={15}>
                  Try to type something else
                </Typography>
              </Box>
            )}
          {text.length > 0 &&
            (listMeta.firstPage.isLoading ||
              listMeta.nextPage.isLoading ||
              list.sequence.length > 0) && (
              <FeedList
                data={sequenceFinal}
                hasNextPage={list.paginationInfo.hasNext}
                HeaderComponent={<Header />}
                ItemComponent={renderItem}
                paddingHorizontal={16}
                style={styles.panelResult}
              />
            )}
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
};

const styles = StyleSheet.create({
  input: {
    flex: 1,
  },
  panelResult: {
    marginLeft: unit(-16),
    marginRight: unit(-16),
    flex: 1,
  },
});

export default memo(SearchUser);
