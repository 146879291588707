import type {
  PaginationResult,
  PaginationInput,
  ActivityType,
} from 'app/entities';

import { guard, prepare, requestCursorModifier } from 'utils';
import network from 'lib/network';

import interaction from 'services/api/interaction/route';

const filterOff = (item: ActivityType) => {
  if (guard.isActivityNewJob(item)) {
    return false;
  }
  return true;
};

export default async (
  login: 'my' | 'promo' | string = 'my',
  pagination: PaginationInput = { pageSize: 10, cursor: null },
): Promise<PaginationResult<ActivityType>> => {
  const query: PaginationInput = {
    pageSize: pagination?.pageSize || 10,
  };
  if (pagination?.cursor) {
    query.cursor = pagination?.cursor;
  }

  let url = `/user/${login}/activity`;
  if (login === 'my') {
    url = '/user/activity/feed';
  }
  if (login === 'promo') {
    url = '/user/activity/feed_pre_login';
  }

  const activityResponse = await network
    .request<ActivityType[]>(url)
    .query(query)
    .get(requestCursorModifier());
  if (activityResponse.errors || !activityResponse.data) {
    return {
      data: null,
      error: activityResponse.errors?.[0] || new Error('Unknown'),
    };
  }

  const contentIds: number[] = [];
  const collectionIds: number[] = [];
  activityResponse.data.items.forEach((item) => {
    if (
      guard.isActivityNewMaterial(item) ||
      guard.isActivityCommentMaterial(item) ||
      guard.isActivityCompletedMaterial(item)
    ) {
      contentIds.push(item.content.id);
    }
    if (
      guard.isActivityNewCollection(item) ||
      guard.isActivityCommentCollection(item) ||
      guard.isActivityCompletedCollection(item)
    ) {
      collectionIds.push(item.playlist.id);
    }
  });
  const [contentInteractionsMap, collectionInteractionsMap] = await Promise.all(
    [
      interaction.items('content', contentIds),
      interaction.items('playlist', collectionIds),
    ],
  );

  return {
    data: {
      items: activityResponse.data.items.filter(filterOff)?.map((item) => {
        if (
          guard.isActivityNewMaterial(item) ||
          guard.isActivityCommentMaterial(item) ||
          guard.isActivityCompletedMaterial(item)
        ) {
          return {
            ...item,
            content: prepare.material({
              ...item.content,
              ...contentInteractionsMap?.data?.[item.content.id],
            }),
          };
        }
        if (
          guard.isActivityNewCollection(item) ||
          guard.isActivityCommentCollection(item) ||
          guard.isActivityCompletedCollection(item)
        ) {
          return {
            ...item,
            playlist: {
              ...item.playlist,
              ...collectionInteractionsMap?.data?.[item.playlist.id],
            },
          };
        }
        return item;
      }),
      paginationInfo: {
        hasNext: activityResponse.data.hasNext,
        nextCursor: activityResponse.data.nextCursor,
      },
    },
    error: activityResponse.errors?.[0] || null,
  };
};
