import { memo, useCallback } from 'react';
import { Box } from '@mui/joy';
import Typography from '@mui/joy/Typography';

import * as collectionStore from 'store/nodes/playlist';
import { useSelector } from 'react-redux';

import { navigate } from 'navigation/methods';

import CompositePoster from 'components/CompositePoster';

export type CollectionProvidedProps = {
  resourceId?: number;
};

const CollectionProvided = (props: CollectionProvidedProps) => {
  const { resourceId } = props;

  const collection = useSelector(
    collectionStore.selectors.dataById(resourceId),
  );

  const handleNavigateToMaterial = useCallback(() => {
    if (!resourceId) {
      return;
    }
    navigate('Playlist', { resourceId });
  }, [resourceId]);

  if (!resourceId || !collection) {
    return null;
  }

  return (
    <Box component="div" onClick={handleNavigateToMaterial}>
      <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
        {collection?.contentImages.length > 0 && (
          <Box width={64}>
            <CompositePoster sources={collection?.contentImages} />
          </Box>
        )}
        <Box flex={1}>
          <Typography display="inline" component="span">
            <b>{collection.title}</b>
            {'. '}
            You have been given access to the collection.
          </Typography>{' '}
          <Typography
            display="inline"
            component="span"
            color="primary"
            fontWeight="600"
          >
            open
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(CollectionProvided);
