import { type SagaReturnType, takeEvery, put } from 'redux-saga/effects';

import type { UserType } from 'app/entities';

import * as api from 'services/api';

import { call } from 'store/utils/saga/effects';
import * as userStore from 'store/nodes/user';

import * as actions from '../actions';

export const config = {
  action: actions.loadUser.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.loadUser>) {
  const { login, mode } = action.payload;

  const logins = !Array.isArray(login) ? [login] : login;

  const results = yield* call(() =>
    Promise.all(logins.map((item) => api.resource.user.profile(item))),
  );

  yield put(
    userStore.actions.setProfile({
      data: results
        .filter((result) => !!result?.data)
        .map((result) => result.data) as UserType[],
      mode,
    }),
  );
}
