import { type ForwardedRef, type RefObject, useImperativeHandle } from 'react';
import { type LexicalEditor, $getRoot } from 'lexical';
import { $generateNodesFromDOM } from '@lexical/html';
import { marked } from 'marked';

import insertNode from './insertNode';
import scrollToNode from './scrollToNode';
import highlightNodes from './highlightNodes';

export type EditorMethods = {
  clear: (options: { focus?: boolean }) => void;
  set: (value: string) => void;
  focus: () => void;
  insert: (text: string, strategy?: 'start' | 'current' | 'end') => void;
};

const useMethods = (
  editorRef: RefObject<LexicalEditor>,
  forwardedRef: ForwardedRef<EditorMethods>,
) => {
  useImperativeHandle(forwardedRef, () => ({
    clear: ({ focus }) => {
      if (!editorRef.current) {
        return;
      }
      editorRef.current.update(() => {
        $getRoot().clear();
      });
      if (focus) {
        setTimeout(() => {
          editorRef.current?.focus();
        }, 10);
      }
    },
    set: (value) => {
      const { current: editor } = editorRef;
      if (!editor) {
        return;
      }
      editor.update(() => {
        const parser = new DOMParser();
        const dom = parser.parseFromString(value, 'text/html');
        const nodes = $generateNodesFromDOM(editor as LexicalEditor, dom);
        const root = $getRoot();
        root.clear();
        root.append(...nodes);
      });
    },
    focus: () => {
      if (!editorRef.current) {
        return;
      }
      editorRef.current?.focus();
    },
    insert: (text, strategy = 'current') => {
      if (typeof text !== 'string' || !text.trim()) {
        return;
      }
      const { current: editor } = editorRef;
      if (!editor) {
        return;
      }
      editor.update(() => {
        const htmlText = marked(text, { async: false }) as string;
        const parser = new DOMParser();
        const dom = parser.parseFromString(htmlText, 'text/html');
        const nodes = $generateNodesFromDOM(editor as LexicalEditor, dom);

        const isInserted = insertNode(strategy, nodes);
        if (!isInserted) {
          return;
        }

        const removeUpdateListener = editor.registerUpdateListener(
          ({ editorState }) => {
            removeUpdateListener();
            scrollToNode(editor, nodes[0]);
            highlightNodes(
              editor,
              nodes,
              'ed-node-inserted',
              'ed-node-inserted--out',
              300,
            );
          },
        );
      });
    },
  }));
};

export default useMethods;
