import { type ReactNode, memo } from 'react';
import { isEqual } from 'lodash';
import { Box, type BoxProps, useTheme } from '@mui/joy';

type DualPanelsProps = {
  leftPanel: ReactNode;
  rightPanel?: ReactNode;
  rightPanelBackGround?: string;
} & BoxProps;

const DualPanels = (props: DualPanelsProps) => {
  const { leftPanel, rightPanel, rightPanelBackGround, sx, ...rest } = props;

  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        ...sx,
      }}
      {...rest}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flex: 1,
          justifyContent: 'center',
          px: 4,
          button: {
            '--joy-fontWeight-lg': 400,
            '--variant-softColor': theme.palette.neutral.softBg,
            '--variant-softDisabledBg':
              theme.palette.mode === 'dark'
                ? theme.palette.neutral[400]
                : theme.palette.neutral.softHoverBg,
            '--variant-softActiveColor': theme.palette.neutral.softBg,
            '--variant-softBg': theme.palette.text.primary,
            '--variant-softHoverBg':
              theme.palette.mode === 'dark'
                ? theme.palette.neutral['300']
                : theme.palette.neutral['700'],
            '--variant-softActiveBg':
              theme.palette.mode === 'dark'
                ? theme.palette.neutral['400']
                : theme.palette.neutral['600'],
            '--Button-radius': `${parseInt(theme.radius.md, 10) * 1.25}px`,
          },
          '--joy-radius-sm': `${parseInt(theme.radius.md, 10) * 1.25}px`,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            py: 4,
            width: '100%',
            maxWidth: 'var(--left-max-width, 21rem)',
          }}
        >
          {leftPanel}
        </Box>
      </Box>
      <Box
        sx={{
          '&': {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            '@media (max-width: 767px)': {
              display: 'none',
            },
            backgroundColor: 'var(--joy-palette-neutral-800)',
            '--joy-palette-text-primary': 'var(--joy-palette-neutral-100)',
            px: 2,
            button: {
              color: 'var(--joy-palette-neutral-100)',
              backdropFilter: 'blur(0.125rem)',
              '--variant-outlinedBorder': 'var(--joy-palette-neutral-100)',
              '--variant-outlinedHoverBg':
                'color-mix(in srgb, var(--joy-palette-neutral-100) 10%, transparent)',
              '--variant-outlinedActiveBg':
                'color-mix(in srgb, var(--joy-palette-neutral-100) 20%, transparent)',
            },
          },
        }}
      >
        {!!rightPanelBackGround && (
          <video
            autoPlay
            muted
            loop
            src={rightPanelBackGround}
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              top: 0,
              left: 0,
              opacity: 0.5,
            }}
          >
            Your browser does not support the video tag.
          </video>
        )}
        {rightPanel}
      </Box>
    </Box>
  );
};

export default memo(DualPanels, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps),
);
