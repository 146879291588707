import { type DefaultRootState } from 'react-redux';
import { createCachedSelector } from 're-reselect';

import { type RemarkType } from 'app/entities';

const remarksById = createCachedSelector(
  (state: DefaultRootState, resourceId: number | null | undefined) =>
    !resourceId ? null : state.content.data[resourceId],
  (material): Pick<RemarkType, 'type' | 'id'>[] => {
    if (!material) {
      return [];
    }
    return material.remarks.map((item) => ({
      type: item.type,
      id: item.id,
    }));
  },
)((_state_, resourceId) => resourceId);

export default (resourceId?: number | null) =>
  (state: DefaultRootState): Pick<RemarkType, 'type' | 'id'>[] => {
    return remarksById(state, resourceId);
  };
