import { type PropsWithChildren, memo, useMemo } from 'react';
import { type StyleProp, type TextStyle } from 'react-native';
import { createUseStyles } from 'react-jss';
import isEqual from 'lodash.isequal';

import { useThemeColor, useFontProps } from 'hooks';

import { unit, styleSheetToCss } from 'utils';

const defaultProps = {
  weight: 400,
};

type TextProps = {
  style?: StyleProp<TextStyle>;
  size?: number | string;
  weight?: 400 | 500 | 600;
  lightColor?: string;
  darkColor?: string;
  numberOfLines?: number;
} & typeof defaultProps;

const Text = (props: PropsWithChildren<TextProps>) => {
  const {
    style,
    size,
    weight,
    numberOfLines,
    lightColor,
    darkColor,
    children,
  } = props;

  const color = useThemeColor({
    light: lightColor || '#000000',
    dark: darkColor || '#FFFFFF',
  });

  const classes = useStyles({
    color,
    size,
    numberOfLines,
    weight,
  });

  const textStyle = useMemo(() => styleSheetToCss(style), [style]);

  return (
    <div className={classes.text} style={textStyle}>
      {children}
    </div>
  );
};

Text.defaultProps = defaultProps;

const useStyles = createUseStyles<
  'text',
  {
    color?: string;
    size?: number | string;
    numberOfLines?: number;
    weight?: number;
  }
>({
  text: (props) => ({
    fontSize:
      typeof props.size === 'number' ? unit(props.size) : props.size || '1em',
    fontWeight: props.weight,
    color: props.color,
    ...(props.numberOfLines
      ? {
          display: '-webkit-box',
          lineClamp: props.numberOfLines,
          WebkitLineClamp: props.numberOfLines,
          textOverflow: 'ellipsis',
          boxOrient: 'vertical',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
        }
      : undefined),
  }),
});

export default memo(Text, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps),
);
