import {
  takeEvery,
  put,
  cancel,
  type SagaReturnType,
} from 'redux-saga/effects';

import Alert from 'components/Alert';

import { call } from 'store/utils/saga/effects';

import network from 'lib/network';
import * as actions from '../actions';

export const config = {
  action: actions.loadRelated.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.loadRelated>) {
  const { contentId } = action.payload;

  const { errors, data } = yield* call(() =>
    network.request<any>(`content/${contentId}/related`).get(),
  );
  if (errors || !data) {
    Alert.error(errors?.[0].message || 'Server error 12');
    yield cancel();
    return;
  }
  yield put(actions.loadRelatedDone(contentId, data));
  yield put(actions.loadById(data));
}
