import { Platform } from 'react-native';
import { takeEvery, cancel, put } from 'redux-saga/effects';

import * as api from 'services/api';
import { type InitResult } from 'services/api/credentials';

import { call } from 'store/utils/saga/effects';

import * as actions from '../actions';

export const config = {
  action: actions.checkAuth.type,
  method: takeEvery,
};

export function* func() {
  // const navigationState = navigation.getState();
  // const { path, params } = navigationState?.routes[navigationState?.index] || {} as any;
  const isOAuthLink = false; // /^\/welcome.*/.test(path) && params?.access && params?.refresh;
  let credentialsInit: InitResult;
  if (isOAuthLink) {
    credentialsInit = yield* call(() => api.credentials.init(/* , params */));
  } else {
    credentialsInit = yield* call(() => api.credentials.init());
  }

  if (!credentialsInit?.hasSession) {
    yield put(actions.checkAuthDone('unauthorized'));
    yield cancel();
    return;
  }

  if (
    isOAuthLink &&
    Platform.OS === 'web' &&
    typeof window?.parent !== 'undefined'
  ) {
    window.parent.location.href = '/';
    yield cancel();
    return;
  }

  yield put(actions.checkAuthDone());
}
