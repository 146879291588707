import { memo } from 'react';
import { type DefaultRootState, useSelector } from 'react-redux';
import { Box, LinearProgress, Typography } from '@mui/joy';

import Body from '../elements/Body';

const ProcessingLayout = () => {
  const upload = useSelector(
    (state: DefaultRootState) => state.plus.request.upload,
  );

  return (
    <Body>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        height={240}
      >
        <Typography fontSize={18} textAlign="center">
          We are processing your data,
          <br />
          it won&rsquo;t take long
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="stratch"
          mt={2}
          gap={1}
        >
          <Typography
            color="neutral"
            fontSize={12}
            fontWeight={500}
            textAlign="center"
          >
            {upload?.count === 1 && 'preparing'}
            {Number(upload?.count) > 1 &&
              `${upload?.percent.toFixed(2)}% uploaded`}
          </Typography>
          <LinearProgress
            variant="plain"
            {...(!!upload?.percent && {
              determinate: true,
              value: upload?.percent,
            })}
          />
        </Box>
      </Box>
    </Body>
  );
};

export default memo(ProcessingLayout);
