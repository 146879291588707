import React, { memo, useCallback, useMemo, type MouseEvent } from 'react';
import { StyleSheet } from 'react-native';
import moment from 'moment-timezone';
import { Typography, Box, Avatar } from '@mui/joy';

import type { AuthorType, UserType } from 'app/entities';
import { unit, guard } from 'utils';

import Icon from 'components/LegacyIcon';

import { useNavigate } from 'navigation/hooks';

type AuthorBlockProps = {
  data: AuthorType | UserType;
  eventType: 'created' | 'completed' | 'commented';
  eventDescription?: string;
  eventTime?: string;
};

const AuthorBlock = (props: AuthorBlockProps) => {
  const { data, eventType, eventTime, eventDescription } = props;

  const navigate = useNavigate();

  const handleAuthorPress = useCallback(
    (
      event: MouseEvent<HTMLDivElement>,
      value: UserType | AuthorType | null,
    ) => {
      event.stopPropagation();

      if (guard.isUser(value)) {
        navigate('Profile', {
          login: value.login,
        });
        return;
      }
      if (guard.isAuthor(value) && value.owner) {
        navigate('Profile', {
          login: value.owner.login,
        });
        return;
      }
      if (guard.isAuthor(value) && !value.owner) {
        navigate('Author', {
          id: value.id,
        });
      }
    },
    [],
  );

  const photoFinal: string | undefined = useMemo(() => {
    if (guard.isUser(data) && data?.photo) {
      return data.photo.url;
    }
    if (guard.isAuthor(data) && data?.owner?.photo) {
      return data.owner.photo.url;
    }
    if (guard.isAuthor(data) && data?.photo) {
      return data.photo.url;
    }
    return undefined;
  }, [data]);

  const nameFinal = useMemo(() => {
    let name = '';
    let surname = '';
    if (guard.isUser(data)) {
      name = data.name;
      surname = data.surname;
    }
    if (guard.isAuthor(data) && data?.owner) {
      name = data.owner.name;
      surname = data.owner.surname;
    }
    if (guard.isAuthor(data) && !data?.owner) {
      name = data.name || '';
      surname = data.surname || '';
    }
    if (!name && !surname) {
      return 'Guest';
    }
    const result = [];
    if (name) {
      result.push(name);
    }
    if (surname) {
      result.push(surname);
    }
    return result.join(' ');
  }, [data]);

  const eventTimeText = useMemo(() => {
    const diff = moment().diff(moment(eventTime));
    if (diff <= 1000 * 60 * 60 * 24) {
      return moment(eventTime).fromNow();
    }
    if (moment().isSame(moment(eventTime), 'day')) {
      return moment(eventTime).format('h:mm:ss');
    }
    if (moment().isSame(moment(eventTime), 'month')) {
      return moment(eventTime).format('MMM, D   h:mm');
    }
    if (moment().isSame(moment(eventTime), 'year')) {
      return moment(eventTime).format('MMM, D   h:mm');
    }
    return moment(eventTime).format('MMM, D YYYY   h:mm');
  }, [eventTime]);

  return (
    <Box
      onClick={(event) => handleAuthorPress(event, data)}
      display="flex"
      flexDirection="row"
      alignItems="center"
      flex={1}
    >
      <Avatar src={photoFinal} sx={{ '--Avatar-size': '24px' }} />
      {['created', 'commented'].includes(eventType) && (
        <Icon name="ActivityAdded28" size={24} style={styles.eventIcon} />
      )}
      {['completed'].includes(eventType) && (
        <Icon name="ActivityCompleted28" size={24} style={styles.eventIcon} />
      )}
      <Typography fontSize={12}>{nameFinal}</Typography>
      {!!eventDescription && (
        <>
          <Typography fontSize={12} mx={0.5}>
            •
          </Typography>
          <Typography fontSize={12}>{eventDescription}</Typography>
        </>
      )}
      {eventTime && (
        <>
          <Typography fontSize={12} mx={0.5}>
            •
          </Typography>
          <Typography fontSize={12}>{eventTimeText}</Typography>
        </>
      )}
    </Box>
  );
};

const styles = StyleSheet.create({
  eventIcon: {
    marginLeft: -10,
    marginRight: unit(10),
  },
});

export default memo(AuthorBlock);
