import { createAction } from '@reduxjs/toolkit';
import type {
  CollectionType,
  MaterialType,
  PoorCollectionType,
  PoorMaterialType,
  RagMaterialType,
} from 'app/entities';
import camelcaseKeys from 'camelcase-keys';

export const askSearchResult = createAction(
  'socket/ask_search_result',
  (payload: {
    data: (
      | MaterialType
      | PoorMaterialType
      | CollectionType
      | PoorCollectionType
      | RagMaterialType
    )[];
    metadata: {
      replace: boolean;
      isMaterialsExists: boolean;
      metadataHighlights: {
        highlights: string[];
      }[];
    };
  }) => ({
    payload: camelcaseKeys<typeof payload>(payload, { deep: true }),
  }),
);

export const askSearchClear = createAction(
  'socket/ask_search_clear',
  (payload: { message: string }) => ({
    payload: camelcaseKeys<typeof payload>(payload, { deep: true }),
  }),
);
