import { createAction } from '@reduxjs/toolkit';

import type {
  CollectionType,
  MaterialType,
  SourceMaterialType,
} from 'app/entities';

export const clear = createAction('MY_CONTENT/CLEAR');

export const addMaterialSetData = createAction(
  'MY_CONTENT/ADD_MATERIAL_SET_DATA',
  (data: SourceMaterialType) => ({
    payload: {
      data,
    },
  }),
);

export const addMaterialClearData = createAction(
  'MY_CONTENT/ADD_MATERIAL_CLEAR_DATA',
);

export const addMaterialSetLastCreatedId = createAction(
  'MY_CONTENT/ADD_MATERIAL_SET_LAST_CREATED_ID',
  (id: number | null) => ({
    payload: {
      id,
    },
  }),
);

export const addMaterial = createAction(
  'MY_CONTENT/ADD_MATERIAL',
  (data?: SourceMaterialType) => ({
    payload: data,
  }),
);

export const addMaterialDone = createAction('MY_CONTENT/ADD_MATERIAL_DONE');

export const editMaterial = createAction(
  'MY_CONTENT/EDIT_MATERIAL',
  (material?: MaterialType, options?: { showSuccessToast: boolean }) => ({
    payload: {
      material,
      options,
    },
  }),
);

export const editMaterialDone = createAction('MY_CONTENT/EDIT_MATERIAL_DONE');

export const addPlaylist = createAction(
  'MY_CONTENT/ADD_PLAYLIST',
  (
    data: Pick<CollectionType, 'title'> &
      Partial<
        Pick<
          CollectionType,
          'description' | 'cover' | 'isPrivate' | 'materialsMetadata'
        >
      >,
  ) => ({
    payload: {
      title: data.title,
      description: data.description,
      cover: data?.cover,
      isPrivate: data.isPrivate || false,
      isSmartListEnabled: false,
      materialsMetadata: data.materialsMetadata || [],
    },
  }),
);

export const addPlaylistDone = createAction(
  'MY_CONTENT/ADD_PLAYLIST_DONE',
  (isError = false) => ({
    payload: {
      isError,
    },
  }),
);

export const createPlaylistAddSelectedId = createAction(
  'MY_CONTENT/CREATE_PLAYLIST_ADD_SELECTED_ID',
  (ids: number | number[]) => ({
    payload: {
      ids,
    },
  }),
);

export const createPlaylistToggleSelectedId = createAction(
  'MY_CONTENT/CREATE_PLAYLIST_TOGGLE_SELECTED_ID',
  (ids: number | number[]) => ({
    payload: {
      ids,
    },
  }),
);

export const createPlaylistRemoveSelectedId = createAction(
  'MY_CONTENT/CREATE_PLAYLIST_REMOVE_SELECTED_ID',
  (ids: number | number[]) => ({
    payload: {
      ids,
    },
  }),
);

export const createPlaylistResortContent = createAction(
  'MY_CONTENT/CREATE_PLAYLIST_RESORT_CONTENT_IDS',
  (ids: number[]) => ({
    payload: {
      ids,
    },
  }),
);

export const createPlaylistClearSelectedIds = createAction(
  'MY_CONTENT/CREATE_PLAYLIST_CLEAR_SELECTED_IDS',
);
