import { createReducer } from '@reduxjs/toolkit';
import md5 from 'blueimp-md5';

import { guard } from 'utils';

import type { SearchModalStore } from './types';
import * as actions from './actions';

const initialState: SearchModalStore = {
  list: {
    sequence: [],
    map: {},
    paginationInfo: {
      hasNext: false,
      nextCursor: null,
    },
    meta: {
      resultWithFilterHash: md5(
        JSON.stringify({ text: '', type: 'all', tags: [] }),
      ),
      isFirstPageLoading: false,
      isFirstPageLoaded: false,
      isNextPageLoading: false,
      isNextPageLoaded: false,
    },
  },
  filter: {
    text: '',
    type: {
      options: [],
      value: 'all',
    },
    tags: [],
    meta: {
      isLoading: false,
      isLoaded: false,
    },
  },
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(actions.clear, (state) => {
      const cloned = JSON.parse(JSON.stringify(initialState));
      state.list.sequence = cloned.list.sequence;
      state.list.map = cloned.list.map;
      state.list.paginationInfo = cloned.list.paginationInfo;
      state.filter.text = '';
      state.filter.type.value = 'all';
      state.filter.tags = [];
      state.list.meta = cloned.list.meta;
    })
    .addCase(actions.loadFilters, (state) => {
      state.filter.meta.isLoading = true;
    })
    .addCase(actions.loadFiltersDone, (state) => {
      state.filter.meta.isLoading = false;
      state.filter.meta.isLoaded = true;
    })
    .addCase(actions.setFilters, (state, action) => {
      state.filter.type.options = [...action.payload.type.options];
    })
    .addCase(actions.setFilterText, (state, action) => {
      state.filter.text = action.payload.value;
    })
    .addCase(actions.setFilterType, (state, action) => {
      state.filter.type.value = action.payload.value;
    })
    .addCase(actions.setFilterTags, (state, action) => {
      state.filter.tags = action.payload.value;
    })
    .addCase(actions.loadNextPage, (state, action) => {
      if (action?.payload?.reload) {
        state.list.meta.isFirstPageLoading = true;
      } else {
        state.list.meta.isNextPageLoading = true;
      }
    })
    .addCase(actions.loadNextPageDone, (state, action) => {
      const { reload } = action.payload;
      state.list.meta.isFirstPageLoading = false;
      state.list.meta.isNextPageLoading = false;
      if (reload) {
        state.list.meta.isFirstPageLoaded = true;
      } else {
        state.list.meta.isNextPageLoaded = true;
      }
      state.list.meta.resultWithFilterHash = md5(
        JSON.stringify({
          text: state.filter.text,
          type: state.filter.type.value,
          tags: state.filter.tags,
        }),
      );
    })
    .addCase(actions.addPage, (state, action) => {
      if (!action.payload.items) {
        return;
      }
      if (action.meta.reload) {
        state.list.sequence = [];
        state.list.map = {};
      }
      action.payload.items.forEach((item) => {
        if (item.id in state.list.map) {
          return;
        }
        let type: 'material' | 'collection' | 'unknown' = 'unknown';
        if (guard.isMaterial(item)) {
          type = 'material';
        }
        if (guard.isCollection(item)) {
          type = 'collection';
        }
        state.list.sequence.push({
          id: item.id,
          type,
        });
        state.list.map[item.id] = state.list.sequence.length - 1;
      });
      state.list.paginationInfo.nextCursor =
        action.payload.paginationInfo.nextCursor;
      state.list.paginationInfo.hasNext = action.payload.paginationInfo.hasNext;
    })
    .addCase(actions.logOut, (state) => {
      const closed = JSON.parse(JSON.stringify(state));
      state.list = closed.list;
    }),
);
