import {
  type ComponentType,
  type MutableRefObject,
  type ReactElement,
  type ReactNode,
  type JSXElementConstructor,
  memo,
  useCallback,
  useState,
  useEffect,
  useRef,
  useId,
} from 'react';
import {
  Platform,
  ScrollView,
  type LayoutChangeEvent,
  type RefreshControlProps,
  type ScrollViewProps,
  type StyleProp,
  type ViewStyle,
  type NativeSyntheticEvent,
  type NativeScrollEvent,
} from 'react-native';
import MasonryList from '@react-native-seoul/masonry-list';

import { guard } from 'utils';

import { View } from 'components/Themed';

import getNumColumnsByScheme from './methods/getNumColumnsByScheme';

import WebInfiniteScroll from './elements/WebInfiniteScroll';

const defaultProps = {
  type: 'masonry',
  scrollEmitter: 'list' as 'web-page' | 'list',
  hasNextPage: true as boolean,
};

interface ListProps<T> extends Omit<ScrollViewProps, 'refreshControl'> {
  type?: 'masonry' | 'flat';
  columnsScheme?: Record<number, number> | string;
  scrollEmitter?: 'web-page' | 'list';
  hasNextPage?: boolean;
  innerRef?: MutableRefObject<ScrollView | undefined>;
  loading?: boolean;
  refreshing?: RefreshControlProps['refreshing'];
  onRefresh?: RefreshControlProps['onRefresh'];
  refreshControl?: boolean;
  onEndReached?: () => void;
  onEndReachedThreshold?: number;
  style?: StyleProp<ViewStyle>;
  componentStyle?: StyleProp<ViewStyle>;
  data: T[];
  renderItem: ({
    item,
    i,
  }: {
    item: T;
    i: number;
  }) => ReactElement<any, string | JSXElementConstructor<any>>;
  LoadingView?: ComponentType<any> | ReactElement | null;
  ListHeaderComponent?: ReactNode | null;
  ListEmptyComponent?: ComponentType<any> | ReactElement | null;
  ListFooterComponent?: ComponentType<any> | ReactElement | null;
  ListHeaderComponentStyle?: StyleProp<ViewStyle>;
  contentContainerStyle?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  keyExtractor?: ((item: T | any, index: number) => string) | undefined;
  refreshControlProps?: Omit<RefreshControlProps, 'onRefresh' | 'refreshing'>;
  onLayout?: (event: LayoutChangeEvent) => void;
}

const List = <T,>(props: ListProps<T> & typeof defaultProps) => {
  const {
    style,
    componentStyle,
    type,
    columnsScheme,
    scrollEmitter,
    renderItem,
    hasNextPage,
    loading,
    onScroll,
    onEndReached,
    onLayout,
    ...masonryProps
  } = props;

  const componentId = useId();
  const masonryListRef = useRef<HTMLDivElement | any>();

  const [numColumns, setNumColumns] = useState(0);
  const [layoutWidth, setLayoutWidth] = useState<number | null>(null);

  useEffect(() => {
    if (layoutWidth === null) {
      return;
    }
    setNumColumns(getNumColumnsByScheme(layoutWidth, columnsScheme));
  }, [columnsScheme, layoutWidth]);

  const handleLayout = useCallback(
    (event: LayoutChangeEvent) => {
      const { layout } = event.nativeEvent;
      setLayoutWidth(layout.width);
      onLayout?.(event);
    },
    [onLayout],
  );

  const handleWebPageScroll = useCallback(() => {
    if (typeof document === 'undefined' || typeof window === 'undefined') {
      return;
    }
    const result = {
      nativeEvent: {
        contentOffset: {
          y:
            document.documentElement.scrollTop -
            (document.documentElement.clientTop || 0),
          x:
            document.documentElement.scrollLeft -
            (document.documentElement.clientLeft || 0),
        },
        contentSize: {
          width: document.body.clientWidth,
          height: document.body.clientHeight,
        },
        layoutMeasurement: {
          width: window.innerWidth,
          height: window.innerHeight,
        },
      },
      timeStamp: Date.now(),
    };
    onScroll?.(result as NativeSyntheticEvent<NativeScrollEvent>);
  }, []);

  useEffect(() => {
    if (
      scrollEmitter === 'web-page' &&
      typeof window !== 'undefined' &&
      Platform.OS === 'web'
    ) {
      window.addEventListener('scroll', handleWebPageScroll);
    }
    return () => {
      if (Platform.OS === 'web' && typeof window !== 'undefined') {
        window.removeEventListener('scroll', handleWebPageScroll);
      }
    };
  }, []);

  useEffect(() => {
    const { current: element } = masonryListRef;
    if (
      Platform.OS !== 'web' ||
      numColumns === 0 ||
      !guard.isHTMLDivElement(element)
    ) {
      return undefined;
    }
    const elementWrapper = element.parentNode as HTMLDivElement | null;
    if (!elementWrapper) {
      return undefined;
    }
    const componentListClass = `component-list-${componentId.replace(/:/g, '')}`;
    const componentListWrapperClass = `${componentListClass}`;
    element.classList.add(componentListClass);
    elementWrapper.classList.add(componentListWrapperClass);
    const webStyles = document.createElement('style');
    if (scrollEmitter === 'web-page') {
      webStyles.innerHTML += `
        .${componentListWrapperClass},
        .${componentListClass} {
          overflow: unset;
          overflow-x: unset;
        }
      `;
    }
    webStyles.innerHTML += `
      .${componentListClass},
      .${componentListClass} > div,
      .${componentListClass} > div > div,
      .${componentListClass} > div > div:nth-child(2) > div,
      .${componentListClass} > div > div:nth-child(2) > div > div {
        z-index: unset;
      }
    `;
    document.head.appendChild(webStyles);
    return () => {
      webStyles.remove();
    };
  }, [numColumns, scrollEmitter, componentId]);

  if (type === 'flat') {
    console.log('NEED TO IMPLEMENT LIST.TYPE = "FLAT"');
    return null;
  }

  if (type === 'masonry') {
    return (
      <View style={style} onLayout={handleLayout}>
        {numColumns > 0 && (
          <MasonryList
            innerRef={masonryListRef}
            style={componentStyle}
            numColumns={numColumns}
            renderItem={renderItem as any}
            loading={loading}
            // @ts-ignore
            onScroll={
              scrollEmitter === 'list' || Platform.OS !== 'web'
                ? onScroll
                : undefined
            }
            onEndReached={
              scrollEmitter === 'list' || Platform.OS !== 'web'
                ? onEndReached
                : undefined
            }
            {...masonryProps}
          />
        )}
        {scrollEmitter === 'web-page' && Platform.OS === 'web' && (
          <WebInfiniteScroll
            loading={loading}
            hasNextPage={hasNextPage}
            onLoadMore={onEndReached}
          />
        )}
      </View>
    );
  }

  return null;
};

List.defaultProps = defaultProps;

export default memo(List);
