import { takeLatest, put, cancel } from 'redux-saga/effects';
import type { SagaReturnType } from 'redux-saga/effects';

import * as api from 'services/api';

import { call } from 'store/utils/saga/effects';

import network from 'lib/network';
import * as actions from '../actions';

export const config = {
  action: actions.doPasswordUpdate.type,
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof actions.doPasswordUpdate>) {
  const { token, password } = action.payload;

  const { errors } = yield* call(() =>
    network
      .request<{
        closed: boolean;
      }>('/auth/password-reset/set-password', {
        baseUrl: process.env.PROTECTED_API_URL || '/api',
      })
      .body({ token, password })
      .post(),
  );

  if (errors) {
    yield put(actions.doPasswordUpdateDone(errors[0]?.message));
    yield cancel();
    return;
  }

  yield put(actions.doPasswordUpdateDone());
}
