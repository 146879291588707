import { memo } from 'react';
import {
  Select,
  Option,
  type SelectProps,
  selectClasses,
  ListDivider,
} from '@mui/joy';

import Icon from 'ui/Icon';

type SelectInputProps = SelectProps<any, any> & {
  options: {
    value: string;
    label: string;
  }[];
};

const SelectInput = (props: SelectInputProps) => {
  const { options, ...rest } = props;

  return (
    <Select
      variant="plain"
      indicator={<Icon color="inherit" name="chevron-down" size="sm" />}
      slotProps={{
        button: {
          sx: {
            borderRadius: (theme) => theme.radius.xl,
          },
        },
        root: {
          sx: {
            minHeight: '2.125em',
            [`& .${selectClasses.indicator}`]: {
              transition: '0.2s',
              [`&.${selectClasses.expanded}`]: {
                transform: 'rotate(-180deg)',
              },
            },
          },
        },
      }}
      {...rest}
    >
      {options.map((option) => {
        if (option.value === 'divider') {
          return <ListDivider key="divider" />;
        }
        return (
          <Option
            key={option.value}
            value={option.value}
            sx={{
              color: option.label === 'delete' ? 'red' : 'inherit',
            }}
          >
            {option.label}
          </Option>
        );
      })}
    </Select>
  );
};

export default memo(SelectInput);
