import { type SagaReturnType, put, delay, takeEvery } from 'redux-saga/effects';

import { actions as collectionActions } from 'store/nodes/playlist/slice';

import * as actions from '../actions';

export const config = {
  action: collectionActions.removeDone.type,
  method: takeEvery,
};

export function* func(
  action: SagaReturnType<typeof collectionActions.removeDone>,
) {
  const { id } = action.payload;
  const ids = Array.isArray(id) ? id : [id];
  yield delay(10);

  for (let key = 0; key < ids.length; key += 1) {
    yield put(actions.removeFromSequence('collection', ids[key]));
  }
}
