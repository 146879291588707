import { useSelector } from 'store/utils/redux/hooks';
import * as copilotStore from 'widgets/Copilot/store';

const useLayout = () => {
  const hasItems = useSelector(
    copilotStore.selectors.hasItems([
      { type: 'web' },
      { type: 'library-fulltext' },
      { type: 'global-fulltext' },
    ]),
  );
  if (!hasItems) {
    return 'Start';
  }
  return 'Regular';
};

export default useLayout;
