import { memo, useCallback } from 'react';
import {
  DialogTitle,
  Modal,
  ModalClose,
  ModalDialog,
  DialogContent,
} from '@mui/joy';
import { useDispatcher } from 'store/utils/redux/hooks';

import { useSelector } from 'react-redux';
import * as modalStore from 'widgets/modals/store';

const ProductVideo = () => {
  const dispatcher = useDispatcher();
  const { isOpen, params } = useSelector(
    modalStore.selectors.state('ProductVideo'),
  );

  const handleModalClose = useCallback(async () => {
    dispatcher.modal.close('ProductVideo');
  }, []);

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <ModalDialog
        variant="soft"
        size="lg"
        sx={{
          overflow: 'hidden',
          aspectRatio: '3/2',
          height: 'calc(100% - 128px)',
          maxWidth: 'calc(100% - 64px)',
          background: 'transparent',
          '--ModalClose-radius': '1.125em',
          '--Card-padding': 0,
        }}
      >
        <DialogContent>
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/JJ4RL_jURZA?si=jZO3syJQ5F6Gx10t?autoplay=1"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
};

export default memo(ProductVideo);
