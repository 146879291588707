import { memo, useMemo } from 'react';
import { Box, Card, CardContent, Typography, CardActions } from '@mui/joy';
import pluralize from 'pluralize';

import { useSelector } from 'store/utils/redux/hooks';
import type { ResourceType } from 'widgets/Copilot/store/types';
import * as contentStore from 'store/nodes/content';

import Icon from 'ui/Icon';

import SourceCard from '../ui/SourceCard';

type RelatedMaterialsProps = {
  resourceType: ResourceType;
  resourceId?: number | null;
};

const RelatedMaterials = (props: RelatedMaterialsProps) => {
  const { resourceType, resourceId } = props;

  const data = useSelector((state) => {
    if (resourceType === 'material') {
      return contentStore.selectors.dataById(resourceId)(state);
    }
    return null;
  });

  const sources = useMemo(() => {
    if (!data || data?.relatedIds?.length === 0) {
      return [];
    }
    return (
      data?.relatedIds
        ?.slice(0, 3)
        ?.map((id) => ({ type: 'Material', id }) as const) || null
    );
  }, [data?.relatedIds]);

  if (!sources || sources?.length === 0) {
    return null;
  }

  return (
    <Card
      variant="plain"
      sx={{
        backgroundColor: 'transparent',
        '@media (max-width: 767px)': {
          display: 'none',
        },
      }}
    >
      <CardContent orientation="horizontal">
        {sources.map((source) => (
          <SourceCard type={source.type} id={source.id} />
        ))}
      </CardContent>
      <CardActions sx={{ minHeight: 36, '--Card-padding': 0 }}>
        <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
          <Icon name="folder-open" fw weight="light" color="icon" />
          <Typography fontSize={13} fontWeight={600}>
            {pluralize('Related material', sources.length)}
          </Typography>
        </Box>
      </CardActions>
    </Card>
  );
};

export default memo(RelatedMaterials);
