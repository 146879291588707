import {
  cancel,
  put,
  type SagaReturnType,
  takeEvery,
} from 'redux-saga/effects';

import { call } from 'store/utils/saga/effects';
import network from 'lib/network';
import type { ModelType } from 'widgets/Copilot/store/types';
import { actions } from '../slice';

export const config = {
  action: actions.loadSelectedModel.type,
  method: takeEvery,
};

export function* func(
  action: SagaReturnType<typeof actions.loadSelectedModel>,
) {
  const { data, hasError, errors } = yield* call(() =>
    network.request<ModelType>('user/model').get(),
  );
  if (hasError || !data) {
    yield put(actions.loadSelectedModelDone());
    yield cancel();
    return;
  }

  yield put(actions.loadSelectedModelDone({ model: data }));
}
