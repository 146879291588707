import { type CSSProperties, useCallback, memo, useMemo } from 'react';
import {
  type TextStyle,
  type StyleProp,
  Platform,
  StyleSheet,
  TouchableOpacity,
} from 'react-native';
import { Box } from '@mui/joy';

import { useNavigate, useRoute } from 'navigation/hooks';

import { unit } from 'utils';

import { Text, View, BlurView } from 'components/Themed';
import Icon from 'components/LegacyIcon';
import SearchInput from 'components/SearchInput';
import ScreenFiltersDropdown from 'components/ScreenFiltersDropdown';

import { CopilotScopeSwitcher } from 'widgets/Copilot';
import CopilotInput from 'widgets/Copilot/ui/CopilotInput';

type HeaderProps = {
  style?: StyleProp<TextStyle>;
};

const DefaultHeader = (props: HeaderProps) => {
  const { style } = props;

  const navigate = useNavigate();
  const route = useRoute();

  const styleFinal = useMemo(() => {
    const result = {
      ...StyleSheet.flatten(styles.Header),
      ...StyleSheet.flatten(style),
    };
    if (Platform.OS === 'web') {
      (result as CSSProperties).position = 'fixed';
    }
    return result;
  }, [style]);

  const placeholder = useMemo(() => {
    const map = {
      Ask: 'Intelligent Knowledge Interface',
      Library: 'Library',
      Feed: 'Staff Picks',
      News: 'News',
    };
    return map[route.name as keyof typeof map] || route.name;
  }, [route.name]);

  const handleProfile = useCallback(() => {
    navigate('Profile', { login: 'my' });
  }, []);

  const handleCopilotFocus = useCallback(() => {
    if (!route.isScreen('Ask')) {
      navigate('Ask');
    }
  }, []);

  return (
    <BlurView
      style={styleFinal}
      darkColor="#00000055"
      lightColor="#FFFFFF55"
      factor={8}
    >
      <View style={styles.panel}>
        <View style={styles.title}>
          <Text size={22} weight="semi">
            {placeholder}
          </Text>
          <View style={styles.buttons}>
            <TouchableOpacity
              style={styles.button}
              onPress={handleProfile}
              activeOpacity={0.8}
            >
              <Icon name="PersonOutline28" size={28} />
            </TouchableOpacity>
          </View>
        </View>
        <View style={styles.wrapperSearchAndFilter}>
          {route.name === 'Ask' && <CopilotScopeSwitcher mx={2} mt={1} />}
          {route.name === 'Library' && (
            <>
              <SearchInput style={styles.searchInput} size="small" />
              <ScreenFiltersDropdown style={styles.screenFiltersDropdown} />
            </>
          )}
          {route.name !== 'Ask' && route.name !== 'Library' && (
            <Box
              mx={2}
              mt={1}
              flex={1}
              display="flex"
              flexDirection="column"
              alignItems="stretch"
            >
              <CopilotInput
                size="small"
                focusBorderSize="0.0666em"
                onFocus={handleCopilotFocus}
                multiline={false}
              />
            </Box>
          )}
        </View>
      </View>
    </BlurView>
  );
};

const styles = StyleSheet.create({
  Header: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 2,
  },
  searchInput: {
    flex: 1,
    width: 'auto',
    marginTop: unit(8),
    marginLeft: unit(16),
    marginRight: unit(8),
  },
  screenFiltersDropdown: {
    marginTop: unit(8),
    marginRight: unit(9),
    marginLeft: 0,
  },
  wrapperSearchAndFilter: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  panel: {
    flex: 1,
    maxWidth: unit(1280),
    paddingBottom: unit(8),
  },
  title: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: unit(16),
    marginTop: unit(20),
    flex: 1,
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: 1,
  },
  button: {},
  toolbar: {
    paddingHorizontal: unit(16),
    maxWidth: unit(362 + 32),
    alignSelf: 'center',
    width: '100%',
  },
  toolbarRow: {
    flexDirection: 'row',
    marginTop: unit(8),
  },
});

export default memo(DefaultHeader);
