import {
  type ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Box, Typography } from '@mui/joy';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import { type DropzoneRef } from 'react-dropzone';

import { stripTags } from 'utils';
import SwitchPrivacy from 'components/Plus/web/elements/SwitchPrivacy';

import * as userStore from 'store/nodes/user';
import DataInput from '../elements/DataInput';
import Body from '../elements/Body';
import AddButton from '../elements/AddButton';
import Dropzone from '../elements/DropZone';
import Text from '../elements/Text';

const AddLayout = () => {
  const [value, setValue] = useState({
    html: '',
    text: '',
    hasContent: false,
  });
  const [privacy, setPrivacy] = useState(
    (localStorage.getItem('plus-add-privacy') || 'true') === 'true',
  );

  const dispatcher = useDispatcher();
  const dropzoneRef = useRef<DropzoneRef | null>(null);
  const userProfile = useSelector(userStore.selectors.getMy);
  const isLegalUser = userProfile?.type === 'legal';

  useEffect(() => {
    if (isLegalUser) {
      setPrivacy(true);
      return;
    }
    const privacyValue = localStorage.getItem('plus-add-privacy') || 'true';
    setPrivacy(privacyValue === 'true');
  }, [isLegalUser]);

  const handleOpenDropzone = useCallback(() => {
    dropzoneRef?.current?.open?.();
  }, []);

  const handleAddClick = useCallback(() => {
    if (!value.hasContent) {
      return;
    }
    const isNote = !/https?:\/\//.test(value.text.trim());
    if (isNote) {
      dispatcher.plus.parseText({ value: value.html, privacy });
    } else {
      dispatcher.plus.parseText({
        value: stripTags(value.html.replace(/<br>/g, '\n')),
        privacy,
      });
    }
  }, [dispatcher.plus, value]);
  const handleAcceptedFiles = useCallback(async (files?: File[]) => {
    if (!files) {
      return;
    }
    dispatcher.plus.parseFiles({
      files: Array.isArray(files) ? files : [files],
      privacy,
    });
  }, []);

  const handleChange = useCallback(
    (state: { html: string; text: string; hasContent: boolean }) => {
      setValue({
        html: state.html,
        text: state.text,
        hasContent: state.hasContent,
      });
    },
    [],
  );

  const handlePrivacyChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setPrivacy(event.target.checked);
      localStorage.setItem('plus-add-privacy', String(event.target.checked));
    },
    [],
  );

  return (
    <Body>
      <Text
        style={{ marginBottom: '1.325em', marginTop: '-0.5em' }}
        darkColor="#B8B6BF"
        size={13}
      >
        Import your data
      </Text>
      <Dropzone
        ref={dropzoneRef}
        accept={{
          'application/pdf': ['.pdf'],
          'text/csv': ['.csv'],
          'text/plain': ['.txt'],
          'text/rtf': ['.rtf'],
          'text/markdown': ['.md'],
          'image/jpeg': ['.jpg'],
          'image/jpg': ['.jpg'],
          'image/png': ['.png'],
          'image/gif': ['.gif'],
          'image/webp': ['.webp'],
        }}
        onAcceptedFiles={handleAcceptedFiles}
      >
        <DataInput
          onCtrlEnter={handleAddClick}
          onChange={handleChange}
          onUploadPress={handleOpenDropzone}
        />
        <Box display="flex" flexDirection="column" gap={1}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent={isLegalUser ? 'flex-end' : 'space-between'}
            alignItems="center"
            mt={2}
          >
            {!isLegalUser && (
              <SwitchPrivacy onChange={handlePrivacyChange} value={privacy} />
            )}
            <AddButton onClick={handleAddClick} />
          </Box>
          <Typography
            fontSize={12}
            fontWeight={400}
            color="danger"
            sx={{
              height: !privacy ? 54 : 0,
              overflow: 'hidden',
              transition: '300ms height ease',
              textWrap: 'balance',
            }}
          >
            * If you make the uploaded material public, anyone in community will
            be able to see it. It is ok for web-originated pages, public blogs,
            etc, but mind your private data.
          </Typography>
        </Box>
      </Dropzone>
    </Body>
  );
};

export default memo(AddLayout);
