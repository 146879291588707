import {
  type SagaReturnType,
  takeEvery,
  put,
  cancel,
} from 'redux-saga/effects';

import { call } from 'store/utils/saga/effects';

import Alert from 'components/Alert';

import type { CollectionPermissionType, CollectionType } from 'app/entities';

import moment from 'moment-timezone';
import interaction from 'services/api/interaction/route';
import network from 'lib/network';

import { actions } from '../slice';

export const config = {
  action: actions.loadById.type,
  method: takeEvery,
};

const loadCollections = async (ids: number[]) => {
  const response = await Promise.all(
    ids.map((id) =>
      network.request<CollectionType>(`share/user/playlists/${id}`).get(),
    ),
  );
  let data: CollectionType[] = [];
  let error: Error | null = null;

  response.forEach((result, key) => {
    if (result.errors && result.errors?.[0]?.message === 'object_not_found') {
      data.push({
        id: ids[key],
        internalUrl: '',
        contentImages: [],
        materialsMetadata: [],
        cover: null,
        createdAt: '',
        description: '',
        duration: '',
        isCompleted: false,
        isPrivate: false,
        isShown: false,
        level: '',
        tags: [],
        title: '',
        updatedAt: '',
        userId: 0,
        deletedAt: moment().toISOString(),
      });
      return;
    }
    if (result.errors?.[0]?.message || !result.data) {
      error = result.errors?.[0] || new Error('Error server #15');
      data = [];
      return;
    }
    data.push(result.data);
  });

  return {
    data,
    error,
  };
};

const loadPermissions = async (ids: number[]) => {
  const response = await Promise.all(
    ids.map((id) =>
      network
        .request<
          CollectionPermissionType[]
        >(`share/user/playlists/${id}/actions`)
        .get(),
    ),
  );

  let data: Record<number, CollectionPermissionType[]> = {};
  let error: Error | null = null;

  response.forEach((result, index) => {
    if (result.errors?.[0]?.message || !result.data) {
      error = result.errors?.[0] || new Error('Error server #15');
      data = [];
      return;
    }
    data[ids[index]] = result.data;
  });

  return {
    data,
    error,
  };
};

const loadData = async (
  ids: number[],
): Promise<{
  collections: CollectionType[];
  error: Error | Error[] | null;
}> => {
  if (!ids || (Array.isArray(ids) && ids.length === 0)) {
    return {
      collections: [],
      error: null,
    };
  }
  const idsFinal = Array.isArray(ids) ? ids : [ids];

  const [playlists, permissions, interactionMap, pendingContentCount] =
    await Promise.all([
      loadCollections(idsFinal),
      loadPermissions(idsFinal),
      interaction.items('playlist', idsFinal),
      network
        .request<{
          pendingContentCount: Record<number, number | null>;
        }>('/user/playlists/pendingContentCount')
        .query({ resourceIds: idsFinal })
        .get(),
    ]);

  if (
    playlists.error ||
    !playlists.data ||
    interactionMap.error ||
    !interactionMap.data
  ) {
    return {
      collections: [],
      error: playlists.error || interactionMap.error,
    };
  }

  return {
    collections: playlists.data.map((collection) => ({
      ...collection,
      ...interactionMap.data?.[collection?.id],
      pendingMaterialsCount:
        pendingContentCount.data?.pendingContentCount?.[collection?.id],
      permissions: permissions.data?.[collection?.id],
    })),
    error: null,
  };
};

export function* func(action: SagaReturnType<typeof actions.loadById>) {
  const { payload } = action;
  if (!payload.id) {
    yield cancel();
    return;
  }

  const ids = !Array.isArray(payload.id) ? [payload.id] : payload.id;

  const { collections, error } = yield* call(() => loadData(ids));
  if (error || !collections.length) {
    Alert.error('Server error #14' || error);
    yield put(actions.loadByIdDone({ id: payload.id }));
    yield cancel();
    return;
  }

  yield put(actions.setItem({ data: collections }));
  yield put(actions.loadByIdDone({ id: payload.id }));
}
