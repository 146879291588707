import { memo, useCallback } from 'react';
import { type StyleProp, type ViewStyle } from 'react-native';
import pluralize from 'pluralize';
import { Box, Typography } from '@mui/joy';

import { useLibraryFilters } from 'hooks';

import { Text, View } from 'components/Themed';

import MenuButton from '../../elements/MenuButton';

type IconName =
  | 'GridView'
  | 'Playlist28'
  | 'School28'
  | 'ArticleOutline28'
  | 'YoutubeOutline28'
  | 'SourceOutline28';

type LibraryFiltersPanelProps = {
  style?: StyleProp<ViewStyle>;
  isSlim?: boolean;
};

const LibraryFiltersPanel = (props: LibraryFiltersPanelProps) => {
  const { style, isSlim } = props;
  const { items, selectedValue, handleToggle } = useLibraryFilters();

  const handlePress = useCallback(
    (event: any, context: any) => {
      handleToggle(context.data.id);
    },
    [handleToggle],
  );

  const getIconName = useCallback(
    (label: string) =>
      (({
        all: 'GridView',
        collection: 'Playlist28',
        course: 'School28',
        article: 'ArticleOutline28',
        video: 'YoutubeOutline28',
        source: 'SourceOutline28',
        link: 'CopyLink',
        pdf: 'FilePDF',
        image: 'Image',
        note: 'FileBlank',
      })[label.toLowerCase()] || 'Source28') as IconName,
    [],
  );

  return (
    <View style={style}>
      {!isSlim && (
        <Typography
          fontSize={17}
          mb={0.5}
          px={2.75}
          fontWeight={500}
          textColor="text.primary"
        >
          Filters
        </Typography>
      )}
      {isSlim && (
        <Typography
          fontSize={16}
          mb={1}
          fontWeight={500}
          textAlign="center"
          textColor="text.primary"
        >
          Filters
        </Typography>
      )}
      <Box
        display="flex"
        flexDirection="column"
        gap={0.25}
        alignItems="stratch"
      >
        {items.map((item) => (
          <MenuButton
            key={item.id}
            isSlim={isSlim}
            context={{ data: item }}
            onPress={handlePress}
            iconLeft={getIconName(item.label)}
            text={pluralize(item.label)}
            hoverLevel="level2"
            isEnabled={selectedValue.includes(item.id)}
          />
        ))}
      </Box>
    </View>
  );
};

export default memo(LibraryFiltersPanel);
