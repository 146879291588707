import {
  type ReactElement,
  type SyntheticEvent,
  useCallback,
  useState,
} from 'react';
import {
  TouchableOpacity,
  StyleSheet,
  type ViewStyle,
  type StyleProp,
} from 'react-native';

import { useThemeColor } from 'hooks';

import { Text } from 'components/Themed';
import { unit } from 'utils';

interface ItemProps {
  id: string;
  text: string;
  sizeByContent?: boolean;
  isSelected: boolean;
  isFirst: boolean;
  isLast: boolean;
  onPress?: (event: SyntheticEvent, context?: { id: string }) => void;
  style?: StyleProp<ViewStyle>;
}

const ItemTab = (props: ItemProps): ReactElement | null => {
  const {
    id,
    text,
    sizeByContent,
    isSelected,
    isFirst,
    isLast,
    onPress,
    style,
  } = props;

  const [flexGrow, setFlexGrow] = useState(1);

  const backgroundColor = useThemeColor(
    isSelected
      ? { light: '#000000', dark: '#ffffff' }
      : { light: 'transparent', dark: 'transparent' },
  );
  const borderColor = useThemeColor({ light: '#000000', dark: '#ffffff' });

  const handlePress = useCallback(
    (event: any) => {
      onPress?.(event, { id });
    },
    [onPress, id],
  );

  const handleTextLayout = useCallback(
    ({ nativeEvent }: any) => {
      const { layout } = nativeEvent;
      if (sizeByContent) {
        setFlexGrow(layout.width);
      }
    },
    [sizeByContent],
  );

  return (
    <TouchableOpacity
      style={[
        styles.Item,
        style,
        { flexGrow, backgroundColor, borderColor },
        isFirst ? styles.isFirst : undefined,
        isLast ? styles.isLast : undefined,
      ]}
      onPress={handlePress}
      activeOpacity={0.8}
    >
      <Text
        size={13}
        lightColor={isSelected ? '#ffffff' : '#000000'}
        darkColor={isSelected ? '#000000' : '#ffffff'}
        onLayout={handleTextLayout}
        style={styles.text}
      >
        {text}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  Item: {
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    marginRight: -1,
    borderStyle: 'solid',
  },
  text: {
    minWidth: unit(20),
    textAlign: 'center',
  },
  isFirst: {
    borderTopLeftRadius: unit(4),
    borderBottomLeftRadius: unit(4),
  },
  isLast: {
    borderTopRightRadius: unit(4),
    borderBottomRightRadius: unit(4),
    marginRight: 0,
  },
});

export default ItemTab;
