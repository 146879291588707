import {
  type ChangeEvent,
  useCallback,
  type MouseEvent,
  type FC,
  memo,
} from 'react';
import { isEqual } from 'lodash';
import {
  Box,
  Button,
  Radio,
  RadioGroup,
  ToggleButtonGroup,
  type BoxProps,
} from '@mui/joy';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as userStore from 'store/nodes/user';
import * as askStore from 'store/nodes/ask';

const ScopeSwitcher: FC<BoxProps> = (props) => {
  const { sx, ...rest } = props;
  const dispatcher = useDispatcher();

  const userProfile = useSelector(userStore.selectors.getMy);
  const scope = useSelector(askStore.selectors.scope);

  const handleScopeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.value) {
        return;
      }
      dispatcher.ask.setScope({
        scope: event.target.value as
          | 'web'
          | 'global-fulltext'
          | 'library-fulltext',
      });
    },
    [],
  );

  const handleScopeMobileChange = useCallback(
    (
      event: MouseEvent<HTMLElement>,
      value: 'web' | 'global-fulltext' | 'library-fulltext' | null,
    ) => {
      if (!value) {
        return;
      }
      dispatcher.ask.setScope({ scope: value });
    },
    [],
  );

  return (
    <Box
      display="flex"
      flexDirection="row"
      gap={6}
      sx={{
        '@media (max-width: 599px)': {
          gap: 0.75,
        },
        ...sx,
      }}
      {...rest}
    >
      <Box display="flex" flexDirection="row" alignSelf="center" gap={2}>
        <ToggleButtonGroup
          defaultValue="Web"
          variant="soft"
          size="sm"
          sx={{
            '--Button-minHeight': '24px',
            '--joy-fontSize-sm': '12px',
            '--joy-fontWeight-lg': 400,
            '--ButtonGroup-radius': '40px',
            '@media (min-width: 600px)': {
              display: 'none',
            },
          }}
          onChange={handleScopeMobileChange}
          value={scope}
          orientation="horizontal"
        >
          <Button value="web">Web</Button>
          <Button disabled={!userProfile} value="library-fulltext">
            Library
          </Button>
          <Button value="global-fulltext">IKI</Button>
        </ToggleButtonGroup>
        <RadioGroup
          orientation="horizontal"
          defaultValue="Web"
          value={scope}
          sx={{
            '@media (max-width: 599px)': {
              display: 'none',
            },
          }}
          onChange={handleScopeChange}
        >
          <Radio label="Web" color="neutral" value="web" />
          <Radio
            disabled={!userProfile}
            label="Library"
            color="neutral"
            value="library-fulltext"
          />
          <Radio label="IKI" color="neutral" value="global-fulltext" />
        </RadioGroup>
      </Box>
    </Box>
  );
};

export default memo(ScopeSwitcher, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps),
);
