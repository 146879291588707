import type { ResultLegacy, JobType, JobInput } from 'app/entities';
import request from 'services/api/request';

export default async (
  id?: number,
  params?: JobInput,
): Promise<ResultLegacy<JobType>> => {
  const { id: paramsId, ...body } = params || {};
  const finalId = id || paramsId || -1;
  const { data, error } = await request.post<JobType>(`/user/jobs/${finalId}`, {
    body,
  });
  return { data, error };
};
