import { memo, type MouseEvent, useCallback } from 'react';
import { Box, ChipDelete, Chip } from '@mui/joy';

import { useNavigate } from 'navigation/hooks';
import { useSelector } from 'store/utils/redux/hooks';
import * as searchStore from 'store/nodes/search';

import { capitalize } from 'utils';

import { type BoxProps } from '@mui/joy/Box';

const Tags = (props: BoxProps) => {
  const { ...rest } = props;
  const navigate = useNavigate();

  const tags = useSelector(searchStore.selectors.filterTags);

  const handleTagRemove = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (!tags) {
        return;
      }
      navigate('Ask', {
        tags: tags?.filter?.((item) => item !== event.currentTarget.dataset.id),
      });
    },
    [tags],
  );

  if (tags.length === 0) {
    return null;
  }

  return (
    <Box {...rest}>
      {tags.map((tag) => (
        <Chip
          size="lg"
          variant="outlined"
          key={tag}
          endDecorator={
            <ChipDelete
              slotProps={{ root: { 'data-id': tag } }}
              onDelete={handleTagRemove}
            />
          }
        >
          {capitalize(tag)}
        </Chip>
      ))}
    </Box>
  );
};

export default memo(Tags);
