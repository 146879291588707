import { createAction } from '@reduxjs/toolkit';
import * as uuid from 'uuid';
import camelcaseKeys from 'camelcase-keys';

export const messageWarning = createAction(
  'socket/messageWarning',
  (payload: { message: string; context: Record<string, string> }) => ({
    payload: {
      id: uuid.v4(),
      ...camelcaseKeys<typeof payload>(payload, { deep: true }),
    },
  }),
);

export const messageError = createAction(
  'socket/messageError',
  (payload: { message: string; context: Record<string, string> }) => ({
    payload: {
      id: uuid.v4(),
      ...camelcaseKeys<typeof payload>(payload, { deep: true }),
    },
  }),
);

export const messageInfo = createAction(
  'socket/messageInfo',
  (payload: { message: string; context: Record<string, string> }) => ({
    payload: {
      id: uuid.v4(),
      ...camelcaseKeys<typeof payload>(payload, { deep: true }),
    },
  }),
);
