import { type SagaReturnType, takeEvery, put, take } from 'redux-saga/effects';

import * as socketStore from 'store/nodes/socket';
import * as collectionStore from 'store/nodes/playlist';

import * as actions from 'store/nodes/screenCollection/actions';

export const config = {
  action: socketStore.actions.collectionUpdated.type,
  method: takeEvery,
};

export function* func(
  action: SagaReturnType<typeof socketStore.actions.collectionUpdated>,
) {
  const { payload } = action;
  const { collection_id: collectionId } = payload;
  yield put(collectionStore.actions.loadById({ id: collectionId }));
  yield take(collectionStore.actions.loadByIdDone.type);
  yield put(actions.loadNextMaterials());
}
