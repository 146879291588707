import { type PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { Context } from './context';
import type { Config } from './types';

const Provider = (props: PropsWithChildren<unknown>) => {
  const { children } = props;

  const [value, setConfig] = useState<Config>({});

  const handleUpdateConfig = useCallback(
    (config: Omit<Config, 'setConfig'>) => {
      setConfig(config);
    },
    [],
  );

  const desktopBarContextValue = useMemo(
    () => ({
      ...value,
      setConfig: handleUpdateConfig,
    }),
    [value],
  );

  return (
    <Context.Provider value={desktopBarContextValue}>
      {children}
    </Context.Provider>
  );
};

export default Provider;
