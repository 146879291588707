import { type DefaultRootState } from 'react-redux';
import { createCachedSelector } from 're-reselect';

import type { ModalType, ModalParams } from './types';

const selector = createCachedSelector(
  (state: DefaultRootState, name: ModalType) => name in state.modal.opened,
  (state: DefaultRootState, name: ModalType) =>
    state.modal.opened[name] || null,
  (isOpen, params) => ({
    isOpen,
    params,
  }),
)((_, name) => name);

export const state =
  <T extends ModalType>(name: T) =>
  (s: DefaultRootState) =>
    selector(s, name) as never as {
      isOpen: boolean;
      // @ts-ignore @todo fix it
      params: ModalParams[T];
    };
