import { type DefaultRootState } from 'react-redux';

export const getIdByLogin =
  (login: string | 'my') => (state: DefaultRootState) => {
    let targetLogin = login;
    if (targetLogin === 'my' && state.user.my) {
      targetLogin = state.user.my.login;
    }
    return state.user.data[targetLogin]?.id || null;
  };
