import { createContext, useContext, useCallback, useMemo } from 'react';
import type { ChangeEvent, FocusEvent, FormEvent, SyntheticEvent } from 'react';
import type {
  FormikState,
  FormikHandlers,
  FormikHelpers,
  FormikComputedProps,
} from 'formik';
import get from 'lodash.get';

type Change = (event: ChangeEvent<any>) => void;
type ValueChange = (value: any) => void;
type Focus = (event: FocusEvent<any>) => void;

interface BehaviorFormikProps {
  isLocked?: boolean;
}

type FormContextProps<Values = Record<string, any>> = FormikState<Values> &
  FormikHelpers<Values> &
  FormikHandlers &
  FormikComputedProps<Values> &
  BehaviorFormikProps;

interface FieldProps extends BehaviorFormikProps {
  value: string | string[] | number | boolean | Record<string, any>;
  error?: string;
  submitForm?: () => Promise<void>;
  onBlur: Focus;
  onChange: Change;
  onValueChange: ValueChange;
}

interface ButtonProps extends BehaviorFormikProps {
  handleSubmit: (e?: FormEvent<any>) => void;
  handleReset?: (e?: SyntheticEvent<any>) => void;
  isValid?: boolean;
}

export const FormContext = createContext<Partial<FormContextProps>>({});

export const useField = (name?: string): FieldProps | null => {
  const context = useContext(FormContext);
  const {
    values,
    errors,
    setFieldValue,
    handleChange,
    handleBlur,
    submitForm,
  } = context || {};

  const isConnected = context && name;
  const value = isConnected ? get(values, name, undefined) : undefined;
  const error = isConnected ? String(errors?.[name] || '') : undefined;

  const onValueChange = useCallback(
    (newValue) => {
      if (isConnected && name) {
        setFieldValue?.(name, newValue, false);
      }
    },
    [isConnected, name, setFieldValue],
  );
  const onBlur = useMemo(() => {
    if (isConnected && name) {
      return handleBlur?.(name);
    }
    return null;
  }, [isConnected, name, handleBlur]);
  const onChange = useMemo(() => {
    if (isConnected && name) {
      return handleChange?.(name);
    }
    return null;
  }, [isConnected, name, handleChange]);

  if (
    !name ||
    (typeof name as unknown) !== 'string' ||
    !context ||
    !onChange ||
    !onBlur
  ) {
    return null;
  }

  return {
    value,
    error,
    onBlur,
    onChange,
    onValueChange,
    submitForm,
  };
};

export const useButton = (): ButtonProps | null => {
  const context = useContext(FormContext);
  if (!context) {
    return null;
  }
  const { handleSubmit, handleReset, isValid, isLocked } = context;
  if (!handleSubmit) {
    return null;
  }
  return {
    handleSubmit,
    handleReset,
    isValid,
    isLocked,
  };
};
