import type { DefaultRootState } from 'react-redux';
import createCachedSelector from 're-reselect';

import { createIndexByResource } from '../utils';
import type { Resource } from '../types';

// Селектор для получения последовательности и атрибутов
const getSequence = (state: DefaultRootState) => state.copilot.sequence;
const getAttributes = (state: DefaultRootState) => state.copilot.attribute;

const sequenceSelector = createCachedSelector(
  getSequence,
  getAttributes,
  (_: any, resources: Resource | Resource[]) =>
    createIndexByResource(resources),
  (itemSequence, itemAttribute, resourceIndex) => {
    return itemSequence
      .filter((itemId) =>
        resourceIndex.includes(
          `${itemAttribute[itemId]?.resourceType}::${itemAttribute[itemId].resourceId || 'null'}`,
        ),
      )
      .map((itemId) => ({
        id: itemId,
        attribute: itemAttribute[itemId],
      }));
  },
)({
  keySelector: (_, resources) => createIndexByResource(resources),
});

export default (resource: Resource | Resource[]) =>
  (state: DefaultRootState) => {
    return sequenceSelector(state, resource);
  };
