import { type DefaultRootState } from 'react-redux';
import { createCachedSelector } from 're-reselect';

const selector = createCachedSelector(
  (state: DefaultRootState, id: number | string) => state.smartNote.data[id],
  (note) => note || null,
)((_state_, id) => id.toString());

export default (id: number | string) => (state: DefaultRootState) =>
  selector(state, id);
