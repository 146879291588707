import { memo, type ReactNode } from 'react';
import { Box, IconButton, Tooltip, type BoxProps, Stack } from '@mui/joy';

import { useCardContextHandlers } from 'hooks';
import { guard } from 'utils';

import Icon from 'ui/Icon';

import useData from '../model/useData';
import useIsIndexing from '../model/useIsIndexing';

import type { CardType, LegacyCardType } from '../types';

const defaultProps = {
  hasQueue: false,
  hasToCollection: false,
  hasLink: false,
  hasRemove: false,
  hasEdit: false,
};

type ActionsProps = BoxProps & {
  resourceType: CardType | LegacyCardType;
  resourceId: number;
  startElements?: ReactNode;
  hasQueue?: boolean;
  hasToCollection?: boolean;
  hasLink?: boolean;
  hasRemove?: boolean;
  hasEdit?: boolean;
  isHover?: boolean;
} & typeof defaultProps;

const Actions = (props: ActionsProps) => {
  const {
    resourceType,
    resourceId,
    startElements,
    hasQueue,
    hasToCollection,
    hasLink,
    hasRemove,
    hasEdit,
    isHover,
    sx,
    ...rest
  } = props;

  const data = useData(resourceType, resourceId);
  const { isIndexing, isIndexed } = useIsIndexing(data);

  const {
    handleQueue,
    handleToCollection,
    handleLink,
    handleEdit,
    handleRemove,
    handleAddRagMaterialLink,
  } = useCardContextHandlers(data);

  return (
    <Box
      display="flex"
      flexDirection="row"
      sx={{ width: '100%', ...sx }}
      {...rest}
    >
      <Stack
        direction="row"
        sx={{
          flex: 1,
          opacity: 1,
          transition: 'opacity 0.3s',
        }}
      >
        {startElements}
      </Stack>
      <Stack
        direction="row"
        sx={{
          opacity: 'var(--card-hover-opacity)',
          transition: 'opacity 0.3s',
          '@media (hover: none)': {
            opacity: 1,
          },
        }}
      >
        {!guard.isRagMaterial(data) && hasEdit && (
          <Tooltip
            arrow
            disableInteractive
            variant="outlined"
            size="sm"
            title={
              resourceType === 'material' ? 'Edit material' : 'Edit collection'
            }
          >
            <IconButton size="sm" onClick={handleEdit}>
              <Icon name="pen-to-square" fw weight="light" color="primary" />
            </IconButton>
          </Tooltip>
        )}
        {!guard.isRagMaterial(data) &&
          hasToCollection &&
          resourceType === 'material' && (
            <Tooltip
              arrow
              disableInteractive
              variant="outlined"
              size="sm"
              title="Add material to collection"
            >
              <IconButton size="sm" onClick={handleToCollection}>
                <Icon
                  family="kit"
                  name="sharp-light-bars-circle-plus"
                  fw
                  color="primary"
                />
              </IconButton>
            </Tooltip>
          )}
        {!guard.isRagMaterial(data) && hasLink && (
          <Tooltip
            arrow
            disableInteractive
            variant="outlined"
            size="sm"
            title="Copy link"
          >
            <IconButton size="sm" onClick={handleLink}>
              <Icon name="link" fw weight="light" color="primary" />
            </IconButton>
          </Tooltip>
        )}
        {!guard.isRagMaterial(data) && hasRemove && (
          <Tooltip
            arrow
            disableInteractive
            variant="outlined"
            size="sm"
            title="Delete"
          >
            <IconButton size="sm" onClick={handleRemove}>
              <Icon name="trash" weight="light" fw color="primary" />
            </IconButton>
          </Tooltip>
        )}
        {!guard.isRagMaterial(data) && hasQueue && (
          <Tooltip
            disableInteractive
            arrow
            variant="outlined"
            size="sm"
            title={
              !data?.isBookmarked ? 'Add to bookmarks' : 'Remove from bookmarks'
            }
          >
            <IconButton size="sm" onClick={handleQueue}>
              <Icon
                name="bookmark"
                fw
                weight={!data?.isBookmarked ? 'light' : 'solid'}
                color="primary"
              />
            </IconButton>
          </Tooltip>
        )}
        {guard.isRagMaterial(data) && data.isAdded && (
          <Tooltip
            arrow
            disableInteractive
            variant="outlined"
            size="sm"
            title="Added to library"
          >
            <Box
              display="flex"
              width={32}
              height={32}
              alignItems="center"
              justifyContent="center"
            >
              <Icon name="check" fw weight="light" color="primary" />
            </Box>
          </Tooltip>
        )}
        {guard.isRagMaterial(data) && !data.isAdded && (
          <Tooltip
            arrow
            disableInteractive
            variant="outlined"
            size="sm"
            title="Add to library"
          >
            <IconButton size="sm" onClick={handleAddRagMaterialLink}>
              <Icon name="plus-large" fw weight="light" color="primary" />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      <Stack alignSelf="flex-end" flexDirection="row">
        {isIndexing && (
          <Tooltip
            disableInteractive
            arrow
            variant="outlined"
            size="sm"
            title={
              <>
                Processing...
                <br />
                {!isIndexed &&
                  'Assistant are not available for this material yet'}
                {isIndexed &&
                  'Assistant is still working on previous version of this material yet'}
              </>
            }
          >
            <IconButton
              size="sm"
              color="danger"
              sx={{
                '&': {
                  '--variant-plainActiveBg': 'var(--variant-plainHoverBg)',
                },
                '&:hover': {
                  cursor: 'default',
                },
              }}
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <Icon
                name="sync"
                animate="spin"
                weight="light"
                fw
                color="inherit"
              />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
    </Box>
  );
};

Actions.defaultProps = defaultProps;

export default memo(Actions);
