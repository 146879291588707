import { memo } from 'react';
import { TouchableWithoutFeedback } from 'react-native';
import { Typography, useTheme } from '@mui/joy';

type PlaceholderProps = {
  onUploadPress?: () => void;
};
const Placeholder = (props: PlaceholderProps) => {
  const { onUploadPress } = props;

  const theme = useTheme();

  return (
    <Typography
      position="absolute"
      top={8}
      left={8}
      sx={{
        pointerEvents: 'none',
        zIndex: 1,
        color: theme.palette.neutral.solidBg,
      }}
    >
      {'Add one or many links or '}
      <TouchableWithoutFeedback onPress={onUploadPress}>
        <Typography
          component="span"
          display="initial"
          color="primary"
          sx={{
            cursor: 'pointer',
            textDecoration: 'underline',
            pointerEvents: 'all',
          }}
        >
          upload
        </Typography>
      </TouchableWithoutFeedback>
      , .pdf, .csv, .txt to library
    </Typography>
  );
};

export default memo(Placeholder);
