import {
  type SagaReturnType,
  takeEvery,
  cancel,
  put,
} from 'redux-saga/effects';
import moment from 'moment-timezone';

import network from 'lib/network';

import { call, select } from 'store/utils/saga/effects';
import * as appStore from 'store/nodes/app';
import * as authStore from 'store/nodes/auth';
import * as userProfile from 'store/nodes/user';
import * as modalStore from 'widgets/modals/store';
import { getRoute, navigate } from 'navigation/methods';

import { actions } from '../slice';

type MatchResult = {
  status:
    | 'invalid_code'
    | 'exists_stripe'
    | 'exists_appsumo'
    | 'already_matched'
    | 'success';
};

export const config = {
  action: [
    appStore.actions.prepared.type,
    authStore.actions.loginSuccess.type,
    actions.replaceTo.type,
  ],
  method: takeEvery,
};

function* setCode(code: string | null) {
  yield call(() => {
    localStorage.setItem(
      'appsumo',
      JSON.stringify({
        code,
        createdAt: moment().utc().format('YYYY-MM-DDTHH:mm:ssZ'),
      }),
    );
  });
}

function* getCode() {
  const route = getRoute<any>();
  if (!route.isScreen('Auth/WithAppSumo') && route.params?.code) {
    return null;
  }
  if (route.params?.code) {
    return route.params?.code as string;
  }
  const storedValue = yield* call(() => localStorage.getItem('appsumo'));
  if (!storedValue) {
    return null;
  }
  let code: string | null = null;
  let createdAt: string | null = null;
  try {
    const parsed = yield* call(() => JSON.parse(storedValue));
    code = parsed?.code;
    createdAt = parsed?.createdAt;
  } catch {
    return null;
  }
  if (!code || !createdAt) {
    return null;
  }

  const now = moment();
  const savedAt = moment(createdAt);

  if (now.diff(savedAt, 'minutes') > 10) {
    yield call(() => localStorage.removeItem('appsumo'));
    return null;
  }

  return code as string;
}

function* removeCode() {
  yield call(() => localStorage.removeItem('appsumo'));
}

function* match() {
  const code = yield* getCode();
  if (!code) {
    return;
  }

  const user = yield* select(userProfile.selectors.getMy);
  if (!user) {
    yield setCode(code);
    yield* call(() => navigate('Auth/WithAppSumo', {}, { replace: true }));
    return;
  }

  yield removeCode();

  const { data, hasError } = yield* call(() =>
    network
      .request<MatchResult>('user/match')
      .query({ appsumoCode: code })
      .post(),
  );

  if (hasError) {
    yield put(modalStore.actions.open('AppSumoMatch', { status: 'error' }));
    return;
  }

  if (data!.status === 'invalid_code') {
    yield put(
      modalStore.actions.open('AppSumoMatch', { status: 'invalid_code' }),
    );
    return;
  }

  if (data!.status === 'exists_stripe') {
    yield put(
      modalStore.actions.open('AppSumoMatch', {
        status: 'exists_stripe',
        context: { code },
      }),
    );
    return;
  }

  if (data!.status === 'exists_appsumo') {
    yield put(
      modalStore.actions.open('AppSumoMatch', { status: 'exists_appsumo' }),
    );
    return;
  }

  if (data!.status === 'already_matched') {
    yield put(
      modalStore.actions.open('AppSumoMatch', { status: 'already_matched' }),
    );
    return;
  }

  if (data!.status === 'success') {
    yield put(actions.loadState());
  }
}

function* replace(context?: Record<string, any>) {
  if (!context || !('code' in context)) {
    return;
  }
  const { code } = context;
  const { data, hasError } = yield* call(() =>
    network
      .request<MatchResult>('user/switch_subscription')
      .query({ appsumoCode: code })
      .post(),
  );

  if (hasError) {
    yield put(modalStore.actions.open('AppSumoMatch', { status: 'error' }));
    return;
  }

  if (data!.status === 'invalid_code') {
    yield put(
      modalStore.actions.open('AppSumoMatch', { status: 'invalid_code' }),
    );
    return;
  }

  if (data!.status === 'success') {
    yield put(actions.loadState());
  }
}

export function* func(
  action: SagaReturnType<
    | typeof appStore.actions.prepared
    | typeof authStore.actions.loginSuccess
    | typeof actions.replaceTo
  >,
) {
  if (
    action.type === appStore.actions.prepared.type ||
    action.type === authStore.actions.loginSuccess.type
  ) {
    yield* match();
    yield cancel();
    return;
  }
  if (
    action.type === actions.replaceTo.type &&
    action.payload.target === 'AppSumo'
  ) {
    yield* replace(action.payload.context);
  }
}
