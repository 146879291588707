import {
  type PropsWithChildren,
  memo,
  useCallback,
  useState,
  useMemo,
  cloneElement,
  useEffect,
} from 'react';
import { createUseStyles } from 'react-jss';

import { styleSheetToCss } from 'utils';

import type { DropdownPanelProps } from 'components/DropdownPanel/types';

import { useThemeColor } from 'hooks';

import Panel from './elements/Panel';

const DropdownPanel = (props: PropsWithChildren<DropdownPanelProps>) => {
  const {
    componentId,
    style,
    panelStyle,
    isOpen,
    anchor,
    button,
    children,
    transparent,
    onOpenQuery,
    onCloseQuery,
    onMouseEnter,
    onMouseLeave,
    onEscape,
  } = props;

  const [isOpenLocal, setIsOpenLocal] = useState(false);

  const titleColor = useThemeColor({ light: '#000000', dark: '#ffffff' });
  const contentColor = useThemeColor({ light: '#515151', dark: '#B8B6BF' });

  const classes = useStyle({
    titleColor,
    contentColor,
  });

  const handleMouseEnter = useCallback(() => {
    onMouseEnter?.();
  }, [onMouseEnter]);

  const handleMouseLeave = useCallback(() => {
    onMouseLeave?.();
  }, [onMouseLeave]);

  const handleOpen = useCallback(() => {
    if (typeof isOpen === 'undefined') {
      setIsOpenLocal(true);
      return;
    }
    onOpenQuery?.();
  }, [isOpen, onOpenQuery]);

  const handleOutsidePress = useCallback(() => {
    if (typeof isOpen === 'undefined') {
      setIsOpenLocal(false);
      return;
    }
    onCloseQuery?.();
  }, [isOpen, onCloseQuery]);

  const buttonRender = useMemo(() => {
    if (!button) {
      return null;
    }
    return cloneElement(button, {
      onClick: handleOpen,
      onPress: handleOpen,
    });
  }, [button, handleOpen]);

  const openState = useMemo(() => {
    if (typeof isOpen === 'undefined') {
      return isOpenLocal;
    }
    return isOpen;
  }, [isOpen, isOpenLocal]);

  const handleEscapePress = useCallback(
    (event: KeyboardEvent) => {
      if ((event.key || event.code) === 'Escape') {
        onEscape?.();
      }
    },
    [onEscape],
  );

  useEffect(() => {
    if (isOpen) {
      document.body.addEventListener('keydown', handleEscapePress);
    }

    return () => {
      if (isOpen) {
        document.body.removeEventListener('keydown', handleEscapePress);
      }
    };
  }, [isOpen, handleEscapePress]);

  return (
    <div
      data-component-id={componentId}
      className={classes.container}
      style={styleSheetToCss(style)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {buttonRender}
      <Panel
        isOpen={openState}
        anchor={anchor}
        transparent={transparent}
        onOutsidePress={handleOutsidePress}
        style={panelStyle}
      >
        {children}
      </Panel>
    </div>
  );
};

const useStyle = createUseStyles<
  'container',
  {
    titleColor?: string;
    contentColor?: string;
    transparent?: boolean;
  }
>({
  container: {
    color: (props) => props.contentColor,
    position: 'relative',
    zIndex: 100,
    '& > :first-child:focus-visible': {
      outline: 'none',
    },
  },
});

export default memo(DropdownPanel);
