import {
  type SagaReturnType,
  cancel,
  put,
  takeEvery,
} from 'redux-saga/effects';

import network from 'lib/network';

import { call, select } from 'store/utils/saga/effects';
import * as userStore from 'store/nodes/user';
import type { ModelType } from 'widgets/Copilot/store/types';

import { actions } from '../slice';

export const config = {
  action: actions.setSelectedModel.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.setSelectedModel>) {
  const userData = yield* select(userStore.selectors.getByLogin('my'));
  if (!userData) {
    yield put(actions.setSelectedModelDone());
    yield cancel();
    return;
  }

  const { data, hasError, errors } = yield* call(() =>
    network
      .request<ModelType>('user/model')
      .body({ id: action.payload.id })
      .post(),
  );
  if (hasError || !data) {
    yield put(actions.setSelectedModelDone());
    yield cancel();
    return;
  }

  yield put(actions.setSelectedModelDone({ id: data.id }));
}
