import { type DefaultRootState } from 'react-redux';
import createCachedSelector from 're-reselect';

type Permissions = {
  hasRead: boolean;
  hasWrite: boolean;
  hasInvite: boolean;
  hasDelete: boolean;
  hasPrivacyChange: boolean;
};

// Функция для создания ключа кеша
const cacheKeyCreator = (
  state: DefaultRootState,
  collectionId: number,
  userId: number | 'my',
) => `${collectionId}-${userId}`;

const userCollectionPermissionSelector = createCachedSelector(
  (state: DefaultRootState, collectionId: number, userId: number | 'my') =>
    state.playlist.data[collectionId],
  (state, collectionId, userId) =>
    (userId === 'my' ? state.user.my?.id || null : userId) as number | null,
  (collection, userId): Permissions => {
    const hasRead = !collection?.isPrivate;

    if (!collection?.permissions) {
      return {
        hasRead,
        hasWrite: false,
        hasInvite: false,
        hasDelete: false,
        hasPrivacyChange: false,
      };
    }

    const permission = collection.permissions.find(
      (item) => item.user.id === userId,
    );

    if (!permission) {
      return {
        hasRead,
        hasWrite: false,
        hasInvite: false,
        hasDelete: false,
        hasPrivacyChange: false,
      };
    }

    return {
      hasRead:
        permission.action.some(
          (action: { id: number; name: string }) => action.name === 'READ',
        ) || hasRead,
      hasWrite: permission.action.some(
        (action: { id: number; name: string }) => action.name === 'WRITE',
      ),
      hasInvite: permission.action.some(
        (action: { id: number; name: string }) => action.name === 'INVITE',
      ),
      hasDelete: permission.action.some(
        (action: { id: number; name: string }) => action.name === 'DELETE',
      ),
      hasPrivacyChange: permission.action.some(
        (action: { id: number; name: string }) =>
          action.name === 'CHANGE_PRIVACY',
      ),
    };
  },
)(
  cacheKeyCreator, // Используйте созданную функцию для создания ключа кеша
);

// Обертка для использования в useSelector
export default (collectionId: number, userId: number | 'my') =>
  (state: DefaultRootState) => {
    return userCollectionPermissionSelector(state, collectionId, userId);
  };
