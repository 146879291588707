import { type ReactElement, Fragment, memo } from 'react';

import pluralize from 'pluralize';

import type { FontSizeVariant } from 'font';

import Icon from 'components/LegacyIcon';
import { Typography, Box, type BoxProps } from '@mui/joy';
import { useThemeColor } from 'hooks';

type InfoProps = BoxProps & {
  list: string[];
  fontSize?: FontSizeVariant;
};

const Info = (props: InfoProps): ReactElement | null => {
  const { list, fontSize = 14, ...rest } = props;
  const listFinal = list.map((text) => text || '').filter(Boolean);
  const color = useThemeColor({ dark: '#9A99A2', light: '#888888' });

  if (!Array.isArray(listFinal)) {
    return null;
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      flexWrap="wrap"
      alignItems="center"
      columnGap={0.25}
      {...rest}
    >
      {listFinal.map((item, key) => (
        <Fragment key={item}>
          {/^[0-9]+pages$/.test(item) && (
            <Typography color="neutral" fontSize={fontSize}>
              {pluralize('page', parseInt(item, 10), true)}
            </Typography>
          )}
          {item !== '**by-iki**' && !/^[0-9]+pages$/.test(item) && (
            <Typography color="neutral" fontSize={fontSize}>
              {item}
            </Typography>
          )}
          {item === '**by-iki**' && (
            <>
              <Icon
                name="MarkerByIKI"
                size={(fontSize / 14) * 20}
                style={{ marginRight: 8 }}
              />
              <Typography color="neutral" fontSize={fontSize}>
                by IKI
              </Typography>
            </>
          )}
          {key < listFinal.length - 1 && (
            <span style={{ color, fontSize: 32, lineHeight: 0.5 }}>
              {' '}
              &middot;{' '}
            </span>
          )}
        </Fragment>
      ))}
    </Box>
  );
};

export default memo(Info);
