import { takeEvery, put, cancel } from 'redux-saga/effects';

import network from 'lib/network';

import { call } from 'store/utils/saga/effects';

import * as actions from '../actions';

export const config = {
  action: actions.initProtection.type,
  method: takeEvery,
};

export function* func() {
  const { data, errors } = yield* call(() =>
    network
      .request<any>('/analytics', {
        baseUrl: process.env.PROTECTED_API_URL || '/api',
      })
      .get(),
  );

  if (errors || !data) {
    yield put(actions.initProtectionDone());
    yield cancel();
    return;
  }

  yield put(actions.initProtectionDone());
}
