import { type DefaultRootState } from 'react-redux';
import { createCachedSelector } from 're-reselect';

const dataById = createCachedSelector(
  (state: DefaultRootState, resourceId: number) =>
    state.search.data[resourceId],
  (ragMaterial) => {
    if (!ragMaterial) {
      return null;
    }
    return ragMaterial;
  },
)((_state_, id) => id);
export default (id: number) => (state: DefaultRootState) => {
  return dataById(state, id);
};
