import qs from 'query-string';

import type {
  PaginationResult,
  PaginationInput,
  MaterialType,
  CollectionType,
  SearchItemType,
} from 'app/entities';

import { requestCursorModifier, guard, highlightText } from 'utils';
import network from 'lib/network';
import interaction from 'services/api/interaction/route';

type Filters = Record<string, any>;

type MetadataType = {
  isMaterialsExists?: boolean;
};

export default async (
  method:
    | 'GlobalFullText'
    | 'GlobalTitle'
    | 'LibraryFullText'
    | 'LibraryTitle'
    | 'Web',
  pagination: PaginationInput,
  filters?: Filters,
  extraParams?: Record<string, string | number>,
): Promise<PaginationResult<SearchItemType, { metadata: MetadataType }>> => {
  const query: Record<string, any> = {
    pageSize: pagination?.pageSize || 10,
    ...extraParams,
  };
  Object.entries(filters || {}).forEach(([key, value]) => {
    if (typeof value === 'string') {
      query[key] = value;
      return;
    }
    if (Array.isArray(value) && value.length > 0) {
      query[key] = value.join(',').trim();
      return;
    }
    if (typeof value === 'number') {
      query[key] = value.toString();
    }
  });

  if (pagination?.cursor) {
    query.cursor = pagination?.cursor;
  }

  if (!query.q && !query.tags) {
    return {
      data: {
        items: [],
        paginationInfo: {
          hasNext: false,
          nextCursor: null,
        },
        metadata: {},
      },
      error: null,
    };
  }

  const endpoint =
    {
      GlobalTitle: '/content/search',
      GlobalFullText: '/content/fullTextSearch',
      LibraryTitle: '/user/library/search',
      LibraryFullText: '/user/library/fullTextSearch',
      Web: '/web/search',
    }[method || 'GlobalTitle'] || '/content/search';

  const materialIds: number[] = [];
  const collectionIds: number[] = [];

  let metadata: MetadataType = {};
  const { data, errors } = await network
    .request<SearchItemType[]>(endpoint)
    .query(query)
    .get(
      requestCursorModifier((response) => {
        const item: SearchItemType[] = response.data.map(
          (element: MaterialType | CollectionType, index) => {
            if (guard.isMaterial(element)) {
              materialIds.push(element.id);
            }
            if (guard.isCollection(element)) {
              collectionIds.push(element.id);
            }
            const highlights = (response.metadata?.[index]?.highlights ||
              response.metadata?.metadataHighlights?.[index].highlights ||
              []) as string[];
            return {
              element,
              searchMeta: {
                highlights: highlights.map((text) =>
                  !query.q ? text : highlightText(text, query.q),
                ),
              },
            };
          },
        );
        metadata = response?.metadata;
        return {
          data: item,
          nextUrl: response?.nextUrl,
        };
      }),
    );

  if ((Array.isArray(errors) && errors.length > 0) || !data) {
    return {
      data: null,
      error: errors?.[0] || null,
    };
  }

  const ragExtraData: Record<string, { isAdded: boolean }> = {};
  if (method === 'Web') {
    const urls = data.items
      .map((item) =>
        guard.isRagMaterial(item.element)
          ? item.element.originalUrl
          : undefined,
      )
      .filter(Boolean);

    const { data: responseData } = await network
      .request('content/exist')
      .query({
        urls: btoa(JSON.stringify(urls)),
      })
      .get();
    if (Array.isArray(responseData) && responseData.length > 0) {
      data.items.forEach((item, key) => {
        ragExtraData[item.element.id] = { isAdded: responseData[key] || false };
      });
    }
  }

  const [materialInteractionsMap, collectionInteractionsMap] =
    await Promise.all([
      interaction.items('content', materialIds),
      interaction.items('playlist', collectionIds),
    ]);

  const next = qs.parseUrl(data.nextUrl || '');

  return {
    data: {
      items: (data?.items || []).map((item) => {
        return {
          element: {
            ...item.element,
            ...(guard.isMaterial(item.element)
              ? materialInteractionsMap?.data?.[item.element.id]
              : {}),
            ...(guard.isCollection(item.element)
              ? collectionInteractionsMap?.data?.[item.element.id]
              : {}),
            ...(guard.isRagMaterial(item.element)
              ? ragExtraData[item.element.id]
              : {}),
          },
          searchMeta: item.searchMeta,
        };
      }),
      paginationInfo: {
        hasNext: !!next?.query?.cursor,
        nextCursor: (next?.query?.cursor as string) || null,
      },
      metadata,
    },
    error: null,
  };
};
