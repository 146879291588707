import type { ResultLegacy } from 'app/entities';
import request from 'services/api/request';

interface Data {}

export default async (
  token: string,
  password: string,
): Promise<ResultLegacy<Data>> => {
  const { data, error } = await request.post<Data>(
    '/auth/password-reset/set-password',
    {
      body: {
        token,
        password,
      },
    },
  );
  if (error) {
    return {
      data: null,
      error,
    };
  }

  return {
    data,
    error,
  };
};
