import type { ResultLegacy, AuthRegisterType } from 'app/entities';
import request from 'services/api/request';

export default async (
  email: string,
  password: string,
  extraData?: Record<string, string> | null,
): Promise<ResultLegacy<AuthRegisterType>> => {
  const { data, error } = await request.post<AuthRegisterType>(
    '/auth/register',
    {
      body: {
        email,
        password,
        ...extraData,
      },
    },
  );
  if (error || !data?.email) {
    return {
      data: null,
      error,
    };
  }
  return {
    data,
    error,
  };
};
