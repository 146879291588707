import { memo, useCallback, useMemo, useState } from 'react';
import {
  type StyleProp,
  type ViewStyle,
  type GestureResponderEvent,
  StyleSheet,
  View,
} from 'react-native';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/joy';

import type { CollectionType } from 'app/entities';
import { unit } from 'utils';

import * as userProfile from 'store/nodes/user';

import Icon from 'ui/Icon';
import Button from 'components/Button';

import Item from './elements/Item';
import MenuButton from '../../../elements/MenuButton';

type CollectionsPanelProps = {
  style?: StyleProp<ViewStyle>;
  onPress?: (
    event: GestureResponderEvent,
    context: { data: CollectionType },
  ) => void;
  onAddPress?: (event: GestureResponderEvent) => void;
};

const CollectionsPanel = (props: CollectionsPanelProps) => {
  const { style, onPress, onAddPress } = props;

  const [isShowMore, setShowMore] = useState<boolean>(false);

  const user = useSelector(userProfile.selectors.getMy);

  const handleMore = useCallback(() => {
    setShowMore(!isShowMore);
  }, [isShowMore]);

  const handlePress = useCallback(
    (event: GestureResponderEvent, context: { data: CollectionType }) => {
      onPress?.(event, context);
    },
    [onPress],
  );

  const list = useMemo(() => {
    if (!user?.playlistIds || !user?.id) {
      return null;
    }
    const collapsedMax = 6;
    const items = isShowMore
      ? user?.playlistIds
      : user?.playlistIds.slice(0, collapsedMax);
    return {
      items,
      hasMore: user?.playlistIds.length > collapsedMax,
      showCount: items.length,
      totalCount: user?.playlistIds.length,
    };
  }, [isShowMore, user?.playlistIds]);

  if (!list) {
    return null;
  }

  return (
    <View style={style}>
      <View style={styles.header}>
        <Typography fontSize={16} fontWeight={400} textColor="text.secondary">
          Collections
        </Typography>
        <Button
          onPress={onAddPress}
          type="button-icon"
          variant="text"
          width={24}
          height={24}
        >
          <Icon name="plus" fw weight="light" size="xs" color="primary" />
        </Button>
      </View>
      {list.items.map((item) => (
        <Item key={item} id={item} onPress={handlePress} />
      ))}
      {list.hasMore && (
        <MenuButton
          onPress={handleMore}
          iconLeft={
            <Icon
              name={isShowMore ? 'angle-up' : 'angle-down'}
              fw={24}
              weight="light"
              color="primary"
              size="sm"
            />
          }
          text={
            isShowMore
              ? 'Show less'
              : `Show ${list.totalCount - list.showCount} more`
          }
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: unit(4),
    paddingHorizontal: unit(20),
  },
});

export default memo(CollectionsPanel);
