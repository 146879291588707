import {
  type ReactElement,
  type SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  Typography,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
} from '@mui/joy';

import { useNavigate, useRoute } from 'navigation/hooks';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';

import Alert from 'components/Alert';

import { useOnInputEnter } from 'hooks';

import DualPanels from 'widgets/DualPanels';

import videoBackground from './assets/videoBackground.mp4';

import { useScreenStyle } from './hooks';

const PasswordReset = (): ReactElement => {
  const navigate = useNavigate();
  const route = useRoute<'Auth/PasswordReset'>();
  const dispatcher = useDispatcher();
  const screenStyle = useScreenStyle();

  const [email, setEmail] = useState<string | undefined>(route.params.email);
  const [password, setPassword] = useState<string | null>(null);
  const [confirm, setConfirm] = useState<string | null>(null);

  const { isLoading: isSending, result: sendingResult } = useSelector(
    (state) => state.auth.passwordReset,
  );
  const { isLoading: isUpdating, result: updatingResult } = useSelector(
    (state) => state.auth.passwordUpdate,
  );

  const sendValidation = useMemo(() => {
    let isValid = true;
    const errors: Partial<{ email: string }> = {};
    if (!email || !/.+@.+\..+/.test(email)) {
      isValid = false;
      if (email !== null) {
        errors.email = 'not valid';
      }
    }
    return {
      isValid,
      errors,
    };
  }, [email]);

  const updateValidation = useMemo(() => {
    let isValid = true;
    const errors: Partial<{ password: string; confirm: string }> = {};
    if (!password || password?.length < 4) {
      isValid = false;
      if (password !== null) {
        errors.password = 'too short';
      }
    }
    if (!confirm || password !== confirm) {
      isValid = false;
      if (confirm !== null) {
        errors.confirm = 'does not match';
      }
    }
    return {
      isValid,
      errors,
    };
  }, [password, confirm]);

  useEffect(() => {
    if (sendingResult.success === false && sendingResult.error) {
      Alert.error(sendingResult.error);
    }
    if (sendingResult.success === true) {
      Alert.success('Instructions sent, please check your email.');
    }
  }, [sendingResult.success, sendingResult.error, sendingResult]);

  useEffect(() => {
    if (updatingResult.success === false && updatingResult.error) {
      Alert.error(updatingResult.error);
    }
    if (updatingResult.success === true) {
      Alert.success('Update successful');
      navigate('Auth/SignIn');
    }
  }, [updatingResult.success, updatingResult.error]);

  const handleChangeEmail = useCallback(
    (event: { target: { value: SetStateAction<string | undefined> } }) => {
      setEmail(event.target.value);
    },
    [],
  );

  const handleChangePassword = useCallback(
    (event: { target: { value: SetStateAction<string | null> } }) => {
      setPassword(event.target.value);
    },
    [],
  );

  const handleChangeConfirm = useCallback(
    (event: { target: { value: SetStateAction<string | null> } }) => {
      setConfirm(event.target.value);
    },
    [],
  );

  const handleSendEmail = useCallback(async () => {
    if (sendValidation.isValid && email) {
      dispatcher.auth.doPasswordReset(email);
    }
  }, [email, sendValidation]);

  const { onKeyDown } = useOnInputEnter(
    useCallback(() => {
      handleSendEmail();
    }, [handleSendEmail]),
  );

  const handleUpdate = useCallback(async () => {
    if (updateValidation.isValid && password) {
      dispatcher.auth.doPasswordUpdate(route?.params?.token || '', password);
    }
  }, [route?.params?.token, password, updateValidation]);

  const handleSendAgain = useCallback(() => {
    setEmail('');
    dispatcher.auth.doPasswordResetAgain();
  }, []);

  const handleBackToSignIn = useCallback(() => {
    navigate('Auth/SignIn');
  }, []);

  const hasToken = !!route?.params?.token;
  const isRecover = !sendingResult.success && !hasToken;
  const isSent = !!sendingResult.success && !hasToken;
  const isUpdate = hasToken;

  const recoverRender = useMemo(
    () => ({
      title: 'Recover your password',
      body: (
        <Box display="flex" flexDirection="column" gap={2}>
          <FormControl size="md" inputMode="email">
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <FormLabel
                sx={{ color: (theme) => theme.palette.neutral.plainColor }}
              >
                Email *
              </FormLabel>
              {!sendValidation.isValid && (
                <FormHelperText sx={{ color: '#DB3327' }}>
                  {sendValidation.errors.email}
                </FormHelperText>
              )}
            </Box>
            <Input
              placeholder="Enter your email"
              variant="soft"
              required
              value={email || ''}
              disabled={isSending}
              name="email"
              onChange={handleChangeEmail}
              onKeyDown={onKeyDown}
              size="lg"
              sx={{
                '--_Input-focusedHighlight': (theme) =>
                  theme.palette.neutral.softColor,
              }}
            />
          </FormControl>
          <Typography fontSize={15} color="neutral">
            We will send the instructions on how to reset your password to the
            email you provided. If you have not received an email from us,
            please check your spam folder or write to support@iki.ai
          </Typography>
        </Box>
      ),
      buttons: (
        <>
          <Button
            color="neutral"
            variant="soft"
            size="lg"
            sx={{ mt: 2 }}
            onClick={handleSendEmail}
            loading={isSending}
            disabled={!sendValidation.isValid}
          >
            Send
          </Button>
          <Button
            variant="plain"
            size="lg"
            sx={{ mt: 2 }}
            color="neutral"
            onClick={handleBackToSignIn}
            disabled={isSending}
          >
            Back to Sign In
          </Button>
        </>
      ),
    }),
    [
      email,
      isSending,
      sendValidation,
      handleSendEmail,
      handleBackToSignIn,
      screenStyle.form,
    ],
  );

  const sentRender = useMemo(
    () => ({
      title: `We have just sent you instructions on how to reset your password to ${email}`,
      body: (
        <Box display="flex" flexDirection="column">
          <Typography fontSize={15} color="neutral">
            If you have not received an email from us, please check your spam
            folder or write to us on support@iki.ai
          </Typography>
        </Box>
      ),
      buttons: (
        <>
          <Button
            color="neutral"
            size="lg"
            sx={{ mt: 2 }}
            variant="soft"
            onClick={handleSendAgain}
            loading={isSending}
            disabled={!sendValidation.isValid}
          >
            Send
          </Button>
          <Button
            variant="plain"
            size="lg"
            sx={{ mt: 2 }}
            color="neutral"
            onClick={handleBackToSignIn}
            disabled={isSending}
          >
            Back to Sign In
          </Button>
        </>
      ),
    }),
    [handleSendAgain, handleBackToSignIn, screenStyle.form, email],
  );

  const renderUpdate = useMemo(
    () => ({
      title: 'Pick a new password',
      body: (
        <Box display="flex" flexDirection="column" gap={2}>
          <FormControl size="md" inputMode="text">
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <FormLabel
                sx={{ color: (theme) => theme.palette.neutral.plainColor }}
              >
                Password *
              </FormLabel>
              {!updateValidation.isValid && (
                <FormHelperText sx={{ color: '#DB3327' }}>
                  {updateValidation.errors.password}
                </FormHelperText>
              )}
            </Box>
            <Input
              placeholder="Enter your password"
              variant="soft"
              required
              type="password"
              value={password || ''}
              disabled={isUpdating}
              name="password"
              onChange={handleChangePassword}
              size="lg"
              sx={{
                '--_Input-focusedHighlight': (theme) =>
                  theme.palette.neutral.softColor,
              }}
            />
          </FormControl>
          <FormControl size="md" inputMode="text">
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <FormLabel
                sx={{ color: (theme) => theme.palette.neutral.plainColor }}
              >
                Repeat password *
              </FormLabel>
              {!updateValidation.isValid && (
                <FormHelperText sx={{ color: '#DB3327' }}>
                  {updateValidation.errors.confirm}
                </FormHelperText>
              )}
            </Box>
            <Input
              placeholder="Repeat your password"
              variant="soft"
              required
              type="password"
              value={confirm || ''}
              disabled={isUpdating}
              name="password"
              onChange={handleChangeConfirm}
              size="lg"
              sx={{
                '--_Input-focusedHighlight': (theme) =>
                  theme.palette.neutral.softColor,
              }}
            />
          </FormControl>
        </Box>
      ),
      buttons: (
        <>
          <Button
            color="neutral"
            variant="soft"
            size="lg"
            sx={{ mt: 2 }}
            onClick={handleUpdate}
            loading={isUpdating}
            disabled={!updateValidation.isValid}
          >
            Update
          </Button>
          <Button
            variant="plain"
            size="lg"
            sx={{ mt: 2 }}
            color="neutral"
            onClick={handleBackToSignIn}
            disabled={isUpdating}
          >
            Back to Sign In
          </Button>
        </>
      ),
    }),
    [
      password,
      confirm,
      isUpdating,
      updateValidation,
      handleUpdate,
      handleBackToSignIn,
      screenStyle.form,
    ],
  );

  const chunk = useMemo((): {
    title: string;
    body: ReactElement;
    buttons: ReactElement;
  } | null => {
    if (isRecover) {
      return recoverRender;
    }
    if (isSent) {
      return sentRender;
    }
    if (isUpdate) {
      return renderUpdate;
    }
    return null;
  }, [isRecover, isSent, isUpdate, recoverRender, sentRender, renderUpdate]);

  return (
    <DualPanels
      leftPanel={
        <>
          <Typography alignSelf="center" mb={2} fontSize={32} fontWeight={500}>
            {chunk?.title}
          </Typography>
          {chunk?.body}
          {chunk?.buttons}
        </>
      }
      rightPanelBackGround={videoBackground}
    />
  );
};

export default PasswordReset;
