import decode from 'jwt-decode';
import moment from 'moment-timezone';
import EventEmitter from 'events';

import Storage from 'lib/Storage';

import * as api from 'services/api/index';
import * as uuid from 'uuid';
import network from 'lib/network';

interface TokenData {
  session_id: number;
  exp: number;
  iat: number;
  type: 'ACCESS' | 'REFRESH';
}

type RefreshResult =
  | 'REFRESH_TOKEN_NOT_EXISTS'
  | 'REFRESH_TOKEN_EXPIRED'
  | 'UPDATE_REQUEST_ERROR'
  | 'STILL_ACTIVE'
  | 'SUCCESS';

type Events =
  | 'REFRESH_TOKEN_NOT_EXISTS'
  | 'REFRESH_TOKEN_EXPIRED'
  | 'UPDATE_REQUEST_ERROR'
  | 'STILL_ACTIVE'
  | 'SET_TOKEN'
  | 'SUCCESS'
  | string
  | symbol;

export interface InitResult {
  result: RefreshResult;
  hasSession: boolean;
  sessionId: number | null;
}

interface Pair {
  access: string;
  refresh: string;
}

const REFRESH_TOKEN_NOT_EXISTS = 'REFRESH_TOKEN_NOT_EXISTS';
const REFRESH_TOKEN_EXPIRED = 'REFRESH_TOKEN_EXPIRED';
const UPDATE_REQUEST_ERROR = 'UPDATE_REQUEST_ERROR';
const STILL_ACTIVE = 'STILL_ACTIVE';
const SET_TOKEN = 'SET_TOKEN';
const SUCCESS = 'SUCCESS';

class Credentials {
  private anonymousId?: string | null;

  private accessToken?: string | null;

  private refreshToken?: string | null;

  private readonly event = new EventEmitter();

  private tickUpdate = async () => {
    const aMinute = 1000 * 60;
    const result = await this.update();

    if ([SUCCESS].includes(result) && this.accessToken && this.refreshToken) {
      await this.set(this.accessToken, this.refreshToken);
    }

    if ([REFRESH_TOKEN_EXPIRED, UPDATE_REQUEST_ERROR].includes(result)) {
      await this.clear();
    }

    setTimeout(() => {
      this.tickUpdate().catch((error) => console.log(error.message));
    }, aMinute);

    if (result !== REFRESH_TOKEN_NOT_EXISTS) {
      this.event.emit(result);
    }
    return result;
  };

  private update = async (): Promise<RefreshResult> => {
    if (!this.refreshToken) {
      return REFRESH_TOKEN_NOT_EXISTS;
    }
    const refreshExpired = this.getMinutesLeftOfExpire(this.refreshToken) < 2;
    if (refreshExpired) {
      return REFRESH_TOKEN_EXPIRED;
    }
    const accessExpired = this.getMinutesLeftOfExpire(this.accessToken) < 2;
    if (!accessExpired) {
      return STILL_ACTIVE;
    }
    const { data, errors } = await network
      .request<{
        access: string;
        refresh: string;
      }>('/auth/refresh', { baseUrl: process.env.PROTECTED_API_URL || '/api' })
      .body({ refresh: this.refreshToken })
      .post();
    if (errors || !data) {
      return UPDATE_REQUEST_ERROR;
    }
    this.accessToken = data.access;
    this.refreshToken = data.refresh;
    return SUCCESS;
  };

  private getTokenData = (jwt: string | null | undefined): TokenData | null => {
    if (!jwt) {
      return null;
    }
    let data: TokenData | null | undefined;
    try {
      data = decode(jwt);
    } catch (error) {
      data = null;
    }
    if (!data) {
      return null;
    }
    return data;
  };

  private getMinutesLeftOfExpire = (jwt: string | null | undefined): number => {
    if (!jwt) {
      return 0;
    }
    const data = this.getTokenData(jwt);
    if (!data) {
      return 0;
    }
    const secondsLeft = moment.utc(data.exp * 1000).diff(moment.utc()) / 1000;
    if (secondsLeft < 0) {
      return 0;
    }
    return secondsLeft / 60;
  };

  private load = async (): Promise<void> => {
    if (!localStorage.getItem('anonymous_id')) {
      localStorage.setItem('anonymous_id', uuid.v4());
    }
    const [access, refresh] = Storage.getMulti([
      '@access_token',
      '@refresh_token',
    ]);
    this.anonymousId = localStorage.getItem('anonymous_id');
    this.accessToken = access;
    this.refreshToken = refresh;
    this.event.emit(SET_TOKEN);
  };

  public init = async (pair?: Pair): Promise<InitResult> => {
    if (pair && pair.access && pair.refresh) {
      await this.set(pair.access, pair.refresh);
    } else {
      await this.load();
    }
    const updateResult = await this.tickUpdate();
    return {
      result: updateResult,
      hasSession: [SUCCESS, STILL_ACTIVE].includes(updateResult),
      sessionId: this.sessionId(),
    };
  };

  public getAnonymousId = () => `uuid.${this.anonymousId}`;

  public getAccess = () => this.accessToken;

  public getAuthorizationHeader = () =>
    this.accessToken
      ? `Bearer ${this.accessToken}`
      : `uuid.${this.anonymousId}`;

  public getRefresh = () => this.refreshToken;

  public hasSession = () => {
    return (
      this.getMinutesLeftOfExpire(this.accessToken) > 0 &&
      this.getMinutesLeftOfExpire(this.refreshToken) > 0
    );
  };

  public sessionId = (): number | null => {
    const data = this.getTokenData(this.accessToken);
    if (!data) {
      return null;
    }
    return data.session_id;
  };

  public clear = () => {
    this.accessToken = undefined;
    this.refreshToken = undefined;
    Storage.remove('@access_token');
    Storage.remove('@refresh_token');
  };

  public set = (access: string, refresh: string) => {
    this.accessToken = access;
    this.refreshToken = refresh;
    Storage.set('@access_token', this.accessToken);
    Storage.set('@refresh_token', this.refreshToken);
    this.event.emit(SET_TOKEN);
  };

  public on = (eventName: Events, listener: (...args: any[]) => void) => {
    this.event.on(eventName, listener);
    return {
      off: () => this.event.off(eventName, listener),
    };
  };

  public off = (
    eventName: string | symbol,
    listener: (...args: any[]) => void,
  ): void => {
    this.event.off(eventName, listener);
  };

  public removeAllListeners = (event?: string | symbol): this => {
    this.event.removeAllListeners(event);
    return this;
  };
}

export default new Credentials();
