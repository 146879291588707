import { memo, useCallback, useEffect, useRef, useState } from 'react';
import {
  type StyleProp,
  type ViewStyle,
  type LayoutChangeEvent,
  Platform,
} from 'react-native';
import { createUseStyles } from 'react-jss';
import { Box, Button } from '@mui/joy';

import { View } from 'components/Themed';

import { Document, Page, pdfjs } from 'react-pdf';
import { type DocumentCallback } from 'react-pdf/src/shared/types';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

type PDFViewerProps = {
  style?: StyleProp<ViewStyle>;
  src?: string;
  limit?: number;
};

const PDFViewer = (props: PDFViewerProps) => {
  const { style, src, limit } = props;

  const classes = useStyles();

  const visiblePagesRef = useRef<number[]>([]);

  const [numPages, setNumPages] = useState<number>(0);
  const [isCut, setIsCut] = useState<boolean>(false);
  const [availableWidth, setAvailableWidth] = useState<number>(0);
  const [pageViewportsMap, setPageViewportsMap] = useState<
    Record<number, { ratio: number }>
  >({});

  useEffect(() => {
    if (!numPages) {
      return undefined;
    }
    const pages = document.querySelectorAll<HTMLDivElement>(
      `.${classes.pdfPage}`,
    );
    const observer = new IntersectionObserver(
      (entries) => {
        const viewportHeight = window.innerHeight;
        entries.forEach((entry) => {
          const currentPage = entry.target as HTMLDivElement;
          const currentPageRect = entry.boundingClientRect;
          const currentPageId = Number(currentPage.dataset.pageId);

          visiblePagesRef.current.forEach((visiblePageId, key) => {
            const visiblePage = pages[visiblePageId - 1];
            const visibleRect = visiblePage.getBoundingClientRect();
            if (
              visibleRect.bottom < -viewportHeight &&
              visibleRect.top > viewportHeight * 2 &&
              visiblePagesRef.current.includes(currentPageId)
            ) {
              visiblePagesRef.current.splice(key, 1);
              (
                pages[visiblePageId - 1].firstChild as HTMLDivElement
              ).style.display = 'none';
            }
          });

          if (
            !(
              currentPageRect.bottom < -viewportHeight &&
              currentPageRect.top > viewportHeight * 2
            ) &&
            !visiblePagesRef.current.includes(currentPageId)
          ) {
            visiblePagesRef.current.push(currentPageId);
            (
              pages[currentPageId - 1].firstChild as HTMLDivElement
            ).style.display = 'block';
          }
        });
      },
      { threshold: 0.1 }, // Adjust the threshold value to your needs
    );
    pages.forEach((page) => {
      observer.observe(page);
    });
    return () => {
      observer.disconnect();
    };
  }, [numPages]);

  const handleOpenFull = useCallback(() => {
    if (!src) {
      return;
    }
    if (Platform.OS === 'web' && !!document) {
      const link = document.createElement('a');
      link.setAttribute('href', src);
      link.setAttribute('target', '_blank');
      link.click();
    }
  }, [src]);

  const handleDocumentLoadSuccess = useCallback(
    async (document: DocumentCallback) => {
      if (!availableWidth) {
        return undefined;
      }
      let pages = document.numPages;
      if (limit && document.numPages > limit) {
        pages = limit;
        setIsCut(true);
      }
      const timeout = setTimeout(async () => {
        setNumPages(pages);
        const updatePageViewportsMap: Record<number, { ratio: number }> = {};
        const viewports = await Promise.all(
          new Array(document.numPages)
            .fill(null)
            .map((_, key) =>
              document.getPage(key + 1).then((data: any) => data.getViewport()),
            ),
        );
        viewports.forEach((viewport: { viewBox: number[] }, key: number) => {
          const [, , width, height] = viewport.viewBox;
          const ratio = width / height;
          const pageIndex = key + 1;
          updatePageViewportsMap[pageIndex] = {
            ratio,
          };
        });
        setPageViewportsMap(updatePageViewportsMap);
      }, 1000);
      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    },
    [availableWidth, limit],
  );

  const handleLayout = useCallback((event: LayoutChangeEvent) => {
    const newAvailableWidth = event.nativeEvent.layout.width;
    setAvailableWidth(newAvailableWidth);
  }, []);

  if (!src) {
    return null;
  }

  return (
    <View onLayout={handleLayout}>
      <Box display="flex" flexDirection="column">
        <Document
          file={src}
          onLoadSuccess={handleDocumentLoadSuccess}
          className={classes.pdfDocument}
        >
          {Array.from(new Array(numPages), (_, key) => {
            const pageIndex = key + 1;
            const pageViewport = pageViewportsMap[pageIndex];
            return (
              <div
                className={classes.pdfPage}
                key={`page_${pageIndex}`}
                data-page-id={pageIndex}
                style={{
                  boxShadow:
                    '0 0 2px rgba(0, 0, 0, 0.25), 0 2px 5px rgba(0, 0, 0, 0.15)',
                  marginBottom: 16,
                  aspectRatio: pageViewport?.ratio || undefined,
                }}
              >
                <Page
                  // onRenderSuccess={() => {
                  //   console.log(`onRenderSuccess: ${pageIndex}`);
                  // }}
                  pageNumber={pageIndex}
                  width={availableWidth}
                  renderMode="canvas"
                />
              </div>
            );
          })}
        </Document>
        {isCut && (
          <Button
            onClick={handleOpenFull}
            variant="plain"
            color="neutral"
            sx={{ mb: 2 }}
          >
            Open full document
          </Button>
        )}
      </Box>
    </View>
  );
};

const useStyles = createUseStyles({
  pdfDocument: {},
  pdfPage: {},
});

export default memo(PDFViewer);
