import { createAction } from '@reduxjs/toolkit';

import type { CollectionType, UserType } from 'app/entities';

import { prepare, guard } from 'utils';

export const loadUser = createAction(
  'user/loadUser',
  (payload: { login: string | string[]; mode?: 'full' | 'basic' }) => ({
    payload,
  }),
);

export const loadProfile = createAction(
  'user/loadProfile',
  (login?: 'my' | string) => ({
    payload: {
      login: login || 'my',
      optimistic: false,
    },
  }),
);

export const loadProfileOptimistic = createAction(
  'user/loadProfileOptimistic',
  () => ({
    payload: {
      login: 'my',
      optimistic: true,
    },
  }),
);

export const loadProfileDone = createAction(
  'user/loadProfileDone',
  (login: string) => ({
    payload: {
      login,
    },
  }),
);

export const setProfile = createAction(
  'user/setProfile',
  (payload: { data: UserType | UserType[]; mode?: 'full' | 'basic' }) => {
    const { data, mode = 'full' } = payload;
    if (guard.isUserArray(data)) {
      return {
        payload: {
          data: data.map((item) => prepare.user(item, mode)),
          mode,
        },
      };
    }
    if (guard.isUser(data)) {
      return {
        payload: {
          data: prepare.user(data, mode),
          mode,
        },
      };
    }
    return {
      payload: {
        data: null,
        mode: 'full',
      },
    };
  },
);

export const updateProperty = createAction(
  'user/updateProperty',
  <K extends keyof UserType>(payload: {
    target: 'my' | string | number;
    name: K;
    value: UserType[K];
  }) => {
    return {
      payload,
    };
  },
);

export const setCollections = createAction(
  'user/setCollections',
  (login: 'my' | string, collections: CollectionType | CollectionType[]) => ({
    payload: {
      login,
      collections,
    },
  }),
);

export const addedCollectionPermission = createAction(
  'user/addedCollectionPermission',
  (collectionId: number) => ({
    payload: {
      collectionId,
    },
  }),
);

export const removedCollectionPermission = createAction(
  'user/removedCollectionPermission',
  (collectionId: number) => ({
    payload: {
      collectionId,
    },
  }),
);

export const subscribe = createAction('user/subscribe', (login: string) => ({
  payload: {
    login,
  },
}));

export const subscribeSuccess = createAction(
  'user/subscribeSuccess',
  (login: string) => ({
    payload: {
      login,
    },
  }),
);

export const subscribeDone = createAction(
  'user/subscribeDone',
  (login: string) => ({
    payload: {
      login,
    },
  }),
);

export const unsubscribeSuccess = createAction(
  'user/unsubscribeSuccess',
  (login: string) => ({
    payload: {
      login,
    },
  }),
);

export const unsubscribe = createAction(
  'user/unsubscribe',
  (login: string) => ({
    payload: {
      login,
    },
  }),
);

export const unsubscribeDone = createAction(
  'user/unsubscribeDone',
  (login: string) => ({
    payload: {
      login,
    },
  }),
);

export const logOut = createAction('user/logout');
