import { forwardRef, memo, type PropsWithChildren } from 'react';
import { Box, type BoxProps, useTheme } from '@mui/joy';

const ScrollBox = forwardRef<HTMLDivElement, PropsWithChildren<BoxProps>>(
  (props, ref) => {
    const { children, sx, ...rest } = props;
    const theme = useTheme();

    return (
      <Box
        ref={ref}
        sx={{
          overflowY: 'scroll',
          overflowX: 'hidden',
          '&::-webkit-scrollbar': {
            width: '0.5rem',
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'transparent',
            borderRadius: '0.5rem',
            border: '2px solid transparent',
            backgroundClip: 'content-box',
          },
          '&:hover::-webkit-scrollbar-thumb:hover': {
            backgroundColor: theme.palette.primary.softDisabledColor,
          },
          '&:hover::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.background.level3,
          },
          ...sx,
        }}
        {...rest}
      >
        {children}
      </Box>
    );
  },
);

export default memo(ScrollBox);
