import { memo } from 'react';
import { type StyleProp, type ViewStyle } from 'react-native';

import { useRoute } from 'navigation/hooks';
import {
  isStaffPicksScreen,
  isLibraryScreen,
  isCommunityCollectionScreen,
  isCommunityPeersScreen,
  isMaterialScreen,
  isCollectionScreen,
  isAuthorScreen,
  isNewsScreen,
  isProfileScreen,
  isAskScreen,
} from 'navigation/guards';

import CommunityHeader from './elements/CommunityHeader';
import DefaultHeader from './elements/DefaultHeader';
import ContentHeader from './elements/ContentHeader';

type HeaderProps = {
  style?: StyleProp<ViewStyle>;
};

const MobileTopBar = (props: HeaderProps) => {
  const { style } = props;

  const route = useRoute();

  return (
    <>
      {(isCommunityPeersScreen(route) ||
        isCommunityCollectionScreen(route)) && (
        <CommunityHeader style={style} />
      )}
      {(isMaterialScreen(route) ||
        isCollectionScreen(route) ||
        isAuthorScreen(route) ||
        isProfileScreen(route)) && <ContentHeader style={style} />}
      {(isStaffPicksScreen(route) ||
        isLibraryScreen(route) ||
        isNewsScreen(route) ||
        isAskScreen(route)) && <DefaultHeader style={style} />}
    </>
  );
};

export default memo(MobileTopBar);
