import { memo, useMemo } from 'react';
import { type StyleProp, type ViewStyle, StyleSheet } from 'react-native';
import { useColorScheme, useScreenSize, useThemeColor } from 'hooks';
import { guard, unit } from 'utils';

import { Text, View } from 'components/Themed';
import Lottie from 'components/Lottie';

type PendingMaterialsProps = {
  count?: number | null;
};

const PendingMaterials = (props: PendingMaterialsProps) => {
  const { count } = props;

  const screenSize = useScreenSize();
  const indicatorColor = useThemeColor({ light: '#777777', dark: '#777777' });
  const theme = useColorScheme();

  const itemStyle = useMemo(() => {
    const result: StyleProp<ViewStyle> = {};
    if (guard.isWebStyleProp(result) && theme === 'light') {
      result.boxShadow =
        '0 0 2px rgba(0, 0, 0, 0.25), 0 2px 5px rgba(0, 0, 0, 0.15)';
    }
    if (guard.isWebStyleProp(result) && theme === 'dark') {
      result.boxShadow = '0 0 0 1px rgb(48, 48, 48)';
      result.margin = 1;
    }
    if (guard.isWebStyleProp(result)) {
      result.userSelect = 'none';
    }
    return result;
  }, [theme]);

  const componentStyle = useMemo(() => {
    const result: StyleProp<ViewStyle> = {};
    return result;
  }, [screenSize]);

  if (!count) {
    return null;
  }

  return (
    <View style={[styles.PendingMaterials, componentStyle]}>
      {new Array(count).fill(null).map(() => (
        <View style={[styles.item, itemStyle]}>
          <Text
            size={15}
            style={styles.text}
            lightColor="#777777"
            darkColor="#777777"
          >
            Processing your material...
          </Text>
          <View style={styles.loading}>
            <Lottie
              name="LoadingCircle"
              loop
              color={indicatorColor}
              width={72}
              height={72}
            />
          </View>
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  PendingMaterials: {
    gap: unit(12),
    marginTop: unit(20),
    width: '100%',
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: unit(6),
    paddingTop: unit(18),
    paddingRight: unit(20),
    paddingBottom: unit(18),
    paddingLeft: unit(20),
  },
  text: {
    flex: 1,
  },
  loading: {
    margin: unit(-24),
    opacity: 0.8,
  },
});

export default memo(PendingMaterials);
