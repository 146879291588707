import { type DefaultRootState } from 'react-redux';

export const isLoadedById =
  (id: number | number[]) => (state: DefaultRootState) => {
    const ids = Array.isArray(id) ? id : [id];
    return ids.map((item) => ({
      id: item,
      isLoaded: !!state.content.data?.[item],
    }));
  };
