import { useMemo } from 'react';

import type {
  CollectionType,
  MaterialType,
  NoteType,
  RagMaterialType,
} from 'app/entities';
import { guard } from 'utils';

const usePosterSources = (
  data?: CollectionType | MaterialType | NoteType | RagMaterialType | null,
) =>
  useMemo(() => {
    if (guard.isCollection(data)) {
      if (data?.cover) {
        return [data?.cover];
      }
      if (!data?.cover) {
        return data?.contentImages || [];
      }
      return [];
    }
    if (guard.isMaterial(data)) {
      if (data.image) {
        return [data.image.url];
      }
      return [];
    }
    if (guard.isNote(data)) {
      return [];
    }
    return [];
  }, [data]);

export default usePosterSources;
