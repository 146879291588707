import type { SuccessResult } from 'app/entities';
import network from 'lib/network';

export default async (): Promise<SuccessResult> => {
  const { errors, data } = await network
    .request<SuccessResult>('/auth/register/completed', {
      baseUrl: '/api',
    })
    .post();

  if (errors) {
    return {
      data: null,
      error: errors[0]?.message as any,
    };
  }
  return {
    data: { success: true },
    error: errors,
  };
};
