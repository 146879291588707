import {
  type SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Typography,
} from '@mui/joy';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as userStore from 'store/nodes/user';

import { PhotoInput } from 'components/Form';
import Alert from 'components/Alert';

import DualPanels from 'widgets/DualPanels';

import Icon from 'ui/Icon';
import { useOnInputEnter } from 'hooks';

import videoBackground from './assets/videoBackground.mp4';

type FormValues = {
  photo: string | null;
  name: string | null;
  phone: string | null;
  login: string | null;
  location: string | null;
};

const validate = (
  values: Partial<FormValues>,
): { isValid: boolean; errors: Partial<FormValues> } => {
  let isValid = true;
  const errors: Partial<FormValues> = {};
  if (!values?.name) {
    isValid = false;
    if (values?.name !== null) {
      errors.name = 'required';
    }
  }
  if (!values?.login) {
    isValid = false;
    if (values?.login !== null) {
      errors.login = 'required';
    }
  }
  return {
    isValid,
    errors,
  };
};

const Step1 = () => {
  const dispatcher = useDispatcher();
  const formRef = useRef<any>();

  const [photo, setPhoto] = useState<string | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [phone, setPhone] = useState<string | null>(null);
  const [login, setLogin] = useState<string | null>(null);

  const userProfile = useSelector(userStore.selectors.getByLogin('my'));
  const { isLoading: isProfileUpdating, result: profileResult } = useSelector(
    (state) => state.profile.meta.update,
  );
  const { isLoading: isSignOutLoading, result: signOutResult } = useSelector(
    (state) => state.auth.signOut,
  );

  const validation = useMemo(() => validate({ name, login }), [name, login]);

  useEffect(() => {
    formRef?.current?.setValues?.(userProfile);
  }, [userProfile]);

  useEffect(() => {
    if (signOutResult.success === false && signOutResult.error) {
      Alert.error(signOutResult.error);
    }
    if (profileResult.success === false && profileResult.error) {
      Alert.error(profileResult.error);
    }
  }, [
    signOutResult.error,
    signOutResult.success,
    profileResult.error,
    profileResult.success,
    profileResult,
  ]);

  const handleChangeName = useCallback(
    (event: { target: { value: SetStateAction<string | null> } }) => {
      setName(event.target.value);
    },
    [],
  );

  const handleChangeLogin = useCallback(
    (event: { target: { value: SetStateAction<string | null> } }) => {
      setLogin(event.target.value);
    },
    [],
  );

  const handleSubmit = useCallback(() => {
    if (!validation.isValid) {
      return;
    }
    dispatcher.auth.step1({
      phone,
      name,
      photo,
      login,
    });
  }, [validation.isValid, phone, name, photo, login]);

  const { onKeyDown } = useOnInputEnter(
    useCallback(() => {
      handleSubmit();
    }, [handleSubmit]),
  );

  const handleSignOut = useCallback(() => {
    dispatcher.auth.logOut();
  }, [dispatcher.auth]);

  return (
    <DualPanels
      leftPanel={
        <>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={1.5}
            mb={8}
          >
            <Typography fontSize={52} fontWeight={500}>
              Set up profile
            </Typography>
            <Typography fontSize={16} fontWeight={400}>
              You can change this later
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap={2}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              gap={1}
              mb={1}
            >
              <PhotoInput value={photo} onChange={setPhoto} />
              <Typography fontSize={16} fontWeight={400}>
                Profile photo
              </Typography>
            </Box>
            <FormControl size="md" inputMode="email">
              <FormLabel
                sx={{ color: (theme) => theme.palette.neutral.plainColor }}
              >
                Name *
              </FormLabel>
              <Input
                placeholder="Enter your name"
                variant="soft"
                required
                value={name || ''}
                name="name"
                onChange={handleChangeName}
                size="lg"
                sx={{
                  '--_Input-focusedHighlight': (theme) =>
                    theme.palette.neutral.softColor,
                }}
              />
            </FormControl>
            <FormControl size="md" inputMode="email">
              <FormLabel
                sx={{ color: (theme) => theme.palette.neutral.plainColor }}
              >
                Username *
              </FormLabel>
              <Input
                placeholder="Enter your username"
                variant="soft"
                required
                value={login || ''}
                name="name"
                onChange={handleChangeLogin}
                onKeyDown={onKeyDown}
                size="lg"
                sx={{
                  '--_Input-focusedHighlight': (theme) =>
                    theme.palette.neutral.softColor,
                }}
              />
            </FormControl>
            <Button
              fullWidth
              loading={isProfileUpdating}
              disabled={!validation?.isValid || isSignOutLoading}
              onClick={handleSubmit}
              size="lg"
              variant="soft"
              color="neutral"
              sx={{
                mt: 2,
              }}
              endDecorator={<Icon name="arrow-right" color="inherit" />}
            >
              Next
            </Button>
          </Box>
          <Button
            onClick={handleSignOut}
            color="neutral"
            size="lg"
            variant="plain"
            sx={{
              mt: 2,
            }}
            loading={isSignOutLoading}
            disabled={isProfileUpdating}
          >
            <Typography fontSize={15} fontWeight={400} color="neutral">
              Sign Out
            </Typography>
          </Button>
        </>
      }
      rightPanelBackGround={videoBackground}
    />
  );
};

export default Step1;
