import type { ImageType } from 'app/entities';
import { guard } from 'utils';

const thumbnail = (
  image?: ImageType | string | null,
  size?: 'small' | 'medium' | 'large' | 'original',
): ImageType | null => {
  if (!image) {
    return null;
  }

  let sizeFinal = size || 'original';
  const url = typeof image === 'string' ? image : image.url;
  if (/\/thumbnails\/(small|medium|large)/.test(url)) {
    sizeFinal = 'original';
  }
  const [, domain, type, path] =
    url.match(/(^https?:\/\/[^/]+)(\/[^/]+)(.*)/) || [];
  const canResize = domain && type && path;
  if (!canResize) {
    sizeFinal = 'original';
  }

  if (guard.isImage(image)) {
    return {
      id: image.id,
      url:
        sizeFinal === 'original'
          ? url
          : `${domain}/thumbnails/${size}${type}${path}`,
      width: image.width,
      height: image.height,
      createdAt: image.createdAt,
      updatedAt: image.updatedAt,
    };
  }

  return {
    id: 0,
    url:
      sizeFinal === 'original'
        ? url
        : `${domain}/thumbnails/${size}${type}${path}`,
    width: undefined,
    height: undefined,
    createdAt: '',
    updatedAt: '',
  };
};

export default thumbnail;
