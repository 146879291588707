import { type SetStateAction, useCallback, useMemo, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Typography,
} from '@mui/joy';

import { useNavigate } from 'navigation/hooks';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';

import DualPanels from 'widgets/DualPanels';

import { useOnInputEnter } from 'hooks';

import videoBackground from './assets/videoBackground.mp4';

type FormValues = {
  email: string | null;
  password: string | null;
  confirm: string | null;
};

const validate = (
  values: FormValues,
): { isValid: boolean; errors: Partial<FormValues> } => {
  let isValid = true;
  const errors: Partial<FormValues> = {};
  if (!values?.email || !/.+@.+\..+/.test(values?.email)) {
    isValid = false;
    if (values?.email !== null) {
      errors.email = 'not valid';
    }
  }
  if (!values?.password || values?.password?.length < 4) {
    isValid = false;
    if (values?.password !== null) {
      errors.password = 'too short';
    }
  }
  if (!values?.confirm || values?.password !== values.confirm) {
    isValid = false;
    if (values?.confirm !== null) {
      errors.confirm = 'does not match';
    }
  }
  return {
    isValid,
    errors,
  };
};

const AuthSignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatcher();

  const { email: emailInStore } = useSelector(
    (state) => state.auth.signIn.data,
  );

  const [email, setEmail] = useState<string | null>(emailInStore || null);
  const [password, setPassword] = useState<string | null>(null);
  const [confirm, setConfirm] = useState<string | null>(null);

  const handleChangeEmail = useCallback(
    (event: { target: { value: SetStateAction<string | null> } }) => {
      setEmail(event.target.value);
    },
    [],
  );

  const handleChangePassword = useCallback(
    (event: { target: { value: SetStateAction<string | null> } }) => {
      setPassword(event.target.value);
    },
    [],
  );

  const handleChangeConfirm = useCallback(
    (event: { target: { value: SetStateAction<string | null> } }) => {
      setConfirm(event.target.value);
    },
    [],
  );

  const { isLoading } = useSelector((state) => state.auth.signUp);

  const validation = useMemo(
    () => validate({ email, password, confirm }),
    [email, password, confirm],
  );

  const handleSubmit = useCallback(() => {
    if (!email || !password || !validation.isValid) {
      return;
    }
    dispatch.auth.doSignUp(email, password);
  }, [email, password, validation.isValid]);

  const { onKeyDown } = useOnInputEnter(
    useCallback(() => {
      handleSubmit();
    }, [handleSubmit]),
  );

  const handleLogin = useCallback(() => {
    navigate('Auth/SignIn');
  }, []);

  return (
    <DualPanels
      leftPanel={
        <>
          <Box alignSelf="center" mb={2}>
            <Typography fontSize={32} fontWeight={500}>
              Create your account
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap={2}>
            <FormControl size="md" inputMode="email">
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <FormLabel
                  sx={{ color: (theme) => theme.palette.neutral.plainColor }}
                >
                  Email *
                </FormLabel>
                {!validation.isValid && (
                  <FormHelperText sx={{ color: '#DB3327' }}>
                    {validation.errors.email}
                  </FormHelperText>
                )}
              </Box>
              <Input
                placeholder="Enter your email"
                variant="soft"
                required
                value={email || ''}
                name="email"
                onChange={handleChangeEmail}
                size="lg"
                sx={{
                  '--_Input-focusedHighlight': (theme) =>
                    theme.palette.neutral.softColor,
                }}
              />
            </FormControl>
            <FormControl size="md" inputMode="email">
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <FormLabel
                  sx={{ color: (theme) => theme.palette.neutral.plainColor }}
                >
                  Password *
                </FormLabel>
                {!validation.isValid && (
                  <FormHelperText sx={{ color: '#DB3327' }}>
                    {validation.errors.password}
                  </FormHelperText>
                )}
              </Box>
              <Input
                placeholder="Enter your password"
                variant="soft"
                required
                type="password"
                value={password || ''}
                name="password"
                onChange={handleChangePassword}
                size="lg"
                sx={{
                  '--_Input-focusedHighlight': (theme) =>
                    theme.palette.neutral.softColor,
                }}
              />
            </FormControl>
            <FormControl size="md" inputMode="email">
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <FormLabel
                  sx={{ color: (theme) => theme.palette.neutral.plainColor }}
                >
                  Repeat password *
                </FormLabel>
                {!validation.isValid && (
                  <FormHelperText sx={{ color: '#DB3327' }}>
                    {validation.errors.confirm}
                  </FormHelperText>
                )}
              </Box>
              <Input
                placeholder="Repeat your password"
                variant="soft"
                required
                type="password"
                value={confirm || ''}
                name="confirm"
                onChange={handleChangeConfirm}
                onKeyDown={onKeyDown}
                size="lg"
                sx={{
                  '--_Input-focusedHighlight': (theme) =>
                    theme.palette.neutral.softColor,
                }}
              />
            </FormControl>
          </Box>
          <Button
            color="neutral"
            variant="soft"
            size="lg"
            sx={{ mt: 4 }}
            onClick={handleSubmit}
            loading={isLoading}
            disabled={!validation?.isValid}
          >
            Continue
          </Button>
          <Button
            onClick={handleLogin}
            color="neutral"
            size="lg"
            sx={{ mt: 2 }}
            variant="outlined"
            disabled={isLoading}
          >
            Already have an account?
          </Button>
        </>
      }
      rightPanelBackGround={videoBackground}
    />
  );
};

export default AuthSignUp;
