import type { DefaultRootState } from 'react-redux';
import type { ResourceVariant, InteractionVariants } from 'app/entities';

export const isLoading =
  (type: InteractionVariants, resource: ResourceVariant, resourceId?: number) =>
  (state: DefaultRootState) => {
    return (
      state.interaction.meta.loadingMap[
        `${type}-${resource}-${resourceId || 0}`
      ] || false
    );
  };
