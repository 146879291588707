/**
 * @deprecated
 */
export default (
  type: 'material' | 'collection' | 'note',
): 'content' | 'playlist' | 'note' =>
  ({
    material: 'content',
    collection: 'playlist',
    note: 'note',
  })[type] as 'content' | 'playlist' | 'note';
