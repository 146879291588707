import { put, takeEvery } from 'redux-saga/effects';
import type { SagaReturnType } from 'redux-saga/effects';
import * as Linking from 'expo-linking';

import * as api from 'services/api';

import { call } from 'store/utils/saga/effects';

import * as actions from '../actions';

export const config = {
  action: actions.doOAuth.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.doOAuth>) {
  const { provider } = action.payload;

  const url = Linking.createURL('/welcome');
  const { data, error: errors } = yield* call(() =>
    api.resource.oauth.urls(url),
  );

  const error = Array.isArray(errors) ? errors[0] : errors;

  if (error || !data) {
    yield put(actions.doOAuthDone(error));
  }

  if (!data?.[provider]) {
    yield put(actions.doOAuthDone('unknown_error'));
  }

  window.location.replace(data?.[provider] || '/');
}
