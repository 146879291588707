import type { FontProps, FontSizeVariant } from 'font';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import {
  type StyleProp,
  type ViewStyle,
  type TextStyle,
  StyleSheet,
  TextInput as RNTextInput,
  Platform,
} from 'react-native';
import * as Device from 'expo-device';

import { useFontProps, useThemeColor } from 'hooks';

import { Text, View } from 'components/Themed';
import { fontSettingBySize } from 'hooks/useFontProps';

const defaultProps = {
  fontSize: 18 as FontProps['size'],
  disabled: false,
};
const getLineHeightByFontSize = (size: number): number => {
  const lineHeightMap: Record<number, number> = {
    11: 13,
    12: 16,
    13: 18,
    15: 20,
    16: 21,
    17: 22,
    20: 25,
    22: 28,
    28: 34,
    34: 41,
    54: 52,
    57: 64,
  };
  return lineHeightMap[size] || size * 1.15;
};

type TextInputProps = {
  fontSize?: FontSizeVariant;
  style?: StyleProp<ViewStyle>;
  inputStyle?: StyleProp<ViewStyle>;
  value?: string;
  placeholder?: string;
  placeholderTextColor?: string;
  autoFocus?: boolean;
  autoHeight?: boolean;
  multiline?: boolean;
  maxLines?: number;
  onChangeText?: (value: string) => void;
  onSubmit?: () => void;
  onCancel?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  disabled?: boolean;
} & typeof defaultProps;

const TextInput = (props: TextInputProps) => {
  const {
    style,
    inputStyle,
    value,
    fontSize,
    placeholder,
    placeholderTextColor,
    autoFocus,
    autoHeight,
    multiline,
    maxLines,
    onChangeText,
    onSubmit,
    onCancel,
    disabled,
    onFocus,
    onBlur,
  } = props;

  const placeholderColor = useThemeColor({ light: '#a6a6a6', dark: '#4E4E53' });
  const textColor = useThemeColor({ light: '#000000', dark: '#ffffff' });

  const [lineHeight, setLineHeight] = useState<number>();
  const [height, setHeight] = useState<number | undefined>(undefined);
  const [sizerValue, setSizerValue] = useState<string>(`${value}`);

  const paddingBottom = 3;

  const handleContentSizeChange = useCallback(
    (event: any) => {
      if (height === event.nativeEvent.contentSize.height) {
        return;
      }
      const newHeight = event.nativeEvent.contentSize.height + paddingBottom;
      if (!maxLines || maxLines * lineHeight + paddingBottom >= newHeight) {
        setHeight(newHeight);
      }
    },
    [height, lineHeight],
  );

  const handleSizerLayout = useCallback(
    (event: any) => {
      const newHeight = event.nativeEvent.layout.height + paddingBottom;
      if (!height) {
        setHeight(newHeight);
        return;
      }
      if (newHeight < height) {
        setHeight(newHeight);
      }
    },
    [height],
  );

  const handleInputChange = useCallback(
    (text: string) => {
      setSizerValue(text);
      onChangeText?.(text);
    },
    [onChangeText],
  );

  const handleKeyPress = useCallback(
    (event: any) => {
      if ((event.key || event.code) === 'Enter') {
        if (
          ['Mac OS'].includes(Device.osName || '') &&
          event.metaKey === true
        ) {
          onSubmit?.();
        }
        if (
          !['Mac OS'].includes(Device.osName || '') &&
          event.ctrlKey === true
        ) {
          onSubmit?.();
        }
      }
      if ((event.key || event.code) === 'Escape') {
        onCancel?.();
      }
    },
    [onCancel, onSubmit],
  );

  useEffect(() => {
    setLineHeight(getLineHeightByFontSize(fontSize));
  }, [fontSize]);

  const sizerStyle = useMemo(
    (): StyleProp<TextStyle> => ({
      ...StyleSheet.flatten(inputStyle),
      position: 'relative',
      backgroundColor: '#ccddee',
      height: 'auto',
      width: '100%',
      paddingBottom: 0,
      maxHeight: maxLines
        ? getLineHeightByFontSize(fontSize) * maxLines
        : undefined,
      overflow: 'hidden',
      zIndex: 1,
      opacity: 0,
      ...Platform.select({
        web: {
          pointerEvents: 'none',
        },
      }),
    }),
    [fontSize, height],
  );

  const inputStyleFinal = useMemo(
    (): StyleProp<TextStyle> => ({
      ...StyleSheet.flatten(inputStyle),
      ...(fontSettingBySize(fontSize || 16) as TextStyle),
      color: textColor,
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      height,
      zIndex: 2,
      ...Platform.select({
        web: {
          outline: 'none',
        },
      }),
    }),
    [fontSize, textColor, height],
  );

  return (
    <View style={[style, styles.TextInput]}>
      {autoHeight && (
        <Text onLayout={handleSizerLayout} size={fontSize} style={sizerStyle}>
          {`${sizerValue} `}
        </Text>
      )}
      <RNTextInput
        editable={!disabled}
        value={value}
        style={inputStyleFinal}
        placeholder={placeholder}
        placeholderTextColor={placeholderTextColor || placeholderColor}
        autoFocus={autoFocus}
        multiline={multiline}
        onChangeText={handleInputChange}
        onContentSizeChange={handleContentSizeChange}
        onKeyPress={handleKeyPress}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </View>
  );
};

TextInput.defaultProps = defaultProps;

const styles = StyleSheet.create({
  TextInput: {
    position: 'relative',
  },
});

export default memo(TextInput);
