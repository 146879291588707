import { fork } from 'redux-saga/effects';

export interface ISagaOptions {
  config: {
    action: string | string[] | null;
    method: CallableFunction | 'fork' | null;
  };
  func: CallableFunction;
}

export default function connect(
  list: Array<ISagaOptions>,
): () => Generator<void> {
  return function* generator() {
    for (let key = 0; key < list.length; key += 1) {
      const item: ISagaOptions = list[key];
      if (item.config.method === 'fork') {
        yield fork(item.func as any);
      }
      if (
        item.config.method !== 'fork' &&
        item.config.method &&
        item.config.action
      ) {
        yield item.config.method(item.config.action, item.func);
      }
    }
  };
}
