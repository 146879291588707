import type { Result, RegistrationCompletedType } from 'app/entities';

import network from 'lib/network';

export default async () => {
  const { errors, data } = await network
    .request<RegistrationCompletedType>('/auth/register/completed', {
      baseUrl: '/api',
    })
    .get();

  const result: Result<RegistrationCompletedType> = {
    data: data || null,
    error: {
      hasErrors: false,
      list: [],
    },
  };
  if (errors instanceof Error) {
    result.error.hasErrors = true;
    result.error.list = [errors];
  }
  if (Array.isArray(errors)) {
    result.error.hasErrors = true;
    result.error.list = errors;
  }
  return result;
};
