import { type PropsWithChildren, type FC, type ReactNode, memo } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { Box, type BoxProps } from '@mui/joy';

import * as copilotStore from 'widgets/Copilot/store';

export type UtilityBarProps = {
  left?: ReactNode;
  right?: ReactNode;
} & Pick<BoxProps, 'p' | 'px' | 'py' | 'pt' | 'pr' | 'pb' | 'pl'>;

const Item: FC<PropsWithChildren & { canHidden?: boolean }> = memo(
  (props) => {
    const { canHidden, children } = props;
    const isShow = useSelector(copilotStore.selectors.rightBarIsShow);
    if (!isShow && canHidden) {
      return null;
    }
    return <>{children}</>;
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps),
);

const UtilityBar: FC<UtilityBarProps> = (props) => {
  const { left, right, ...rest } = props;
  const rightBarWidth = useSelector(copilotStore.selectors.rightBarWidth);
  const isShow = useSelector(copilotStore.selectors.rightBarIsShow);

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-end"
      alignItems="center"
      gap={1}
      boxSizing="border-box"
      minWidth={isShow ? rightBarWidth : undefined}
      {...rest}
    >
      {left}
      <Box flex={1} />
      {right}
    </Box>
  );
};

const UtilityBarMemoized = memo(UtilityBar, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps),
) as unknown as typeof UtilityBar & { Item: typeof Item };

UtilityBarMemoized.Item = Item;

export default UtilityBarMemoized;
