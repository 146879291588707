import { type ReactElement, memo, useMemo, useCallback } from 'react';
import {
  type GestureResponderEvent,
  StyleSheet,
  TouchableOpacity,
} from 'react-native';
import { type DefaultRootState, useSelector } from 'react-redux';

import type { MaterialType } from 'app/entities';

import { useThemeColor } from 'hooks';
import { unit } from 'utils';

import { View, Text } from 'components/Themed';
import Picture from 'components/Picture';
import Info from 'components/Info';
import TagsOld from 'components/TagsOld';

const defaultProps = {
  selectorData: (() => () => null) as (
    id: number,
  ) => (state: DefaultRootState) => MaterialType | null,
};

type ItemProps = {
  id: number;
  selectorData?: (
    id: number,
  ) => (state: DefaultRootState) => MaterialType | null;
  selectorState?: (id: number) => (state: DefaultRootState) => boolean;
  ComponentTrue?: ReactElement;
  ComponentFalse?: ReactElement;
  hasTags?: boolean;
  onPress?: (resourceId: number, resourceType: string) => void;
  onTagPress?: (event: GestureResponderEvent, context: { tag: string }) => void;
  isLast?: boolean;
} & typeof defaultProps;

const SlimContentItem = (props: ItemProps) => {
  const {
    id,
    selectorData,
    selectorState,
    ComponentTrue,
    ComponentFalse,
    hasTags,
    onPress,
    onTagPress,
    isLast,
  } = props;

  const borderBottomColor = useThemeColor({
    light: '#a6a6a6',
    dark: '#4E4E53',
  });
  const imageBackground = useThemeColor({ light: '#a6a6a6', dark: '#4E4E53' });

  const data = useSelector(selectorData(id));
  const isStateTrue = useSelector(
    selectorState ? selectorState(id) : () => false,
  );

  const handlePress = useCallback(() => {
    if (!data || !data.type) {
      return;
    }
    onPress?.(id, data.type);
  }, [onPress, id, data?.type]);

  const renderBody = useMemo(
    () => (
      <>
        <Picture
          source={data?.image || data?.source?.image}
          size="small"
          aspectRatio={1}
          style={styles.image}
        />
        <View style={styles.content}>
          <Text
            size={17}
            weight="medium"
            lightColor="#000000"
            darkColor="#ffffff"
          >
            {data?.title}
          </Text>
          <Info
            style={styles.info}
            list={[
              data?.type || '',
              data?.level || '',
              data?.duration || '',
              data?.source?.name || '',
            ].filter((item) => !!item)}
            fontSize={13}
          />
          {hasTags && <TagsOld items={data?.tags} onPress={onTagPress} />}
        </View>
      </>
    ),
    [
      data?.duration,
      data?.level,
      data?.source?.name,
      data?.tags,
      data?.title,
      data?.type,
      hasTags,
      data?.image,
      data?.source?.image,
      onTagPress,
    ],
  );

  if (!data) {
    return (
      <View
        style={[
          styles.SlimContentItem,
          { borderBottomColor },
          isLast && styles.lastItem,
        ]}
      >
        <View style={[styles.image, { backgroundColor: imageBackground }]} />
        <View style={styles.content}>
          <View
            style={styles.wireframeTitle}
            lightColor="#d2d2d2"
            darkColor="#232326"
          />
          <View
            style={styles.wireframeTitle}
            lightColor="#d2d2d2"
            darkColor="#232326"
          />
          <View
            style={styles.wireframeDescription}
            lightColor="#d2d2d2"
            darkColor="#232326"
          />
        </View>
      </View>
    );
  }

  return (
    <View
      style={[
        styles.SlimContentItem,
        { borderBottomColor },
        isLast && styles.lastItem,
      ]}
    >
      {!onPress && renderBody}
      {!!onPress && (
        <TouchableOpacity
          style={styles.touchable}
          onPress={handlePress}
          activeOpacity={0.8}
        >
          {renderBody}
        </TouchableOpacity>
      )}
      {!!isStateTrue && ComponentTrue}
      {!isStateTrue && ComponentFalse}
    </View>
  );
};

SlimContentItem.defaultProps = defaultProps;

const styles = StyleSheet.create({
  SlimContentItem: {
    flexDirection: 'row',
    paddingTop: unit(16),
    paddingBottom: unit(16),
    borderBottomWidth: 1,
  },
  lastItem: {
    borderBottomWidth: 0,
  },
  touchable: {
    flexDirection: 'row',
    flex: 1,
  },
  image: {
    width: unit(50),
    height: unit(50),
    borderRadius: unit(5),
  },
  content: {
    flex: 1,
    marginHorizontal: unit(10),
    justifyContent: 'center',
  },
  info: {
    marginTop: unit(4),
  },
  wireframeTitle: {
    height: unit(14),
    marginBottom: unit(8),
    width: '100%',
  },
  wireframeDescription: {
    height: unit(14),
    width: '70%',
  },
});

export default memo(SlimContentItem);
