import { useContext, useMemo } from 'react';
import { Dimensions } from 'react-native';

import { Context } from '../elements/Context';
import type { Config } from '../types';

const defaultMethods = {
  setScrollValue: () => undefined,
  getScrollValue: () => ({
    top: 0,
    dimension: {
      window: Dimensions.get('window'),
    },
  }),
};

export const useScrollMethods = () => {
  let context: Config = defaultMethods;
  try {
    context = useContext(Context);
  } catch (error) {
    console.error('ScrollerStore Context is unavailable');
  }

  return useMemo(
    () => ({
      ...context,
      scrollToStart: (animated = false) => {
        window.scrollTo({
          top: 0,
          behavior: (animated ? 'smooth' : 'instant') as unknown as
            | 'smooth'
            | 'auto',
        });
      },
      scrollToEnd: (animated = false) => {
        window.scrollTo({
          top: document.body.scrollHeight - window.innerHeight,
          behavior: (animated ? 'smooth' : 'instant') as unknown as
            | 'smooth'
            | 'auto',
        });
      },
      scrollTo: (position: number, animated = false) => {
        window.scrollTo({
          top: position,
          behavior: (animated ? 'smooth' : 'instant') as unknown as
            | 'smooth'
            | 'auto',
        });
      },
    }),
    [context],
  );
};
