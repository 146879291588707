import { useCallback, useMemo, useState, type SetStateAction } from 'react';
import {
  Button,
  Box,
  FormControl,
  Typography,
  FormHelperText,
  FormLabel,
  Input,
  useTheme,
} from '@mui/joy';
import { StyleSheet } from 'react-native';

import { useNavigate } from 'navigation/hooks';

import { dispatcher, useDispatcher } from 'store/utils/redux/hooks';

import { unit } from 'utils';

import Picture from 'components/Picture';
import Divider from 'components/Divider';
import Alert from 'components/Alert';
import DualPanels from 'widgets/DualPanels';
import useAuth from 'widgets/OAuth/model/useAuth';

import Icon from 'ui/Icon';

import { useOnInputEnter } from 'hooks';

import { credentials } from 'services/api';
import videoBackground from './assets/videoBackground.mp4';

interface IOAuthProviders {
  name: 'Facebook' | 'Google' | 'Linkedin';
  slug: string;
  handler: () => void;
}

type FormValues = {
  email: string | null;
};

const validate = (
  values: FormValues,
): { isValid: boolean; errors: Partial<FormValues> } => {
  let isValid = true;
  const errors: Partial<FormValues> = {};
  if (!values?.email || !/.+@.+\..+/.test(values?.email)) {
    isValid = false;
    if (values?.email !== null) {
      errors.email = 'not valid';
    }
  }
  return {
    isValid,
    errors,
  };
};

const AuthStart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatcher();

  const theme = useTheme();
  const [email, setEmail] = useState<string | null>(null);

  const validation = useMemo(() => validate({ email }), [email]);

  const [oauthName, setOauthName] = useState<
    null | 'facebook' | 'google' | 'linkedin'
  >(null);

  const handleChangeEmail = useCallback(
    (event: { target: { value: SetStateAction<string | null> } }) => {
      setEmail(event.target.value);
    },
    [],
  );

  const auth = useAuth({
    onSuccess: (result) => {
      console.log('result', result);
      credentials.set(result.token.access, result.token.refresh);
      if (typeof location !== 'undefined') {
        location.reload();
      }
    },
    onFail: (result) => {
      if (result.type === 'error') {
        Alert.error(result.message);
      }
      if (result.type === 'warning') {
        Alert.info(result.message);
      }
    },
  });

  const handleAuthLinkedin = useCallback(() => {
    setOauthName('linkedin');
    dispatch.auth.doOAuth('linkedin');
  }, []);

  const handleAuthEmail = useCallback(() => {
    if (!email || !validation.isValid) {
      return;
    }
    dispatch.auth.setSignInEmail(email);
    navigate('Auth/SignUp');
  }, [email, validation.isValid]);

  const { onKeyDown } = useOnInputEnter(
    useCallback(() => {
      handleAuthEmail();
    }, [handleAuthEmail]),
  );

  const handleLogin = useCallback(() => {
    if (email && validation.isValid) {
      dispatch.auth.setSignInEmail(email);
    }
    navigate('Auth/SignIn');
  }, [validation.isValid, email]);

  const handleVideoOpen = useCallback(() => {
    dispatcher.modal.open('ProductVideo');
  }, []);

  const oauthProviders: IOAuthProviders[] = useMemo(
    () => [
      // { name: 'Facebook', slug: 'facebook', handler: handleAuthFacebook },
      // { name: 'Google', slug: 'google', handler: handleAuthGoogle },
      { name: 'Linkedin', slug: 'linkedin', handler: handleAuthLinkedin },
    ],
    [],
  );

  return (
    <DualPanels
      leftPanel={
        <>
          <Box sx={{ alignSelf: 'center', mb: 2 }}>
            {theme.palette.mode === 'dark' && (
              <Picture
                source={require('assets/images/new-logo-circle-white.png')}
                hasWireframe={false}
                style={styles.logo}
              />
            )}
            {theme.palette.mode === 'light' && (
              <Picture
                source={require('assets/images/new-logo-circle-black.png')}
                hasWireframe={false}
                style={styles.logo}
              />
            )}
          </Box>
          <Box alignSelf="center" mb={2}>
            <Typography fontSize={52} fontWeight={500}>
              Sign up
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap={2} mb={2}>
            <Box>
              <Button
                size="lg"
                onClick={() => auth.login('google')}
                fullWidth
                variant="soft"
                loading={auth.inProcess === 'google'}
                disabled={auth.inProcess !== 'google' && !!auth.inProcess}
              >
                <Icon
                  name="google"
                  fw
                  family="brands"
                  size="lg"
                  color={theme.palette.background.body}
                  marginRight={1}
                />
                <Typography
                  sx={{
                    color: theme.palette.background.body,
                  }}
                  fontSize={16}
                  fontWeight={400}
                >
                  Continue with Google
                </Typography>
              </Button>
            </Box>
            {oauthProviders.map((item) => (
              <Box key={item.name}>
                <Button
                  size="lg"
                  onClick={item.handler}
                  fullWidth
                  variant="soft"
                  loading={oauthName === item.slug}
                  disabled={
                    (!!oauthName && oauthName !== item.slug) || !!auth.inProcess
                  }
                >
                  <Icon
                    name={item.name.toLowerCase()}
                    fw
                    family="brands"
                    size="lg"
                    color={theme.palette.background.body}
                    marginRight={1}
                  />
                  <Typography
                    sx={{
                      color: theme.palette.background.body,
                    }}
                    fontSize={16}
                    fontWeight={400}
                  >
                    {`Continue with ${item.name}`}
                  </Typography>
                </Button>
              </Box>
            ))}
          </Box>
          <Divider orientation="horizontal">
            <Typography
              fontSize={16}
              px={1}
              py={2}
              bgcolor={theme.palette.background.body}
              alignSelf="center"
            >
              OR
            </Typography>
          </Divider>
          <Box display="flex" flexDirection="column">
            <FormControl size="md" inputMode="email">
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <FormLabel sx={{ color: theme.palette.neutral.plainColor }}>
                  Email
                </FormLabel>
                {!validation.isValid && (
                  <FormHelperText sx={{ color: '#DB3327' }}>
                    {validation.errors.email}
                  </FormHelperText>
                )}
              </Box>
              <Input
                placeholder="Enter your email"
                variant="outlined"
                value={email || ''}
                sx={{
                  '--_Input-focusedHighlight': theme.palette.neutral.softColor,
                }}
                name="email"
                disabled={!!auth.inProcess}
                onChange={handleChangeEmail}
                onKeyDown={onKeyDown}
                size="lg"
              />
            </FormControl>
            <Button
              fullWidth
              disabled={!validation.isValid || !!auth.inProcess}
              onClick={handleAuthEmail}
              size="lg"
              variant="soft"
              color="neutral"
              sx={{
                mt: 4,
              }}
              endDecorator={<Icon name="arrow-right" color="inherit" />}
            >
              Continue
            </Button>
            <Box mt={3} alignSelf="center">
              <Typography fontSize={16} fontWeight={400}>
                Already have an account?{' '}
                <Typography
                  sx={{ cursor: 'pointer' }}
                  onClick={handleLogin}
                  color="primary"
                  fontSize={17}
                >
                  Log in
                </Typography>
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={1}
            mt={4}
          >
            <Box display="flex" flexDirection="row" gap={1}>
              <Typography
                color="neutral"
                fontSize={13}
                component="a"
                href="https://www.iubenda.com/terms-and-conditions/39334813"
                target="_blank"
              >
                Terms of use
              </Typography>
              <Typography color="neutral" fontSize={12}>
                |
              </Typography>
              <Typography
                color="neutral"
                fontSize={13}
                component="a"
                href="https://www.iubenda.com/privacy-policy/39334813"
                target="_blank"
              >
                Privacy Policy
              </Typography>
            </Box>
          </Box>
        </>
      }
      rightPanel={
        <Box
          display="flex"
          flex={1}
          alignItems="center"
          justifyContent="center"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            height={633}
            py={4}
          >
            <Typography
              textAlign="center"
              position="relative"
              lineHeight="normal"
              fontSize={50}
              fontWeight={500}
              pt={7}
            >
              Intelligent
              <br />
              knowledge
              <br />
              interface
            </Typography>
            <Button
              sx={{
                mt: 4,
                color: theme.palette.text.primary,
                '--variant-outlinedBorder': theme.palette.text.primary,
                '--variant-outlinedHoverBg': theme.palette.neutral['100'],
              }}
              endDecorator={<Icon name="play" weight="solid" color="inherit" />}
              variant="outlined"
              size="lg"
              onClick={handleVideoOpen}
            >
              Play product video
            </Button>
          </Box>
        </Box>
      }
      rightPanelBackGround={videoBackground}
    />
  );
};

const styles = StyleSheet.create({
  logo: {
    width: unit(32),
    height: unit(32),
    backgroundColor: 'transparent',
  },
});

export default AuthStart;
