import { memo, forwardRef, useMemo, useEffect, type MouseEvent } from 'react';
import { type GestureResponderEvent, StyleSheet } from 'react-native';
import { createUseStyles } from 'react-jss';
import cn from 'classnames';
import { Box, Chip, Tooltip } from '@mui/joy';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';

import { unit } from 'utils';
import { useResponsive } from 'hooks';

import Card from 'widgets/Card';
import Button from 'components/Button';

import Icon from 'ui/Icon';
import Note from './Note';

type ItemProps = {
  collectionId: number;
  id: number;
  type: 'material' | 'note' | 'new-note';
  props?: any;
  handleProps?: any;
  isRecommended?: boolean;
  isActive?: boolean;
  isDragged?: boolean;
  isMy?: boolean;
  draggable?: boolean;
  onAddMaterialPress?: (
    event: GestureResponderEvent,
    context: { id: number; type: 'material' | 'note' },
  ) => void;
  onAddNotePress?: (
    event: GestureResponderEvent,
    context: { id: number; type: 'material' | 'note' },
  ) => void;
  onRemovePress?: (
    event:
      | GestureResponderEvent
      | MouseEvent<HTMLDivElement | HTMLButtonElement>,
    context: { id: number; type: 'material' | 'note' },
  ) => void;
};

const Item = (args: ItemProps, forwardedRef: any) => {
  const {
    collectionId,
    id,
    type,
    isRecommended,
    isActive,
    isDragged,
    props,
    handleProps,
    draggable,
    isMy,
    onAddMaterialPress,
    onAddNotePress,
    onRemovePress,
  } = args;

  const classes = useClasses();
  const responsive = useResponsive();
  const hasDrag = !responsive.is.mobileL;
  const dispatcher = useDispatcher();

  const isItemExist = useSelector((state) => {
    let result = false;
    if (type === 'material') {
      result = !!state.content.data[id];
    }
    if (type === 'note') {
      result = !!state.smartNote.data[id];
    }
    return result;
  });

  useEffect(() => {
    if ((type === 'material' || type === 'note') && !isItemExist) {
      dispatcher.playlist.loadItem({ collectionId, type, id });
    }
  }, [isItemExist, collectionId, type, id]);

  const context = useMemo(() => {
    if (type === 'new-note') {
      return null;
    }
    return {
      id,
      type,
    };
  }, [id, type]);

  const renderBody = useMemo(() => {
    if (type === 'material') {
      return (
        <Card
          id={id}
          type={type}
          hasSummary
          hasHighlight
          hasTags
          hasRemove={false}
          hasLink
          hasQueue
          hasAuthor
          hasToCollection
          hasIndexed
          footerStart={
            <>
              {isRecommended && (
                <Chip variant="solid" color="primary" sx={{ px: 0, mr: 1 }}>
                  <Box display="flex" width="1.5rem" justifyContent="center">
                    <Icon name="stars" fw weight="light" color="#FFFFFF" />
                  </Box>
                </Chip>
              )}
            </>
          }
        />
      );
    }
    if (type === 'note') {
      return <Note id={id} isEditable={isMy} />;
    }
    if (type === 'new-note') {
      return <Note id={id} isEditable={isMy} isNew />;
    }
    return null;
  }, [id, type, isMy, isRecommended, responsive.is.mobileL]);

  if (!renderBody) {
    return null;
  }

  if (!hasDrag) {
    return (
      <Box
        ref={draggable ? forwardedRef : undefined}
        className={cn(classes.wrapper, {
          [classes.isActive]: isActive,
          [classes.isDragged]: isDragged,
        })}
        {...props}
        {...handleProps}
      >
        {renderBody}
      </Box>
    );
  }

  return (
    <Box
      ref={forwardedRef}
      className={cn(classes.wrapper, `${classes.wrapper}-withDrag`, {
        [classes.isActive]: isActive,
        [classes.isDragged]: isDragged,
      })}
      sx={{
        '.MuiCard-root': {
          paddingLeft: 'calc(var(--Card-padding) * 1.375)',
          minHeight: '1.5rem',
        },
      }}
      {...props}
    >
      {draggable && (
        <Box
          className={`${classes.wrapper}-controls`}
          sx={{ pointerEvents: 'none' }}
        >
          {type !== 'new-note' && (
            <Tooltip
              arrow
              variant="outlined"
              placement="left"
              size="sm"
              title="Drag to switch position"
              disableInteractive
            >
              <Box
                className={`${classes.wrapper}-drag`}
                sx={{ pointerEvents: 'all' }}
                {...handleProps}
              >
                <Icon
                  name="grip-dots-vertical"
                  weight="light"
                  fw
                  color="primary"
                />
              </Box>
            </Tooltip>
          )}
          {type !== 'new-note' && context && (
            <Tooltip
              arrow
              variant="outlined"
              placement="left"
              size="sm"
              title="Delete"
              disableInteractive
            >
              <Box
                className={`${classes.wrapper}-trash`}
                component="div"
                sx={{ pointerEvents: 'all', cursor: 'pointer' }}
                onClick={(event) => {
                  onRemovePress?.(event, context);
                }}
              >
                <Icon name="trash" weight="light" fw color="#db3327" />
              </Box>
            </Tooltip>
          )}
        </Box>
      )}
      {renderBody}
      {isMy && (
        <Box className={classes.actions}>
          <Box
            className={`${classes.actions}--line ${classes.actions}--line--left`}
          />
          <Box
            className={`${classes.actions}--line ${classes.actions}--line--right`}
          />
          <Tooltip
            arrow
            variant="outlined"
            placement="top"
            size="sm"
            title="Add material"
            disableInteractive
          >
            <Box>
              <Button
                type="button-icon"
                variant="contained"
                radius="full"
                width={32}
                height={32}
                style={styles.action}
                lightColor="#EAEAEA"
                darkColor="#ffffff"
                context={context}
                onPress={onAddMaterialPress}
              >
                <Icon name="plus" size="sm" weight="light" fw color="current" />
              </Button>
            </Box>
          </Tooltip>
          <Tooltip
            arrow
            variant="outlined"
            placement="top"
            size="sm"
            title="Add smart note"
            disableInteractive
          >
            <Box>
              <Button
                type="button-icon"
                variant="contained"
                radius="full"
                width={32}
                height={32}
                style={styles.action}
                lightColor="#EAEAEA"
                darkColor="#ffffff"
                context={context}
                onPress={onAddNotePress}
              >
                <Icon name="text" size="sm" weight="light" fw color="current" />
              </Button>
            </Box>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

const styles = StyleSheet.create({
  action: {
    position: 'relative',
    marginHorizontal: unit(6),
  },
});

const useClasses = createUseStyles({
  actions: {
    '&': {
      position: 'absolute',
      left: unit(24),
      right: unit(4),
      bottom: unit(-9),
      height: unit(32),
      display: 'flex',
      flexDirection: 'row',
      zIndex: 1,
      opacity: 0,
      transition: 'opacity 300ms ease',
      justifyContent: 'center',
    },
    '&--line': {
      '&': {
        position: 'absolute',
        left: 0,
        right: 0,
        height: 1,
        top: 16,
        background: '#888888',
        boxShadow: '0 0 2px #888888',
      },
      '&--left': {
        right: `calc(50% + ${unit(48)})`,
      },
      '&--right': {
        left: `calc(50% + ${unit(48)})`,
      },
    },
    '&:hover': {
      transition: 'opacity 50ms ease',
      opacity: 1,
    },
  },
  // @todo реверстка
  wrapper: {
    '&': {
      position: 'relative',
      // marginBottom: unit(12),
      paddingBottom: unit(12),
    },
    '&:first-child': {
      // marginTop: 1,
      // marginLeft: 1,
      // marginRight: 1,
    },
    '&-controls': {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '1.25rem',
      top: 0,
      bottom: '0.75rem',
      left: 0,
      zIndex: 10,
      opacity: 0,
      gap: '0.25rem',
      transition: 'opacity 300ms ease',
    },
    '&-drag': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '1.25rem',
      height: '1.25rem',
      cursor: 'pointer',
      // marginTop: unit(4),
      // marginBottom: unit(4),
    },
    '&-trash': {
      width: '1.25rem',
      height: '1.25rem',
      cursor: 'pointer',
    },
    '&-withDrag:hover > &-controls': {
      opacity: 1,
    },
  },
  isActive: {
    opacity: 0.3,
  },
  isDragged: {
    opacity: 1,
  },
});

export default memo(forwardRef(Item));
