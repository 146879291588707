import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import {
  type CollectionPermissionType,
  type CollectionRoleType,
  type CollectionType,
  type MaterialMetadataItemType,
  type MaterialType,
  type NoteType,
} from 'app/entities';

import { arrayMoveElement, guard } from 'utils';
import { v4 as uuidV4 } from 'uuid';

type Item<T> = {
  type: 'material' | 'note' | 'unknown';
  id: T;
};

export type PlaylistStore = {
  data: Record<number, CollectionType>;
  permissions: CollectionRoleType[];
  meta: {
    loadingMap: Record<number | string, boolean>;
    deletingMap: Record<number, boolean>;
    loadingPermissionMap: Record<string, boolean>;
    edit?: CollectionType;
    create?: CollectionType;
  };
};

const initialState: PlaylistStore = {
  data: {},
  permissions: [],
  meta: {
    loadingMap: {},
    deletingMap: {},
    loadingPermissionMap: {},
  },
};

const playlistSlice = createSlice({
  name: 'playlist',
  initialState,
  reducers: {
    loadById: (state, action: PayloadAction<{ id: number | number[] }>) => {
      if (!action.payload.id) {
        return;
      }
      const ids = !Array.isArray(action.payload.id)
        ? [action.payload.id]
        : action.payload.id;
      ids.forEach((id) => {
        state.meta.loadingMap[id] = true;
      });
    },
    loadByIdDone: (state, action: PayloadAction<{ id: number | number[] }>) => {
      if (!action.payload.id) {
        return;
      }
      const ids = !Array.isArray(action.payload.id)
        ? [action.payload.id]
        : action.payload.id;
      ids.forEach((id) => {
        delete state.meta.loadingMap[id];
      });
    },
    setItem: (
      state,
      action: PayloadAction<{
        data:
          | (Pick<CollectionType, 'id'> & Partial<Omit<CollectionType, 'id'>>)
          | (Pick<CollectionType, 'id'> & Partial<Omit<CollectionType, 'id'>>)[]
          | null;
      }>,
    ) => {
      const { data } = action.payload;
      if (!data) {
        return;
      }
      const elementType = {
        CONTENT: 'material',
        NOTE: 'note',
        material: 'material',
        note: 'note',
      } as const;
      const items = !Array.isArray(data) ? [data] : data;
      items.forEach((item) => {
        state.data[item.id] = {
          ...state.data[item.id],
          ...item,
          internalUrl: `/playlist/${item.id}`,
          isSmartListEnabled: false, // temp closed smart list
          materialsMetadata:
            item.materialsMetadata?.map((element) => ({
              ...element,
              type: elementType[element.type as keyof typeof elementType],
            })) || [],
        };
      });
    },
    remove: (state, action: PayloadAction<{ id: number | number[] }>) => {
      const { payload } = action;
      const ids = !Array.isArray(payload.id) ? [payload.id] : payload.id;
      ids.forEach((id) => {
        state.meta.deletingMap[id] = true;
      });
    },
    removeSuccess: (
      state,
      action: PayloadAction<{ id: number | number[] }>,
    ) => {
      const { payload } = action;
      const ids = !Array.isArray(payload.id) ? [payload.id] : payload.id;
      ids.forEach((id) => {
        delete state.data[id];
      });
    },
    removeDone: (state, action: PayloadAction<{ id: number | number[] }>) => {
      const { payload } = action;
      const ids = !Array.isArray(payload.id) ? [payload.id] : payload.id;
      ids.forEach((id) => {
        delete state.meta.deletingMap[id];
      });
    },
    toggleSmartList: (state, action: PayloadAction<{ id: number }>) => {
      const { payload } = action;
      if (!payload.id || !state.data[payload.id]) {
        return;
      }

      state.data[payload.id].isSmartListEnabled = false; // temp closed smart list
      // !state.data[payload.id].isSmartListEnabled;
    },
    moveItems: (
      state,
      action: PayloadAction<{ id: number; oldIndex: number; newIndex: number }>,
    ) => {
      const { oldIndex, newIndex, id } = action.payload;
      arrayMoveElement(state.data[id].materialsMetadata, oldIndex, newIndex);
    },
    sortItems: (
      state,
      action: PayloadAction<{ id: number; oldIndex: number; newIndex: number }>,
    ) => {
      const { id, oldIndex, newIndex } = action.payload;
      if (!id || !state.data[id]) {
        return;
      }

      const data = state.data[id];
      if (data.isSmartListEnabled) {
        data.materialsMetadata = arrayMoveElement(
          data.materialsMetadata,
          oldIndex,
          newIndex,
        );
        return;
      }

      const userItems: MaterialMetadataItemType[] = [];
      const relatedItems: MaterialMetadataItemType[] = [];
      const relatedItemsMap: Record<
        number | string,
        MaterialMetadataItemType[]
      > = {};

      data.materialsMetadata.forEach((item) => {
        if (item.relatedToContentId) {
          relatedItems.push(item);
          return;
        }
        userItems.push(item);
      });

      relatedItems.sort((a, b) => {
        if (a.id > b.id) return 1;
        if (a.id < b.id) return -1;
        if (a.position > b.position) return 1;
        if (a.position < b.position) return -1;
        return 0;
      });

      relatedItems.forEach((item) => {
        const { relatedToContentId } = item;
        if (!relatedToContentId) {
          return;
        }
        if (!relatedItemsMap[relatedToContentId]) {
          relatedItemsMap[relatedToContentId] = [];
        }
        relatedItemsMap[relatedToContentId].push({ ...item });
      });

      data.materialsMetadata.splice(0);
      arrayMoveElement(userItems, oldIndex, newIndex).forEach((item) => {
        data.materialsMetadata.push(item);
        if (
          !Array.isArray(relatedItemsMap[item.id]) ||
          relatedItemsMap[item.id].length === 0
        ) {
          return;
        }
        data.materialsMetadata.push(...relatedItemsMap[item.id]);
      });
    },
    addItemDone: (
      state,
      action: PayloadAction<{
        success: boolean;
        data?: {
          collectionId: number;
          item: MaterialType | NoteType;
          position: number;
        };
      }>,
    ) => {
      const { success, data } = action.payload;
      if (!success || !data) {
        return;
      }
      const collection = state.data[data.collectionId];
      if (!collection) {
        return;
      }
      const { materialsMetadata } = collection;

      const existingElementsMap = new Set();
      materialsMetadata.forEach((item) => {
        existingElementsMap.add(`${item.id}-${item.type}`);
      });

      if (
        guard.isNote(data.item) &&
        !existingElementsMap.has(`${data.item.id}-note`)
      ) {
        existingElementsMap.add(`${data.item.id}-note`);
        collection.materialsMetadata.splice(data.position, 0, {
          id: data.item.id as number,
          type: 'note',
          isSmartListRecommendation: false,
          position: data.position,
          relatedToContentId: null,
        });
      }

      if (
        guard.isMaterial(data.item) &&
        !existingElementsMap.has(`${data.item.id}-material`)
      ) {
        existingElementsMap.add(`${data.item.id}-material`);
        collection.materialsMetadata.splice(data.position, 0, {
          id: data.item.id as number,
          type: 'material',
          isSmartListRecommendation: false,
          position: data.position,
          relatedToContentId: null,
        });
      }
    },
    removeItem: (
      state,
      action: PayloadAction<{
        collectionId: number;
        itemType: 'material' | 'note';
        itemId: number;
      }>,
    ) => undefined,
    removeItemDone: (
      state,
      action: PayloadAction<{
        collectionId: number;
        success: boolean;
        removedMaterials: { type: 'material' | 'note'; id: number }[];
      }>,
    ) => {
      const { collectionId, success, removedMaterials } = action.payload;
      if (!success) {
        return;
      }
      const removedMap = removedMaterials.map(
        (item) => `${item.type}-${item.id}`,
      );
      state.data[collectionId].materialsMetadata = state.data[
        collectionId
      ].materialsMetadata.filter(
        (item) => !removedMap.includes(`${item.type}-${item.id}`),
      );
    },
    updateInBackground: (
      state,
      action: PayloadAction<{
        id: number;
        data?: Pick<CollectionType, 'title'> &
          Partial<
            Pick<
              CollectionType,
              'description' | 'cover' | 'isPrivate' | 'materialsMetadata'
            >
          >;
      }>,
    ) => undefined,
    addNoteItem: (
      state,
      action: PayloadAction<{
        collectionId: number;
        itemType: 'note';
        text: string;
        options?: {
          after?: { type: 'material' | 'note'; id: number | string };
          append?: boolean;
        };
      }>,
    ) => undefined,
    addMaterialItem: (
      state,
      action: PayloadAction<{
        collectionId: number;
        data: MaterialType;
        itemType: 'material';
        options?: {
          after?: { type: 'material' | 'note'; id: number | string };
          append?: boolean;
        };
      }>,
    ) => undefined,
    newNote: (
      state,
      action: PayloadAction<{
        collectionId: number;
        options?: { after: Item<number | string> };
      }>,
    ) => {
      const { collectionId, options } = action.payload;
      if (!options?.after) {
        state.data[collectionId].materialsMetadata.splice(0, 0, {
          id: uuidV4(),
          type: 'new-note',
          relatedToContentId: null,
          isSmartListRecommendation: false,
          position: 0,
        });
        return;
      }

      const index = state.data[collectionId].materialsMetadata.findIndex(
        (item) =>
          item.type === options.after.type && item.id === options.after.id,
      );
      if (index === -1) {
        return;
      }
      state.data[collectionId].materialsMetadata.splice(index + 1, 0, {
        id: uuidV4(),
        type: 'new-note',
        relatedToContentId: null,
        isSmartListRecommendation: false,
        position: index + 1,
      });
    },
    cancelNewNote: (
      state,
      action: PayloadAction<{ tempId: string; collectionId: number }>,
    ) => {
      const { tempId, collectionId } = action.payload;
      const index = state.data[collectionId].materialsMetadata.findIndex(
        (item) => item.type === 'new-note' && item.id === tempId,
      );
      if (index === -1) {
        return;
      }
      state.data[collectionId].materialsMetadata.splice(index, 1);
    },
    commitNewNote: (
      state,
      action: PayloadAction<{
        tempId: string;
        text: string;
        collectionId: number;
      }>,
    ) => undefined,
    saveNewNoteDone: (
      state,
      action: PayloadAction<{
        success: boolean;
        data?: { realId: number; tempId: string };
        collectionId?: number;
      }>,
    ) => {
      const { success, data, collectionId } = action.payload;
      if (!success || !data || !collectionId) {
        return;
      }
      const index = state.data[collectionId].materialsMetadata.findIndex(
        (item) => item.type === 'new-note' && item.id === data.tempId,
      );
      if (index === -1) {
        return;
      }
      state.data[collectionId].materialsMetadata.splice(index, 1);
    },
    updateInBackgroundDone: (
      state,
      action: PayloadAction<{
        success: boolean;
        id?: number;
        data?: CollectionType;
        updates?: {
          addedElements?: {
            item: MaterialMetadataItemType;
            after: MaterialMetadataItemType;
          }[];
          removedElements?: MaterialMetadataItemType[];
        };
      }>,
    ) => {
      const { success, id, data, updates } = action.payload;
      if (!success || !id) {
        return;
      }
      if (data?.contentImages) {
        state.data[id].contentImages = data?.contentImages;
      }

      const { addedElements, removedElements } = updates || {};
      const { materialsMetadata } = state.data[id];

      if (addedElements && addedElements.length > 0) {
        const existingElementsMap = new Set();
        materialsMetadata.forEach((item) => {
          existingElementsMap.add(`${item.id}-${item.type}`);
        });
        addedElements.forEach((added) => {
          const { item, after } = added;
          if (existingElementsMap.has(`${item.id}-${item.type}`)) {
            return;
          }
          if (!after) {
            materialsMetadata.push(item);
            return;
          }
          const afterIndex = materialsMetadata.findIndex(
            (curItem) =>
              `${curItem.id}-${curItem.type}` === `${after.id}-${after.type}`,
          );
          if (afterIndex === -1) {
            materialsMetadata.push(item);
            return;
          }
          materialsMetadata.splice(afterIndex + 1, 0, item);
          existingElementsMap.add(`${item.id}-${item.type}`);
        });
      }
      if (removedElements && removedElements.length > 0) {
        const existingElementsMap = new Map();
        materialsMetadata.forEach((item, index) => {
          existingElementsMap.set(`${item.id}-${item.type}`, index);
        });
        removedElements
          .map((removedElement) =>
            existingElementsMap.get(
              `${removedElement.id}-${removedElement.type}`,
            ),
          )
          .filter((removedIndex) => removedIndex !== undefined)
          .sort((a, b) => b - a)
          .forEach((removedIndex) => {
            materialsMetadata.splice(removedIndex, 1);
          });
      }
    },
    createPermission: (
      state,
      action: PayloadAction<{
        userId: number | string;
        permissionId: number;
        collectionId: number;
      }>,
    ) => {
      const { userId, permissionId, collectionId } = action.payload;
      const key = `${userId}-${permissionId}-${collectionId}`;
      state.meta.loadingPermissionMap[key] = true;
    },
    setPermissions: (
      state,
      action: PayloadAction<{
        collectionId: number;
        permissions: CollectionPermissionType[];
      }>,
    ) => {
      const { collectionId, permissions } = action.payload;
      if (state.data[collectionId]) {
        state.data[collectionId].permissions = permissions;
      }
    },
    createPermissionDone: (
      state,
      action: PayloadAction<{
        userId: number | string;
        permissionId: number;
        collectionId: number;
      }>,
    ) => {
      const { userId, permissionId, collectionId } = action.payload;
      const key = `${userId}-${permissionId}-${collectionId}`;
      delete state.meta.loadingPermissionMap[key];
    },
    deletePermission: (
      state,
      action: PayloadAction<{
        userId: number;
        permissionId: number | string;
        collectionId: number;
      }>,
    ) => {
      const { userId, collectionId, permissionId } = action.payload;
      const key = `${userId}-${permissionId}-${collectionId}`;
      state.meta.loadingPermissionMap[key] = true;
    },
    deletePermissionDone: (
      state,
      action: PayloadAction<{
        userId: number;
        permissionId: number | string;
        collectionId: number;
      }>,
    ) => {
      const { userId, collectionId, permissionId } = action.payload;
      const key = `${userId}-${permissionId}-${collectionId}`;
      delete state.meta.loadingPermissionMap[key];
    },
    updatePermission: (
      state,
      action: PayloadAction<{
        userId: number;
        permissionId: number;
        collectionId: number;
      }>,
    ) => {
      const { userId, permissionId, collectionId } = action.payload;
      const key = `${userId}-${permissionId}-${collectionId}`;
      state.meta.loadingPermissionMap[key] = true;
    },
    updatePermissionDone: (
      state,
      action: PayloadAction<{
        userId: number | string;
        permissionId: number;
        collectionId: number;
      }>,
    ) => {
      const { userId, permissionId, collectionId } = action.payload;
      const key = `${userId}-${permissionId}-${collectionId}`;
      delete state.meta.loadingPermissionMap[key];
    },
    loadPermissionRole: () => undefined,
    loadPermissionRoleDone: (
      state,
      action: PayloadAction<{ permissions: CollectionRoleType[] }>,
    ) => {
      const { permissions } = action.payload;
      state.permissions = permissions;
    },
    materialsMetadataUpdate: (
      state,
      action: PayloadAction<{
        collectionId: number;
        items: MaterialMetadataItemType[];
      }>,
    ) => {
      state.data[action.payload.collectionId].materialsMetadata.splice(0);
      state.data[action.payload.collectionId].materialsMetadata.push(
        ...action.payload.items,
      );
    },
    loadItem: (
      _,
      action: PayloadAction<{
        collectionId: number;
        type: 'material' | 'note';
        id: number;
      }>,
    ) => undefined,
    loadItemPush: (
      state,
      actions: PayloadAction<{ collectionId: number; keyId: string }>,
    ) => {
      const { collectionId, keyId } = actions.payload;
      state.meta.loadingMap[`${collectionId}-${keyId}`] = true;
    },
    loadItemFlushDone: (
      state,
      actions: PayloadAction<{ collectionId: number; keyIds: string[] }>,
    ) => {
      const { collectionId, keyIds } = actions.payload;
      keyIds.forEach((keyId) => {
        delete state.meta.loadingMap[`${collectionId}-${keyId}`];
      });
    },
  },
});

export const { reducer, actions } = playlistSlice;
