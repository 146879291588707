import { type ForwardRefRenderFunction, memo, forwardRef } from 'react';
import { StyleSheet } from 'react-native';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';

import { unit } from 'utils';
import { Text, View } from 'components/Themed';
import MaterialAdded from 'assets/lottie/material-added.json';

import { type Controller } from '../types';
import Popup from '../elements/Info';

export interface Context {
  originalUrl: string;
  tags: string[];
  title?: string;
  type?: 'course' | 'article' | 'video' | 'source' | 'link' | string;
  description?: string;
  sectionId?: number;
  sourceId?: number;
  image?: string;
  level?: 'junior' | 'middle' | 'senior' | string;
  duration?: string;
}

const AddMaterial: ForwardRefRenderFunction<Controller, any> = (
  props,
  forwardedRef,
) => {
  const size = 200;
  const loop = false;

  return (
    <Popup.Panel ref={forwardedRef} isBlocked>
      <View style={styles.container}>
        <DotLottieReact
          style={{
            width: unit(size),
          }}
          data={MaterialAdded}
          loop={loop}
          autoplay
        />
        <Text size={34}>Material added</Text>
      </View>
    </Popup.Panel>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: unit(100),
  },
});

export default memo(forwardRef<Controller, any>(AddMaterial));
