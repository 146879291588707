import * as uuid from 'uuid';
import {
  takeEvery,
  put,
  cancel,
  type SagaReturnType,
} from 'redux-saga/effects';

import type { MaterialType, CollectionType } from 'app/entities';

import { navigate } from 'navigation/methods';
import { saveLastRoute } from 'utils';

import * as api from 'services/api';

import { call, select } from 'store/utils/saga/effects';
import * as contentStore from 'store/nodes/content';

import * as actions from '../actions';

export const config = {
  action: [
    actions.toggleLike.type,
    actions.toggleDislike.type,
    actions.toggleBookmark.type,
  ],
  method: takeEvery,
};

const tasks: Record<string, string> = {};

export function* func(
  action: SagaReturnType<
    | typeof actions.toggleLike
    | typeof actions.toggleDislike
    | typeof actions.toggleBookmark
  >,
) {
  const hasSession = yield* call(() => api.credentials.hasSession());
  if (!hasSession) {
    yield* call(() => saveLastRoute());
    yield* call(() => navigate('Auth/Start'));
    yield cancel();
    return;
  }

  const { payload } = action;
  const type = payload.type as 'like' | 'dislike' | 'bookmark';
  const { resource, resourceId } = payload;

  const requestId = uuid.v4();
  const key = `${resource}-${type}-${resourceId}`;
  tasks[key] = requestId;

  yield put(actions.updateInteraction(type, resource, resourceId));

  const data = yield* select((state): MaterialType | CollectionType | null => {
    if (resource === 'content') {
      return {
        ...state.content.data[resourceId],
        ...state?.interaction?.content?.[resourceId],
      };
    }
    if (resource === 'playlist') {
      return {
        ...state.playlist.data[resourceId],
        ...state?.interaction?.playlist?.[resourceId],
      };
    }
    return null;
  });

  if (!data) {
    delete tasks[key];
    yield put(actions.updateInteractionDone(type, resource, resourceId));
    yield cancel();
    return;
  }

  const propertyMap = {
    like: 'isLiked',
    dislike: 'isDisliked',
    bookmark: 'isBookmarked',
  };

  const targetProperty = propertyMap[
    type as 'like' | 'dislike' | 'bookmark'
  ] as 'isLiked' | 'isDisliked' | 'isBookmarked';
  const isSet = data[targetProperty] as boolean;

  const oldValue = {
    isLiked: data.isLiked,
    isDisliked: data.isDisliked,
    isBookmarked: data.isBookmarked,
  };
  const newValue = {
    isLiked: data.isLiked,
    isDisliked: data.isDisliked,
    isBookmarked: data.isBookmarked,
  };
  if (type === 'bookmark') {
    newValue.isBookmarked = !isSet;
  }
  if (type === 'like' && data.isLiked === false && data.isDisliked === false) {
    newValue.isLiked = true;
  }
  if (type === 'like' && data.isLiked === true && data.isDisliked === false) {
    newValue.isLiked = false;
  }
  if (type === 'like' && data.isLiked === false && data.isDisliked === true) {
    newValue.isLiked = true;
    newValue.isDisliked = false;
  }
  if (
    type === 'dislike' &&
    data.isDisliked === false &&
    data.isLiked === false
  ) {
    newValue.isDisliked = true;
  }
  if (
    type === 'dislike' &&
    data.isDisliked === true &&
    data.isLiked === false
  ) {
    newValue.isDisliked = false;
  }
  if (
    type === 'dislike' &&
    data.isDisliked === false &&
    data.isLiked === true
  ) {
    newValue.isDisliked = true;
    newValue.isLiked = false;
  }

  if (['content', 'playlist'].includes(resource)) {
    yield put(
      actions.setValues(resource, resourceId, type, {
        ...data,
        ...newValue,
      }),
    );
  }

  const result = yield* call(() => {
    if (isSet) {
      return api.resource.interaction.remove(resource, resourceId, type);
    }
    return api.resource.interaction.set(resource, resourceId, type);
  });

  if (result.error || !result.data) {
    if (tasks[key] === requestId && type === 'bookmark') {
      yield put(actions.setValues(resource, resourceId, type, oldValue));
    }
    delete tasks[key];
    yield put(actions.updateInteractionDone(type, resource, resourceId));
    yield cancel();
    return;
  }

  const confirmValue = {
    isLiked: result.data.isLiked,
    isDisliked: result.data.isDisliked,
    isBookmarked: result.data.isBookmarked,
  };

  if (['content', 'playlist'].includes(resource)) {
    if (tasks[key] === requestId && type === 'bookmark') {
      yield put(actions.setValues(resource, resourceId, type, confirmValue));
    }
  }

  if (
    ['content', 'playlist'].includes(resource) &&
    tasks[key] === requestId &&
    type === 'dislike'
  ) {
    yield put(contentStore.actions.removeFromSequence(resource, resourceId));
  }

  delete tasks[key];
  yield put(actions.updateInteractionDone(type, resource, resourceId));

  // if (['content', 'playlist'].includes(resource) && type === 'bookmark') {
  //   yield put(libraryStore.actions.loadCollections());
  // }
}
