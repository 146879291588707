import { memo, useMemo } from 'react';
import { type GestureResponderEvent } from 'react-native';
import { useSelector } from 'react-redux';

import type { CollectionType } from 'app/entities';
import { unit } from 'utils';
import { useRoute } from 'navigation/hooks';
import * as collectionStore from 'store/nodes/playlist';

import CollectionSingleCover from 'components/CollectionSingleCover';
import Icon from 'ui/Icon';
import { Box } from '@mui/joy';
import MenuButton from '../../../../elements/MenuButton';

type ItemProps = {
  id: number;
  onPress: (
    event: GestureResponderEvent,
    context: { data: CollectionType },
  ) => void;
};

const Item = (props: ItemProps) => {
  const { id, onPress } = props;

  const route = useRoute<'Playlist'>();
  const collection = useSelector(collectionStore.selectors.dataById(id));

  const buttonStyles = useMemo(() => {
    if (!collection?.isPrivate) {
      return undefined;
    }
    return { paddingRight: unit(30) };
  }, [collection?.isPrivate]);

  if (!collection) {
    return null;
  }

  return (
    <MenuButton
      context={{ data: collection }}
      onPress={onPress}
      iconLeft={
        <>
          <CollectionSingleCover
            data={collection}
            iconSize={24}
            widthPicture={24}
            heightPicture={24}
          />
          {collection?.isPrivate && (
            <Box
              style={{
                position: 'absolute',
                right: unit(24),
                opacity: 0.5,
              }}
            >
              <Icon
                size="xs"
                fw
                weight="solid"
                color="var(--joy-palette-primary-solidDisabledColor)"
                name="eye-slash"
              />
            </Box>
          )}
        </>
      }
      text={collection.title || 'New collection'}
      style={buttonStyles}
      isSelected={
        route.name === 'Playlist' &&
        collection.id === Number(route.params?.resourceId)
      }
    />
  );
};

export default memo(Item);
