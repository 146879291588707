import { takeLatest, put, cancel } from 'redux-saga/effects';
import type { SagaReturnType } from 'redux-saga/effects';
import * as Linking from 'expo-linking';

import * as api from 'services/api';

import { call } from 'store/utils/saga/effects';

import network from 'lib/network';

import { type SuccessResult } from 'app/entities';
import * as actions from '../actions';

export const config = {
  action: actions.doPasswordReset.type,
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof actions.doPasswordReset>) {
  const { email } = action.payload;

  const url = yield* call(() => Linking.createURL('/auth/password-reset'));
  const { errors } = yield* call(() =>
    network
      .request<SuccessResult>('/auth/password-reset', {
        baseUrl: process.env.PROTECTED_API_URL || '/api',
      })
      .body({ email, frontendRedirectUri: url })
      .post(),
  );

  if (errors) {
    yield put(actions.doPasswordResetDone(errors[0]?.message));
    yield cancel();
    return;
  }

  yield put(actions.doPasswordResetDone());
}
