import { memo, useCallback } from 'react';
import { Box, type BoxProps, Card, CardContent, Typography } from '@mui/joy';
import Icon from 'ui/Icon';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as userStore from 'store/nodes/user';

const CardPlus = (props: BoxProps) => {
  const { sx, ...rest } = props;
  const dispatcher = useDispatcher();

  const userProfile = useSelector(userStore.selectors.getMy);

  const handlePlusOpen = useCallback(() => {
    const isMobile = window.innerWidth <= 460;
    if (isMobile) {
      return dispatcher.modal.open('PlusBottomSheet');
    }
    return dispatcher.plus.open({ toCollection: { append: true } });
  }, []);

  if (!userProfile) {
    return null;
  }

  return (
    <Box
      sx={{
        containerType: 'inline-size',
        ...sx,
      }}
      {...rest}
    >
      <Card
        variant="soft"
        onClick={handlePlusOpen}
        sx={{
          '&': {
            '--Card-radius': (theme) => theme.radius.xl,
            backgroundColor: 'unset',
            transition: '200ms box-shadow ease, 200ms background-color ease',
            cursor: 'pointer',
            gap: 1,
            '--card-hover-opacity': 0,
            '--nestedBg': (theme) => theme.palette.background.level1,
            '--nestedHoverBg': (theme) => theme.palette.background.level1,
            '--nestedActiveBg': (theme) => theme.palette.background.level2,
            '--variant-plainHoverBg': 'var(--nestedHoverBg)',
            '--variant-plainActiveBg': 'var(--nestedActiveBg)',
            '--variant-outlinedHoverBg': 'var(--nestedHoverBg)',
            '--variant-outlinedActiveBg': 'var(--nestedActiveBg)',
          },
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: (theme) => theme.palette.background.level1,
            '--card-hover-opacity': 1,
            '--nestedBg': (theme) => theme.palette.background.level2,
            '--nestedHoverBg': (theme) => theme.palette.background.level2,
            '--nestedActiveBg': (theme) => theme.palette.background.level3,
          },
          '@container (width <= 400px )': {
            '&': {
              gap: 1.5,
              height: 380,
              backgroundColor: (theme) => theme.palette.background.level1,
              '--nestedBg': (theme) => theme.palette.background.level2,
              '--nestedHoverBg': (theme) => theme.palette.background.level2,
              '--nestedActiveBg': (theme) => theme.palette.background.level3,
            },
            '&:hover': {
              boxShadow: (theme) => theme.shadow.md,
            },
            '@media (max-width: 460px)': {
              height: 220,
              '& > .MuiCardContent-root > .MuiBox-root': {
                gap: 2.25,
              },
              '& > .MuiCardContent-root > .MuiBox-root > .MuiBox-root': {
                fontSize: 72,
              },
            },
          },
          '@media (max-width: 460px)': {
            height: 220,
          },
        }}
      >
        <CardContent>
          <Box
            sx={{
              '&': {
                gap: 2,
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                wordWrap: 'balance',
                px: 1,
              },
            }}
          >
            <Icon
              sx={{
                color: (theme) => theme.palette.text.secondary,
                fontSize: 56,
              }}
              name="plus"
              weight="thin"
            />
            <Typography
              fontSize={17}
              fontWeight={500}
              textAlign="center"
              color="neutral"
            >
              Add one or many links or{' '}
              <Box component="span" sx={{ color: 'text.primary' }}>
                upload
              </Box>{' '}
              .pdf, .csv, .txt to library
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default memo(CardPlus);
