import { memo, useCallback, useMemo, useRef } from 'react';

import { useRoute } from 'navigation/hooks';

import { useSelector } from 'store/utils/redux/hooks';
import * as noteStore from 'store/nodes/note';

import PanelsBar from 'widgets/PanelsBar';

import useIsMy from '../model/useIsMy';

import EditorProcessor, {
  type EditorMethods,
} from '../components/EditorProcessor';
import CopilotConnector from '../components/CopilotConnector';
import Viewer from '../components/Viewer';

const Layout = () => {
  const route = useRoute<'Note'>();

  const editorRef = useRef<EditorMethods>(null);

  const id =
    route.params?.id === 'create' ? 'create' : Number(route.params?.id);
  const selectedContext = useSelector(noteStore.selectors.selectedContext);
  const isMy = useIsMy(id);

  const handleCopyToText = useCallback((text: string) => {
    editorRef.current?.insert(text, 'current');
  }, []);

  const contentMeta = useMemo(
    () => ({
      label: 'Note',
      value: 'note',
    }),
    [],
  );

  const contentRender = useMemo(
    () => (
      <>
        {isMy && (
          <EditorProcessor
            ref={editorRef}
            id={id}
            autofocus={id === 'create'}
            contentWrapperClassName="content-body-wrapper"
          />
        )}
        {!isMy && typeof id === 'number' && (
          <Viewer id={id} contentWrapperClassName="content-body-wrapper" />
        )}
      </>
    ),
    [id, EditorProcessor, Viewer, isMy],
  );

  const panelsRender = useMemo(() => {
    const map = {
      note: 'current note',
      'library-fulltext': 'library',
    } as const;
    return [
      {
        label: `AI Copilot by ${map[selectedContext]}`,
        value: 'copilot',
        component: <CopilotConnector id={id} onCopyToText={handleCopyToText} />,
      },
    ];
  }, [isMy, id, selectedContext, handleCopyToText]);

  if (!id) {
    return null;
  }

  return (
    <PanelsBar
      contentMeta={contentMeta}
      content={contentRender}
      panels={panelsRender}
      sx={{
        '@media (max-width: 767px)': {
          mx: -2,
        },
      }}
    />
  );
};

export default memo(Layout);
