import {
  type ReactNode,
  type ReactElement,
  type JSXElementConstructor,
  type MouseEvent,
  type CSSProperties,
  memo,
  useCallback,
  useMemo,
  cloneElement,
} from 'react';

import type { AuthorType, UserType } from 'app/entities';
import { guard } from 'utils';

import Picture from 'components/Picture';

import { Box, Chip, Typography, type BoxProps } from '@mui/joy';

type AuthorBlockProps = {
  data: AuthorType | UserType;
  Component?: ReactNode;
  onPress?: (event: MouseEvent<HTMLButtonElement>) => void;
  style?: CSSProperties;
};

const AuthorBlock = (props: AuthorBlockProps) => {
  const { data, Component, onPress, style } = props;

  const handlePress = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      onPress?.(event);
    },
    [onPress],
  );

  const photoFinal = useMemo(() => {
    if (Component) {
      return null;
    }
    if (guard.isUser(data) && data?.photo) {
      return data.photo;
    }
    if (guard.isAuthor(data) && data?.owner?.photo) {
      return data.owner.photo;
    }
    if (guard.isAuthor(data) && data?.photo) {
      return data.photo;
    }
    return null;
  }, [Component, data]);

  const nameFinal = useMemo(() => {
    if (Component) {
      return null;
    }
    let name = '';
    let surname = '';
    if (guard.isUser(data)) {
      name = data.name;
      surname = data.surname;
    }
    if (guard.isAuthor(data) && data?.owner) {
      name = data.owner.name;
      surname = data.owner.surname;
    }
    if (guard.isAuthor(data) && !data?.owner) {
      name = data.name || '';
      surname = data.surname || '';
    }
    if (!name && !surname) {
      return 'Guest';
    }
    const result = [];
    if (name) {
      result.push(name);
    }
    if (surname) {
      result.push(surname);
    }
    return result.join(' ');
  }, [Component, data]);

  const componentProps = useMemo(() => {
    if (!Component) {
      return null;
    }
    return { data };
  }, [Component, data, handlePress]);

  if (onPress) {
    return (
      <Chip
        variant="plain"
        color="neutral"
        onClick={handlePress}
        style={style}
        sx={{
          '--Chip-paddingInline': '0',
        }}
        startDecorator={
          <Box display="flex" flexDirection="row" alignItems="center">
            {!!Component &&
              componentProps &&
              cloneElement(
                Component as ReactElement<
                  any,
                  string | JSXElementConstructor<any>
                >,
                componentProps,
              )}
            {!Component && (
              <>
                {!!photoFinal && (
                  <Picture
                    source={photoFinal}
                    size="small"
                    width={20}
                    height={20}
                    style={{ marginRight: 10 }}
                    radius={10}
                  />
                )}
                <Typography fontSize={16} fontWeight="bolder">
                  {nameFinal}
                </Typography>
              </>
            )}
          </Box>
        }
      />
    );
  }

  return (
    <Box style={style} display="flex" flexDirection="row" alignItems="center">
      {!!Component &&
        componentProps &&
        cloneElement(
          Component as ReactElement<any, string | JSXElementConstructor<any>>,
          componentProps,
        )}
      {!Component && (
        <>
          <Picture
            source={photoFinal}
            size="small"
            width={20}
            height={20}
            style={{ marginRight: 10 }}
            radius={10}
          />
          <Typography fontSize={16} fontWeight="bolder">
            {nameFinal}
          </Typography>
        </>
      )}
    </Box>
  );
};

export default memo(AuthorBlock);
