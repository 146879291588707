import request from 'services/api/request';
import type { ResultLegacy, SuccessResult } from 'app/entities';

type AddContentResult = {
  playlistId: number;
  contentId: number;
};

export default async (
  id: number,
  contentId: number | number[],
): Promise<SuccessResult> => {
  const contentIds = Array.isArray(contentId) ? contentId : [contentId];
  if (contentIds.length === 0) {
    return {
      data: { success: true },
      error: null,
    };
  }

  const bodies: AddContentResult[] = contentIds.map((item) => ({
    playlistId: id,
    contentId: item,
  }));

  const results = await Promise.all(
    bodies.map((body) =>
      request.post<unknown>(
        `/user/playlists/${body.playlistId}/content/${body.contentId}`,
      ),
    ),
  );

  let error: ResultLegacy<unknown>['error'] | null = null;
  results.forEach((item) => {
    if (error) {
      return;
    }
    if (item.error) {
      error = item.error;
    }
  });

  return {
    data: { success: !error },
    error,
  };
};
