import {
  type FC,
  type MouseEvent,
  type ReactElement,
  type ReactNode,
  memo,
  useCallback,
  useMemo,
} from 'react';
import { isEqual } from 'lodash';
import {
  type DropdownProps,
  type MenuButtonProps,
  type TypographyProps,
  type BoxProps,
  type ButtonProps,
  Button,
  Dropdown,
  Menu,
  MenuButton,
  MenuItem,
  Typography,
} from '@mui/joy';

import Icon from 'ui/Icon';

type OptionType = Record<string, any>;

export type DropdownMenuProps<V> = {
  options: OptionType[];
  value: V;
  onChange?: (value: V) => void;
  slotProps?: {
    menuButton?: MenuButtonProps;
    typography?: TypographyProps;
  };
  disabled?: boolean;
  optionValueName?: string;
  optionLabelName?: string;
  labelPrefix?: ReactNode;
  labelSuffix?: ReactNode;
} & Omit<DropdownProps, 'children'> &
  Pick<BoxProps, 'm' | 'mx' | 'my' | 'mt' | 'mr' | 'mb' | 'ml'>;

const DropdownMenu: FC<DropdownMenuProps<string | number>> = (props) => {
  const {
    options,
    value,
    onChange,
    disabled,
    slotProps: { menuButton, typography } = {},
    optionValueName = 'value',
    optionLabelName = 'label',
    labelPrefix,
    labelSuffix,
    m,
    mx,
    my,
    mt,
    mr,
    mb,
    ml,
    ...dropdownRest
  } = props;

  const handleSelect = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      const newValue = event.currentTarget.getAttribute('itemid');
      if (!newValue) {
        return;
      }
      onChange?.(Number.isNaN(Number(newValue)) ? newValue : Number(newValue));
    },
    [onChange],
  );

  const menu = useMemo(
    () => (
      <Menu>
        {options.map((option) => (
          <MenuItem
            key={option[optionValueName]}
            selected={value === option[optionValueName]}
            itemID={`${option[optionValueName]}`}
            onClick={handleSelect}
            disabled={option?.disabled}
          >
            {option[optionLabelName]}
          </MenuItem>
        ))}
      </Menu>
    ),
    [value, options, handleSelect, optionValueName, optionLabelName],
  );

  const label = useMemo(
    () =>
      options.find((option) => option[optionValueName] === value)?.[
        optionLabelName
      ] || '',
    [options, value, optionValueName, optionLabelName],
  );

  return (
    <Dropdown {...dropdownRest}>
      <MenuButton
        {...menuButton}
        slots={{
          root: Button,
          ...menuButton?.slots,
        }}
        slotProps={{
          ...menuButton?.slotProps,
          root: {
            variant: 'plain',
            color: 'neutral',
            size: 'sm',
            disabled,
            sx: {
              m,
              mx,
              my,
              mt,
              mr,
              mb,
              ml,
              ...(menuButton?.slotProps?.root as ButtonProps)?.sx,
            },
            ...(menuButton?.slotProps?.root as ButtonProps),
          },
        }}
      >
        {labelPrefix && typeof labelPrefix !== 'string' && labelPrefix}
        <Typography
          fontSize={16}
          fontWeight="400"
          sx={{ color: 'inherit', ...typography?.sx }}
          {...typography}
        >
          {labelPrefix && typeof labelPrefix === 'string' && labelPrefix}
          {label}
          {labelSuffix && typeof labelSuffix === 'string' && labelSuffix}
        </Typography>
        {labelPrefix && typeof labelSuffix !== 'string' && labelSuffix}
        <Icon
          name="circle-chevron-down"
          size="1x"
          fw
          weight="regular"
          color="inherit"
          ml={1}
        />
      </MenuButton>
      {menu}
    </Dropdown>
  );
};

export default memo(DropdownMenu, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps),
) as <T extends string | number>(props: DropdownMenuProps<T>) => ReactElement;
