import { memo, useCallback, useEffect, useRef } from 'react';
import { Card, CardContent, Skeleton, Typography } from '@mui/joy';

import { useSelector } from 'react-redux';

import * as smartNoteStore from 'store/nodes/smartNote';
import * as collectionStore from 'store/nodes/playlist';

import { useDispatcher } from 'store/utils/redux/hooks';

import { useRoute } from 'navigation/hooks';
import EditorInput from 'ui/EditorInput';
import type { EditorInputMethodsType } from 'ui/EditorInput';
import { useEditorState } from 'hooks';

type NoteProps = {
  id: number | string;
  isNew?: boolean;
  isEditable?: boolean;
};

const Note = (props: NoteProps) => {
  const { id, isNew, isEditable } = props;
  const route = useRoute<'Playlist'>();
  const { resourceId } = route.params;
  const dispatcher = useDispatcher();
  const data = useSelector(smartNoteStore.selectors.dataById(id));
  const isLoadingItem = useSelector(
    collectionStore.selectors.isLoadingItem(
      Number(resourceId),
      'note',
      Number(id),
    ),
  );

  const editorRef = useRef<EditorInputMethodsType | null>(null);

  const { html, text, hasContent, onChange } = useEditorState(data?.text);

  useEffect(() => {
    if (!isLoadingItem && data?.text) {
      editorRef.current?.set(data?.text);
    }
  }, [data?.text, isLoadingItem]);

  useEffect(() => {
    if (isNew) {
      editorRef.current?.focus();
    }
  }, []);

  const cancel = useCallback(() => {
    if (!route.params.resourceId) {
      return;
    }
    if (isNew && typeof id === 'string') {
      dispatcher.playlist.cancelNewNote({
        tempId: id,
        collectionId: route.params.resourceId,
      });
    }
  }, [isNew, id, route.params.resourceId]);

  const create = useCallback(
    (value: string) => {
      if (!route.params.resourceId) {
        return;
      }

      if (isNew && typeof id === 'string') {
        dispatcher.playlist.commitNewNote({
          tempId: id,
          collectionId: route.params.resourceId,
          text: value,
        });
      }
    },
    [isNew, id, route.params.resourceId],
  );

  const save = useCallback(
    (value: string) => {
      if (!isNew && typeof id === 'number') {
        dispatcher.smartNote.update({ id, text: value });
      }
    },
    [isNew, id],
  );

  const handleBlur = useCallback(() => {
    if (isNew && !hasContent) {
      cancel();
      return;
    }
    if (isNew && hasContent) {
      create(html);
      return;
    }
    if (!isNew && text !== data?.text) {
      save(html);
    }
  }, [id, data?.text, isNew, html, text, hasContent, create, save, cancel]);

  if (!data && !isNew) {
    return (
      <Card
        variant="soft"
        color="neutral"
        sx={{
          '&': {
            '--Card-radius': (theme) => theme.radius.xl,
          },
        }}
      >
        <CardContent>
          <Typography level="h2" fontSize="md" sx={{ mb: 0.5 }}>
            <Skeleton>
              0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0
            </Skeleton>
          </Typography>
          <Typography level="h2" fontSize="md" sx={{ mb: 0.5 }}>
            <Skeleton>0 0 0 0 0 0 0 0 0 0 0 0 0 0</Skeleton>
          </Typography>
          <Typography level="h2" fontSize="md" sx={{ mb: 0.5 }}>
            <Skeleton>0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0</Skeleton>
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card
      variant="soft"
      sx={{
        '&': {
          '--Card-radius': (theme) => theme.radius.xl,
          minHeight: 48,
          backgroundColor: 'unset',
          transition: '200ms box-shadow ease, 200ms background-color ease',
          cursor: 'pointer',
          gap: 1,
          '--card-hover-opacity': 0,
          '--nestedBg': (theme) => theme.palette.background.level1,
          '--nestedHoverBg': (theme) => theme.palette.background.level1,
          '--nestedActiveBg': (theme) => theme.palette.background.level2,
        },
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: (theme) => theme.palette.background.level1,
          '--card-hover-opacity': 1,
          '--nestedBg': (theme) => theme.palette.background.level2,
          '--nestedHoverBg': (theme) => theme.palette.background.level2,
          '--nestedActiveBg': (theme) => theme.palette.background.level3,
        },
      }}
    >
      <EditorInput
        disabled={!isEditable}
        ref={editorRef}
        onChange={onChange}
        onBlur={handleBlur}
        placeholder="Add note"
      />
    </Card>
  );
};

export default memo(Note);
