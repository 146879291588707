import React, { type FC, memo } from 'react';
import { Box, Typography } from '@mui/joy';

import Icon from 'ui/Icon';

type PlanIncludeProps = {
  list: string[];
};

const PlanInclude: FC<PlanIncludeProps> = (props) => {
  const { list } = props;
  return (
    <Box display="flex" flexDirection="column" gap={1.5}>
      {list.map((text) => (
        <Box display="flex" flexDirection="row" alignItems="center" gap={1.5}>
          <Icon
            size="lg"
            name="circle-check"
            fw
            weight="solid"
            color="current"
          />
          <Typography>{text}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default memo(PlanInclude);
