import type { ResultLegacy, MaterialType } from 'app/entities';
import request from 'services/api/request';
import interaction from 'services/api/interaction/route';
import { prepare } from 'utils';

export default async (id: number): Promise<ResultLegacy<MaterialType>> => {
  const { data, error } = await request.get<MaterialType>(`/content/${id}`);

  if (error || !data) {
    return {
      data: null,
      error,
    };
  }

  const [interactions] = await Promise.all([
    interaction.items('content', data.id),
  ]);

  if (interactions.error || !interactions.data) {
    return {
      data: null,
      error: interactions.error,
    };
  }

  const result = {
    ...data,
    ...interactions.data[data?.id],
  };

  return {
    data: prepare.material(result),
    error,
  };
};
