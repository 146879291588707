import { useState, useCallback, useEffect, useMemo } from 'react';
import {
  $getSelection,
  $isRangeSelection,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import { mergeRegister } from '@lexical/utils';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

const LowPriority = 1;

const useSelectionCoordinates = () => {
  const [editor] = useLexicalComposerContext();
  const [coordinates, setCoordinates] = useState<{
    top: number;
    left: number;
    right: number;
    bottom: number;
  } | null>(null);
  const [isPrepared, setIsPrepared] = useState<boolean>(false);

  useEffect(() => {
    if (coordinates) {
      setIsPrepared(true);
    }
  }, [coordinates]);

  const updateSelectionCoordinates = useCallback(() => {
    const selection = $getSelection();
    if (!$isRangeSelection(selection)) {
      setIsPrepared(false);
      setCoordinates(null);
      return;
    }
    const { anchor, focus } = selection;
    const isRange =
      anchor.getNode() !== focus.getNode() || anchor.offset !== focus.offset;
    if (!isRange) {
      setIsPrepared(false);
      setCoordinates(null);
      return;
    }
    const nativeSelection = window.getSelection();
    if (!nativeSelection || nativeSelection.rangeCount === 0) {
      setIsPrepared(false);
      setCoordinates(null);
      return;
    }
    const range = nativeSelection.getRangeAt(0);
    const rect = range.getBoundingClientRect();
    setCoordinates({
      top: rect.top,
      left: rect.left,
      right: rect.right,
      bottom: rect.bottom,
    });
  }, []);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateSelectionCoordinates();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateSelectionCoordinates();
          return false;
        },
        LowPriority,
      ),
    );
  }, [editor, updateSelectionCoordinates]);

  return useMemo(
    () => ({
      coordinates,
      isPrepared,
    }),
    [coordinates, isPrepared],
  );
};

export default useSelectionCoordinates;
