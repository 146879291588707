import type { ResultLegacy, SourceMaterialType } from 'app/entities';
import request from 'services/api/request';

export default async (
  url: string,
): Promise<ResultLegacy<SourceMaterialType>> => {
  const query: Record<string, any> = {
    url,
  };
  const { data, error } = await request.get<SourceMaterialType>(
    '/content/parse',
    { query },
  );
  if (error || !data) {
    return {
      data: null,
      error,
    };
  }
  const result: SourceMaterialType = {
    ...data,
    authorUrls: data?.authorUrls || [],
    originalUrl: data?.originalUrl || url,
    tags: data?.tags || [],
    type: data?.type ? data?.type.toLocaleLowerCase() : 'article',
    level: data?.level ? data?.level.toLocaleLowerCase() : undefined,
    isSaved: data?.isSaved || false,
  };

  if ('title' in result && !result.title) {
    delete result.title;
  }
  if ('type' in result && !result.type) {
    delete result.type;
  }
  if ('description' in result && !result.description) {
    delete result.description;
  }
  if ('sectionId' in result && !result.sectionId) {
    delete result.sectionId;
  }
  if ('sourceId' in result && !result.sourceId) {
    delete result.sourceId;
  }
  if ('image' in result && !result.image) {
    delete result.image;
  }
  if ('level' in result && !result.level) {
    delete result.level;
  }
  if ('duration' in result && !result.duration) {
    delete result.duration;
  }

  return {
    data: result,
    error,
  };
};
