import { memo, useMemo } from 'react';
import type { CollectionType, ImageType } from 'app/entities';

import Icon from 'components/LegacyIcon';
import Picture from 'components/Picture';

const defaultProps = {
  heightPicture: 24,
  widthPicture: 24,
  radiusPicture: 5,
  iconSize: 24,
};

type CollectionSingleCoverProps = {
  data: CollectionType;
  heightPicture?: number;
  widthPicture?: number;
  radiusPicture?: number;
  iconSize?: number;
} & typeof defaultProps;

const CollectionSingleCover = (props: CollectionSingleCoverProps) => {
  const { data, heightPicture, radiusPicture, iconSize, widthPicture } = props;

  if (!data) {
    return null;
  }

  const posterSource = useMemo<ImageType | null>(() => {
    if (data?.cover) {
      return data?.cover;
    }
    const [materialImage] = data.contentImages;
    if (materialImage) {
      return materialImage;
    }
    return null;
  }, [data]);

  if (!posterSource) {
    return <Icon name="Playlist28" size={iconSize} style={{ opacity: 0.6 }} />;
  }

  return (
    <Picture
      source={posterSource}
      width={widthPicture}
      height={heightPicture}
      radius={radiusPicture}
    />
  );
};

CollectionSingleCover.defaultProps = defaultProps;

export default memo(CollectionSingleCover);
