import { memo } from 'react';
import { createUseStyles } from 'react-jss';

import Icon from 'components/LegacyIcon';

import { unit } from 'utils';

type StateIconProps = {
  variant: 'add' | 'processing' | 'material' | 'materials';
  size: number | string;
};

const StateIcon = (props: StateIconProps) => {
  const { variant, size } = props;

  const classes = useStyles({
    size,
  });

  return (
    <div className={classes.StateIcon}>
      {variant === 'add' && <Icon name="MenuPlusOutline" />}
      {variant === 'processing' && <Icon name="IkiCoPilot" />}
      {variant === 'material' && <Icon name="Done" />}
      {variant === 'materials' && <Icon name="Done" />}
    </div>
  );
};

const useStyles = createUseStyles<
  'StateIcon' | 'image',
  {
    size?: number | string;
  }
>({
  StateIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: (props) =>
      typeof props.size === 'string' ? props.size : unit(props.size),
    width: (props) =>
      typeof props.size === 'string' ? props.size : unit(props.size),
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
});

export default memo(StateIcon);
