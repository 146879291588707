import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/joy';
import { useResponsive } from 'hooks';
import { unit, userFullName } from 'utils';
import * as userStore from 'store/nodes/user';

import { useNavigate, useRoute } from 'navigation/hooks';

import Icon from 'components/LegacyIcon';

import ListLayoutSwitcher from 'components/ListLayoutSwitcher';
import Avatar from 'ui/Avatar';

import { useDispatcher } from 'store/utils/redux/hooks';

const TopBarDesktop = () => {
  const route = useRoute<'CommunityPeers'>();
  const navigate = useNavigate();
  const responsive = useResponsive();
  const dispatcher = useDispatcher();
  const user = useSelector(userStore.selectors.getByLogin(route.params?.user));

  const handleUserPress = useCallback(() => {
    if (!user?.login) {
      return;
    }
    navigate('Profile', { login: user?.login });
  }, [user?.login]);

  const handleSubscribe = useCallback(() => {
    dispatcher.modal.open('SearchUser');
  }, []);

  if (responsive.is.tabletL) {
    return null;
  }

  return (
    <Box my={2}>
      <Box display="flex" flexDirection="row" alignItems="center" ml={-0.2}>
        {!user && <Icon size={28} name="MenuCommunityOutline" />}
        <Box
          onClick={handleUserPress}
          display="flex"
          flexDirection="row"
          alignItems="center"
          sx={{ cursor: 'pointer' }}
        >
          {user && !!user?.photo && (
            <Avatar src={user.photo.url} thumbnail="small" />
          )}
          {user && !user?.photo && (
            <Avatar src={user?.photo?.url} thumbnail="small">
              <Typography fontSize={22}>
                {userFullName(user).slice(0, 1)}
              </Typography>
            </Avatar>
          )}
          {!!user && (
            <Typography fontSize={22} fontWeight="bolder" ml={1}>
              {`${user.name}'s`}
              <Typography color="neutral" fontSize={22} fontWeight="normal">
                {' '}
                recent activity
              </Typography>
            </Typography>
          )}
        </Box>
        {!user && (
          <Typography fontSize={22} fontWeight="bolder" ml={0.5}>
            Peers feed
            <Typography fontSize={22} fontWeight="normal" color="neutral">
              {' '}
              see what your peers find interesting
            </Typography>
          </Typography>
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        mt={0.5}
      >
        <Typography fontSize={17}>
          <Typography
            color="primary"
            fontWeight={500}
            onClick={handleSubscribe}
            sx={{ cursor: 'pointer' }}
          >
            subscribe
          </Typography>
          {' to more people.'}
        </Typography>
        <ListLayoutSwitcher
          stateKey="peers.list.mode"
          style={{ marginTop: unit(-2), marginRight: unit(-4) }}
        />
      </Box>
    </Box>
  );
};

export default memo(TopBarDesktop);
