import { memo } from 'react';

import { AspectRatio, Card, CardContent, Skeleton, Typography } from '@mui/joy';

const Wireframe = () => {
  return (
    <Card variant="soft" sx={{ maxHeight: 320 }}>
      <AspectRatio maxHeight="130px">
        <Skeleton>
          <img
            src="https://images.unsplash.com/photo-1527549993586-dff825b37782?auto=format&fit=crop&w=286"
            srcSet="https://images.unsplash.com/photo-1527549993586-dff825b37782?auto=format&fit=crop&w=286&dpr=2 2x"
            loading="lazy"
            alt=""
          />
        </Skeleton>
      </AspectRatio>
      <div>
        <Typography level="h2" fontSize="md" sx={{ mb: 0.5 }}>
          <Skeleton>
            {' '}
            Lorem ipsum is placeholder text commonly used in the graphic, print,
            and publishing industries.
          </Skeleton>
        </Typography>
        <Typography level="body-sm">
          <Skeleton>Lorem ipsum is placeholder publishing industries.</Skeleton>
        </Typography>
      </div>
      <CardContent orientation="horizontal">
        <div>
          <Typography fontSize="lg" fontWeight="lg">
            <Skeleton>Lorem ipsum is placeholder text publishing</Skeleton>
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

export default memo(Wireframe);
