import { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Tooltip, Typography } from '@mui/joy';

import { useNavigate } from 'navigation/hooks';

import { useDispatcher } from 'store/utils/redux/hooks';
import * as libraryStore from 'store/nodes/library';

import { useResponsive } from 'hooks';

import LegacyIcon from 'components/LegacyIcon';
import ListLayoutSwitcher from 'components/ListLayoutSwitcher';
import Icon from 'ui/Icon';

import TagsFilter from '../ui/TagsFilter';

const TopBarDesktop = () => {
  const navigate = useNavigate();
  const dispatcher = useDispatcher();
  const responsive = useResponsive();
  const enabledFilters = useSelector(libraryStore.selectors.enabledFilters);
  const isSearchingEnabled = useSelector(
    libraryStore.selectors.searchingEnabled,
  );

  const handleCopilot = useCallback(() => {
    dispatcher.ask.setScope({ scope: 'library-fulltext' });
    navigate('Ask');
  }, [navigate]);

  const currentInteraction = useMemo(() => {
    if (isSearchingEnabled) {
      return {
        label: 'Searching',
        description: 'in your library',
        icon: 'MenuSearchOutline' as const,
      };
    }
    const found = enabledFilters.list.find(
      (item) => item.type === 'USER_INTERACTION',
    ) || { label: 'Library', query: { interactionTypes: ['ALL'] } };
    const type = found.query.interactionTypes[0].toLowerCase() as
      | 'all'
      | 'added'
      | 'completed'
      | 'bookmark'
      | 'legal bok';

    const map = {
      all: {
        label: '',
        icon: 'MenuLibraryOutline' as const,
        description: 'the content you’ve bookmarked or added to IKI',
      },
      added: {
        label: '',
        icon: 'MenuAddedOutline' as const,
        description: 'to your IKI library',
      },
      completed: {
        label: '',
        icon: 'MenuCompletedOutline' as const,
        description: "the content you've marked as completed",
      },
      bookmark: {
        label: '',
        icon: 'MenuBookmarkOutline' as const,
        description: 'bookmarks you’ve made',
      },
      'legal bok': {
        label: '',
        icon: 'MenuLibraryOutline' as const,
        description: 'legal knowledge base',
      },
    };
    const icon = map[type]?.icon || 'MenuLibraryOutline';

    const description = map[type]?.description || null;
    return {
      label: found.label,
      description,
      icon,
    };
  }, [enabledFilters, isSearchingEnabled]);

  if (responsive.is.tabletL) {
    return null;
  }

  return (
    <Box mt={2} mb={2}>
      <Box display="flex" flexDirection="row" alignItems="center">
        <LegacyIcon size={28} name={currentInteraction.icon} />
        <Typography ml={0.25} fontSize={22} fontWeight={600}>
          {currentInteraction.label}
          <Typography color="neutral" fontSize={22} fontWeight={400}>
            {' - '}
            {currentInteraction.description}
          </Typography>
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        mt={1}
        alignItems="center"
      >
        <TagsFilter textSize={13} style={{ flex: 1 }} flowItems />
        <Box display="flex" flexDirection="row" gap={1} mt={-0.5}>
          <ListLayoutSwitcher
            stateKey="library.list.mode"
            style={{ marginTop: 0, marginRight: 0 }}
          />
          <Tooltip
            disableInteractive
            arrow
            variant="outlined"
            size="sm"
            title="Chat with your library"
          >
            <Button
              size="sm"
              color="neutral"
              variant="soft"
              onClick={handleCopilot}
              startDecorator={
                <Icon name="lightbulb-on" fw weight="light" color="inherit" />
                // <LegacyIcon name="IkiCoPilot" size={28} darkColor="#ffffff" lightColor="#000000" />
              }
              sx={{
                height: '2rem',
                pl: '0.5rem',
                fontWeight: 400,
                '--Button-gap': '0.25rem',
              }}
            >
              AI copilot
            </Button>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(TopBarDesktop);
