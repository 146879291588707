import { API_URI, API_VERSION } from '@env';
import qs from 'query-string';

import getAppId from 'utils/getAppId';
import getSessionId from 'utils/getSessionId';

import type { ResultLegacy } from 'app/entities';

import credentials from './credentials';

interface ServerResponse {
  data: Record<string, any> | null;
  errors: Error[] | null;
}

type Method = 'get' | 'post' | 'put' | 'delete';

interface Options {
  method: Method;
  headers: Partial<{
    'Content-Type': 'application/json';
    Authorization: string;
    'x-app-id'?: any;
    'x-session-id'?: any;
  }>;
  body?: string;
}

interface Params {
  query?: Record<string, any>;
  body?: Record<string, any>;
}

const responseCodes: Record<number, string> = {
  100: 'Continue',
  101: 'Switching Protocol',
  102: 'Processing',
  103: 'Early Hints',
  200: 'OK',
  201: 'Created',
  202: 'Accepted',
  203: 'Non-Authoritative Information',
  204: 'No Content',
  205: 'Reset Content',
  206: 'Partial Content',
  300: 'Multiple Choice',
  301: 'Moved Permanently',
  302: 'Found',
  303: 'See Other',
  304: 'Not Modified',
  305: 'Use Proxy',
  306: 'Switch Proxy',
  307: 'Temporary Redirect',
  308: 'Permanent Redirect',
  400: 'Bad Request',
  401: 'Unauthorized',
  402: 'Payment Required',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  407: 'Proxy Authentication Required',
  408: 'Request Timeout',
  409: 'Conflict',
  410: 'Gone',
  411: 'Length Required',
  412: 'Precondition Failed',
  413: 'Request Entity Too Large',
  414: 'Request-URI Too Long',
  415: 'Unsupported Media Type',
  416: 'Requested Range Not Satisfiable',
  417: 'Expectation Failed',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  505: 'HTTP Version Not Supported',
};

/**
 * @deprecated
 */
const request = async <ResponseStructure = Record<string, any>>(
  method: Method,
  endpoint: string,
  params?: Params,
): Promise<ResultLegacy<ResponseStructure>> => {
  let url = `${API_URI}/${API_VERSION}/${endpoint.replace(/^\//, '')}`;
  if (params?.query) {
    url += `?${qs.stringify(params.query)}`;
  }

  const options: Options = {
    method,
    headers: {
      Authorization: credentials.getAuthorizationHeader(),
      'x-app-id': getAppId(),
      'x-session-id': getSessionId(),
    },
  };
  if (method !== 'get') {
    options.headers['Content-Type'] = 'application/json';
  }
  if (method !== 'get' && params?.body) {
    options.body = JSON.stringify(params.body);
  }

  let response: ServerResponse | null = null;
  try {
    const result = await fetch(url, options);
    const buffer: string = await result.text();
    if (buffer.length > 0) {
      const parsed = JSON.parse(buffer);
      response = {
        data:
          'nextUrl' in parsed
            ? {
                items: parsed.data as ResponseStructure,
                nextUrl: parsed.nextUrl as string,
              }
            : parsed.data,
        errors: Array.isArray(parsed?.errors)
          ? parsed?.errors.map(
              (error: { message: string }) => new Error(error.message),
            )
          : null,
      };
    }
    if (
      buffer.length === 0 &&
      [200, 201, 202, 203, 204, 205, 206].includes(result.status)
    ) {
      response = {
        data: null,
        errors: null,
      };
    }
    if (
      buffer.length === 0 &&
      ![200, 201, 202, 203, 204, 205, 206].includes(result.status)
    ) {
      response = {
        data: null,
        errors: [new Error(responseCodes[result.status])],
      };
    }
  } catch (error) {
    response = {
      data: null,
      errors: [error as Error],
    };
  }

  const { errors, data = {} } = response || {};
  const [error = null] = errors ?? [];

  if (error) {
    return {
      data: null,
      error,
    };
  }

  return {
    data: data as ResponseStructure,
    error: null,
  };
};

const methodGet = async <ResponseStructure = Record<string, any>>(
  endpoint: string,
  params?: Params,
): Promise<ResultLegacy<ResponseStructure>> =>
  request<ResponseStructure>('get', endpoint, params);

const methodPost = async <ResponseStructure = Record<string, any>>(
  endpoint: string,
  params?: Params,
): Promise<ResultLegacy<ResponseStructure>> =>
  request<ResponseStructure>('post', endpoint, params);

const methodPut = async <ResponseStructure = Record<string, any>>(
  endpoint: string,
  params?: Params,
): Promise<ResultLegacy<ResponseStructure>> =>
  request<ResponseStructure>('put', endpoint, params);

const methodDelete = async <ResponseStructure = Record<string, any>>(
  endpoint: string,
  params?: Params,
): Promise<ResultLegacy<ResponseStructure>> =>
  request<ResponseStructure>('delete', endpoint, params);

export default {
  /**
   * @deprecated use network.request api
   */
  get: methodGet,
  /**
   * @deprecated use network.request api
   */
  post: methodPost,
  /**
   * @deprecated use network.request api
   */
  put: methodPut,
  /**
   * @deprecated use network.request api
   */
  delete: methodDelete,
};
