import { memo, useCallback, useEffect, useState, useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useFormStore, useResponsive } from 'hooks';

import type { JobType } from 'app/entities';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as userStore from 'store/nodes/user';

import { unit } from 'utils';
import { Text, View } from 'components/Themed';
import { TextInput } from 'components/Form';
import { controller as modal } from 'components/Modal2';
import ModalToolbar from 'components/ModalToolbar';
import Button from 'components/Button';

const validate = (
  values: Partial<JobType>,
): { isValid: boolean; errors: Partial<JobType> } => {
  let isValid = true;
  const errors: Partial<JobType> = {};
  if (!values?.company) {
    isValid = false;
    if (values?.company !== null) {
      errors.company = 'required';
    }
  }
  if (!values?.location) {
    isValid = false;
    if (values?.location !== null) {
      errors.location = 'required';
    }
  }
  if (!values?.position) {
    isValid = false;
    if (values?.position !== null) {
      errors.position = 'required';
    }
  }
  if (!values?.startDate) {
    isValid = false;
    if (values?.startDate !== null) {
      errors.startDate = 'required';
    }
  }
  if (!values?.description) {
    isValid = false;

    if (values?.description !== null) {
      errors.description = 'required';
    }
  }
  if (values?.startDate && /[0-9]{4}\/[0-9]{2}/.test(values.startDate)) {
    const [year, month] = values.startDate.split(/\//);
    const date = new Date(Number(year), Number(month) - 1, 1);
    if (Number(year) < 1970 || Number(year) > new Date().getFullYear()) {
      isValid = false;
      if (values?.startDate !== null) {
        errors.startDate = 'incorrect';
      }
    }
    if (Number(month) < 1 || Number(month) > 12) {
      isValid = false;
      if (values?.startDate !== null) {
        errors.startDate = 'incorrect';
      }
    }
    if (date > new Date()) {
      isValid = false;
      if (values?.startDate !== null) {
        errors.startDate = 'incorrect';
      }
    }
  }
  if (values?.endDate && /[0-9]{4}\/[0-9]{2}/.test(values.endDate)) {
    const [year, month] = values.endDate.split(/\//);
    if (Number(year) < 1970 || Number(year) > new Date().getFullYear()) {
      isValid = false;
      if (values?.endDate !== null) {
        errors.endDate = 'incorrect';
      }
    }
    if (Number(month) < 1 || Number(month) > 12) {
      isValid = false;
      if (values?.endDate !== null) {
        errors.endDate = 'incorrect';
      }
    }
  }
  return {
    isValid,
    errors,
  };
};

export type JobAddProps = {
  id?: number;
};

const JobAdd = (props: JobAddProps) => {
  const { id } = props;
  const dispatcher = useDispatcher();
  const responsive = useResponsive();

  const userProfile = useSelector(userStore.selectors.getMy);

  const [isEdit, setEdit] = useState(false);
  const [formStore, formValues] = useFormStore<Partial<JobType>>();

  const validation = useMemo(() => validate(formValues), [formValues]);

  useEffect(() => {
    if (!userProfile) {
      return;
    }
    const job: Partial<JobType> = {
      ...userProfile?.jobExperience.find((item) => item.id === id),
    };
    if (job?.id && job.startDate) {
      const [year, month] = job.startDate.split(/-/);
      job.startDate = `${year}/${month}`;
    }
    if (job?.id && job.endDate) {
      const [year, month] = job.endDate.split(/-/);
      job.endDate = `${year}/${month}`;
    }
    setEdit(!!job?.id);
    formStore.set({
      company: job.company,
      location: job.location,
      position: job.position,
      startDate: job.startDate,
      endDate: job.endDate,
      description: job.description,
    });
  }, [userProfile, id]);

  const handleCreate = useCallback(
    (data: Omit<JobType, 'id'>) => {
      const createdJob = { ...data };
      const jobExperience = [...(userProfile?.jobExperience || [])];
      if (createdJob.startDate) {
        const [year, month] = createdJob.startDate.split(/\//);
        createdJob.startDate = `${year}-${month}-01`;
      }
      if (createdJob.endDate) {
        const [year, month] = createdJob.endDate.split(/\//);
        createdJob.endDate = `${year}-${month}-01`;
      } else {
        delete createdJob.endDate;
      }
      jobExperience.push(createdJob as any);
      dispatcher.profile.updateData({ jobExperience });
    },
    [userProfile],
  );

  const handleUpdate = useCallback(
    (data: Omit<JobType, 'id'>) => {
      const updatedJob = { ...data };
      const jobExperience = [...(userProfile?.jobExperience || [])];
      if (updatedJob.startDate) {
        const [year, month] = updatedJob.startDate.split(/\//);
        updatedJob.startDate = `${year}-${month}-01`;
      }
      if (updatedJob.endDate) {
        const [year, month] = updatedJob.endDate.split(/\//);
        updatedJob.endDate = `${year}-${month}-01`;
      } else {
        delete updatedJob.endDate;
      }
      const index = jobExperience.findIndex((job) => job.id === id);
      if (index === -1) {
        return;
      }
      jobExperience[index] = {
        ...updatedJob,
        id,
      } as any;
      dispatcher.profile.updateData({ jobExperience });
    },
    [userProfile, id],
  );

  const handleDelete = useCallback(() => {
    const jobExperience =
      userProfile?.jobExperience?.filter?.((item) => item.id !== id) || [];
    dispatcher.profile.updateData({ jobExperience });
  }, [userProfile, id]);

  const handleClose = useCallback(() => {
    modal.popup.jobAdd.close();
  }, []);

  const handleSubmit = useCallback(() => {
    if (!userProfile) {
      return;
    }
    if (id) {
      handleUpdate(formValues as Omit<JobType, 'id'>);
    } else {
      handleCreate(formValues as Omit<JobType, 'id'>);
    }
    modal.popup.jobAdd.close();
  }, [id, formValues]);

  return (
    <View
      style={[styles.JobAdd, responsive.isMoreThen.mobile && styles.desktop]}
      lightColor="#e7e7e7"
      darkColor="#181818"
      pointerEvents="auto"
    >
      <ModalToolbar
        title={!isEdit ? 'Add Job' : 'Edit Job'}
        isDisabled={!validation.isValid}
        onCancel={handleClose}
        onDone={handleSubmit}
      />
      <View style={styles.form}>
        <TextInput
          label="Company *"
          value={formStore.get('company')}
          onChange={(value) => formStore.set('company', value)}
          error={validation.errors.company}
          onEnter={handleSubmit}
        />
        <TextInput
          label="Location *"
          value={formStore.get('location')}
          onChange={(value) => formStore.set('location', value)}
          error={validation.errors.location}
          onEnter={handleSubmit}
          style={styles.field}
        />
        <TextInput
          label="Position *"
          value={formStore.get('position')}
          onChange={(value) => formStore.set('position', value)}
          error={validation.errors.position}
          onEnter={handleSubmit}
          style={styles.field}
        />
        <View style={styles.dateInput}>
          <View style={styles.startDate}>
            <TextInput
              mask="9999/99"
              label="Start date *"
              value={formStore.get('startDate')}
              onChange={(value) => formStore.set('startDate', value)}
              error={validation.errors.startDate}
              onEnter={handleSubmit}
              style={styles.field}
            />
          </View>
          <View style={styles.endDate}>
            <TextInput
              mask="9999/99"
              label="End date"
              value={formStore.get('endDate')}
              onChange={(value) => formStore.set('endDate', value)}
              error={validation.errors.endDate}
              onEnter={handleSubmit}
              style={styles.field}
            />
          </View>
        </View>
        <TextInput
          label="Job description *"
          value={formStore.get('description')}
          onChange={(value) => formStore.set('description', value)}
          error={validation.errors.description}
          multiline
          style={styles.field}
        />
        <Button
          type="button"
          onPress={handleSubmit}
          isDisabled={!validation?.isValid}
          lightColor="#497CFF"
          darkColor="#497CFF"
          style={styles.btnSubmit}
        >
          {!isEdit && (
            <Text lightColor="#ffffff" darkColor="#ffffff" size={17}>
              Add job experience
            </Text>
          )}
          {isEdit && (
            <Text lightColor="#ffffff" darkColor="#ffffff" size={17}>
              Update job experience
            </Text>
          )}
        </Button>
        {isEdit && (
          <Button
            type="button"
            variant="text"
            onPress={handleDelete}
            style={styles.btnDelete}
          >
            <Text size={16} lightColor="#db3327" darkColor="#db3327">
              Delete job experience
            </Text>
          </Button>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  JobAdd: {
    width: '100%',
    maxWidth: unit(560),
    alignSelf: 'center',
    paddingHorizontal: unit(16),
    paddingTop: unit(18),
    paddingBottom: unit(32),
    borderTopLeftRadius: unit(40),
    borderTopRightRadius: unit(40),
  },
  desktop: {
    borderRadius: unit(40),
    marginTop: unit(40),
    marginBottom: unit(40),
  },
  form: {
    alignItems: 'stretch',
    marginTop: unit(16),
  },
  field: {
    marginTop: unit(16),
  },
  dateInput: {
    flexDirection: 'row',
  },
  startDate: {
    flex: unit(0.5),
    marginRight: unit(16),
  },
  endDate: {
    flex: unit(0.5),
  },
  btnSubmit: {
    marginTop: unit(24),
    minWidth: unit(120),
    alignSelf: 'center',
  },
  btnDelete: {
    marginTop: unit(16),
    alignSelf: 'center',
  },
});

export default memo(JobAdd);
