import { useCallback, useMemo } from 'react';
import { useSelector, useDispatcher } from 'store/utils/redux/hooks';

export default () => {
  const dispatcher = useDispatcher();

  const items = useSelector((state) => state.content.list.filters.type.options);
  const selectedValue = useSelector(
    (state) => state.content.list.filters.type.value,
  );

  const handleChange = useCallback((newValue?: string) => {
    if (!newValue) {
      return;
    }
    dispatcher.content.setFilterType(newValue);
  }, []);

  return useMemo(
    () => ({
      items,
      selectedValue,
      handleChange,
    }),
    [items, selectedValue],
  );
};
