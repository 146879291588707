import { cloneDeep } from 'lodash';
import EventEmitter from 'lib/EventEmitter';
import type { EmitterSubscription } from 'lib/EventEmitter/types';

class Storage {
  private readonly emitter = new EventEmitter();

  private data: Record<string, string | null> = {};

  public sync = (): void => {
    const keys: string[] = [];
    const data: Record<string, string | null> = {};
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key) {
        keys.push(key);
        data[key] = localStorage.getItem(key);
      }
    }
    this.data = data;
    this.emitter.emit('DATA_SYNC', { keys, values: cloneDeep(this.data) });
  };

  public set = (key: string, value: string, callback?: () => void): void => {
    if (this.data[key] === value) {
      return;
    }
    this.data[key] = value;
    localStorage.setItem(key, value);
    this.emitter.emit('DATA_SET', { keys: [key], values: { [key]: value } });
    callback?.();
  };

  public setMulti = (
    pairs: Record<string, string>,
    callback?: () => void,
  ): void => {
    if (Object.keys(pairs).length === 0) {
      return;
    }
    const keys: string[] = [];
    const values: Record<string, string | null> = {};
    Object.entries(pairs).forEach(([key, value]) => {
      localStorage.setItem(key, value);
      keys.push(key);
      values[key] = value;
      this.data[key] = value;
    });
    this.emitter.emit('DATA_SET', { keys, values });
    callback?.();
  };

  public get = (key: string): string | null => {
    return this.data[key] || null;
  };

  public getMulti = (keys: string[]): (string | null)[] => {
    return keys.map((key) => this.data[key] || null);
  };

  public remove = (key: string, callback?: () => void): void => {
    delete this.data[key];
    localStorage.removeItem(key);
    this.emitter.emit('DATA_DELETE', { keys: [key] });
    callback?.();
  };

  public addListener = (
    type: 'DATA_SET' | 'DATA_DELETE' | 'DATA_SYNC',
    listener: (data: any) => void,
    context?: any,
  ): EmitterSubscription => {
    return this.emitter.addListener(type, listener);
  };
}

export default new Storage();
