import { createAction } from '@reduxjs/toolkit';

import type { PaginationType, ActivityType } from 'app/entities';

export const clear = createAction('activity/clear');

export const loadList = createAction(
  'activity/loadList',
  (user: 'my' | 'promo' | string = 'my') => ({
    payload: {
      user,
    },
  }),
);

export const loadNextPage = createAction('activity/loadPage');

export const loadNextPageDone = createAction(
  'activity/loadPageDone',
  (reload = false, hasError = false) => ({
    payload: {
      reload,
      hasError,
    },
  }),
);

export const addPage = createAction(
  'activity/setPage',
  (items: ActivityType[], paginationInfo: PaginationType, reload = false) => ({
    payload: {
      items,
      paginationInfo,
    },
    meta: {
      reload,
    },
  }),
);

export const setItem = createAction(
  'activity/setItem',
  (data: ActivityType | ActivityType[]) => ({
    payload: {
      data,
    },
  }),
);
