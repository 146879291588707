import { takeEvery, put, cancel } from 'redux-saga/effects';
import { track } from '@amplitude/analytics-browser';

import { getRoute } from 'navigation/methods';

import { call } from 'store/utils/saga/effects';
import * as materialStore from 'store/nodes/content';
import * as noteStore from 'store/nodes/note';
import * as interactionStore from 'store/nodes/interaction';

import { actions } from '../slice';

export const config = {
  action: actions.runPageHandler.type,
  method: takeEvery,
};

export function* func() {
  const route = yield* call(() => getRoute<'Content'>());
  if (!route.isScreen('Content')) {
    yield cancel();
    return;
  }

  const { resourceType, resourceId } = route.params;
  if (!resourceId) {
    yield cancel();
    return;
  }

  yield put(materialStore.actions.loadById(resourceId));
  yield put(interactionStore.actions.view('content', resourceId));
  yield put(
    noteStore.actions.loadByResource({ resourceType: 'material', resourceId }),
  );
  yield put(materialStore.actions.loadRelated(resourceId));
  yield* call(() =>
    track('Content', {
      type: resourceType,
      id: resourceId,
    }),
  );
}
