import React, { memo, type ReactElement, useCallback } from 'react';
import { Box, IconButton, Tooltip, Typography } from '@mui/joy';

import { useSelector } from 'store/utils/redux/hooks';
import * as userStore from 'store/nodes/user';

import { controller as modal } from 'components/Modal2';
import Icon from 'ui/Icon';
import Tags from 'components/Tags';
import EmptyPanel from 'screens/Profile/elements/EmptyPanel';

const defaultProps = {
  bigLabel: false as boolean,
};

type SkillsProps = {
  userLogin?: string;
  bigLabel?: boolean;
} & typeof defaultProps;

const Skills = (props: SkillsProps): ReactElement | null => {
  const { userLogin, bigLabel } = props;

  const data = useSelector(userStore.selectors.getByLogin(userLogin));

  const skills = data?.skills || [];

  const handleAddSkill = useCallback(() => {
    modal.popup.skills.open();
  }, []);

  if (!userLogin || !data) {
    return null;
  }

  return (
    <Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Typography fontSize={bigLabel ? 22 : 20} fontWeight="bolder">
          Topics
        </Typography>
        {data.isMy && (
          <Tooltip
            arrow
            variant="outlined"
            size="sm"
            title="Add or delete topic"
          >
            <IconButton onClick={handleAddSkill}>
              <Icon size="xl" name="plus" fw weight="light" color="primary" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      {data.isMy && (!Array.isArray(skills) || !skills.length) && (
        <EmptyPanel
          mt={2}
          sx={{ cursor: 'pointer' }}
          onClick={handleAddSkill}
          text="Add interesting topics"
        />
      )}
      {!data.isMy && (!Array.isArray(skills) || !skills.length) && (
        <EmptyPanel mt={2} text={`${data.name} did not specify topics`} />
      )}
      <Box flexDirection="row" flexWrap="wrap" mt={1.5}>
        <Tags size="md" data={{ tags: data.skills }} />
      </Box>
    </Box>
  );
};

Skills.defaultProps = defaultProps;

export default memo(Skills);
