import { type DefaultRootState } from 'react-redux';

export default (reload: boolean) =>
  (state: DefaultRootState): string | null => {
    const nextCursor = state.searchModal.list.paginationInfo?.nextCursor as
      | string
      | null
      | undefined;
    if (reload || !nextCursor) {
      return null;
    }
    return nextCursor;
  };
