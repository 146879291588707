import type { DefaultRootState } from 'react-redux';

export default (state: DefaultRootState) => {
  if (
    state.copilot.model.available.length === 1 &&
    !state.copilot.model.selectedId
  ) {
    return state.copilot.model.available[0];
  }
  return state.copilot.model.available.find(
    (item) => item.id === state.copilot.model.selectedId,
  );
};
