import { useEffect } from 'react';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';

import type { CardType, LegacyCardType } from '../types';

const useLoadData = (
  id?: number | string,
  type?: CardType | LegacyCardType,
) => {
  const dispatcher = useDispatcher();

  const isNeedLoad = useSelector((state) => {
    if (!id || !type || typeof id === 'string') {
      return false;
    }
    if (
      (type === 'Material' || type === 'material') &&
      !state.content.data[id] &&
      !state.content.list.meta.loadingMap[id]
    ) {
      return true;
    }
    if (
      (type === 'Collection' || type === 'collection') &&
      !state.playlist.data[id] &&
      !state.playlist.meta.loadingMap[id]
    ) {
      return true;
    }
    return false;
  });

  useEffect(() => {
    if (typeof id === 'string') {
      return;
    }
    if (id && isNeedLoad && (type === 'Material' || type === 'material')) {
      dispatcher.content.loadById(id);
    }
    if (id && isNeedLoad && (type === 'Collection' || type === 'collection')) {
      dispatcher.playlist.loadById({ id });
    }
  }, [id, isNeedLoad, type]);
};

export default useLoadData;
