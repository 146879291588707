import {
  type FC,
  type MouseEvent as ReactMouseEvent,
  type AllHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
  memo,
} from 'react';
import { isEqual } from 'lodash';
import { createPortal } from 'react-dom';
import { createUseStyles } from 'react-jss';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
} from 'lexical';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import { $wrapNodes } from '@lexical/selection';
import {
  $createHeadingNode,
  $createQuoteNode,
  $isHeadingNode,
} from '@lexical/rich-text';
import { $isCodeNode } from '@lexical/code';
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  ListNode,
  $isListNode,
} from '@lexical/list';

import Icon from 'ui/Icon';
import { useColorScheme, useTheme, type Radius } from '@mui/joy';

export const blockTypes = {
  paragraph: {
    label: 'Normal',
    icon: {
      name: 'align-justify',
      sx: {},
    },
  },
  h1: {
    label: 'Heading',
    icon: {
      name: 'h1',
      sx: {
        fontSize: 14,
        ml: 0.375,
      },
    },
  },
  h2: {
    label: 'Heading',
    icon: {
      name: 'h2',
      sx: {
        fontSize: 14,
        ml: 0.375,
      },
    },
  },
  h3: {
    label: 'Heading',
    icon: {
      name: 'h3',
      sx: {
        fontSize: 14,
        ml: 0.375,
      },
    },
  },
  h4: {
    label: 'Heading',
    icon: {
      name: 'h4',
      sx: {
        fontSize: 14,
        ml: 0.375,
      },
    },
  },
  h5: {
    label: 'Heading',
    icon: {
      name: 'h5',
      sx: {
        fontSize: 14,
        ml: 0.375,
      },
    },
  },
  ol: {
    label: 'Numbered List',
    icon: {
      name: 'list-ol',
      sx: {},
    },
  },
  ul: {
    label: 'Bulleted List',
    icon: {
      name: 'list-ul',
      sx: {},
    },
  },
  quote: {
    label: 'Quote',
    icon: {
      name: 'message-quote',
      sx: {},
    },
  },
} as const;

export type BlockTypeSelectorProps = {
  slots?: {
    button?: AllHTMLAttributes<HTMLButtonElement>;
  };
};

const BlockTypeSelector: FC<BlockTypeSelectorProps> = (props) => {
  const { slots } = props;

  const theme = useTheme();
  const { colorScheme } = useColorScheme();

  const [editor] = useLexicalComposerContext();
  const classes = useStyles({
    colorScheme,
    boxShadow: `${theme.shadow.xl}, ${theme.shadow.xl}, ${theme.shadow.sm}`,
    radius: theme.radius,
  });

  const controlRef = useRef<HTMLButtonElement>(null);
  const dropDownRef = useRef<HTMLDivElement>(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [blockType, setBlockType] =
    useState<keyof typeof blockTypes>('paragraph');

  const handleUpdate = useCallback(() => {
    const selection = $getSelection();
    if (!$isRangeSelection(selection)) {
      return;
    }
    const anchorNode = selection.anchor.getNode();
    const element =
      anchorNode.getKey() === 'root'
        ? anchorNode
        : anchorNode.getTopLevelElementOrThrow();
    const elementKey = element.getKey();
    const elementDOM = editor.getElementByKey(elementKey);
    if (elementDOM === null) {
      return;
    }
    if ($isListNode(element)) {
      const parentList = $getNearestNodeOfType(anchorNode, ListNode);
      const type = parentList ? parentList.getTag() : element.getTag();
      setBlockType(type);
      return;
    }
    let type = $isHeadingNode(element) ? element.getTag() : element.getType();
    if (type === 'root') {
      type = 'paragraph';
    }
    setBlockType(type as keyof typeof blockTypes);
    if ($isCodeNode(element)) {
      // setCodeLanguage(element.getLanguage() || getDefaultCodeLanguage());
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          handleUpdate();
        });
      }),
    );
  }, [editor, handleUpdate]);

  useEffect(() => {
    const control = controlRef.current;
    const dropDown = dropDownRef.current;
    if (control !== null && dropDown !== null) {
      const { top, left } = control.getBoundingClientRect();
      dropDown.style.top = `${top + 40}px`;
      dropDown.style.left = `${left - 8}px`;
    }
  }, [isDropdownOpen]);

  useEffect(() => {
    const dropDown = dropDownRef.current;
    const toolbar = controlRef.current;

    if (dropDown !== null && toolbar !== null) {
      const handle = (event: MouseEvent) => {
        const target = event.target as HTMLElement;

        if (!dropDown.contains(target) && !toolbar.contains(target)) {
          setIsDropdownOpen(false);
        }
      };
      document.addEventListener('click', handle);

      return () => {
        document.removeEventListener('click', handle);
      };
    }

    return undefined;
  }, [isDropdownOpen]);

  const handleClick = useCallback(
    (event: ReactMouseEvent<HTMLButtonElement>) => {
      const itemID = event.currentTarget.getAttribute('itemID');
      if (!itemID) {
        return;
      }
      setIsDropdownOpen(false);
      editor.update(() => {
        const selection = $getSelection();

        if (!$isRangeSelection(selection)) {
          return;
        }

        if (itemID === 'paragraph' && blockType !== 'paragraph') {
          $wrapNodes(selection, () => $createParagraphNode());
          return;
        }

        if (itemID === 'h1' && blockType !== 'h1') {
          $wrapNodes(selection, () => $createHeadingNode('h1'));
          return;
        }

        if (itemID === 'h2' && blockType !== 'h2') {
          $wrapNodes(selection, () => $createHeadingNode('h2'));
          return;
        }

        if (itemID === 'h3' && blockType !== 'h3') {
          $wrapNodes(selection, () => $createHeadingNode('h3'));
          return;
        }

        if (itemID === 'h4' && blockType !== 'h4') {
          $wrapNodes(selection, () => $createHeadingNode('h4'));
          return;
        }

        if (itemID === 'h5' && blockType !== 'h5') {
          $wrapNodes(selection, () => $createHeadingNode('h5'));
          return;
        }

        if (itemID === 'quote' && blockType !== 'quote') {
          $wrapNodes(selection, () => $createQuoteNode());
          return;
        }

        if (itemID === 'ul') {
          if (blockType !== 'ul') {
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
          } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
          }
          return;
        }

        if (itemID === 'ol') {
          if (blockType !== 'ol') {
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
          } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
          }
          return;
        }
        console.log('unknown command');
      });
    },
    [editor, blockType],
  );

  return (
    <>
      <button
        {...slots?.button}
        type="button"
        ref={controlRef}
        onClick={() => setIsDropdownOpen(true)}
      >
        <span className={`icon block-type ${blockType}`} />
        <span className={classes.selectedText}>
          <Icon
            family="classic"
            name={blockTypes[blockType]?.icon.name}
            color="inherit"
            size="lg"
            sx={blockTypes[blockType]?.icon.sx}
            fw
          />
          {blockTypes[blockType]?.label}
        </span>
        <i className="chevron-down" />
      </button>
      {isDropdownOpen &&
        createPortal(
          <div className={classes.dropdown} ref={dropDownRef}>
            {Object.entries(blockTypes).map(([itemType, item]) => (
              <button
                type="button"
                className={classes.item}
                itemID={itemType}
                onClick={handleClick}
              >
                <Icon
                  family="classic"
                  name={item.icon.name}
                  color="inherit"
                  size="lg"
                  sx={item.icon.sx}
                  fw
                />
                <span className={classes.text}>{item.label}</span>
                {blockType === itemType && <span className={classes.active} />}
              </button>
            ))}
          </div>,
          document.body,
        )}
    </>
  );
};

const useStyles = createUseStyles<
  'dropdown' | 'selectedText' | 'item' | 'active' | 'text' | 'icon',
  {
    boxShadow?: string;
    colorScheme?: string;
    radius?: Radius;
  }
>({
  dropdown: {
    zIndex: 5,
    display: 'flex',
    padding: 8,
    flexDirection: 'column',
    alignItems: 'stretch',
    position: 'fixed',
    boxShadow: (props) => props.boxShadow,
    borderRadius: (props) => props.radius?.xl,
    transform: 'translateX(-1px)',
    minWidth: 48,
    minHeight: 40,
    backgroundColor: (props) =>
      `var(${props?.colorScheme === 'light' ? '--joy-palette-common-white' : '--joy-palette-neutral-800'})`,
    border: (props) =>
      `1px solid var(${props?.colorScheme === 'light' ? '--joy-palette-neutral-50' : '--joy-palette-neutral-900'})`,
  },
  selectedText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 6,
  },
  item: {
    padding: 8,
    color: 'var(--joy-palette-text-primary)',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    lineHeight: 16,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexShrink: 0,
    gap: 6,
    justifyContent: 'space-between',
    borderRadius: (props) => props.radius?.lg,
    border: 0,
    '&:hover:not([disabled])': {
      backgroundColor: (props) =>
        `var(${props.colorScheme === 'light' ? '--joy-palette-neutral-100' : '--joy-palette-neutral-700'})`,
    },
    '&:active:not([disabled])': {
      backgroundColor: (props) =>
        `var(${props.colorScheme === 'light' ? '--joy-palette-neutral-200' : '--joy-palette-neutral-600'})`,
    },
    '&.active, &:hover.active': {
      backgroundColor: (props) =>
        `var(${props.colorScheme === 'light' ? '--joy-palette-neutral-200' : '--joy-palette-neutral-600'})`,
    },
  },
  active: {
    display: 'flex',
    width: '20px',
    height: '20px',
    backgroundSize: 'contain',
  },
  text: {
    display: 'flex',
    lineHeight: '20px',
    flexGrow: 1,
    width: '200px',
  },
  icon: {
    display: 'flex',
    width: '20px',
    height: '20px',
    userSelect: 'none',
    marginRight: '12px',
    lineHeight: '16px',
    backgroundSize: 'contain',
  },
});

export default memo(BlockTypeSelector, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps),
);
