import { memo, useCallback, useMemo, type ReactNode } from 'react';
import {
  AspectRatio,
  Box,
  Card,
  CardContent,
  Skeleton,
  Typography,
} from '@mui/joy';

import { type DefaultRootState, useSelector } from 'react-redux';
import { type StyleProp, type TextStyle } from 'react-native';

import type { FontSizeVariant } from 'font';
import type { MaterialType, CollectionType } from 'app/entities';

import { useThemeColor } from 'hooks';

import * as contentStore from 'store/nodes/content';
import * as playlistStore from 'store/nodes/playlist';

import { guard } from 'utils';
import Info from 'components/Info';
import CompositePoster from 'components/CompositePoster';

const defaultProps = {
  infoSize: 13 as FontSizeVariant,
};

type CardSmallProps = {
  id?: number;
  type?: 'content' | 'playlist';
  data?: MaterialType | CollectionType;
  infoSize?: FontSizeVariant;
  toolbar?: ReactNode;
  onPress?: (
    type: 'content' | 'playlist',
    id: number,
    context: { data: MaterialType | CollectionType },
  ) => void;
} & typeof defaultProps;

const CardSmall = (props: CardSmallProps) => {
  const { id, type, data: dataByProps, infoSize, toolbar, onPress } = props;

  const imageBackgroundColor = useThemeColor({
    light: '#a6a6a6',
    dark: '#4E4E53',
  });
  const textBackgroundColor = useThemeColor({
    light: '#d2d2d2',
    dark: '#232326',
  });

  // @todo требуется оптимизация
  const data = useSelector(
    useCallback(
      (state: DefaultRootState) => {
        if (dataByProps) {
          return dataByProps;
        }
        if (!type) {
          return null;
        }
        if (['content'].includes(type)) {
          return contentStore.selectors.dataById(id)(state);
        }
        // @todo удалить playlist после изменения на сервере, деприкейтед.
        if (['collection', 'playlist'].includes(type)) {
          return playlistStore.selectors.dataById(id)(state);
        }
        return null;
      },
      [dataByProps, type],
    ),
  );

  const posterSources = useMemo(() => {
    if (guard.isMaterial(data)) {
      return [data.image];
    }
    if (guard.isCollection(data) && data?.cover) {
      return [data?.cover];
    }
    if (guard.isCollection(data) && !data?.cover) {
      return data.contentImages;
    }
    return null;
  }, [data]);

  const infoData = useMemo(() => {
    if (guard.isMaterial(data)) {
      return [data?.type, data?.level, data?.duration]
        .filter((item) => !!item)
        .splice(0, 3);
    }
    // if (guard.isCollection(data)) {
    //   return ['Collection', data?.level, data?.duration].filter((item) => !!item).splice(0, 3);
    // }
    return [];
  }, [data]);

  const handlePress = useCallback(() => {
    if (guard.isMaterial(data)) {
      onPress?.('content', data.id, { data });
    }
    if (guard.isCollection(data)) {
      onPress?.('playlist', data.id, { data });
    }
  }, [onPress, data]);

  if (!data) {
    return (
      <Card
        variant="outlined"
        sx={{
          width: '10em',
          display: 'flex',
          gap: 2,
        }}
      >
        <AspectRatio ratio="4/3">
          <Skeleton variant="overlay">
            <img
              alt=""
              src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
            />
          </Skeleton>
        </AspectRatio>
        <Typography>
          <Skeleton>Lorem ipsum is placeholder text</Skeleton>
        </Typography>
      </Card>
    );
  }

  return (
    <Card
      variant="outlined"
      onClick={handlePress}
      sx={{
        width: '10em',
        cursor: 'pointer',
        backgroundColor: 'transparent',
        transition: '300ms background-color ease',
        '--Card-radius': 'var(--joy-radius-lg)',
      }}
    >
      <Box
        sx={{
          maxWidth: 160,
        }}
      >
        <CompositePoster
          sources={posterSources || []}
          radius={10}
          aspectRatio={1}
        />
      </Box>
      <CardContent>
        <Typography
          fontSize={16}
          fontWeight={500}
          sx={{
            display: '-webkit-box',
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {data?.title}
        </Typography>
        <Info list={infoData as string[]} fontSize={infoSize} />
        {guard.isMaterial(data) && !!data?.source?.name && (
          <Typography color="neutral" fontSize={12} mt={-0.5}>
            {data?.source?.name}
          </Typography>
        )}
      </CardContent>
      {!!toolbar && (
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            right: 0,
          }}
        >
          {toolbar}
        </Box>
      )}
    </Card>
  );
};

CardSmall.defaultProps = defaultProps;

export default memo(CardSmall);
