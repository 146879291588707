import { memo, useCallback, useEffect } from 'react';
import { type StyleProp, type TextStyle } from 'react-native';
import { useSelector } from 'react-redux';
import { Box, IconButton } from '@mui/joy';

import type { CollectionType } from 'app/entities';

import { useDispatcher } from 'store/utils/redux/hooks';
import * as collectionStore from 'store/nodes/playlist';

import Icon from 'ui/Icon';

type DeleteProps = {
  style?: StyleProp<TextStyle>;
  id: number;
  onPress: (context: CollectionType) => void;
};

const Delete = (props: DeleteProps) => {
  const { style, id, onPress } = props;
  const dispatcher = useDispatcher();
  const data = useSelector(collectionStore.selectors.dataById(id));
  const userPermission = useSelector(
    collectionStore.selectors.userPermission(id, 'my'),
  );

  useEffect(() => {
    dispatcher.playlist.loadById({ id });
  }, [id]);

  const handlePress = useCallback(
    (event: { stopPropagation: () => void }) => {
      event.stopPropagation();
      if (!data) {
        return;
      }
      onPress?.(data);
    },
    [onPress, data],
  );

  if (!data || !userPermission.hasDelete) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <IconButton
        onClick={handlePress}
        variant="soft"
        sx={{
          opacity: '0.7',
          borderRadius: (theme) => theme.radius.md,
        }}
      >
        <Icon name="trash" color="red" />
      </IconButton>
    </Box>
  );
};

export default memo(Delete);
