import { type DefaultRootState } from 'react-redux';
import createCachedSelector from 're-reselect';

type ResourceType = 'material' | 'collection';

const cacheKeyCreator = (
  state: DefaultRootState,
  resourceType: ResourceType,
  resourceId: number,
) => `${resourceType}-${resourceId}`;

const listByResourceSelector = createCachedSelector(
  (state: DefaultRootState) => state.note.relation,
  (state: DefaultRootState, resourceType: ResourceType, resourceId: number) =>
    resourceType,
  (state: DefaultRootState, resourceType: ResourceType, resourceId: number) =>
    resourceId,
  (relation, resourceType, resourceId) => {
    const filteredEntities = relation.filter(
      (item) =>
        item.resourceType === resourceType &&
        item.resourceId === Number(resourceId),
    );

    return filteredEntities
      .map((item) => item.noteId)
      .sort((a, b) => (a > b ? -1 : 1));
  },
)(cacheKeyCreator);

export default (resourceType: ResourceType, resourceId: number) =>
  (state: DefaultRootState) => {
    return listByResourceSelector(state, resourceType, resourceId);
  };
