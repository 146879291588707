import {
  type SagaReturnType,
  takeEvery,
  put,
  cancel,
} from 'redux-saga/effects';

import * as messageStore from 'store/nodes/message';

import { actions } from '../slice';

export const config = {
  action: messageStore.socketActions.messageInfo.type,
  method: takeEvery,
};

function* handleIndexingStart(resourceId: number) {
  yield put(
    actions.setItem({
      data: { id: resourceId, indexing: true },
      mode: 'update',
    }),
  );
}

function* handleIndexingEnd(resourceId: number) {
  yield put(
    actions.setItem({
      data: { id: resourceId, indexing: false, indexed: true },
      mode: 'update',
    }),
  );
}

export function* func(
  action: SagaReturnType<typeof messageStore.socketActions.messageInfo>,
) {
  const { id, message, context } = action.payload;
  const resourceId = Number(context?.resourceId);

  if (!resourceId || Number.isNaN(resourceId)) {
    yield cancel();
    return;
  }

  if (context.type === 'note_indexing_start') {
    yield put(messageStore.actions.prevent({ id }));
    yield handleIndexingStart(resourceId);
  }
  if (context.type === 'note_indexing_end') {
    yield put(messageStore.actions.prevent({ id }));
    yield handleIndexingEnd(resourceId);
  }
}
