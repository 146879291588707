import type { ResultLegacy, AuthCheckCredentialsType } from 'app/entities';
import request from 'services/api/request';

export default async (
  email: string,
): Promise<ResultLegacy<AuthCheckCredentialsType>> => {
  const { data, error } = await request.post<AuthCheckCredentialsType>(
    '/auth/check-credentials',
    {
      body: {
        email,
      },
    },
  );
  return {
    data,
    error,
  };
};
