import qs from 'query-string';

import type {
  PaginationResult,
  PaginationInput,
  CursorType,
  CollectionType,
} from 'app/entities';
import request from 'services/api/request';

export default async (
  login?: string,
  pagination?: PaginationInput,
): Promise<PaginationResult<CollectionType>> => {
  if (!login) {
    return {
      data: {
        items: [],
        paginationInfo: {
          hasNext: false,
          nextCursor: null,
        },
      },
      error: null,
    };
  }

  const query: Record<string, any> = {
    pageSize: pagination?.pageSize || 50,
  };
  if (pagination?.cursor) {
    query.cursor = pagination?.cursor;
  }

  const { data, error } = await request.get<CursorType<CollectionType>>(
    'share/user/playlists',
    { query },
  );
  if (error || !data) {
    return {
      data: null,
      error: error || new Error('Error'),
    };
  }

  const next = qs.parseUrl(data.nextUrl || '');

  return {
    data: {
      items: data.items,
      paginationInfo: {
        hasNext: !!next?.query?.cursor,
        nextCursor: (next?.query?.cursor as string) || null,
      },
    },
    error,
  };
};
