import { memo, useCallback, useEffect, useMemo } from 'react';
import { Box, Button, Typography } from '@mui/joy';
import { DateTime } from 'luxon';

import { useNavigate } from 'navigation/hooks';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as subscriptionStore from 'widgets/Subscription/store';
import * as userStore from 'store/nodes/user';

import videoBackground from 'screens/auth/assets/videoBackground.mp4';

import DualPanels from 'widgets/DualPanels';
import SubscriptionTryFree from 'widgets/Subscription/TryFree';
import Icon from 'ui/Icon';

const TryFree = () => {
  const navigate = useNavigate();
  const dispatcher = useDispatcher();
  const user = useSelector(userStore.selectors.getByLogin('my'));

  const isTrialProcessing = useSelector(
    subscriptionStore.selectors.isTrialProcessing,
  );
  const freeTrialEndDate = useMemo(() => {
    return DateTime.now().plus({ days: 7 }).toFormat('MMM dd, yyyy');
  }, []);

  const handleTryFree = useCallback(() => {
    dispatcher.subscription.tryFree();
  }, []);

  const handleSkip = useCallback(() => {
    navigate('Ask');
  }, []);

  useEffect(() => {
    localStorage.setItem(`isTrialAsked#${user?.id}`, 'true');
  }, [user?.id]);

  return (
    <DualPanels
      sx={{
        '--left-max-width': '40rem',
        '--panel-max-width': '300px',
      }}
      leftPanel={
        <>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={1}
          >
            <Typography fontSize={52} fontWeight={500} textAlign="center">
              Start your free trial
            </Typography>
            <Typography
              fontSize={16}
              fontWeight={400}
              maxWidth={400}
              textAlign="center"
            >
              You won&apos;t be charged anything until {freeTrialEndDate}.
              Cancel anytime.
            </Typography>
            <Box height="10vh" />
            <Button
              size="lg"
              color="neutral"
              variant="soft"
              loading={isTrialProcessing}
              onClick={handleTryFree}
              endDecorator={<Icon name="arrow-right" color="inherit" />}
              sx={{
                minWidth: '16rem',
                '--Button-minHeight': '3rem',
              }}
            >
              <Typography sx={{ color: 'inherit' }}>
                Start free trial
              </Typography>
            </Button>
            <Typography fontSize={14} mt={1}>
              Not ready yet?{' '}
              <Typography
                component="span"
                onClick={handleSkip}
                sx={{
                  borderBottom: '1px solid currentColor',
                  cursor: 'pointer',
                  transition: '100ms border-color ease',
                  pointerEvents: isTrialProcessing ? 'none' : 'all',
                  '&:hover': {
                    borderColor: 'transparent',
                    transition: '300ms border-color ease',
                  },
                }}
              >
                Skip this step
              </Typography>
            </Typography>
          </Box>
        </>
      }
      rightPanel={
        <Box
          position="relative"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flex={1}
          sx={{ opacity: 0.9 }}
        >
          <SubscriptionTryFree />
        </Box>
      }
      rightPanelBackGround={videoBackground}
    />
  );
};

export default memo(TryFree);
