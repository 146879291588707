import {
  takeLatest,
  put,
  cancel,
  type SagaReturnType,
} from 'redux-saga/effects';

import Alert from 'components/Alert';

import * as api from 'services/api';

import { call, select } from 'store/utils/saga/effects';
import { checkUnauthorized } from 'store/utils/credentials';

import * as actions from '../actions';
import * as selectors from '../selectors';

export const config = {
  action: actions.loadNextPage.type,
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof actions.loadNextPage>) {
  const { payload } = action;
  const { reload } = payload;

  const filterType = yield* select(selectors.filter<string>('type', 'all'));
  const nextCursor = yield* select(selectors.selectLoadNextCursor(reload));

  const response = yield* call(() =>
    api.resource.user.feed(
      {
        cursor: nextCursor,
        pageSize: 24,
      },
      {
        type: filterType.value,
      },
    ),
  );
  yield checkUnauthorized(response);

  if (response.error || !response.data) {
    Alert.error(response?.error?.message || 'Server error #1');
    yield put(actions.loadNextPageDone(reload));
    yield cancel();
    return;
  }

  yield put(
    actions.addPage(response.data.items, response.data.paginationInfo, reload),
  );
  yield put(actions.loadNextPageDone(reload));
}
