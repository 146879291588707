import { type ReactElement, memo, useMemo, useCallback } from 'react';
import { Avatar, Box, Button, Typography } from '@mui/joy';
import { StyleSheet } from 'react-native';
import pluralize from 'pluralize';

import { useSelector } from 'store/utils/redux/hooks';
import * as userStore from 'store/nodes/user';
import * as authorStore from 'store/nodes/author';

import { useGetOpenLink, useThemeColor } from 'hooks';
import { guard, unit } from 'utils';
import { View } from 'components/Themed';
import Icon from 'components/LegacyIcon';

interface InfoProps {
  userLogin?: string;
  authorId?: number;
  onSubscribe?: () => void;
  onUnsubscribe?: () => void;
  onRelationsPress?: () => void;
}

const Info = (props: InfoProps): ReactElement => {
  const { userLogin, authorId, onSubscribe, onUnsubscribe, onRelationsPress } =
    props;

  const imageBackgroundColor = useThemeColor({
    light: '#a6a6a6',
    dark: '#4E4E53',
  });
  const textBackgroundColor = useThemeColor({
    light: '#ffffff',
    dark: '#232326',
  });
  const openLink = useGetOpenLink();
  const userData = useSelector(userStore.selectors.getByLogin(userLogin));
  const authorData = useSelector(authorStore.selectors.getById(authorId));

  const isSubscripting = useSelector((state) => {
    if (userData) {
      return userStore.selectors.isSubscripting(userData?.login)(state);
    }
    return null;
  });
  const photo = userData?.photo || authorData?.photo;

  const nameFinal = useMemo(() => {
    if (userData) {
      return [userData?.name, userData?.surname].join(' ').trim();
    }
    if (authorData) {
      return [authorData?.name, authorData?.surname].join(' ').trim();
    }
    return 'Guest';
  }, [userData, authorData]);

  const loginFinal = useMemo(() => {
    if (userData) {
      return `@${userData.login}`;
    }
    if (authorData && authorData.name) {
      return `@${authorData.name.toLowerCase()}`;
    }
    return null;
  }, [userData, authorData]);

  const handleOpenWebsite = useCallback(() => {
    if (!userData || !userData.websiteLink) {
      return;
    }
    openLink(userData.websiteLink);
  }, [userData?.websiteLink]);

  if (!userData && !authorData) {
    return (
      <View style={styles.Info}>
        <View style={styles.row}>
          <View style={styles.full}>
            <View
              style={[styles.wfName, { backgroundColor: textBackgroundColor }]}
            />
            <View
              style={[
                styles.wfFollowers,
                { backgroundColor: textBackgroundColor },
              ]}
            />
            <View
              style={[styles.wfLogin, { backgroundColor: textBackgroundColor }]}
            />
          </View>
          <View>
            <View
              style={[styles.photo, { backgroundColor: imageBackgroundColor }]}
            />
          </View>
        </View>
        {userLogin && (
          <View
            style={[styles.wfButton, { backgroundColor: textBackgroundColor }]}
          />
        )}
      </View>
    );
  }

  return (
    <Box
      sx={{
        maxWidth: '425px',
        pt: 4,
      }}
    >
      <Box display="flex" flexDirection="row">
        <Box flex={1}>
          <Typography fontSize={22} fontWeight="bolder">
            {nameFinal}
          </Typography>
          {!!userData && (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              mt={1}
              onClick={onRelationsPress}
            >
              <Typography
                fontSize={16}
              >{`${userData?.subscribersCount} ${pluralize('Follower', userData?.subscribersCount)}`}</Typography>
              <View
                style={styles.dot}
                lightColor="#000000"
                darkColor="#ffffff"
              />
              <Typography
                fontSize={16}
              >{`${userData?.subscriptionsCount} Following`}</Typography>
            </Box>
          )}
          {!!loginFinal && <Typography fontSize={15}>{loginFinal}</Typography>}
        </Box>
        <Box>
          {!!photo && <Avatar sx={{ width: 80, height: 80 }} src={photo.url} />}
          {!photo && (
            <View style={styles.emptyPhoto}>
              <Icon name="Profile" size={64} />
            </View>
          )}
        </Box>
      </Box>
      {(!!userData?.profession || !!userData?.websiteLink) && (
        <View style={[styles.full, styles.block2]}>
          {!!userData.profession && (
            <Typography fontSize={17} fontWeight="bolder">
              {userData.profession}
            </Typography>
          )}
          {!!userData.websiteLink && (
            <Typography
              sx={{
                cursor: guard.isLink(userData?.websiteLink)
                  ? 'pointer'
                  : 'default',
              }}
              onClick={
                guard.isLink(userData?.websiteLink)
                  ? handleOpenWebsite
                  : undefined
              }
              fontSize={13}
              color="neutral"
            >
              {userData.websiteLink}
            </Typography>
          )}
        </View>
      )}
      <Box mt={2}>
        {userData?.isSubscribed === false && (
          <Button
            variant="outlined"
            onClick={onSubscribe}
            loading={isSubscripting || false}
            size="sm"
          >
            Follow
          </Button>
        )}
        {userData?.isSubscribed === true && (
          <Button
            variant="outlined"
            onClick={onUnsubscribe}
            loading={isSubscripting || false}
            size="sm"
          >
            Following
          </Button>
        )}
      </Box>
    </Box>
  );
};

const styles = StyleSheet.create({
  Info: {
    maxWidth: unit(425),
    paddingTop: unit(32),
  },
  row: {
    flexDirection: 'row',
  },
  full: {
    flex: 1,
  },
  padding: {
    paddingTop: unit(28),
  },
  photo: {
    width: unit(80),
    height: unit(80),
    borderRadius: unit(40),
  },
  alignCenter: {
    alignItems: 'center',
  },
  emptyPhoto: {
    width: unit(80),
    height: unit(80),
    borderRadius: unit(40),
    borderStyle: 'solid',
    borderColor: '#ffffff',
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  action: {
    marginLeft: unit(6),
    width: unit(44),
    height: unit(44),
    borderRadius: unit(22),
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#4e4e53',
  },
  block2: {
    marginTop: unit(16),
  },
  profession: {},
  websiteLink: {},
  social: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: unit(8),
  },
  dot: {
    width: 2,
    height: 2,
    borderRadius: 1,
    marginHorizontal: unit(8),
  },
  followBtn: {
    marginTop: unit(24),
    borderRadius: unit(5),
    alignSelf: 'flex-start',
  },
  wfName: {
    marginTop: unit(4),
    height: unit(20),
    width: unit(80),
  },
  wfFollowers: {
    marginTop: unit(16),
    height: unit(12),
    width: unit(150),
  },
  wfLogin: {
    marginTop: unit(8),
    height: unit(12),
    width: unit(60),
  },
  wfButton: {
    marginTop: unit(24),
    height: unit(40),
    width: unit(120),
    borderRadius: unit(5),
  },
});

export default memo(Info);
