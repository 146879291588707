import {
  type ReactElement,
  type ReactNode,
  memo,
  useCallback,
  useMemo,
} from 'react';
import {
  type StyleProp,
  type ViewStyle,
  type GestureResponderEvent,
  StyleSheet,
  TouchableOpacity,
} from 'react-native';
import { type FontSizeVariant } from 'font';

import { useThemeColor } from 'hooks';

import { Text, View } from 'components/Themed';
import { unit } from 'utils';
import Icon from 'components/LegacyIcon';

const defaultProps = {
  itemLabel: 'label',
  itemValue: 'value',
  lightColor: '#000000',
  darkColor: '#ffffff',
  variant: 'outline',
  size: 'normal' as 'normal' | 'slim',
  textSize: 11 as FontSizeVariant,
  radius: 5 as number,
};

type TagsProps = {
  style?: StyleProp<ViewStyle>;
  items:
    | (string | { label: string; value: string } | Record<string, any>)[]
    | undefined;
  itemLabel?: string;
  itemValue?: string;
  lightColor?: string;
  darkColor?: string;
  lightTextColor?: string;
  darkTextColor?: string;
  size?: 'normal' | 'slim';
  textSize?: FontSizeVariant;
  radius?: number;
  variant?: 'outline' | 'contained';
  StartComponent?: ReactNode;
  EndComponent?: ReactNode;
  onPress?: (event: GestureResponderEvent, context: { tag: string }) => void;
  onRemove?: (event: GestureResponderEvent, context: { tag: string }) => void;
} & typeof defaultProps;

const TagsOld = (props: TagsProps): ReactElement | null => {
  const {
    style,
    items,
    itemLabel,
    itemValue,
    lightColor,
    darkColor,
    lightTextColor,
    darkTextColor,
    size,
    textSize,
    radius,
    variant,
    StartComponent,
    EndComponent,
    onPress,
    onRemove,
  } = props;
  const borderColor = useThemeColor({ light: lightColor, dark: darkColor });
  const backgroundColor = useThemeColor({ light: lightColor, dark: darkColor });

  const styleFinal = useMemo(() => {
    return [styles.Tags, style];
  }, [style]);

  const handleTagPress = useCallback(
    (event: GestureResponderEvent, tag: string) => {
      event.preventDefault();
      event.stopPropagation();
      onPress?.(event, { tag });
    },
    [onPress],
  );

  const handleRemovePress = useCallback(
    (event: GestureResponderEvent, tag: string) => {
      event.preventDefault();
      event.stopPropagation();
      onRemove?.(event, { tag });
    },
    [onRemove],
  );

  if (!Array.isArray(items) || !items.length) {
    return null;
  }

  return (
    <View style={styleFinal}>
      <View style={styles.wrapper}>
        {!!StartComponent && StartComponent}
        {items.map((tag: any) => {
          const label = typeof tag === 'string' ? tag : tag?.[itemLabel];
          const value = typeof tag === 'string' ? tag : tag?.[itemValue];
          return (
            <View
              key={value}
              style={[
                styles.button,
                size === 'slim' && styles.sizeSlim,
                { borderColor, borderRadius: unit(radius) },
                variant === 'contained' && { backgroundColor },
              ]}
            >
              {!!onPress && (
                <TouchableOpacity
                  onPress={(event) => handleTagPress(event, value)}
                  activeOpacity={0.8}
                >
                  <Text
                    size={textSize}
                    lightColor={lightTextColor || lightColor}
                    darkColor={darkTextColor || darkColor}
                    style={styles.text}
                  >
                    {label}
                  </Text>
                </TouchableOpacity>
              )}
              {!onPress && (
                <Text
                  size={textSize}
                  lightColor={lightTextColor || lightColor}
                  darkColor={darkTextColor || darkColor}
                  style={styles.text}
                >
                  {label}
                </Text>
              )}
              {!!onRemove && (
                <TouchableOpacity
                  onPress={(event) => handleRemovePress(event, value)}
                  activeOpacity={0.8}
                >
                  <Icon
                    name="Remove"
                    size={Math.round((textSize / 11) * 18)}
                    style={styles.close}
                  />
                </TouchableOpacity>
              )}
            </View>
          );
        })}
        {!!EndComponent && EndComponent}
      </View>
    </View>
  );
};

TagsOld.defaultProps = defaultProps;

const styles = StyleSheet.create({
  Tags: {
    maxWidth: '100%',
  },
  wrapper: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    maxWidth: '100%',
    overflow: 'hidden',
    marginBottom: unit(-4),
  },
  button: {
    flexDirection: 'row',
    marginRight: unit(5),
    marginBottom: unit(6),
    borderRadius: unit(5),
    borderWidth: 1,
    paddingHorizontal: unit(10),
    paddingTop: unit(4),
    paddingBottom: unit(4),
  },
  text: {
    letterSpacing: -0.07,
  },
  sizeSlim: {
    paddingHorizontal: unit(8),
    paddingTop: unit(3),
    paddingBottom: unit(4),
  },
  close: {
    marginTop: unit(-2),
    marginBottom: unit(-3),
    marginLeft: unit(4),
    marginRight: unit(-4),
  },
});

export default memo(TagsOld);
