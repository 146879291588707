import {
  takeLatest,
  put,
  cancel,
  delay,
  type SagaReturnType,
} from 'redux-saga/effects';

import type { UserType, MaterialType, CollectionType } from 'app/entities';
import Alert from 'components/Alert';

import * as api from 'services/api';
import { guard } from 'utils';

import { call, select } from 'store/utils/saga/effects';
import { checkUnauthorized } from 'store/utils/credentials';
import * as materialStore from 'store/nodes/content';
import * as collectionStore from 'store/nodes/playlist';
import * as userStore from 'store/nodes/user';

import * as actions from '../actions';
import * as selectors from '../selectors';

export const config = {
  action: [actions.loadList.type, actions.loadNextPage.type],
  method: takeLatest,
};

export function* func(
  action: SagaReturnType<typeof actions.loadList | typeof actions.loadNextPage>,
) {
  let reload = false;
  if (action.type === actions.loadList.type) {
    reload = true;
  }
  if (action.type === actions.loadNextPage.type) {
    reload = false;
  }

  yield delay(10);

  const user = yield* select((state) => state.activity.list.user);

  const nextCursor = yield* select(selectors.selectLoadNextCursor(reload));

  const response = yield* call(() =>
    api.resource.activity.list(user, { cursor: nextCursor, pageSize: 24 }),
  );

  yield checkUnauthorized(response);

  if (response.error || !response.data) {
    Alert.error(response?.error?.message || 'Server error #1');
    yield put(actions.loadNextPageDone(reload));
    yield cancel();
    return;
  }

  const materials: MaterialType[] = [];
  const collections: CollectionType[] = [];
  const users: UserType[] = [];
  response.data.items.forEach((item) => {
    if (guard.isActivityNewMaterial(item)) {
      materials.push(item.content);
      users.push(item.user);
    }
    if (guard.isActivityNewCollection(item)) {
      collections.push(item.playlist);
      users.push(item.user);
    }
    if (guard.isActivityCompletedMaterial(item)) {
      materials.push(item.content);
      users.push(item.user);
      if (
        guard.isUser(item.comment.user) &&
        item.user.id !== item.comment.user.id
      ) {
        users.push(item.comment.user);
      }
    }
    if (guard.isActivityCompletedCollection(item)) {
      collections.push(item.playlist);
      users.push(item.user);
      if (
        guard.isUser(item.comment.user) &&
        item.user.id !== item.comment.user.id
      ) {
        users.push(item.comment.user);
      }
    }
    if (guard.isActivityCommentMaterial(item)) {
      materials.push(item.content);
      users.push(item.user);
      if (
        guard.isUser(item.comment.user) &&
        item.user.id !== item.comment.user.id
      ) {
        users.push(item.comment.user);
      }
    }
    if (guard.isActivityCommentCollection(item)) {
      collections.push(item.playlist);
      users.push(item.user);
      if (
        guard.isUser(item.comment.user) &&
        item.user.id !== item.comment.user.id
      ) {
        users.push(item.comment.user);
      }
    }
  });

  if (materials.length > 0) {
    yield put(materialStore.actions.setItem(materials));
  }
  if (collections.length > 0) {
    yield put(collectionStore.actions.setItem({ data: collections }));
  }
  if (users.length > 0) {
    yield put(userStore.actions.setProfile({ data: users }));
  }

  yield delay(10);
  yield put(
    actions.addPage(response.data.items, response.data.paginationInfo, reload),
  );
  yield put(actions.loadNextPageDone(reload));
}
