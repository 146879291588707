import { memo, useCallback, useEffect, useMemo } from 'react';
import { Box } from '@mui/joy';
import { StyleSheet } from 'react-native';

import { useFocusEffect } from 'navigation/hooks';

import { useListData, useListLayout, useSearchMethod } from 'hooks';
import { unit } from 'utils';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as searchStore from 'store/nodes/search';

import AdaptivePage from 'components/AdaptivePage';
import List from 'components/List';
import { useScrollMethods } from 'components/Scroller';

import Card from 'widgets/Card';

import useSearchState from '../../model/useSearchState';

import StateMessage from '../../ui/StateMessage';

type renderItemProps<T> = {
  item: T;
  i: number;
};

const Regular = () => {
  const dispatcher = useDispatcher();
  const { setScrollValue, getScrollValue, scrollTo } = useScrollMethods();
  const { scope: searchMethodScope, type: searchMethodType } =
    useSearchMethod();

  const list = useSelector(searchStore.selectors.list);
  const listMeta = useSelector(searchStore.selectors.listMeta);

  const { listLayout } = useListLayout('search.list.mode', 'row');

  const listData = useListData(list.sequence, listMeta, 12);

  const searchState = useSearchState();

  useEffect(() => {
    if (listMeta.firstPage.isLoading === true) {
      setScrollValue('Ask', 0);
      scrollTo(0);
    }
  }, [listMeta.firstPage.isLoading]);

  useFocusEffect(
    useCallback(() => {
      const { top } = getScrollValue('Ask');
      setTimeout(() => {
        // @todo реализовать совместимую со списками версию (для devices)
        scrollTo(top);
      }, 10);
    }, []),
  );

  const handleRefresh = useCallback(() => {
    dispatcher.search.loadNextPage({ reload: true });
  }, []);

  const handleNextPage = useCallback(() => {
    if (!listMeta.isConsistent) {
      return;
    }
    dispatcher.search.loadNextPage();
  }, [listMeta.isConsistent]);

  const renderItem = useCallback(
    (args: renderItemProps<any>) => {
      const [highlight] = args?.item?.searchMeta?.highlights || [];
      const extraData: Record<string, string> = {};
      if (highlight) {
        extraData.description = highlight;
      }

      return (
        <Card
          id={args.item.id}
          type={args.item.type}
          descriptionLineLimit={4}
          hasDescription
          hasAuthor
          hasSummary
          hasHighlight
          hasLink
          hasTags
          hasToCollection
          hasIndexed
          hasRemove={searchMethodScope === 'Library'}
          hasQueue={searchMethodScope === 'Global'}
          sx={{
            mx: 1,
            mb: 2,
          }}
        />
      );
    },
    [listLayout.isRow, searchMethodScope],
  );

  const pagePaddingHorizontalScheme = useMemo(
    () => ({
      320: 16,
    }),
    [],
  );

  return (
    <AdaptivePage
      desktopHeaderLeftPanel="search"
      bodyMaxWidth={1280}
      paddingHorizontalScheme={pagePaddingHorizontalScheme}
    >
      {searchState !== 'list' && <StateMessage />}
      {searchState === 'list' && (
        <>
          <List
            type="masonry"
            componentStyle={styles.listComponent}
            contentContainerStyle={styles.listContentContainer}
            scrollEmitter="web-page"
            data={listData.sequence}
            keyExtractor={(item) => `${item.id}`}
            renderItem={renderItem}
            columnsScheme={
              listLayout.isRow ? '320:1' : '320:1;528:2;672:3;960:4'
            }
            // @ts-ignore
            showsVerticalScrollIndicator={false}
            hasNextPage={list.paginationInfo.hasNext}
            onRefresh={handleRefresh}
            onEndReached={handleNextPage}
            ListHeaderComponent={
              <Box
                height={48}
                sx={{ '@media (min-width: 768px)': { height: 0 } }}
              />
            }
            ListFooterComponent={
              <Box
                height={64}
                sx={{ '@media (min-width: 768px)': { height: 16 } }}
              />
            }
          />
        </>
      )}
    </AdaptivePage>
  );
};

const styles = StyleSheet.create({
  listComponent: {
    marginHorizontal: unit(-10),
  },
  listContentContainer: {
    alignSelf: 'stretch',
  },
  tabSwitcher: {
    left: unit(24),
    right: unit(24),
  },
  listItemRegular: {
    marginHorizontal: unit(10),
    marginBottom: unit(20),
  },
});

export default memo(Regular);
