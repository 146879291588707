import qs from 'query-string';

import type {
  CursorType,
  PaginationInput,
  PaginationResult,
  UserType,
  RelationMetaType,
} from 'app/entities';
import request from 'services/api/request';

export default async (
  login: string,
  pagination: PaginationInput,
): Promise<PaginationResult<Partial<UserType>>> => {
  const query: Record<string, any> = {
    pageSize: pagination?.pageSize || 10,
  };
  if (pagination?.cursor) {
    query.cursor = pagination?.cursor;
  }

  const { data, error } = await request.get<CursorType<UserType>>(
    `/user/profile/${login}/subscribers`,
    { query },
  );
  if (error || !data) {
    return {
      data: null,
      error,
    };
  }

  let meta: Record<number, RelationMetaType> = {};
  const userIds: number[] = data.items.map((item) => item.id);
  if (userIds.length > 0) {
    const metaResponse = await request.get<Record<number, RelationMetaType>>(
      '/user/subscriptions/meta',
      { query: { userIds: userIds.join(',') } },
    );
    if (metaResponse.error || !metaResponse.data) {
      return {
        data: null,
        error: metaResponse.error,
      };
    }
    meta = metaResponse.data;
  }

  const next = qs.parseUrl(data.nextUrl || '');

  return {
    data: {
      items: data?.items.map(
        (item): UserType => ({
          ...item,
          ...meta[item.id],
          isMy: item.isMy ?? false,
          isSubscribed: meta[item.id].isSubscribed || false,
        }),
      ),
      paginationInfo: {
        hasNext: !!next?.query?.cursor,
        nextCursor: (next?.query?.cursor as string) || null,
      },
    },
    error: null,
  };
};
