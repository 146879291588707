import { memo, useCallback } from 'react';
import { Platform } from 'react-native';
import { createUseStyles } from 'react-jss';

import { saveLastRoute } from 'utils';
import { useHasSession, useThemeColor } from 'hooks';
import { navigate } from 'navigation/methods';

import { useDispatcher } from 'store/utils/redux/hooks';

import AdaptivePage from 'components/AdaptivePage';
import Icon from 'components/LegacyIcon';

const Empty = () => {
  const dispatcher = useDispatcher();
  const hasSession = useHasSession();

  const textColor = useThemeColor({ light: '#000000', dark: '#ffffff' });
  const panelBackgroundColor = useThemeColor({
    light: '#ffffff',
    dark: '#1b1b1b',
  });
  const buttonBackgroundColor = useThemeColor({
    light: '#efefef',
    dark: '#000000',
  });

  const classes = useStyles({
    textColor,
    panelBackgroundColor,
    buttonBackgroundColor,
  });

  const handleAddMaterial = useCallback(() => {
    if (!hasSession) {
      saveLastRoute();
      navigate('Auth/Start');
      return;
    }
    dispatcher.plus.open({ toCollection: { append: true } });
  }, [hasSession]);

  const handleExtensionChrome = useCallback(() => {
    if (Platform.OS === 'web' && !!document) {
      const link = document.createElement('a');
      link.setAttribute(
        'href',
        'https://chrome.google.com/webstore/detail/ikiai-your-knowledge-hub/bjdlhnaghjcjihjiojhpnimlmfnehbga',
      );
      link.setAttribute('target', '_blank');
      link.click();
    }
  }, []);

  const handleHomeNavigate = useCallback(() => {
    navigate('Ask');
  }, []);

  return (
    <AdaptivePage
      desktopHeaderLeftPanel="ask"
      desktopHeaderMiddlePanel={false}
      bodyMaxWidth={1280}
      paddingHorizontalScheme={{ 320: 16 }}
    >
      <div className={classes.header}>
        <Icon name="MenuLibraryOutline" />
        <span>Library</span>
      </div>
      <div className={classes.panels}>
        <div className="short">
          <span>
            IKI smart library can help you with storing
            <br />
            {' knowledge and reasoning over it'}
          </span>
        </div>
        <div className="panel add" onClick={handleAddMaterial}>
          <div className={classes.buttonIcon}>
            <Icon name="Add2" size="2.25em" />
          </div>
          <p style={{ maxWidth: '14.25em' }}>
            Drop any links (articles, videos, github pages) to add them to your
            library
          </p>
        </div>
        <div className="panel extension" onClick={handleExtensionChrome}>
          <div className={classes.buttonIcon}>
            <Icon name="ExtensionOutline32" size="2em" />
          </div>
          <p style={{ maxWidth: '13.25em' }}>
            Install extension to save pages along with highlights in one click
          </p>
        </div>
        <div className="panel ai" onClick={handleHomeNavigate}>
          <div className={classes.buttonIcon}>
            <Icon name="IkiCoPilot" size="2.5em" />
          </div>
          <p>
            Ask questions on the content and get precise answers powered by full
            text semantic search and LLMs.
          </p>
        </div>
        <div className="panel info">
          <div className="content">
            <p>IKI search is powerful and has 3 regimes</p>
            <ol>
              <li>you can search for materials by titles in IKI index</li>
              <li>
                ask specific questions and we’ll retrieve the documents
                containing needed information along with a generated answer
              </li>
              <li>search by materials titles just inside your library</li>
            </ol>
            <p>
              Soon we’ll add other reasoning capabilities to help you with
              content review and research. Start growing your knowledge base
              today.
            </p>
          </div>
        </div>
      </div>
    </AdaptivePage>
  );
};

const useStyles = createUseStyles<
  'header' | 'panels' | 'buttonIcon' | 'buttonText' | 'nowrap',
  {
    textColor?: string;
    panelBackgroundColor?: string;
    buttonBackgroundColor?: string;
  }
>({
  header: {
    '&': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: '1em',
      color: (props) => props?.textColor,
    },
    '& svg': {
      width: '1.75em',
      height: '1.75em',
      marginRight: '0.25em',
    },
    '& span': {
      lineHeight: '1.2083',
      fontSize: '1.5em',
    },
    '@media (max-width: 767px)': {
      display: 'none',
    },
  },
  panels: {
    '&': {
      display: 'grid',
      gridTemplateAreas: `
        "short short short"
        "add extension ai"
        "info info info"
      `,
      gridTemplateColumns: '1fr 1fr 1fr',
      gap: '1.25em',
      marginTop: '1.5em',
      marginBottom: '1.25em',
      color: (props) => props?.textColor,
    },
    '@media (max-width: 767px)': {
      marginTop: '3.5em',
      marginBottom: '6.25em',
    },
    '@media (max-width: 599px)': {
      gridTemplateAreas: `
        "short" "add" "extension" "ai" "info"
      `,
      gridTemplateColumns: '1fr',
    },
    '& .short': {
      '&': {
        gridArea: 'short',
        paddingBottom: '0.25em',
      },
      '& span': {
        lineHeight: 1.167,
        fontSize: 'clamp(1.5em, 4.69vw, 2.25em)',
      },
      '@media (max-width: 1023px)': {
        '& br': {
          display: 'none',
        },
      },
    },
    '& .panel': {
      '&': {
        boxShadow: '0 0 2px rgba(0, 0, 0, 0.25), 0 2px 5px rgba(0, 0, 0, 0.15)',
        backgroundColor: (props) => props?.panelBackgroundColor,
        borderRadius: '0.3125em',
        padding: '1.5em 1.75em',
      },
      '&.add, &.extension, &.ai': {
        '&': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          paddingLeft: 'clamp(1.25em, 2.73vw, 1.75em)',
          paddingRight: 'clamp(1.25em, 2.73vw, 1.75em)',
        },
        '& p': {
          fontSize: 'clamp(0.9375em, 1.75vw, 1.125em)',
          lineHeight: 1.3,
          margin: 0,
          marginTop: '0.75em',
        },
        '@media (max-width: 599px)': {
          '&': {
            paddingLeft: '1.75em',
            paddingRight: '1.75em',
          },
          '& p': {
            fontSize: '1.125em',
          },
        },
      },
    },
    '& .add': {
      gridArea: 'add',
      cursor: 'pointer',
    },
    '& .extension': {
      gridArea: 'extension',
      cursor: 'pointer',
    },
    '& .ai': {
      gridArea: 'ai',
      cursor: 'pointer',
    },
    '& .info': {
      '&': {
        gridArea: 'info',
      },
      '& ol': {
        paddingLeft: '1.5em',
        fontSize: 'clamp(0.9375em, 1.75vw, 1.125em)',
        lineHeight: 1.3,
      },
      '& p': {
        margin: 0,
        fontSize: 'clamp(0.9375em, 1.75vw, 1.125em)',
        lineHeight: 1.3,
      },
      '& .content': {
        maxWidth: '37em',
      },
    },
  },
  buttonIcon: {
    '&': {
      display: 'flex',
      width: '3.5em',
      height: '3.5em',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: (props) => props?.buttonBackgroundColor,
      borderRadius: '1.75em',
    },
    '& svg': {
      opacity: 0.9,
    },
  },
  buttonText: {
    '&': {
      display: 'flex',
      height: '3em',
      alignItems: 'center',
      paddingLeft: '1.5em',
      paddingRight: '1.5em',
      backgroundColor: (props) => props?.buttonBackgroundColor,
      marginTop: '0.25em',
      marginBottom: '0.25em',
      borderRadius: '1.5em',
    },
    '& span': {
      fontSize: '0.875em',
      opacity: 0.9,
    },
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
});

export default memo(Empty);
