import React, { memo, useCallback } from 'react';
import { type CollectionType } from 'app/entities';
import { Box } from '@mui/joy';

import HorizontalSwiper from 'widgets/HorizontalSwiper';
import CardSmall from 'components/CardSmall';

import { useNavigate } from 'navigation/hooks';
import { dispatcher } from 'store/utils/redux/hooks';

import Edit from '../elements/Edit';
import Delete from '../elements/Delete';

type CollectionsSwiperProps = {
  playlistIds: number[];
  isEditable: boolean;
};

const CollectionsSwiper = (props: CollectionsSwiperProps) => {
  const { playlistIds, isEditable } = props;
  const navigate = useNavigate();

  const handleOpen = useCallback((type: string, id: number) => {
    navigate('Playlist', { resourceId: id });
  }, []);

  const handleEditPlaylist = useCallback((edit: CollectionType) => {
    dispatcher.modal.open('AddorEditCollection', { edit: { id: edit?.id } });
  }, []);

  const handleDeletePlaylist = useCallback((context: CollectionType) => {
    dispatcher.playlist.remove({ id: context.id });
  }, []);

  return (
    <HorizontalSwiper
      className="swiperProfileCollection"
      slidesPerView="auto"
      autoHeight={false}
    >
      {playlistIds.map((playlistId, index) => (
        <CardSmall
          key={playlistId}
          id={playlistId}
          type="playlist"
          infoSize={12}
          onPress={handleOpen}
          toolbar={
            isEditable && (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                mr={2.1}
                mt={2.2}
                gap={0.5}
              >
                <Edit id={playlistId} onPress={handleEditPlaylist} />
                <Delete id={playlistId} onPress={handleDeletePlaylist} />
              </Box>
            )
          }
        />
      ))}
    </HorizontalSwiper>
  );
};

export default memo(CollectionsSwiper);
