import { type NavigateOptions } from 'react-router-native';

import type { ScreenParams } from '../../types';

let method: CallableFunction;

export const setMethod = (func: CallableFunction): void => {
  method = func;
};

export const navigate = <ScreenName extends keyof ScreenParams>(
  ...args: undefined extends ScreenParams[ScreenName]
    ? [name: ScreenName, options?: NavigateOptions]
    : [
        name: ScreenName,
        params: ScreenParams[ScreenName],
        options?: NavigateOptions,
      ]
): void => {
  if (typeof method === 'function') {
    method(...args);
  }
};
