import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

const useCounter = () => {
  const [status, setStatus] = useState<'ready' | 'working' | 'starting'>(
    'ready',
  );
  const [value, setValue] = useState<number | null>(null);
  const ref = useRef<any>(null);
  const intervalRef = useRef<number>(1000);

  useEffect(() => {
    if (status === 'starting' && typeof value === 'number') {
      handleTick(value);
    }
  }, [value, status]);

  useEffect(
    () => () => {
      if (ref.current) {
        clearTimeout(ref.current);
      }
    },
    [],
  );

  const handleTick = useCallback((newValue: number) => {
    setValue(newValue);
    if (newValue > 0) {
      setStatus('working');
      ref.current = setTimeout(() => {
        handleTick(newValue - 1);
      }, intervalRef.current);
    } else {
      setStatus('ready');
    }
  }, []);

  const handleStart = useCallback((points: number, interval = 1000) => {
    handleStop();
    intervalRef.current = interval;
    setStatus('starting');
    setValue(points);
  }, []);

  const handleStop = useCallback(() => {
    if (!ref.current) {
      return;
    }
    clearTimeout(ref.current);
    setValue(null);
    setStatus('ready');
  }, []);

  return useMemo(
    () => ({
      start: handleStart,
      stop: handleStop,
      value,
    }),
    [handleStart, handleStop, value],
  );
};

export default useCounter;
