import { memo, useCallback } from 'react';
import { createUseStyles } from 'react-jss';
import { Button, Tooltip, Typography, useColorScheme } from '@mui/joy';

import Icon from 'ui/Icon';
import { useNavigate } from 'navigation/hooks';

const AddNoteButton = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { colorScheme } = useColorScheme();

  const handleClick = useCallback(() => {
    navigate('Note', { id: 'create' });
  }, [navigate]);

  return (
    <Tooltip
      arrow
      variant="outlined"
      size="sm"
      title="Create a smart document with your library co-pilot"
      disableInteractive
    >
      <Button
        size="sm"
        color="neutral"
        variant="soft"
        className={classes.button}
        onClick={handleClick}
        sx={
          {
            gap: 0.25,
            '--variant-softColor':
              colorScheme === 'light'
                ? 'var(--joy-palette-neutral-100)'
                : 'var(--joy-palette-neutral-900)',
            '--variant-softBg':
              colorScheme === 'light'
                ? 'var(--joy-palette-neutral-700)'
                : 'var(--joy-palette-common-white)',
            '--variant-softHoverBg':
              colorScheme === 'light'
                ? 'var(--joy-palette-neutral-600)'
                : 'var(--joy-palette-neutral-200)',
            '--variant-softActiveColor':
              colorScheme === 'light'
                ? 'var(--joy-palette-neutral-100)'
                : 'var(--joy-palette-neutral-900)',
            '--variant-softActiveBg':
              colorScheme === 'light'
                ? 'var(--joy-palette-neutral-500)'
                : 'var(--joy-palette-neutral-400)',
            '--joy-palette-text-primary': 'current',
          } as any
        }
      >
        <Icon
          name="pen-to-square"
          fw
          weight="regular"
          color="inherit"
          sx={{ mb: 0.125 }}
        />
        <Typography fontWeight={400} fontSize={13} sx={{ mt: 0.125 }}>
          Add note
        </Typography>
      </Button>
    </Tooltip>
  );
};

const useStyles = createUseStyles({
  button: {
    '&[type="button"]': {
      '@media (max-width: 1152px)': {
        '&': {
          width: 32,
          paddingLeft: 0,
          paddingRight: 0,
        },
        '& > p': {
          display: 'none',
        },
      },
    },
  },
});

export default memo(AddNoteButton);
