import { createAction } from '@reduxjs/toolkit';

import { prepareActionDone } from 'store/utils/saga/actions';
import type { UserInput } from 'app/entities';

export const checkAuth = createAction('AUTH/CHECK_AUTH');

export const checkAuthDone = createAction(
  'AUTH/CHECK_AUTH_DONE',
  prepareActionDone,
);

export const doSignIn = createAction(
  'AUTH/DO_SIGN_IN',
  (email: string, password: string) => ({
    payload: {
      email,
      password,
    },
  }),
);

export const setSignInEmail = createAction(
  'AUTH/SET_SIGN_IN_EMAIL',
  (email: string) => ({
    payload: {
      email,
    },
  }),
);

export const doSignInDone = createAction(
  'AUTH/DO_SIGN_IN_DONE',
  prepareActionDone,
);

export const doPasswordResetAgain = createAction(
  'AUTH/DO_PASSWORD_RESET_AGAIN',
  () => ({
    payload: {},
  }),
);

export const doPasswordReset = createAction(
  'AUTH/DO_PASSWORD_RESET',
  (email: string) => ({
    payload: {
      email,
    },
  }),
);

export const doPasswordResetDone = createAction(
  'AUTH/DO_PASSWORD_RESET_DONE',
  prepareActionDone,
);

export const doPasswordUpdate = createAction(
  'AUTH/DO_PASSWORD_UPDATE',
  (token: string, password: string) => ({
    payload: {
      token,
      password,
    },
  }),
);

export const doPasswordUpdateDone = createAction(
  'AUTH/DO_PASSWORD_UPDATE_DONE',
  prepareActionDone,
);

export const doSignUp = createAction(
  'AUTH/DO_SIGN_UP',
  (email: string, password: string) => ({
    payload: {
      email,
      password,
    },
  }),
);

export const doSignUpDone = createAction(
  'AUTH/DO_SIGN_UP_DONE',
  prepareActionDone,
);

export const doOAuth = createAction(
  'AUTH/DO_OAUTH',
  (provider: 'facebook' | 'google' | 'linkedin') => ({
    payload: {
      provider,
    },
  }),
);

export const doOAuthDone = createAction(
  'AUTH/DO_OAUTH_DONE',
  prepareActionDone,
);

export const loginSuccess = createAction('auth/loginSuccess');

export const logOut = createAction('AUTH/LOG_OUT');

export const logOutDone = createAction('AUTH/LOG_OUT_DONE', prepareActionDone);

export const startCredentialListening = createAction(
  'AUTH/START_CREDENTIAL_LISTENING',
);

export const registrationComplete = createAction('AUTH/REGISTRATION_COMPLETE');

export const registrationCompleteDone = createAction(
  'AUTH/REGISTRATION_COMPLETE_DONE',
  (isError = false) => ({
    payload: {
      isError,
    },
  }),
);

export const step1 = createAction('AUTH/STEP1', (data: Partial<UserInput>) => ({
  payload: {
    data,
  },
}));

export const step1Done = createAction('AUTH/STEP1_DONE');
