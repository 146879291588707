import { type Task, type EventChannel, eventChannel, END } from 'redux-saga';
import { put, fork, cancel, delay, take, call } from 'redux-saga/effects';

import Socket from 'lib/Socket';

import * as appStore from 'store/nodes/app';
import * as authStore from 'store/nodes/auth';

import * as actions from '../actions';
import log from '../model/log';

const socket = new Socket();

let socketTask: Task | null = null;

function createWebSocketChannel(ws: Socket) {
  return eventChannel((emit) => {
    const closeListener = ws.addListener('close', () => {
      emit(END);
    });
    const disconnectListener = ws.addListener('disconnect', () => {
      emit(END);
    });
    const openListener = ws.addListener('open', () => {
      emit('OPENED');
    });
    const pingListener = ws.addListener('ping', () => {
      emit('PING');
    });
    const errorListener = ws.addListener('error', () => {
      emit('ERROR');
    });
    const messageListener = ws.addListener('message', (event: MessageEvent) => {
      emit(event.data);
    });
    return () => {
      closeListener.remove();
      disconnectListener.remove();
      openListener.remove();
      pingListener.remove();
      messageListener.remove();
      errorListener.remove();
    };
  });
}

function* initSocketConnection() {
  if (socket.isConnected()) {
    return;
  }
  socket.open({ pingInMs: 15000 /* 15sec */ });

  const socketChannel: EventChannel<string> = yield call(
    createWebSocketChannel,
    socket,
  );
  log('initSocketConnection:: channel created');
  try {
    while (true) {
      const event: string = yield take(socketChannel);
      if (event === 'OPENED') {
        log('initSocketConnection:: socket opened');
        yield put(actions.opened());
        continue;
      }
      if (event === 'PING') {
        log('initSocketConnection:: received ping');
        yield put(actions.sendPing({ message: 'ping' }));
        continue;
      }
      if (event === 'ERROR') {
        log('initSocketConnection:: socket error');
        yield put(actions.error());
        break;
      }
      try {
        const { type, data: payload } = JSON.parse(event);
        log(`initSocketConnection:: message ${type.toUpperCase()}`);
        const action =
          actions.map[`socket/${type}` as unknown as keyof typeof actions.map];
        if (action && typeof action === 'function') {
          yield put((action as CallableFunction)(payload));
        } else {
          yield put({ type: `socket/${type}`, payload });
        }
      } catch (error) {
        console.error((error as Error).message);
      }
    }
  } finally {
    log('initSocketConnection:: socket closed');
    yield put(actions.closed());
  }
}

function* watchReconnectActions() {
  while (true) {
    yield take([
      actions.reconnect.type,
      appStore.actions.prepared.type,
      authStore.actions.doSignInDone.type,
      authStore.actions.logOut.type,
    ]);
    log('watchReconnectActions:: reconnect action received');

    if (socketTask) {
      yield cancel(socketTask);
    }

    socket.close();
    yield delay(200);

    socketTask = yield fork(initSocketConnection);
    log('watchReconnectActions:: socketTask forked');
  }
}

function createOnlineStatusChannel() {
  return eventChannel((emit) => {
    const onlineHandler = () => emit('ONLINE');
    const offlineHandler = () => emit('OFFLINE');

    window.addEventListener('online', onlineHandler);
    window.addEventListener('offline', offlineHandler);

    return () => {
      window.removeEventListener('online', onlineHandler);
      window.removeEventListener('offline', offlineHandler);
    };
  });
}

function* watchOnlineStatus() {
  const channel = createOnlineStatusChannel();
  try {
    while (true) {
      const status: 'ONLINE' | 'OFFLINE' = yield take(channel);
      if (status === 'ONLINE') {
        log('Browser is online');
        yield put(actions.reconnect());
      } else if (status === 'OFFLINE') {
        log('Browser is offline');
      }
    }
  } finally {
    channel.close();
  }
}

export const config = {
  method: 'fork',
};

export function* func() {
  yield fork(watchReconnectActions);
  yield fork(watchOnlineStatus);
}
