import { createSelector } from '@reduxjs/toolkit';
import { type DefaultRootState } from 'react-redux';
import md5 from 'blueimp-md5';

const selector = createSelector(
  (state: DefaultRootState) => state.content.list.filters,
  (state: DefaultRootState) => state.content.list.meta,
  (filters, meta) => ({
    isConsistent:
      meta.resultHash ===
      md5(JSON.stringify({ type: filters.type.value || 'all' })),
    firstPage: {
      isLoading: meta.isFirstPageLoading,
      isLoaded: meta.isFirstPageLoaded,
    },
    nextPage: {
      isLoading: meta.isNextPageLoading,
      isLoaded: meta.isNextPageLoaded,
    },
  }),
);

export const listMeta = (state: DefaultRootState) => selector(state);
