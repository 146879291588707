import React from 'react';

import type { IComponentSVG } from '../types';

class IconSVG {
  public readonly source: IComponentSVG;

  public readonly colorProperty: string;

  public readonly scale: number;

  constructor(
    source: React.FC<React.SVGProps<SVGSVGElement>>,
    colorProperty: 'fill' | 'stroke',
    scale?: number,
  ) {
    this.source = source;
    this.colorProperty = colorProperty;
    this.scale = scale || 1;
  }
}

export default IconSVG;
