import { type FC, type ReactNode, memo } from 'react';
import { isEqual } from 'lodash';
import { ConfigContext, type ConfigType } from './ConfigContext';

type ConfigProviderProps = {
  children: ReactNode;
  value: ConfigType;
};

const ConfigProvider: FC<ConfigProviderProps> = (props) => {
  const { children, value } = props;

  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
};

export default memo(ConfigProvider, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps),
);
