import { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import {
  type NativeSyntheticEvent,
  type TextInputFocusEventData,
  type ViewStyle,
  type StyleProp,
  type TextStyle,
  StyleSheet,
} from 'react-native';
import DropDownPicker, {
  type ThemeNameType,
} from 'react-native-dropdown-picker';

import * as api from 'services/api';

import { useColorScheme, useThemeColor, useFontProps } from 'hooks';

import { unit } from 'utils';
import { Text, View } from 'components/Themed';
import { useField } from 'components/Form';
import Alert from 'components/Alert';

import Badge from './elements/Badge';
import ListEmptyComponent from './elements/ListEmptyComponent';
import DropDownPickerThemes from './elements/themes';

DropDownPicker.addTheme('DARK', DropDownPickerThemes.DARK);
DropDownPicker.addTheme('LIGHT', DropDownPickerThemes.LIGHT);

type Item = {
  label: string;
  value: string;
};

const defaultProps = {
  readonly: false as boolean,
  border: 'half' as 'none' | 'half' | 'full',
};

type TagsInputProps = {
  style?: StyleProp<ViewStyle>;
  value?: string[] | null;
  label?: string;
  name?: string;
  placeholder?: string;
  error?: string;
  readonly?: boolean;
  disabled?: boolean;
  border?: 'none' | 'half' | 'full';
  onChange?: (value: any) => void;
  onBlur?: (event: NativeSyntheticEvent<TextInputFocusEventData>) => void;
  onFocus?: (event: NativeSyntheticEvent<TextInputFocusEventData>) => void;
} & typeof defaultProps;

export const TagsInput = (props: TagsInputProps) => {
  const {
    style,
    value,
    label,
    name,
    placeholder,
    error,
    readonly,
    disabled,
    border,
    onChange,
    onBlur,
    onFocus,
  } = props;

  const batchRef = useRef<any>();

  const theme = useColorScheme();
  const textColor = useThemeColor({ light: '#000000', dark: '#ffffff' });
  const itemTextColor = useThemeColor({ light: '#000000', dark: '#ffffff' });
  const itemSelectedTextColor = useThemeColor({
    light: '#000000',
    dark: '#ffffff',
  });
  const readonlyTextColor = useThemeColor({
    light: '#515151',
    dark: '#E6E4EA',
  });
  const disabledTextColor = useThemeColor({
    light: '#929292',
    dark: '#77767E',
  });
  const backgroundColor = useThemeColor({
    light: '#ffffffff',
    dark: '#000000',
  });
  const backgroundColorDisabled = useThemeColor({
    light: '#d2d2d2',
    dark: '#232326',
  });
  const dropDownBackgroundColor = useThemeColor({
    light: '#d2d2d2',
    dark: '#232326',
  });

  const field = useField(name);

  const onChangeFinal = onChange || field?.onValueChange;
  const onBlurFinal = onBlur || field?.onBlur;
  const onFocusFinal = onFocus;
  const valueFinal = useMemo<string[]>(
    () => (value || field?.value || []) as string[],
    [field?.value, value],
  );
  const errorFinal = error || field?.error;

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<Item[]>(
    valueFinal.map((text) => ({ value: text, label: text })),
  );
  const [dropDownValue, setDropDownValue] = useState<string[] | any>(
    valueFinal,
  );

  useEffect(() => {
    if (disabled) {
      setOpen(false);
    }
  }, [disabled]);

  const themeTransform: Record<'light' | 'dark', ThemeNameType> = {
    light: 'LIGHT',
    dark: 'DARK',
  };
  const themeFinal = themeTransform[theme];

  const handleChange = useCallback(
    (newValue: any) => {
      onChangeFinal?.(newValue);
    },
    [onChangeFinal],
  );

  const handleSearch = useCallback(
    (query: string) => {
      if (batchRef.current) {
        clearTimeout(batchRef.current);
      }
      batchRef.current = setTimeout(() => {
        setLoading(true);
        api.resource.skills.search(query, 0, 100).then((response) => {
          if (response.error || !response.data) {
            Alert.error(response.error?.message || 'Server error #2');
            setLoading(false);
            setItems([]);
            return;
          }
          const newTags = [
            ...new Set([
              ...valueFinal,
              ...response.data.map((item: any) => item?.text),
            ]),
          ];
          const newItems: Item[] = newTags.map((text) => ({
            value: text,
            label: text,
          }));
          setLoading(false);
          setItems(newItems);
        });
      }, 300);
    },
    [valueFinal],
  );

  const radiusMap = {
    none: 0,
    half: 10,
    full: 44 / 2,
  };
  const borderRadius = radiusMap[border];

  const labelStyles = useFontProps({
    size: 17,
    weight: 'regular',
    align: 'left',
  });

  const styleFinal = useMemo(
    () => ({
      ...StyleSheet.flatten(style),
      zIndex: open ? 1000 : undefined,
      opacity: disabled ? 0.75 : 1,
    }),
    [open, disabled],
  );

  const containerStyleFinal = useMemo(
    (): StyleProp<ViewStyle> => ({
      ...StyleSheet.flatten(styles.container),
      backgroundColor: disabled ? backgroundColorDisabled : backgroundColor,
      borderRadius,
    }),
    [disabled, backgroundColorDisabled, backgroundColor, borderRadius],
  );

  const labelStyleFinal = useMemo(() => {
    const result: StyleProp<TextStyle> = {
      ...StyleSheet.flatten(styles.selectedLabel),
      ...labelStyles,
      color: textColor,
    };
    if (readonly) {
      result.color = readonlyTextColor;
    }
    if (disabled) {
      result.color = disabledTextColor;
    }
    return result;
  }, [disabled, labelStyles, borderRadius]);

  return (
    <View style={styleFinal} pointerEvents={disabled ? 'none' : 'auto'}>
      {Boolean(label) && (
        <Text
          size={15}
          weight="regular"
          lightColor="#797979"
          darkColor="#B8B6BF"
          style={styles.label}
        >
          {label}
        </Text>
      )}
      <DropDownPicker
        loading={loading}
        open={open}
        theme={themeFinal}
        value={dropDownValue}
        items={items}
        setOpen={setOpen}
        setValue={setDropDownValue}
        searchable
        onChangeSearchText={handleSearch}
        disableLocalSearch
        mode="BADGE"
        disabled={disabled}
        multiple
        max={6}
        onChangeValue={handleChange}
        containerStyle={containerStyleFinal}
        modalProps={{
          animationType: 'slide',
          style: {},
        }}
        modalContentContainerStyle={{
          backgroundColor: dropDownBackgroundColor,
          paddingVertical: unit(32),
          paddingLeft: unit(16),
          paddingRight: unit(16),
        }}
        style={styles.input}
        labelStyle={labelStyleFinal}
        dropDownContainerStyle={[
          styles.dropDownContainer,
          {
            backgroundColor: dropDownBackgroundColor,
          },
        ]}
        closeOnBackPressed
        listItemContainerStyle={styles.itemContainer}
        listItemLabelStyle={[
          styles.itemText,
          {
            color: itemTextColor,
          },
        ]}
        selectedItemContainerStyle={[styles.itemSelectedContainer, {}]}
        selectedItemLabelStyle={[
          styles.itemSelectedText,
          {
            color: itemSelectedTextColor,
          },
        ]}
        placeholder={placeholder}
        renderBadgeItem={Badge}
        ListEmptyComponent={ListEmptyComponent}
      />
    </View>
  );
};

TagsInput.defaultProps = defaultProps;

const styles = StyleSheet.create({
  cover: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 100,
    height: 100,
    backgroundColor: '#ff0000',
  },
  label: {
    marginBottom: unit(6),
    marginLeft: unit(6),
  },
  container: {
    width: '100%',
    minHeight: unit(44),
    paddingTop: unit(6),
    paddingBottom: unit(8),
  },
  input: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: unit(16),
    flex: 1,
  },
  selectedLabel: {},
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: unit(44),
    paddingHorizontal: unit(16),
  },
  itemText: {},
  itemSelectedContainer: {},
  itemSelectedText: {},
  dropDownContainer: {
    marginVertical: unit(8),
    borderRadius: unit(8),
    overflow: 'hidden',
    borderWidth: 0,
  },
});

export default TagsInput;
