import {
  type ReactElement,
  memo,
  useMemo,
  useCallback,
  type MouseEvent,
} from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import pluralize from 'pluralize';

import { useThemeColor } from 'hooks';
import { unit } from 'utils';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as userStore from 'store/nodes/user';

import { View } from 'components/Themed';

import { Avatar, Box, Button, Typography } from '@mui/joy';

type SlimProfileItemProps = {
  id: number;
  onPress?: (login: string, id: number) => void;
};

const SlimProfileItem = (props: SlimProfileItemProps): ReactElement => {
  const { id, onPress } = props;

  const dispatcher = useDispatcher();
  const borderBottomColor = useThemeColor({
    light: '#a6a6a6',
    dark: '#4E4E53',
  });
  const imageBackground = useThemeColor({ light: '#a6a6a6', dark: '#4E4E53' });

  const userProfile = useSelector(userStore.selectors.getById(id));
  const isSubscripting = useSelector(
    userStore.selectors.isSubscripting(userProfile?.login),
  );

  const handlePress = useCallback(() => {
    if (userProfile?.login && id) {
      onPress?.(userProfile.login, id);
    }
  }, [userProfile?.login, id, onPress]);

  const nameFinal = useMemo(() => {
    if (!userProfile?.name && !userProfile?.surname) {
      return 'Guest';
    }
    if (!userProfile?.surname) {
      return userProfile?.name;
    }
    return `${userProfile?.name} ${userProfile?.surname}`;
  }, [userProfile?.name, userProfile?.surname]);

  const handleSubscribe = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      if (!userProfile?.login || userProfile.isMy) {
        return;
      }
      dispatcher.user.subscribe(userProfile?.login);
    },
    [userProfile?.login],
  );

  const handleUnsubscribe = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      if (!userProfile?.login || userProfile.isMy) {
        return;
      }
      dispatcher.user.unsubscribe(userProfile?.login);
    },
    [userProfile?.login],
  );

  const renderSocial = useMemo(
    () => (
      <Box
        mt={0.5}
        sx={{
          display: 'flex',
          alignItems: 'center',
          '@media (max-width: 424px)': {
            flexDirection: 'column',
            alignItems: 'flex-start',
          },
        }}
      >
        <Typography
          fontSize={12}
          fontWeight={400}
        >{`${userProfile?.subscribersCount} ${pluralize('Follower', userProfile?.subscribersCount)}`}</Typography>
        <Box
          sx={{
            '@media (max-width: 424px)': {
              display: 'none',
            },
          }}
        >
          <View style={styles.dot} lightColor="#000000" darkColor="#ffffff" />
        </Box>
        <Typography
          fontSize={12}
          fontWeight={400}
        >{`${userProfile?.subscriptionsCount} Following`}</Typography>
      </Box>
    ),
    [userProfile?.subscribersCount, userProfile?.subscriptionsCount],
  );

  const renderBody = useMemo(
    () => (
      <>
        <Avatar
          size="lg"
          sx={{
            backgroundColor: (theme) => theme.palette.background.level2,
          }}
          src={userProfile?.photo?.url}
        />
        <View style={styles.content}>
          <Box>
            <Typography fontSize={17} fontWeight={500}>
              {nameFinal}
            </Typography>
            {renderSocial}
          </Box>
          {!!userProfile?.profession && (
            <Typography color="neutral" fontSize={12} fontWeight={400}>
              {userProfile?.profession}
            </Typography>
          )}
        </View>
        {!userProfile?.isSubscribed && (
          <Button
            loading={isSubscripting}
            sx={{
              alignSelf: 'center',
            }}
            onClick={handleSubscribe}
          >
            <Typography
              sx={{
                color: (theme) => theme.palette.neutral['100'],
              }}
              fontSize={12}
              fontWeight={400}
            >
              Follow
            </Typography>
          </Button>
        )}
        {!!userProfile?.isSubscribed && (
          <Button
            loading={isSubscripting}
            onClick={handleUnsubscribe}
            variant="outlined"
            color="neutral"
            sx={{
              alignSelf: 'center',
              '&:hover': {
                background: (theme) => theme.palette.background.level2,
              },
              '@media (hover: none)': {
                '&:hover': {
                  background: (theme) => theme.palette.background.level1,
                },
              },
              '&:active': {
                background: (theme) => theme.palette.background.level3,
              },
            }}
          >
            <Typography fontSize={12} fontWeight={400}>
              Following
            </Typography>
          </Button>
        )}
      </>
    ),
    [
      renderSocial,
      nameFinal,
      isSubscripting,
      userProfile?.photo,
      userProfile?.profession,
      userProfile?.isSubscribed,
    ],
  );

  if (!userProfile) {
    return (
      <View style={[styles.SlimProfileItem, { borderBottomColor }]}>
        <View style={[styles.image, { backgroundColor: imageBackground }]} />
        <View style={styles.content}>
          <View
            style={styles.wireframeTitle}
            lightColor="#d2d2d2"
            darkColor="#232326"
          />
          <View
            style={styles.wireframeTitle}
            lightColor="#d2d2d2"
            darkColor="#232326"
          />
          <View
            style={styles.wireframeDescription}
            lightColor="#d2d2d2"
            darkColor="#232326"
          />
        </View>
      </View>
    );
  }

  return (
    <View style={[styles.SlimProfileItem, { borderBottomColor }]}>
      {!onPress && renderBody}
      {!!onPress && (
        <TouchableOpacity
          style={styles.touchable}
          onPress={handlePress}
          activeOpacity={0.8}
        >
          {renderBody}
        </TouchableOpacity>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  SlimProfileItem: {
    flexDirection: 'row',
    paddingTop: unit(16),
    paddingBottom: unit(16),
    borderBottomWidth: 1,
  },
  touchable: {
    flexDirection: 'row',
    flex: 1,
  },
  image: {
    width: unit(48),
    height: unit(48),
    borderRadius: unit(24),
  },
  content: {
    flex: 1,
    marginHorizontal: unit(10),
    justifyContent: 'center',
  },
  info: {
    marginTop: unit(4),
  },
  wireframeTitle: {
    height: unit(14),
    marginBottom: unit(8),
    width: '100%',
  },
  wireframeDescription: {
    height: unit(14),
    width: '70%',
  },
  social: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  dot: {
    width: 2,
    height: 2,
    borderRadius: 1,
    marginHorizontal: unit(8),
  },
});

export default memo(SlimProfileItem);
