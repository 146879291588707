import { createReducer } from '@reduxjs/toolkit';
import md5 from 'blueimp-md5';

import { guard } from 'utils';

import type { SearchStore } from './types';
import * as actions from './actions';

const initialState: SearchStore = {
  data: {},
  list: {
    sequence: [],
    map: {},
    paginationInfo: {
      hasNext: false,
      nextCursor: null,
    },
    meta: {
      status: null,
      resultWithFilterHash: md5(
        JSON.stringify({ text: '', type: 'all', tags: [] }),
      ),
      isFirstPageLoading: false,
      isFirstPageLoaded: false,
      isNextPageLoading: false,
      isNextPageLoaded: false,
    },
  },
  copilot: {
    key: null,
    text: null,
    status: 'empty',
  },
  filter: {
    method: 'Web',
    text: '',
    typeEnabledIds: {
      GlobalFullText: ['all'],
      GlobalTitle: ['all'],
      LibraryFullText: [],
      LibraryTitle: [],
      Web: [],
    },
    tags: [],
  },
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(actions.clear, (state) => {
      const cloned = JSON.parse(JSON.stringify(initialState));
      state.list.sequence = cloned.list.sequence;
      state.list.map = cloned.list.map;
      state.list.paginationInfo = cloned.list.paginationInfo;
      state.list.meta = cloned.list.meta;
      state.copilot = cloned.copilot;
      state.filter = cloned.filter;
    })
    .addCase(actions.setFilterTypeValue, (state, action) => {
      const ids = !Array.isArray(action.payload.id)
        ? [action.payload.id]
        : action.payload.id;
      const { method } = state.filter;
      state.filter.typeEnabledIds[method] = [...ids];
    })
    .addCase(actions.toggleFilterTypeValue, (state, action) => {
      const ids = !Array.isArray(action.payload.id)
        ? [action.payload.id]
        : action.payload.id;
      const { method } = state.filter;
      ids.forEach((id) => {
        const index = state.filter.typeEnabledIds?.[method].findIndex(
          (itemId) => itemId === id,
        );
        if (index === -1) {
          state.filter.typeEnabledIds?.[method].push(id);
        } else {
          state.filter.typeEnabledIds?.[method].splice(index, 1);
        }
      });
    })
    .addCase(actions.setFilterText, (state, action) => {
      state.filter.text = action.payload.value;
    })
    .addCase(actions.setFilterTags, (state, action) => {
      state.filter.tags = action.payload.value;
    })
    .addCase(actions.setMethod, (state, action) => {
      state.filter.method = action.payload
        .value as SearchStore['filter']['method'];
    })
    .addCase(actions.loadNextPage, (state, action) => {
      const { reload, setMethod } = action.payload;
      if (setMethod) {
        state.filter.method = setMethod as SearchStore['filter']['method'];
      }
      if (reload) {
        state.list.meta.status = 'loading-first';
        state.list.meta.isFirstPageLoading = true;
      } else {
        state.list.meta.status = 'loading-next';
        state.list.meta.isNextPageLoading = true;
      }
    })
    .addCase(actions.loadNextPageDone, (state, action) => {
      const { reload, status } = action.payload;
      state.list.meta.isFirstPageLoading = false;
      state.list.meta.isNextPageLoading = false;
      if (reload) {
        state.list.meta.isFirstPageLoaded = true;
      } else {
        state.list.meta.isNextPageLoaded = true;
      }
      state.list.meta.resultWithFilterHash = md5(JSON.stringify(state.filter));
      state.list.meta.status = status || null;
    })
    .addCase(actions.addPage, (state, action) => {
      if (!action.payload.items) {
        return;
      }
      if (action.meta.reload) {
        state.list.sequence = [];
        state.list.map = {};
      }
      action.payload.items.forEach((item) => {
        const { element, searchMeta } = item;
        if (element.id in state.list.map) {
          return;
        }
        let type: 'material' | 'collection' | 'RagMaterial' | 'unknown' =
          'unknown';
        if (guard.isMaterial(element)) {
          type = 'material';
        }
        if (guard.isCollection(element)) {
          type = 'collection';
        }
        if (guard.isRagMaterial(element)) {
          type = 'RagMaterial';
        }
        state.list.sequence.push({
          id: element.id,
          searchMeta,
          type,
        });
        state.list.map[element.id] = state.list.sequence.length - 1;
      });
      state.list.paginationInfo.nextCursor =
        action.payload.paginationInfo.nextCursor;
      state.list.paginationInfo.hasNext = action.payload.paginationInfo.hasNext;
    })
    .addCase(actions.setRagMaterial, (state, action) => {
      if (!action.payload.items) {
        return;
      }
      action.payload.items.forEach((data) => {
        state.data[data.id] = {
          ...data,
        };
      });
    })
    .addCase(actions.logOut, (state) => {
      const closed = JSON.parse(JSON.stringify(state));
      state.list = closed.list;
    }),
);
