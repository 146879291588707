import { memo, useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Box, Button, ModalClose, Sheet, Typography } from '@mui/joy';
import { createUseStyles } from 'react-jss';

const Disclaimer = () => {
  const [isOpen, setOpen] = useState(
    sessionStorage.getItem('mobile/disclaimer') !== 'closed',
  );
  const [isMount, setMount] = useState(
    sessionStorage.getItem('mobile/disclaimer') !== 'closed',
  );

  useStyles();

  useEffect(() => {
    if (isOpen) {
      document.body.setAttribute('data-disclaimer', 'true');
    }
    if (!isOpen) {
      document.body.removeAttribute('data-disclaimer');
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      setMount(true);
    }
    let timeout: any;
    if (!isOpen) {
      timeout = setTimeout(() => setMount(false), 300);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isOpen]);

  const handleClose = useCallback(() => {
    sessionStorage.setItem('mobile/disclaimer', 'closed');
    setOpen(false);
  }, []);

  if (!isMount) {
    return null;
  }

  return createPortal(
    <Box
      position="fixed"
      bottom={0}
      left={0}
      right={0}
      top={0}
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      justifyContent="flex-end"
      bgcolor={(theme) =>
        `${theme.palette.background.body}${isOpen ? 'A0' : 0}`
      }
      sx={{
        '&': {
          pointerEvents: isOpen ? 'all' : 'none',
          transition: '300ms background-color ease',
        },
        '@media (min-width: 600px)': {
          display: 'none',
        },
      }}
    >
      <Sheet
        className="Disclaimer"
        variant="soft"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderTopRightRadius: (theme) =>
            `${parseInt(theme.radius.xl, 10) * 2}px`,
          borderTopLeftRadius: (theme) =>
            `${parseInt(theme.radius.xl, 10) * 2}px`,
          pt: 4,
          px: 4,
          pb: 8,
          transition: '300ms transform ease',
          transform: `translateY(${isOpen ? '0' : '100%'})`,
        }}
        aria-level={2}
      >
        <Typography>
          Mobile version is still under construction, you&apos;ll get a way
          better experience on desktop.
        </Typography>
        <Button
          onClick={handleClose}
          size="sm"
          sx={{ alignSelf: 'center', mt: 4 }}
        >
          continue here
        </Button>
        <ModalClose onClick={handleClose} />
      </Sheet>
    </Box>,
    document.body,
  );
};

const useStyles = createUseStyles({
  '@global': {
    '@media (max-width: 599px)': {
      'body[data-disclaimer]': {
        overflow: 'hidden',
      },
    },
  },
});

export default memo(Disclaimer);
