import { memo, forwardRef, useCallback, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import cn from 'classnames';
import { isEqual } from 'lodash';

import { type LexicalEditor, TextNode, ParagraphNode } from 'lexical';
import { ListItemNode, ListNode } from '@lexical/list';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { EditorRefPlugin } from '@lexical/react/LexicalEditorRefPlugin';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';

import useMethods, { type EditorMethods } from './model/useMethods';

import ToolbarPlugin from './plugins/ToolbarPlugin';
import FocusAndBlurPlugin from './plugins/FocusAndBlurPlugin';
import HtmlChangePlugin from './plugins/HtmlChangePlugin';
import TreeViewPlugin from './plugins/TreeViewPlugin';

import ExampleTheme from './styles/Theme';

export { type EditorMethods };

const placeholder = '';

const editorConfig = {
  namespace: 'Editor',
  nodes: [
    TextNode,
    ParagraphNode,
    HeadingNode,
    QuoteNode,
    ListNode,
    ListItemNode,
    AutoLinkNode,
    LinkNode,
  ],
  onError(error: Error) {
    throw error;
  },
  theme: ExampleTheme,
};

export type EditorProps = {
  onFocus?: (event: FocusEvent) => void;
  onBlur?: (event: FocusEvent) => void;
  onHtmlChanged?: (html: string) => void;
  slotProps: {
    container?: {
      className?: string;
    };
    inner?: {
      className?: string;
    };
    input?: {
      className?: string;
    };
  };
};

const Editor = forwardRef<EditorMethods, EditorProps>((props, forwardedRef) => {
  const { onFocus, onBlur, onHtmlChanged, slotProps } = props;

  const classes = useStyles();
  const editorRef = useRef<LexicalEditor>(null);

  const handleFocus = useCallback(
    (event: FocusEvent) => {
      document.body
        .querySelector(`.${classes.input}`)
        ?.setAttribute('data-focused', 'true');
      onFocus?.(event);
    },
    [onFocus],
  );

  const handleBlur = useCallback(
    (event: FocusEvent) => {
      document.body
        .querySelector(`.${classes.input}`)
        ?.removeAttribute('data-focused');
      onBlur?.(event);
    },
    [onBlur],
  );

  useMethods(editorRef, forwardedRef);

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className={cn(classes.container, slotProps?.container?.className)}>
        <ToolbarPlugin />
        <div className={cn(classes.inner, slotProps?.inner?.className)}>
          <RichTextPlugin
            contentEditable={
              <ContentEditable
                className={cn(classes.input, slotProps?.input?.className)}
                aria-placeholder={placeholder}
                placeholder={
                  <div className="editor-placeholder">{placeholder}</div>
                }
              />
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
          <HistoryPlugin />
          <AutoFocusPlugin />
          {/* <TreeViewPlugin /> */}
          <ListPlugin />
          <EditorRefPlugin editorRef={editorRef} />
          <FocusAndBlurPlugin onFocus={handleFocus} onBlur={handleBlur} />
          <HtmlChangePlugin onChange={onHtmlChanged} />
        </div>
      </div>
    </LexicalComposer>
  );
});

const useStyles = createUseStyles({
  container: {},
  inner: {
    '& .ed-node-inserted': {
      '[data-joy-color-scheme="light"] &': {
        color: 'transparent',
      },
      '[data-joy-color-scheme="dark"] &': {
        color: 'transparent',
      },
      '[data-joy-color-scheme] &--out': {
        transition: '300ms color ease',
        color: 'inherit',
      },
    },
  },
  input: {
    outline: 'none',
  },
});

export default memo(Editor, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps),
);
