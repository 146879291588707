import {
  type SagaReturnType,
  takeEvery,
  put,
  cancel,
} from 'redux-saga/effects';

import type { CollectionType, MaterialType, UserType } from 'app/entities';

import Storage from 'lib/Storage';
import { requestCursorModifier } from 'utils';
import * as api from 'services/api';

import { call } from 'store/utils/saga/effects';
import { checkUnauthorized } from 'store/utils/credentials';
import * as contentStore from 'store/nodes/content';
import * as playlistStore from 'store/nodes/playlist';

import Alert from 'components/Alert';

import network from 'lib/network';
import * as actions from '../actions';

export const config = {
  action: [actions.loadProfile.type, actions.loadProfileOptimistic.type],
  method: takeEvery,
};

const loadFromCache = (): UserType | null => {
  const key = `@profile/${api.credentials.sessionId()}`;
  let cache: {
    value: UserType;
    releaseTime: string;
  } | null = null;

  try {
    const buffer = Storage.get(key);
    if (buffer) {
      cache = JSON.parse(buffer);
    }
  } catch (error) {
    cache = null;
  }

  if (
    !cache ||
    cache.releaseTime !== process.env.RELEASE_TIME ||
    cache?.value?.id !== api.credentials.sessionId()
  ) {
    return null;
  }

  return cache.value;
};

const saveToCache = (data: UserType): void => {
  const key = `@profile/${api.credentials.sessionId()}`;
  Storage.set(
    key,
    JSON.stringify({
      value: data,
      releaseTime: process.env.RELEASE_TIME,
    }),
  );
};

export function* func(
  action:
    | SagaReturnType<typeof actions.loadProfile>
    | SagaReturnType<typeof actions.loadProfileOptimistic>,
) {
  const { payload } = action;
  const { login } = payload;
  const isMy = login === 'my';

  let data: UserType | null = null;

  if (isMy) {
    data = yield* call(() => loadFromCache());
  }

  if (!payload.optimistic || !data) {
    const profile = yield* call(() => api.resource.user.profile(login));
    yield checkUnauthorized(profile);
    const [playlists, contents, library] = yield* call(() =>
      Promise.all([
        network
          .request<
            CollectionType[]
          >(`/share/user/${profile.data?.id}/playlists`)
          .get(),
        api.resource.user.content.list(profile.data?.login, { pageSize: 100 }),
        isMy
          ? network
              .request<(MaterialType | CollectionType)[]>('/user/library')
              .query({ pageSize: 1 })
              .get(requestCursorModifier())
          : null,
      ]),
    );

    if (
      profile.error ||
      !profile.data ||
      playlists.hasError ||
      !playlists.data
    ) {
      Alert.error(
        (Array.isArray(profile.error)
          ? profile.error[0]?.message
          : profile.error?.message) || 'Server error #24',
      );
      yield put(actions.loadProfileDone(login));
      yield cancel();
      return;
    }
    const result: UserType = {
      ...profile.data,
      isLibraryEmpty:
        library === null ? null : (library.data?.items.length === 0 ?? null),
      playlistIds: playlists.data?.map((item) => item.id) || [],
      contentIds: contents.data?.items?.map((item) => item.id) || [],
    };
    if (isMy) {
      yield call(() => saveToCache(result));
    }
    yield put(actions.setProfile({ data: result }));
    yield put(contentStore.actions.setItem(contents.data?.items || []));
    if (Array.isArray(playlists.data) && playlists.data.length > 0) {
      yield put(playlistStore.actions.setItem({ data: playlists.data }));
    }
    yield put(actions.loadProfileDone(login));
    yield cancel();
    return;
  }

  yield put(actions.setProfile({ data }));
  yield put(actions.loadProfileDone(login));

  const profile = yield* call(() => api.resource.user.profile(login));
  yield* checkUnauthorized(profile);
  const [playlists, contents, library] = yield* call(() =>
    Promise.all([
      network
        .request<CollectionType[]>(`/share/user/${profile.data?.id}/playlists`)
        .query({ pageSize: 50 })
        .get(),
      api.resource.user.content.list(profile.data?.login, { pageSize: 50 }),
      isMy
        ? network
            .request<(MaterialType | CollectionType)[]>('/user/library')
            .query({ pageSize: 1 })
            .get(requestCursorModifier())
        : null,
    ]),
  );

  if (profile.error || !profile.data || playlists.hasError || !playlists.data) {
    yield cancel();
    return;
  }
  const result: UserType = {
    ...profile.data,
    isLibraryEmpty:
      library === null ? null : (library.data?.items.length === 0 ?? null),
    playlistIds: playlists.data?.map((item) => item.id) || [],
    contentIds: contents.data?.items?.map((item) => item.id) || [],
  };
  if (isMy) {
    yield* call(() => saveToCache(result));
  }
  yield put(actions.setProfile({ data: result }));
  yield put(contentStore.actions.setItem(contents.data?.items || []));
  if (Array.isArray(playlists.data) && playlists.data.length > 0) {
    yield put(playlistStore.actions.setItem({ data: playlists.data }));
  }
}
