import Request from './Request';
import RequestContext from './RequestContext';

type Options = {
  baseUrl?: string;
};

class Network {
  private baseUrl = '';

  private listeners: {
    [key: string]: Array<(context: RequestContext) => void>;
  } = {};

  public request = <T = Record<string, any> | string | number>(
    endpoint: string,
    options: Options = {},
  ) => {
    const context = new RequestContext();

    const requestInstance = new Request<T>(
      options?.baseUrl || this.baseUrl,
      endpoint,
      options,
      context,
    );

    this.emit('REQUEST_INIT', context);

    return requestInstance;
  };

  public on = (
    event: 'REQUEST_INIT',
    callback: (context: RequestContext) => void,
  ) => {
    if (!this.listeners[event]) {
      this.listeners[event] = [];
    }

    this.listeners[event].push(callback);
  };

  public off = (
    event: 'REQUEST_INIT',
    callback: (context: RequestContext) => void,
  ) => {
    if (this.listeners[event]) {
      this.listeners[event] = this.listeners[event].filter(
        (listener) => listener !== callback,
      );
    }
  };

  private emit = (event: 'REQUEST_INIT', context: RequestContext) => {
    if (this.listeners[event]) {
      this.listeners[event].forEach((listener) => {
        listener(context);
      });
    }
  };

  public setBaseUrl = (value: string) => {
    this.baseUrl = value;
  };
}

export default new Network();
