import { useCallback } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';

import { Text, View } from 'components/Themed';
import { useNavigate } from 'navigation/hooks';

const NotFound = () => {
  const navigate = useNavigate();

  const handleToHome = useCallback(() => {
    navigate('Library', { interaction: 'all' });
  }, []);

  return (
    <View style={styles.container}>
      <Text size={20} weight="semi">
        This screen doesn&apos;t exist.
      </Text>
      <TouchableOpacity
        onPress={handleToHome}
        style={styles.link}
        activeOpacity={0.8}
      >
        <Text size={15} style={styles.linkText}>
          Go to home screen!
        </Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    color: '#2e78b7',
  },
});

export default NotFound;
