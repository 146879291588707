import { takeLatest, put, cancel } from 'redux-saga/effects';

import type { MaterialType, CollectionType } from 'app/entities';

import { requestCursorModifier } from 'utils';
import network from 'lib/network';

import { call, select } from 'store/utils/saga/effects';
import * as userStore from 'store/nodes/user';

import * as actions from '../actions';

export const config = {
  action: actions.loadMeta.type,
  method: takeLatest,
};

export function* func() {
  const user = yield* select(userStore.selectors.getMy);
  if (!user) {
    yield put(
      actions.loadMetaDone({
        hasAnyItems: false,
      }),
    );
    yield cancel();
    return;
  }

  const { data, errors } = yield* call(() =>
    network
      .request<(MaterialType | CollectionType)[]>('/user/library')
      .query({ pageSize: 1 })
      .get(requestCursorModifier()),
  );
  if (errors || !data) {
    yield put(
      actions.loadMetaDone({
        hasAnyItems: false,
      }),
    );
    yield cancel();
    return;
  }
  yield put(
    actions.loadMetaDone({
      hasAnyItems: !!data.items.length,
    }),
  );
}
