import { memo, useMemo } from 'react';
import cn from 'classnames';
import { createUseStyles } from 'react-jss';
import { Box, type BoxProps, useColorScheme, useTheme } from '@mui/joy';

type AnimateType =
  | 'beat'
  | 'beat-fade'
  | 'bounce'
  | 'fade'
  | 'flip'
  | 'shake'
  | 'spin'
  | 'spin-reverse'
  | 'spin-pulse';

type SizeType =
  | 'default'
  | '2xs'
  | 'xs'
  | 'sm'
  | 'lg'
  | 'xl'
  | '2xl'
  | '1x'
  | '2x'
  | '3x'
  | '4x'
  | '5x'
  | '6x'
  | '7x'
  | '8x'
  | '9x'
  | '10x';

const defaultProps = {
  family: 'classic',
  size: 'default' as SizeType,
  color: 'primary',
};

type BasicType = {
  name: string;
  size?: SizeType;
  color?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'icon'
    | 'inherit'
    | 'current'
    | string;
  animate?: AnimateType;
  fw?: boolean | number;
} & Omit<BoxProps, 'component' | 'className'>;

type IconKitProps = {
  family?: 'kit';
  weight?: undefined;
} & BasicType;

type IconBrandsProps = {
  family?: 'brands';
  weight?: undefined;
} & BasicType;

type IconClassicProps = {
  family?: 'classic';
  weight?: 'solid' | 'regular' | 'light' | 'duotone' | 'thin';
} & BasicType;

type IconSharpProps = {
  family?: 'sharp';
  weight?: 'solid' | 'regular' | 'light';
} & BasicType;

const getAnimate = (name?: AnimateType) => {
  if (!name) {
    return '';
  }
  return {
    beat: 'fa-beat',
    'beat-fade': 'fa-beat-fade',
    bounce: 'fa-bounce',
    fade: 'fa-fade',
    flip: 'fa-flip',
    shake: 'fa-shake',
    spin: 'fa-spin',
    'spin-reverse': 'fa-spin fa-spin-reverse',
    'spin-pulse': 'fa-spin-pulse',
  }[name];
};

const Icon = (
  props: (IconKitProps | IconBrandsProps | IconClassicProps | IconSharpProps) &
    typeof defaultProps,
) => {
  const { family, weight, name, size, color, animate, fw, ...rest } = props;
  const theme = useTheme();
  const colorScheme = useColorScheme();

  const preparedColor = useMemo(() => {
    if (typeof color === 'undefined') {
      return undefined;
    }
    if (color === 'current') {
      return 'var(--Icon-color)';
    }
    if (
      !['primary', 'secondary', 'tertiary', 'icon', 'inherit'].includes(color)
    ) {
      return color;
    }
    return color === 'inherit'
      ? 'inherit'
      : theme.palette.text[
          color as 'primary' | 'secondary' | 'tertiary' | 'icon'
        ];
  }, [color, colorScheme]);

  const classes = useStyles({
    color: preparedColor,
    fw,
  });

  return (
    <Box
      {...rest}
      component="i"
      className={cn(
        `fa-${family}`,
        `fa-${name}`,
        {
          [`fa-${weight || 'regular'}`]: family !== 'kit',
          [`fa-${size}`]: size !== 'default',
          [getAnimate(animate)]: !!animate,
          'fa-fw': fw && typeof fw === 'boolean',
        },
        classes.i,
      )}
    />
  );
};

Icon.defaultProps = defaultProps;

const useStyles = createUseStyles<
  'i',
  {
    color?: string;
    fw?: boolean | number;
  }
>({
  i: {
    lineHeight: 1,
    width: (props) =>
      typeof props.fw === 'number' ? `${props.fw}px` : undefined,
    color: (props) => props.color,
    textAlign: 'center',
  },
});
export default memo(Icon);
