import {
  cancel,
  put,
  type SagaReturnType,
  takeEvery,
} from 'redux-saga/effects';

import { call } from 'store/utils/saga/effects';

import network from 'lib/network';

import { actions } from '../slice';
import type { PlanType } from '../../types';

export const config = {
  action: [actions.loadPlans.type],
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.loadPlans>) {
  const { errors, data } = yield* call(() =>
    network
      .request<
        Omit<PlanType & { price_id: string }, 'planId'>[]
      >('/payment/subscription/plans', { baseUrl: process.env.PROTECTED_API_URL || '/api' })
      .get(),
  );

  if ((errors?.length || 0) > 0 || !data) {
    yield cancel();
    return;
  }

  yield put(
    actions.loadPlansDone({
      plans: data.map(({ price_id: planId, ...item }) => ({
        planId,
        ...item,
      })),
    }),
  );
}
