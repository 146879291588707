import { type SagaReturnType, takeEvery, put } from 'redux-saga/effects';
import { Box } from '@mui/joy';

import { store } from 'store';
import { call } from 'store/utils/saga/effects';
import * as messageStore from 'store/nodes/message';

import * as subscriptionStore from 'widgets/Subscription/store';
import UpgradeToProLink from 'components/UpgradeToProLink';
import Alert from 'components/Alert';

export const config = {
  action: [messageStore.socketActions.messageWarning.type],
  method: takeEvery,
};

export function* func(
  action: SagaReturnType<typeof messageStore.socketActions.messageWarning>,
) {
  const { id, message, context } = action.payload;

  if (
    [
      'Usage_limits_youtube',
      'Usage_limits_pins',
      'Usage_limits_copilot',
    ].includes(context.type)
  ) {
    yield put(messageStore.actions.prevent({ id }));
    yield call(() =>
      Alert.info(
        <Box>
          {message}
          <Box mt={1}>
            <UpgradeToProLink />
          </Box>
        </Box>,
        {
          autoClose: 10000,
          onClick: () => {
            store.dispatch(subscriptionStore.actions.showPricing());
          },
        },
      ),
    );
  }
}
