import {
  type PropsWithChildren,
  type ReactElement,
  memo,
  useEffect,
} from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  generatePath,
  useParams,
  useLocation,
  useNavigate,
} from 'react-router-dom';

type RouterProps = {
  onReady?: () => void;
};

export const Router = memo(
  (props: PropsWithChildren<RouterProps>): ReactElement => {
    const { children, onReady } = props;

    useEffect(() => {
      onReady?.();
    }, []);

    return <BrowserRouter>{children}</BrowserRouter>;
  },
);

export { Routes, Route, generatePath, useParams, useLocation, useNavigate };
