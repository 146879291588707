import { memo, type MouseEvent, useCallback } from 'react';
import { createUseStyles } from 'react-jss';
import moment from 'moment-timezone';

import useThemeColor from 'hooks/useThemeColor';
import useAuthor from 'hooks/useAuthor';

import AuthorBlock from 'components/AuthorBlock';
import Tags from 'components/Tags';

import type { MaterialType } from 'app/entities';

import { useSelector } from 'store/utils/redux/hooks';
import * as contentStore from 'store/nodes/content';

import { navigate } from 'navigation/methods';
import { Box, Typography } from '@mui/joy';
import CollectionBadge from '../panels/CollectionBadge';

type InfoType = {
  data: MaterialType;
};

const Info = (props: InfoType) => {
  const { data } = props;
  const handleCollectionPress = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const { id } = event.currentTarget.dataset;
      if (id) {
        navigate('Playlist', { resourceId: Number(id) });
      }
    },
    [],
  );
  const classes = useStyle();
  const color = useThemeColor({ dark: '#ffffff', light: '#000000' });

  const author = useAuthor(data?.authors);
  const collections = useSelector(
    contentStore.selectors.collectionsByMaterial(data.id),
  );
  const publishedAgo =
    typeof data?.publishedAt === 'string'
      ? moment(data.publishedAt).fromNow()
      : null;

  return (
    <Box>
      <Box className={classes.info}>
        {author && (
          <>
            <AuthorBlock data={author} />
          </>
        )}
        {author && data.source?.name && (
          <span style={{ color }}> &middot; </span>
        )}
        <Typography fontSize={16}>{data.source?.name}</Typography>
        {data.source?.name && data.duration && (
          <span style={{ color }}> &middot; </span>
        )}
        {data.source?.name && (
          <Typography fontSize={16}>{data.duration}</Typography>
        )}
        {(author || data.source?.name || data.duration) && publishedAgo && (
          <span style={{ color }}> &middot; </span>
        )}
        {publishedAgo && <Typography fontSize={16}>{publishedAgo}</Typography>}
      </Box>
      {data?.tags && (
        <Tags
          data={data}
          tagsLimit={6}
          sx={{ marginTop: '0.750em', marginBottom: '1.250em' }}
        />
      )}
      {collections.length > 0 && (
        <Box className={classes.collectionInfo}>
          <Typography fontSize={17}>In collections: </Typography>
          {collections.map((collection) => (
            <CollectionBadge
              key={collection.id}
              id={collection.id}
              onPress={handleCollectionPress}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default memo(Info);

const useStyle = createUseStyles<
  'info' | 'collectionInfo',
  {
    backgroundColorIcon?: string;
  }
>({
  info: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.325em',
    alignItems: 'center',
  },
  collectionInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '0.325em',
  },
});
