import { memo } from 'react';
import { StyleSheet } from 'react-native';

import { unit } from 'utils';
import { Text, View } from 'components/Themed';
import Button from 'components/Button';

type ModalToolbarProps = {
  title?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  onCancel?: () => void;
  onDone?: () => void;
};

const ModalToolbar = (props: ModalToolbarProps) => {
  const { title, isLoading, isDisabled, onCancel, onDone } = props;

  return (
    <View style={styles.ModalToolbar}>
      <Button
        type="button"
        variant="text"
        style={styles.button}
        onPress={onCancel}
      >
        <Text darkColor="#497CFF" lightColor="#497CFF" size={15} weight="semi">
          Cancel
        </Text>
      </Button>
      <Text size={20}>{title}</Text>
      <Button
        type="button"
        variant="text"
        style={styles.button}
        onPress={onDone}
        isLoading={isLoading}
        isDisabled={isDisabled}
      >
        <Text darkColor="#497CFF" lightColor="#497CFF" size={15} weight="semi">
          Done
        </Text>
      </Button>
    </View>
  );
};

const styles = StyleSheet.create({
  ModalToolbar: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  button: {
    width: unit(50),
  },
});

export default memo(ModalToolbar);
