import { memo } from 'react';
import { createUseStyles } from 'react-jss';

import { useThemeColor } from 'hooks';

import Text from './Text';

type AISummaryProps = {
  text?: string | null;
};

const AISummary = (props: AISummaryProps) => {
  const { text } = props;

  const backgroundColor = useThemeColor({
    light: '#00000010',
    dark: '#2C2C2C',
  });

  const classes = useStyle({
    backgroundColor,
  });

  if (!text) {
    return null;
  }

  return (
    <div className={classes.AISummary}>
      <Text
        darkColor="#B8B6BF"
        lightColor="#797979"
        style={{ marginBottom: '0.5em' }}
        size={12}
      >
        AI Summary
      </Text>
      <Text style={{ lineHeight: 1.5 }} numberOfLines={5} size={14}>
        {text}
      </Text>
    </div>
  );
};

const useStyle = createUseStyles<
  'AISummary' | 'loading' | 'wireframe',
  {
    backgroundColor?: string;
  }
>({
  AISummary: {
    display: 'flex',
    flexDirection: 'column',
  },
  wireframe: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0.625em',
    backgroundColor: (props) => props.backgroundColor,
  },
  loading: {
    marginBottom: '1.325em',
  },
});

export default memo(AISummary);
