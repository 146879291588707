import {
  type ReactElement,
  memo,
  useCallback,
  useMemo,
  type CSSProperties,
} from 'react';
import {
  Platform,
  ScrollView,
  type StyleProp,
  StyleSheet,
  TouchableOpacity,
  useWindowDimensions,
  type ViewStyle,
} from 'react-native';

import { useResponsive, useThemeColor } from 'hooks';

import { unit } from 'utils';
import { Text, View } from 'components/Themed';
import Icon from 'components/LegacyIcon';
import Button from 'components/Button';

import { useSelector } from 'store/utils/redux/hooks';
import * as userStore from 'store/nodes/user';

import Item from './elements/Item';

import { useScrollView } from '../../helpers';

export type PlaylistsProps = {
  header?: ReactElement;
  hasNewButton?: boolean;
  addingContentId?: number;
  onNewPress?: () => void;
  onItemPress?: (id: number) => void;
};

const Playlists = (props: PlaylistsProps) => {
  const { addingContentId, onNewPress, onItemPress, hasNewButton, header } =
    props;

  const dimension = useWindowDimensions();
  const responsive = useResponsive();

  const wrapperShadowColor = useThemeColor({
    light: '#0000004d',
    dark: '#00000099',
  });
  const borderBottomColor = useThemeColor({
    light: '#d2d2d2',
    dark: '#303030',
  });

  const maxHeight = responsive.isMoreThen.mobile
    ? dimension.height - 64
    : dimension.height;

  const wrapperStyles = useMemo(() => {
    let result: StyleProp<ViewStyle> = {
      ...StyleSheet.flatten(styles.Playlists),
      maxHeight,
    };
    if (responsive.isMoreThen.mobile) {
      result = {
        ...result,
        ...StyleSheet.flatten(styles.desktop),
      };
    }
    if (Platform.OS === 'web') {
      (result as CSSProperties).boxShadow =
        `0 0 ${unit(4)} #0000000d, 0 0 ${unit(48)} ${wrapperShadowColor}`;
    }
    return result;
  }, [responsive.isMoreThen.mobile, wrapperShadowColor, maxHeight]);

  const collections = useSelector(userStore.selectors.getCollections('my'));
  const { handleTouchStart, handleTouchMove, handleTouchEnd, handleOnScroll } =
    useScrollView();

  const handleNewPress = useCallback(() => {
    onNewPress?.();
  }, []);

  const handleItemPress = useCallback((playlistId: number) => {
    onItemPress?.(playlistId);
  }, []);

  return (
    <View
      style={wrapperStyles}
      lightColor="#e7e7e7"
      darkColor="#181818"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      pointerEvents="auto"
    >
      {!!header && header}
      {collections.length === 0 && (
        <View style={styles.empty}>
          <Text
            size={17}
            weight="semi"
            lightColor="#414141"
            darkColor="#d2d2d2"
          >
            No available collections
          </Text>
          <View style={styles.emptyNewContainer}>
            <Text
              size={15}
              weight="regular"
              lightColor="#797979"
              darkColor="#797979"
            >
              You can{' '}
            </Text>
            <Button
              type="button"
              variant="text"
              onPress={handleNewPress}
              style={styles.emptyNewButton}
            >
              <Text
                size={15}
                weight="semi"
                darkColor="#497CFF"
                lightColor="#497CFF"
              >
                create new collection
              </Text>
            </Button>
          </View>
        </View>
      )}
      {collections.length > 0 && hasNewButton && (
        <TouchableOpacity
          style={{
            flexDirection: 'row',
            paddingBottom: unit(16),
            borderBottomWidth: 1,
            borderBottomColor,
          }}
          onPress={handleNewPress}
          activeOpacity={0.8}
        >
          <View
            style={{
              width: unit(50),
              height: unit(50),
              borderRadius: unit(5),
              alignItems: 'center',
              justifyContent: 'center',
            }}
            darkColor="#232326"
            lightColor="#b0afaf"
          >
            <Icon
              name="AddOutline28"
              size={28}
              lightColor="#ffffff"
              darkColor="#ffffff"
            />
          </View>
          <View
            style={{
              flex: 1,
              marginHorizontal: unit(10),
              justifyContent: 'center',
            }}
          >
            <Text size={17} weight="medium">
              Create new collection
            </Text>
          </View>
        </TouchableOpacity>
      )}
      {collections.length > 0 && (
        <ScrollView
          style={styles.scrollView}
          scrollEventThrottle={16}
          onScroll={handleOnScroll}
        >
          {collections.map((playlist, key) => (
            <Item
              key={playlist.id}
              id={playlist.id}
              data={playlist}
              hasMaterial={playlist.materialsMetadata.some(
                (item) => item.id === addingContentId,
              )}
              isLast={key === collections.length - 1}
              onBodyPress={handleItemPress}
            />
          ))}
        </ScrollView>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  Playlists: {
    width: '100%',
    maxWidth: unit(560),
    alignSelf: 'center',
    paddingHorizontal: unit(16),
    paddingVertical: unit(40),
    borderTopLeftRadius: unit(24),
    borderTopRightRadius: unit(24),
  },
  desktop: {
    borderRadius: unit(24),
  },
  mobile: {},
  scrollView: {
    flex: 1,
    marginHorizontal: unit(-16),
    paddingHorizontal: unit(16),
  },
  empty: {
    height: unit(128),
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyNewContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  emptyNewButton: {
    paddingHorizontal: 0,
  },
});

export default memo(Playlists);
