import { memo, useEffect, useMemo, useRef } from 'react';
import {
  type StyleProp,
  type ViewStyle,
  Platform,
  StyleSheet,
  View,
} from 'react-native';
import { createUseStyles } from 'react-jss';
import Prism from 'ui/Prism';

import { unit } from 'utils';
import { useThemeColor } from 'hooks';

type HtmlReaderProps = {
  style?: StyleProp<ViewStyle>;
  html?: string;
};

const HtmlReader = (props: HtmlReaderProps) => {
  const { style } = props;

  const prismRef = useRef<any>();
  const baseTextColor = useThemeColor({ light: '#000000', dark: '#ffffff' });
  const preTextColor = useThemeColor({ light: '#333', dark: '#eee' });
  const preBackground = useThemeColor({ light: '#ffffff', dark: '#181818' });
  const blockquoteBorderColor = useThemeColor({
    light: '#f8bd00',
    dark: '#fff8b5',
  });
  const blockquoteTextColor = useThemeColor({
    light: 'rgba(0, 0, 0, 0.8)',
    dark: 'rgba(255, 255, 255, 0.8)',
  });
  const hrBorderColor = useThemeColor({ light: '#888888', dark: '#888888' });

  const classes = useStyles({
    baseTextColor,
    preTextColor,
    preBackground,
    blockquoteBorderColor,
    blockquoteTextColor,
    hrBorderColor,
  });

  const html = useMemo(() => {
    if (!props.html) {
      return null;
    }
    const codeBlocks: string[] = [];
    return props.html
      .replace(/<code[\s\S]*?<\/code>/g, (match) => {
        codeBlocks.push(match);
        return 'CODE_BLOCK_PLACEHOLDER';
      })
      .replace(/\sclass="[^"]*"/g, '')
      .replace(/<img([^>]*)>/g, (match, imgTagContent) => {
        const widthMatch = imgTagContent.match(/\swidth="(\d+)"/);
        const width = widthMatch ? parseInt(widthMatch[1], 10) : null;
        if (width && width > 480) {
          return `<img${imgTagContent.replace(/\swidth="[^"]*"|\sheight="[^"]*"/g, '')}>`;
        }
        return match;
      })
      .replace(/CODE_BLOCK_PLACEHOLDER/g, () => {
        return codeBlocks.shift() || '';
      });
  }, [props.html]);

  useEffect(() => {
    const { current: prism } = prismRef;
    if (!html?.length || !prism) {
      return;
    }
    prism.highlightAll();
  }, [html]);

  return (
    <View style={[style]}>
      <Prism ref={prismRef} />
      {!!html && Platform.OS === 'web' && (
        <div
          className={classes.html}
          dangerouslySetInnerHTML={{ __html: html || '' }}
        />
      )}
    </View>
  );
};

type htmlTheme = {
  baseTextColor?: string;
  preTextColor?: string;
  preBackground?: string;
  blockquoteBorderColor?: string;
  blockquoteTextColor?: string;
  hrBorderColor?: string;
};

const useStyles = createUseStyles<'html', htmlTheme>({
  html: {
    '&': {
      lineHeight: 1.5,
      color: (props) => props.baseTextColor,
    },
    '& a': {
      '&': {
        color: 'inherit',
        fontWeight: 500,
      },
      '&:hover': {
        textDecoration: 'none',
      },
    },
    '& h1': {
      fontWeight: 500,
      fontSize: unit(28),
      margin: '1em 0 0',
    },
    '& h2': {
      fontWeight: 500,
      fontSize: unit(24),
      margin: '1em 0 0',
    },
    '& h3': {
      fontWeight: 500,
      fontSize: unit(20),
      margin: '1em 0 0',
    },
    '& h4': {
      fontWeight: 500,
      fontSize: unit(18),
      margin: '1em 0 0',
    },
    '& h5': {
      fontWeight: 500,
      fontSize: unit(16),
      margin: '1em 0 0',
    },
    '& p': {
      margin: '1.25em 0 0',
    },
    '& strong, & b': {
      fontWeight: 500,
      margin: '1em 0 0',
    },
    '& img': {
      '&': {
        maxWidth: '100%',
        height: 'auto',
        borderRadius: '0.25em',
        margin: '1em 0 0',
      },
      '&:not([width])': {
        width: '100%',
      },
    },
    '& video': {
      maxWidth: '100%',
      height: 'auto',
      borderRadius: '0.25em',
      margin: '1em 0 0',
    },
    '& figure': {
      margin: 0,
    },
    '& blockquote': {
      '&': {
        fontSize: '1.125em',
        margin: '1em 0 0',
        paddingLeft: '1.5em',
        paddingTop: '0.5em',
        paddingBottom: '0.5em',
        borderLeft: (props) => `0.25em solid ${props.blockquoteBorderColor}`,
        color: (props) => props.blockquoteTextColor,
      },
      '& p': {
        margin: 0,
      },
    },
    '& hr': {
      border: 0,
      borderBottom: (props) => `1px solid ${props.hrBorderColor}`,
      marginTop: '3em',
      marginBottom: '3em',
    },
    '& pre': {
      '&': {
        position: 'relative',
        counterReset: 'line',
        padding: '0.5em',
        margin: '1em 0 0',
        fontSize: '0.875',
        lineHeight: '1.42857143',
        color: (props) => props.preTextColor,
        wordBreak: 'break-all',
        wordWrap: 'break-word',
        backgroundColor: (props) => props.preBackground,
        borderRadius: '0.2857em',
        // whiteSpace: 'pre',
        whiteSpace: 'normal',
        overflow: 'auto',
      },
      '& code': {
        padding: '0',
      },
      '& code a': {
        color: 'inherit',
      },
    },
    '& code': {
      backgroundColor: (props) => props.preBackground,
      borderRadius: '0.25em',
      padding: '0.25em 0.5em',
    },
    '& ul, & ol': {
      '&': {
        margin: 0,
      },
      '& li': {
        margin: '1em 0 0',
      },
    },
  },
});

const styles = StyleSheet.create({});

export default memo(HtmlReader);
