import { type DefaultRootState } from 'react-redux';

const map = {
  note: 'nt',
  material: 'mt',
} as const;

function makeId(payload: {
  collectionId: number;
  type: 'material' | 'note';
  id: number;
}) {
  const { collectionId, type, id } = payload;
  return `${collectionId}-${map[type]}-${id}`;
}

const isLoadingItem =
  (collectionId: number, type: keyof typeof map, id?: number | null) =>
  (state: DefaultRootState) => {
    return (
      Boolean(
        state.playlist.meta.loadingMap[
          makeId({ collectionId, type, id: id || 0 })
        ],
      ) || false
    );
  };

export default isLoadingItem;
