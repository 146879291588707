import React, { memo, useMemo } from 'react';
import { type StyleProp, type TextStyle, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';

import * as contentStore from 'store/nodes/content';
import { unit, guard } from 'utils';
import { useMaterialType } from 'hooks';
import { useStorageString } from 'lib/storage2';
import Box from '@mui/joy/Box';

import { Text, View } from 'components/Themed';
import PDFViewer from 'components/PDFViewer';
import VideoViewer from 'components/VideoViewer';
import HtmlViewer from 'components/HtmlViewer';
import ImageViewer from 'components/ImageViewer';

import Info from './Info';

type ContentPanelProps = {
  style?: StyleProp<TextStyle>;
  resourceId: number;
  hasTitle?: boolean;
};

const ContentPanel = (props: ContentPanelProps) => {
  const { style, resourceId, hasTitle } = props;

  const data = useSelector(contentStore.selectors.dataById(resourceId));

  const materialType = useMaterialType(data);

  const bodyRender = useMemo(() => {
    if (!guard.isLink(data?.originalUrl)) {
      return <Text size={15}>This material cannot be shown</Text>;
    }
    if (materialType === 'video') {
      return <VideoViewer src={data?.originalUrl} />;
    }
    if (materialType === 'pdf') {
      return <PDFViewer src={data?.originalUrl} limit={50} />;
    }
    if (materialType === 'image') {
      return <ImageViewer src={data?.originalUrl} />;
    }
    return (
      <HtmlViewer content={data?.content?.htmlMarkup || data?.originalUrl} />
    );
  }, [materialType]);

  const titleRender = useMemo(() => {
    if (hasTitle && materialType === 'image') {
      return (
        <Text style={styles.title} size={34} weight="medium" numberOfLines={1}>
          {data?.originalUrl?.replace(
            /.*?([^/]+\.(png|jpe?g|gif|webp|tiff?)).*/i,
            '$1',
          )}
        </Text>
      );
    }
    if (hasTitle && data?.title) {
      return (
        <Text style={styles.title} size={34} weight="medium">
          {data?.title}
        </Text>
      );
    }
    return null;
  }, [hasTitle, data?.title, materialType]);

  if (!data) {
    return null;
  }

  return (
    <View style={[styles.component, style]}>
      <Info data={data} />
      {titleRender}
      {bodyRender}
    </View>
  );
};

const styles = StyleSheet.create({
  component: {
    flex: 1,
    paddingBottom: 64,
  },
  title: {
    marginTop: unit(48),
    marginBottom: unit(16),
    overflowWrap: 'anywhere',
  },
  loading: {
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: unit(320),
  },
});

export default memo(ContentPanel);
