import { type DefaultRootState } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';

const selector = createSelector(
  (state: DefaultRootState) => state.search.filter.method,
  (state: DefaultRootState) => state.search.filter.typeEnabledIds.GlobalTitle,
  (state: DefaultRootState) =>
    state.search.filter.typeEnabledIds.GlobalFullText,
  (state: DefaultRootState) => state.search.filter.typeEnabledIds.LibraryTitle,
  (state: DefaultRootState) =>
    state.search.filter.typeEnabledIds.LibraryFullText,
  (state: DefaultRootState) => state.search.filter.typeEnabledIds.Web,
  (
    method,
    GlobalTitleIds,
    GlobalFullTextIds,
    LibraryTitleIds,
    LibraryFullTextIds,
    WebIds,
  ) => {
    if (method === 'GlobalTitle') {
      return GlobalTitleIds;
    }
    if (method === 'GlobalFullText') {
      return GlobalFullTextIds;
    }
    if (method === 'LibraryTitle') {
      return LibraryTitleIds;
    }
    if (method === 'LibraryFullText') {
      return LibraryFullTextIds;
    }
    if (method === 'Web') {
      return WebIds;
    }
    return [];
  },
);

export default (state: DefaultRootState) => selector(state);
