import { createAction } from '@reduxjs/toolkit';

export const reset = createAction('MENU/RESET');

export const setLeftBarWidth = createAction(
  'MENU/SET_LEFT_BAR_WIDTH',
  (value: number) => ({
    payload: {
      value,
    },
  }),
);

export const load = createAction('MENU/LOAD');

export const loadDone = createAction('MENU/LOAD_DONE');

export const setData = createAction(
  'MENU/SET_DATA',
  (data: { follow: number[]; newsTopics: string[] }) => ({
    payload: {
      data,
    },
  }),
);
