import { useColorScheme } from 'hooks';

const useThemeColor = (args: {
  light?: string;
  dark?: string;
}): string | undefined => {
  const theme = useColorScheme();

  if (typeof args === 'object' && 'dark' in args && 'light' in args) {
    return args[theme];
  }

  return undefined;
};

export default useThemeColor;
