import {
  type FC,
  type PropsWithChildren,
  type ReactElement,
  memo,
  useMemo,
} from 'react';
import { Box, useColorScheme, useTheme } from '@mui/joy';

type PanelProps = {
  variant?: 'plain' | 'solid' | 'flat';
  title?: ReactElement | null;
};

const Panel: FC<PropsWithChildren<PanelProps>> = (props) => {
  const { variant = 'plain', title, children } = props;

  const theme = useTheme();
  const { colorScheme } = useColorScheme();

  const bgColor = useMemo(() => {
    if (colorScheme === 'dark' && variant === 'solid') {
      return 'var(--joy-palette-neutral-100)';
    }
    if (colorScheme === 'dark' && variant === 'plain') {
      return 'var(--joy-palette-neutral-800)';
    }
    if (colorScheme === 'light' && variant === 'solid') {
      return 'var(--joy-palette-neutral-700)';
    }
    if (colorScheme === 'light' && variant === 'plain') {
      return 'var(--joy-palette-neutral-100)';
    }
    return 'transparent';
  }, [colorScheme, variant]);

  const color = useMemo(() => {
    if (variant === 'plain') {
      return theme.palette.text.primary;
    }
    if (variant === 'solid') {
      return theme.palette.background.body;
    }
    return 'transparent';
  }, [variant, theme]);

  const { px, py } = useMemo(() => {
    if (variant === 'solid') {
      return { px: 4, py: 6 };
    }
    if (variant === 'plain') {
      return { px: 4, py: 4 };
    }
    return { px: 4, py: 4 };
  }, [variant]);

  return (
    <Box
      bgcolor={bgColor}
      color={color}
      px={px}
      py={py}
      borderRadius="xl"
      flex={1}
      data-variant={variant}
      maxWidth="var(--panel-max-width, 400px)"
      sx={{
        ...(colorScheme === 'dark' && {
          '--joy-palette-text-primary': `var(${variant === 'solid' ? '--joy-palette-neutral-800' : '--joy-palette-neutral-100'})`,
          '--joy-palette-text-primary-invert': `var(${variant === 'solid' ? '--joy-palette-neutral-100' : '--joy-palette-neutral-800'})`,
          '--joy-palette-text-tertiary': `var(${variant === 'solid' ? '--joy-palette-neutral-500' : '--joy-palette-neutral-500'})`,
          '--Icon-color': `var(${variant === 'solid' ? '--joy-palette-neutral-500' : '--joy-palette-neutral-600'})`,
        }),
        ...(colorScheme === 'light' && {
          '--joy-palette-text-primary': `var(${variant === 'solid' ? '--joy-palette-neutral-100' : '--joy-palette-neutral-800'})`,
          '--joy-palette-text-primary-invert': `var(${variant === 'solid' ? '--joy-palette-neutral-800' : '--joy-palette-neutral-100'})`,
          '--joy-palette-text-tertiary': `var(${variant === 'solid' ? '--joy-palette-neutral-500' : '--joy-palette-neutral-500'})`,
          '--Icon-color': `var(${variant === 'solid' ? '--joy-palette-neutral-500' : '--joy-palette-neutral-400'})`,
        }),
      }}
    >
      {title}
      {children}
    </Box>
  );
};

export default memo(Panel);
