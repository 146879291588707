import { memo, useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { Box } from '@mui/joy';

import stripTags from 'utils/stripTags';
import EditorInput, { type EditorInputMethodsType } from 'ui/EditorInput';

import Placeholder from './elements/Placeholder';

type DataInputProps = {
  onChange?: (state: {
    html: string;
    text: string;
    hasContent: boolean;
  }) => void;
  onCtrlEnter?: () => void;
  onUploadPress?: () => void;
};

const DataInput = (props: DataInputProps) => {
  const { onChange, onCtrlEnter, onUploadPress } = props;

  const [value, setValue] = useState<string | null>(null);
  const editorRef = useRef<EditorInputMethodsType | null>(null);
  const getState = useCallback(() => {
    const text = stripTags(value);
    return {
      html: value || '',
      text: text || '',
      hasContent: !!text,
    };
  }, [value]);

  const state = useMemo(() => getState(), [getState]);

  useEffect(() => {
    onChange?.(getState());
  }, [getState, onChange]);

  useEffect(() => {
    editorRef.current?.focus();
  }, []);

  const handleCLick = useCallback(() => {
    editorRef.current?.focus();
  }, []);

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: '0.0625em',
        borderRadius: '0.625em',
        minHeight: '10em',
        overflow: 'auto',
        maxHeight: '360px',
        lineHeight: 1.4,
        cursor: 'text',
        backgroundColor: (theme) => theme.palette.background.body,
      }}
      component="div"
      onClick={handleCLick}
    >
      <Box flex={1} alignSelf="stretch" m="0.5em">
        <EditorInput ref={editorRef} onChange={setValue} />
      </Box>
      {!state.hasContent && <Placeholder onUploadPress={onUploadPress} />}
    </Box>
  );
};

export default memo(DataInput);
