import type { ResultLegacy } from 'app/entities';
import request from 'services/api/request';

interface Item {
  id: number;
  text?: string;
  image?: string;
}

export default async (ids: number[]): Promise<ResultLegacy<Item[]>> => {
  const query: Record<string, any> = {
    id: ids.join(','),
  };
  const { data, error } = await request.get<Item[]>('/content/source', {
    query,
  });

  if (error || !data) {
    return {
      data: null,
      error,
    };
  }

  return {
    data,
    error,
  };
};
