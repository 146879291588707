import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  isValidElement,
  cloneElement,
} from 'react';
import Prism from 'prismjs';
import {
  Box,
  ButtonGroup,
  Card,
  CardActions,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/joy';
import { useCopyToClipboard } from 'usehooks-ts';

import Lottie from 'components/Lottie';
import Alert from 'components/Alert';
import Icon from 'ui/Icon';

import { useSelector } from 'store/utils/redux/hooks';
import * as copilotStore from '../store';
import type { ItemAttribute, AnswerType, ItemId } from '../store/types';

import useAnswerData from '../model/useAnswerData';
import useConfig from '../model/useConfig';

import RenderHtml from './RenderHtml';
import SourceCard from './SourceCard';
import Limit from './Limit';

type AnswerProps = {
  id: ItemId;
  attribute: ItemAttribute;
  onMount?: () => void;
  onReload?: (requestId: string) => void;
  onSave?: (requestId: string) => void;
  onDone?: () => void;
  onAppend?: () => void;
};

const Answer = (props: AnswerProps) => {
  const { id, attribute, onMount, onReload, onSave, onDone, onAppend } = props;

  const mountRef = useRef(false);
  const data = useSelector(copilotStore.selectors.dataById<AnswerType>(id));
  const config = useConfig();

  useEffect(() => {
    if (!mountRef.current) {
      mountRef.current = true;
      onMount?.();
    }
    if (data.payload.status === 'done') {
      onDone?.();
    }
    if (data.payload.status !== 'done') {
      onAppend?.();
    }
  }, [data.payload.text, data.payload.status, onMount, onAppend, onDone]);

  useEffect(() => {
    if (data.payload.status !== 'done') {
      return;
    }
    try {
      Prism.highlightAll();
    } catch (error) {
      console.error('Prism.highlightAll()::', error);
    }
  }, [data.payload.status]);

  const { html } = useAnswerData(data.payload?.text || '', data.payload.status);
  const [_, copy] = useCopyToClipboard();

  const handleReload = useCallback(() => {
    onReload?.(data.attributes.requestId);
  }, [onReload, data.attributes.requestId]);

  const handleConvertToNote = useCallback(() => {
    onSave?.(data.attributes.requestId);
  }, [onSave, data.attributes.requestId]);

  const handleCopyToClipboard = useCallback(() => {
    copy(data.payload?.text)
      .then(() => {
        Alert.success('The answer copied to clipboard');
      })
      .catch((error) => {
        Alert.error("The answer didn't copy to clipboard");
      });
  }, [data.payload?.text]);

  const isLimit = html.includes(
    '<p>You&#39;ve run out of your limit for co-pilot requests</p>',
  );

  const actionsBarRender = useMemo(() => {
    return config?.answer?.actionsBar?.map((actionComponent, key) => (
      <Fragment key={key}>
        {isValidElement(actionComponent) &&
          typeof actionComponent !== 'string' && (
            <>
              {cloneElement(actionComponent, {
                'data-id': id,
              } as any)}
            </>
          )}
        {actionComponent === 'Reload' && (
          <Tooltip
            key={key}
            arrow
            variant="outlined"
            size="sm"
            title="Reload"
            disableInteractive
          >
            <IconButton onClick={handleReload}>
              <Icon name="rotate-right" weight="regular" color="inherit" />
            </IconButton>
          </Tooltip>
        )}
        {actionComponent === 'ToClipboard' && (
          <Tooltip
            arrow
            variant="outlined"
            size="sm"
            title="To clipboard"
            disableInteractive
          >
            <IconButton onClick={handleCopyToClipboard}>
              <Icon name="clipboard" weight="regular" color="inherit" />
            </IconButton>
          </Tooltip>
        )}
        {actionComponent === 'ToNote' && (
          <Tooltip
            arrow
            variant="outlined"
            size="sm"
            title="To note"
            disableInteractive
          >
            <IconButton onClick={handleConvertToNote}>
              <Icon name="plus" weight="regular" color="inherit" />
            </IconButton>
          </Tooltip>
        )}
      </Fragment>
    ));
  }, [
    config?.answer?.actionsBar,
    handleReload,
    handleCopyToClipboard,
    handleConvertToNote,
    id,
  ]);

  return (
    <Card
      variant="soft"
      color="neutral"
      size="md"
      sx={{
        '--Card-radius': (theme) => theme.radius.xl,
        '--ref-background-color': 'var(--joy-palette-background-level2)',
        '--source-card-softHoverBg': 'var(--joy-palette-background-level1)',
        '--source-card-softActiveBg': 'var(--joy-palette-background-level2)',
        '--variant-softDisabledBg': 'var(--joy-palette-background-level1)',
        backgroundColor: 'transparent',
        transition: '300ms background-color ease',
        '@media (max-width: 767px)': {},
        '&:hover': {
          '--ref-background-color': 'var(--joy-palette-background-level3)',
          '--source-card-softHoverBg': 'var(--joy-palette-background-level2)',
          '--source-card-softActiveBg': 'var(--joy-palette-background-level3)',
          backgroundColor: (theme) => theme.palette.neutral.softBg,
          '@media (max-width: 767px)': {
            backgroundColor: 'transparent',
          },
        },
        '.MuiIconButton-root': {
          opacity: 0,
          transition: '300ms opacity ease',
          '@media (max-width: 767px)': {
            opacity: 1,
          },
        },
        '&:hover .MuiIconButton-root': {
          opacity: 1,
        },
      }}
    >
      {data.payload.status === 'waiting' && (
        <Typography fontSize={17}>waiting...</Typography>
      )}
      {isLimit && <Limit />}
      {!isLimit && (
        <RenderHtml applyReplace={data.payload.status === 'done'}>
          {html}
        </RenderHtml>
      )}
      {data.payload?.sources?.length > 0 && (
        <Box display="flex" gap={1} flexWrap="wrap" pt={0.5}>
          {data.payload.sources.map((item) => (
            <SourceCard
              key={`${item.type}:${item.id}`}
              type={item.type}
              id={item.id}
            />
          ))}
        </Box>
      )}
      <CardActions sx={{ minHeight: 36, '--Card-padding': 0 }}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap={1}
          mr="auto"
        >
          <Icon name="lightbulb-on" fw weight="light" color="icon" />
          <Typography fontSize={13} fontWeight={600}>
            {data.payload.status === 'typing'
              ? 'Copilot typing'
              : 'Copilot answer'}
          </Typography>
          {data.payload.status === 'typing' && (
            <div style={{ marginBottom: '-0.5em' }}>
              <Lottie name="Typing" width={24} loop />
            </div>
          )}
        </Box>
        {data.payload.status === 'done' && !isLimit && (
          <ButtonGroup
            variant="soft"
            color="neutral"
            spacing={1}
            sx={{
              '--ButtonGroup-radius': '50%',
              '--ButtonGroup-separatorSize': 0,
            }}
          >
            {actionsBarRender}
          </ButtonGroup>
        )}
      </CardActions>
    </Card>
  );
};

export default memo(Answer);
