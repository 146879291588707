export default <T extends object>(
  array: T[],
  oldIndex: number,
  newIndex: number,
): T[] => {
  const newArray = [...array];
  let oldIndexValue = oldIndex;
  let newIndexValue = newIndex;
  while (oldIndexValue < 0) {
    oldIndexValue += newArray.length;
  }
  while (newIndexValue < 0) {
    newIndexValue += newArray.length;
  }
  if (newIndexValue >= newArray.length) {
    let key = newIndexValue - newArray.length;
    // eslint-disable-next-line no-plusplus
    while (key-- + 1) {
      newArray.push(undefined as unknown as T);
    }
  }
  newArray.splice(newIndexValue, 0, newArray.splice(oldIndexValue, 1)[0]);
  return newArray;
};
